const statuses = [
  'AL.success.heartbeatConnect',
  'AL.success.mqttConnect',
  'AL.success.mqttDisconnect',
  'AL.success.subscribeState',
  'AL.success.readBinLevels',
  'Backend.success.heartbeatConnect',
  'Backend.success.mqttConnect',
  'Backend.success.mqttDisconnect',
  'Backend.success.subscribeState',
  'Backend.success.subscribeStateChangeRequest',
  'Backend.success.deviceSignedIn',
  'Backend.success.deviceSignedOut',
  'Backend.success.registerDevice',
  'Backend.success.sendHeartbeat',
  'Backend.success.sendBugReport',
  'Backend.success.sendInfo',
  'Backend.success.readCredentials',
  'Backend.success.readSerialNo',
  'Backend.success.getConfigFile',
  'Backend.success.lockDevice',
  'CB.success.heartbeatConnect',
  'CB.success.mqttConnect',
  'CB.success.mqttDisconnect',
  'CB.success.subscribeState',
  'CB.success.onPortOpen',
  'CB.success.onPortClose',
  'CB.success.lockDevice',
  'CB.success.cb.close.throwFlap',
  'CB.success.cb.close.dropFlap',
  'CB.success.cb.crush.bins2And3',
  'CB.success.cb.drop.bin1',
  'CB.success.cb.drop.bin2',
  'CB.success.cb.drop.bin3',
  'CB.success.cb.drop.bin4',
  'CB.success.cb.initialize.bine',
  'CB.success.cb.initialize.home',
  'CB.success.cb.level.bins',
  'CB.success.cb.open.throwFlap',
  'CB.success.cb.open.dropFlap',
  'CB.success.cb.state.switch',
  'CB.success.cb.state.initialization',
  'CB.success.cb.turnOff.bine',
  'CB.success.cb.turnOff.led',
  'CB.success.cb.turnOn.led',
  'CB.success.cb.recognize.waste',
  'app.success.heartbeatConnect',
  'app.success.mqttConnect',
  'app.success.subscribeState',
  'app.success.subscribeStateChangeRequest',
  'SSOT.success.allAppsInitialized',
  'SSOT.success.mqttClientOnMessage',
  'SSOT.success.mqttHeartbeatClientOnMessage',
  'SSOT.success.mqttConnect',
  'SSOT.success.mqttHeartbeatConnect',
  'SSOT.success.subscribeExternalRequest',
  'SSOT.success.subscribeStateChangeRequest',
  'SSOT.success.subscribeIntenalHeartbeat',
  'SSOT.success.quit',
  'SSOT.success.ping',
  'SSOT.success.isDeviceRegistered',
  'IR.success.heartbeatConnect',
  'IR.success.mqttConnect',
  'IR.success.mqttDisconnect',
  'IR.success.subscribeState',
  'IR.success.irRecognize',
  'AL.error.configFileCorrupted',
  'AL.error.heartbeatConnect',
  'AL.error.mqttConnect',
  'AL.error.mqttDisconnect',
  'AL.error.subscribeState',
  'Backend.error.configFileCorrupted',
  'Backend.error.createBineDirectory',
  'Backend.error.heartbeatConnect',
  'Backend.error.mqttConnect',
  'Backend.error.mqttDisconnect',
  'Backend.error.subscribeState',
  'Backend.error.subscribeStateChangeRequest',
  'Backend.error.deviceSignedIn',
  'Backend.error.deviceSignedOut',
  'Backend.error.registerDevice',
  'Backend.error.sendHeartbeat',
  'Backend.error.sendBugReport',
  'Backend.error.sendInfo',
  'Backend.error.readCredentials',
  'Backend.error.readSerialNo',
  'Backend.error.getConfigFile',
  'Backend.error.lockDevice',
  'CB.error.configFileCorrupted',
  'CB.error.heartbeatConnect',
  'CB.error.mqttConnect',
  'CB.error.mqttDisconnect',
  'CB.error.subscribeState',
  'CB.error.unsupportedOperation',
  'CB.error.portError',
  'CB.error.writeToPortError',
  'CB.error.lockDevice',
  'CB.error.timeout',
  'CB.error.breakExecution',
  'CB.error.sentRequestTimeout',
  'CB.error.awaitVariableChangeTimeout',
  'CB.error.requestDoesNotExist',
  'CB.error.poweroff',
  'CB.error.cb.crushDownTimeout',
  'CB.error.cb.crushFlapsDownTimeout',
  'CB.error.cb.crushFlapsUpTimeout',
  'CB.error.cb.crushUpTimeout',
  'CB.error.cb.dropFlapCloseTimeout',
  'CB.error.cb.dropFlapOpenTimeout',
  'CB.error.cb.frontCoverOpen',
  'CB.error.cb.initializeBine',
  'CB.error.cb.missingBin1',
  'CB.error.cb.missingBin2',
  'CB.error.cb.missingBin3',
  'CB.error.cb.missingBin4',
  'CB.error.cb.recognize.waste',
  'CB.error.cb.throwFlapBoltCloseTimeout',
  'CB.error.cb.throwFlapBoltOpenTimeout',
  'CB.error.cb.throwFlapOpen',
  'CB.error.cb.topCoverOpen',
  'CB.error.cb.trolleyMoveTimeout',
  'CB.error.cb.unsupportedResponse',
  'app.error.heartbeatConnect',
  'app.error.mqttConnect',
  'app.error.subscribeState',
  'app.error.subscribeStateChangeRequest',
  'SSOT.error.configFileCorrupted',
  'SSOT.error.mqttClientOnMessage',
  'SSOT.error.mqttHeartbeatClientOnMessage',
  'SSOT.error.mqttConnect',
  'SSOT.error.mqttHeartbeatConnect',
  'SSOT.error.subscribeExternalRequest',
  'SSOT.error.subscribeStateChangeRequest',
  'SSOT.error.subscribeIntenalHeartbeat',
  'SSOT.error.incorrectMessageStructure',
  'SSOT.error.timeout',
  'SSOT.error.quit',
  'IR.error.cameraError',
  'AL.info.deviceNotRegistered',
  'CB.info.configFileCorrupted',
  'CB.info.bineIsFull',
  'CB.info.cb.initializeHome',
  'CB.info.cb.initializeBine',
  'CB.info.cb.dropBin1',
  'CB.info.cb.dropBin2',
  'CB.info.cb.dropBin3',
  'CB.info.cb.dropBin4',
  'CB.info.cb.bin2LevelInProgress',
  'CB.info.cb.frontCoverOpen',
  'CB.info.cb.frontCoverClosed',
  'CB.info.cb.topCoverOpen',
  'CB.info.cb.topCoverClosed',
  'CB.info.cb.throwFlapOpen',
  'CB.info.cb.throwFlapClosed',
  'CB.info.cb.dropFlapOpen',
  'CB.info.cb.dropFlapClosed',
  'CB.info.cb.compressionInProgress',
  'SSOT.info.ready',
  'SSOT.info.progress',
  'IR.info.cameraMissing',
  'IR.info.ready'
];

export default statuses;
