import moment from 'moment';

/*
// ACTION NOTES MANAGE INIT
// Init manage notes
*/
export const manageNotesInit = data => {
  return dispatch => {
    dispatch({ type: 'NOTES_MANAGE_INIT', data });
  };
};

/*
// ACTION NOTES REMOVE
// Remove note
*/
export const removeNotes = id => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'NOTES_REMOVE_PENDING' });
    const firestore = getFirestore();
    return await firestore
      .collection('notes')
      .doc(id)
      .delete()
      .then(() => dispatch({ type: 'NOTES_REMOVE_SUCCESS' }))
      .catch(err => dispatch({ type: 'NOTES_REMOVE_ERROR', err }));
  };
};

/*
// ACTION NOTES ADD
// Add note
*/
export const addNotes = data => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'NOTES_ADD_PENDING' });
    const firestore = getFirestore();
    const deviceRef = await firestore.collection('devices').doc(data.bine);
    return await firestore
      .collection('notes')
      .add({
        ...data.notes,
        bine: deviceRef,
        createdAt: moment()
          .utc()
          .format('YYYY-MM-DDTHH:mm:ssZ'),
        updatedAt: moment()
          .utc()
          .format('YYYY-MM-DDTHH:mm:ssZ')
      })
      .then(() => dispatch({ type: 'NOTES_ADD_SUCCESS' }))
      .catch(err => dispatch({ type: 'NOTES_ADD_ERROR', err }));
  };
};
/*
// ACTION NOTES EDIT
// Edit note
*/
export const editNotes = (id, data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'NOTES_EDIT_PENDING' });
    const firestore = getFirestore();
    const deviceRef = await firestore.collection('devices').doc(data.bine);
    return await firestore
      .collection('notes')
      .doc(id)
      .update({
        ...data.notes,
        bine: deviceRef,
        updatedAt: moment()
          .utc()
          .format('YYYY-MM-DDTHH:mm:ssZ')
      })
      .then(() => dispatch({ type: 'NOTES_EDIT_SUCCESS' }))
      .catch(err => dispatch({ type: 'NOTES_EDIT_ERROR', err }));
  };
};

/*
// ACTION NOTES LIMIT
// Set notes limit
*/
export const setNotesLimit = limit => {
  return dispatch => {
    dispatch({ type: 'NOTES_SET_LIMIT', limit });
  };
};
