import styled from 'styled-components';

export const Item = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
`;

export const Orbholder = styled.div`
  position: relative;
`;

export const Orb = styled.div`
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  border-radius: 100%;
  border: 1px solid ${props => props.theme.border};
  background: ${props =>
    props.status === 'online'
      ? props.theme.backgroundOnOnline
      : props.status === 'away'
      ? props.theme.backgroundOnAway
      : props.theme.backgroundOnOffline};
  right: 0;
  bottom: 0;
`;

export const Imageholder = styled.div`
  display: flex;
  width: 46px;
  height: 46px;
  margin: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: ${props => props.theme.background};
  overflow: hidden;
`;

export const Image = styled.img`
  display: none;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;

  &[data-visible] {
    display: block;
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  padding-left: 15px;
  word-break: break-all;
  cursor: default;

  span {
    display: block;
  }
`;
