/*
//   STYLE-COMPONENT: INFORMATION
*/

import { css } from 'styled-components';
import { Media } from '../Mixins.style';

export const Information = css`
  display: block;
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
  cursor: default;

  ${Media.lg`
    padding: 0 20px 0;
  `}
`;
