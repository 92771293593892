import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useIntl } from 'react-intl';

import SuccessIcon from '../../assets/images/Success.svg';
import ErrorIcon from '../../assets/images/Error.svg';
import CloseIcon from '../../assets/images/Close.svg';

import { Theme } from '../../assets/Theme.style';

import {
  Wrapper,
  Holder,
  Modal,
  Close,
  Image,
  Title,
  Content,
  Subtitle,
  Box,
  Inputbox,
  Selectbox,
  Navigation,
  Button,
  Alertbox,
  Alert,
  AlertImage,
  AlertContent,
  AlertTitle
} from './CustomerManage.style';

import CountryAssign from '../CountryAssign/CountryAssign';
import DistributorAssign from '../DistributorAssign/DistributorAssign';
import ManagerAssign from '../ManagerAssign/ManagerAssign';

function CustomerManage({
  theme = {
    ...Theme.modal,
    alert: Theme.alert,
    button: Theme.button
  },
  store = {},
  onSubmit = () => {},
  handleClose = () => {},
  open = false,
  authRole = {},
  userDistributor = {}
}) {
  const intl = useIntl();

  const validate = values => {
    const errors = {};

    if (!values.name) errors.name = 'CUSTOMERS.MANAGE.VALIDATION.REQUIRED';
    if (!values.street) errors.street = 'CUSTOMERS.MANAGE.VALIDATION.REQUIRED';
    if (!values.city) errors.city = 'CUSTOMERS.MANAGE.VALIDATION.REQUIRED';
    if (!values.postalCode)
      errors.postalCode = 'CUSTOMERS.MANAGE.VALIDATION.REQUIRED';
    if (!values.country)
      errors.country = 'CUSTOMERS.MANAGE.VALIDATION.REQUIRED';
    if (!values.contactEmail)
      errors.contactEmail = 'CUSTOMERS.MANAGE.VALIDATION.REQUIRED';

    if (
      values.contactEmail &&
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        values.contactEmail
      )
    )
      errors.contactEmail =
        'CUSTOMERS.MANAGE.VALIDATION.INVALID_EMAIL_ADDRESS_FORMAT';

    if (
      values.contactNo &&
      !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/im.test(
        values.contactNo
      )
    )
      errors.contactNo =
        'CUSTOMERS.MANAGE.VALIDATION.INVALID_PHONE_NUMBER_FORMAT';

    return errors;
  };

  const WhenFieldChanges = ({ field, becomes, set, to }) => (
    <Field name={set} subscription={{}}>
      {({ input: { onChange } }) => (
        <OnChange name={field}>
          {() => {
            if (becomes) {
              onChange(to);
            }
          }}
        </OnChange>
      )}
    </Field>
  );

  return (
    <Wrapper>
      <Holder
        theme={theme.holder}
        data-open={open ? true : undefined}
        data-close={!open ? true : undefined}
      >
        <Modal
          theme={theme.content.default}
          data-open={open ? true : undefined}
          data-close={!open ? true : undefined}
        >
          <Close
            onClick={handleClose}
            title={intl.formatMessage({ id: 'CUSTOMERS.MANAGE.MENU.CLOSE' })}
          >
            <Image
              src={CloseIcon}
              alt={intl.formatMessage({ id: 'CUSTOMERS.MANAGE.MENU.CLOSE' })}
            />
          </Close>
          <Title>
            {store.customer && store.customer.id
              ? intl.formatMessage({
                  id: 'CUSTOMERS.MANAGE.EDIT_CUSTOMER'
                })
              : intl.formatMessage({
                  id: 'CUSTOMERS.MANAGE.ADD_NEW_CUSTOMER'
                })}
          </Title>
          <Content>
            <Form
              onSubmit={onSubmit}
              validate={validate}
              initialValues={{
                name: store.customer && store.customer.name,
                street: store.customer && store.customer.street,
                city: store.customer && store.customer.city,
                postalCode: store.customer && store.customer.postalCode,
                country: store.customer && store.customer.country,
                contactEmail: store.customer && store.customer.contactEmail,
                contactNo: store.customer && store.customer.contactNo,
                distributor:
                  (store.customer &&
                    store.customer.distributor &&
                    store.customer.distributor.id) ||
                  (authRole &&
                    authRole.privileges &&
                    authRole.privileges[
                      'MANAGE_CUSTOMERS_SET_SELF_DISTRIBUTOR'
                    ] &&
                    userDistributor &&
                    userDistributor.id),
                manager:
                  store.customer &&
                  store.customer.manager &&
                  store.customer.manager.id
              }}
              mutators={{}}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                submitting,
                pristine,
                values,
                invalid
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box>
                    <Field name='name'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'CUSTOMERS.MANAGE.CUSTOMER_NAME'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.inputbox.default}
                        >
                          <input
                            {...input}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'CUSTOMERS.MANAGE.CUSTOMER_NAME'
                            })}
                            autoComplete='off'
                            data-big={true}
                            data-invalid={
                              meta.touched && meta.error ? true : undefined
                            }
                            data-valid={
                              meta.touched && !meta.error ? true : undefined
                            }
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Subtitle>
                      {intl.formatMessage({
                        id: 'CUSTOMERS.MANAGE.LOCATION'
                      })}
                    </Subtitle>
                    <Field name='street'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'CUSTOMERS.MANAGE.LOCATION.STREET'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.inputbox.default}
                        >
                          <input
                            {...input}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'CUSTOMERS.MANAGE.LOCATION.STREET'
                            })}
                            autoComplete='off'
                            data-invalid={
                              meta.touched && meta.error ? true : undefined
                            }
                            data-valid={
                              meta.touched && !meta.error ? true : undefined
                            }
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                    <Field name='city'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'CUSTOMERS.MANAGE.LOCATION.CITY'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.inputbox.default}
                        >
                          <input
                            {...input}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'CUSTOMERS.MANAGE.LOCATION.CITY'
                            })}
                            autoComplete='off'
                            data-invalid={
                              meta.touched && meta.error ? true : undefined
                            }
                            data-valid={
                              meta.touched && !meta.error ? true : undefined
                            }
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                    <Field name='postalCode'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'CUSTOMERS.MANAGE.LOCATION.POSTAL_CODE'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.inputbox.default}
                        >
                          <input
                            {...input}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'CUSTOMERS.MANAGE.LOCATION.POSTAL_CODE'
                            })}
                            autoComplete='off'
                            data-invalid={
                              meta.touched && meta.error ? true : undefined
                            }
                            data-valid={
                              meta.touched && !meta.error ? true : undefined
                            }
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                    <Field name='country'>
                      {({ input, meta }) => (
                        <Selectbox
                          data-name={intl.formatMessage({
                            id: 'CUSTOMERS.MANAGE.LOCATION.COUNTRY'
                          })}
                          data-filled={
                            input && input.value && input.value.length > 0
                              ? true
                              : undefined
                          }
                          theme={theme.selectbox.default}
                        >
                          <CountryAssign input={input} />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Selectbox>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Subtitle>
                      {intl.formatMessage({
                        id: 'CUSTOMERS.MANAGE.ALLOCATION'
                      })}
                    </Subtitle>
                    <Field name='distributor'>
                      {({ input, meta }) => {
                        return authRole &&
                          authRole.privileges &&
                          authRole.privileges[
                            'MANAGE_CUSTOMERS_DISTRIBUTOR'
                          ] ? (
                          <Selectbox
                            data-name={intl.formatMessage({
                              id: 'CUSTOMERS.MANAGE.ALLOCATION.DISTRIBUTOR'
                            })}
                            data-filled={
                              input && input.value && input.value.length > 0
                                ? true
                                : undefined
                            }
                            theme={theme.selectbox.default}
                          >
                            <DistributorAssign input={input} />
                            <span />
                          </Selectbox>
                        ) : (
                          <input
                            {...input}
                            type='hidden'
                            value={input.value}
                            disabled={true}
                          />
                        );
                      }}
                    </Field>
                    <WhenFieldChanges
                      field='distributor'
                      becomes={values.currency !== null}
                      set='manager'
                      to={''}
                    />
                    <Field name='manager'>
                      {({ input, meta }) => (
                        <Selectbox
                          data-name={intl.formatMessage({
                            id: 'CUSTOMERS.MANAGE.ALLOCATION.ACCOUNT_MANAGER'
                          })}
                          data-filled={
                            input && input.value && input.value.length > 0
                              ? true
                              : undefined
                          }
                          theme={theme.selectbox.default}
                        >
                          <ManagerAssign
                            input={input}
                            distributor={values.distributor}
                          />
                          <span />
                        </Selectbox>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Subtitle>
                      {intl.formatMessage({
                        id: 'CUSTOMERS.MANAGE.CONTACT'
                      })}
                    </Subtitle>
                    <Field name='contactEmail'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'CUSTOMERS.MANAGE.CONTACT.EMAIL_ADDRESS'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.inputbox.default}
                        >
                          <input
                            {...input}
                            type='email'
                            placeholder={intl.formatMessage({
                              id: 'CUSTOMERS.MANAGE.CONTACT.EMAIL_ADDRESS'
                            })}
                            autoComplete='off'
                            data-invalid={
                              meta.touched && meta.error ? true : undefined
                            }
                            data-valid={
                              meta.touched && !meta.error ? true : undefined
                            }
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                    <Field name='contactNo'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'CUSTOMERS.MANAGE.CONTACT.PHONE_NUMBER'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.inputbox.default}
                        >
                          <input
                            {...input}
                            type='tel'
                            placeholder={intl.formatMessage({
                              id: 'CUSTOMERS.MANAGE.CONTACT.PHONE_NUMBER'
                            })}
                            autoComplete='off'
                            data-invalid={
                              meta.touched && meta.error ? true : undefined
                            }
                            data-valid={
                              meta.touched && !meta.error ? true : undefined
                            }
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                  </Box>

                  {store.isError ? (
                    <Alertbox>
                      <Alert>
                        <AlertImage
                          src={ErrorIcon}
                          alt={intl.formatMessage({
                            id: 'CUSTOMERS.MANAGE.ALERT.SOMETHING_WENT_WRONG'
                          })}
                        />
                        <AlertContent>
                          <AlertTitle theme={theme.alert.error.title}>
                            {intl.formatMessage({
                              id: 'CUSTOMERS.MANAGE.ALERT.SOMETHING_WENT_WRONG'
                            })}
                          </AlertTitle>
                          {store.error}
                        </AlertContent>
                      </Alert>
                    </Alertbox>
                  ) : (
                    store.isSuccess && (
                      <Alertbox>
                        <Alert>
                          <AlertImage
                            src={SuccessIcon}
                            alt={intl.formatMessage({
                              id: 'CUSTOMERS.MANAGE.ALERT.SUCCESS'
                            })}
                          />
                          <AlertContent>
                            <AlertTitle theme={theme.alert.success.title}>
                              {intl.formatMessage({
                                id: 'CUSTOMERS.MANAGE.ALERT.SUCCESS'
                              })}
                            </AlertTitle>{' '}
                            {store.customer && store.customer.id
                              ? intl.formatMessage({
                                  id: 'CUSTOMERS.MANAGE.ALERT.CUSTOMER_UPDATED'
                                })
                              : intl.formatMessage({
                                  id: 'CUSTOMERS.MANAGE.ALERT.CUSTOMER_CREATED'
                                })}
                          </AlertContent>
                        </Alert>
                      </Alertbox>
                    )
                  )}

                  <Navigation>
                    <Button
                      theme={theme.button.default}
                      disabled={
                        submitting ||
                        pristine ||
                        invalid ||
                        (store && store.isLoading)
                      }
                    >
                      {store && store.isLoading
                        ? intl.formatMessage({
                            id: 'CUSTOMERS.MANAGE.MENU.PLEASE_WAIT'
                          })
                        : store.customer && store.customer.id
                        ? intl.formatMessage({
                            id: 'CUSTOMERS.MANAGE.MENU.EDIT_CUSTOMER'
                          })
                        : intl.formatMessage({
                            id: 'CUSTOMERS.MANAGE.MENU.ADD_CUSTOMER'
                          })}
                    </Button>
                    <Button
                      type='button'
                      theme={theme.button.alternative}
                      onClick={handleClose}
                    >
                      {intl.formatMessage({
                        id: 'CUSTOMERS.MANAGE.MENU.CANCEL'
                      })}
                    </Button>
                  </Navigation>
                </form>
              )}
            />
          </Content>
        </Modal>
      </Holder>
    </Wrapper>
  );
}

CustomerManage.propTypes = {
  theme: PropTypes.object,
  store: PropTypes.object,
  onSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  authRole: PropTypes.object,
  userDistributor: PropTypes.object
};

export default CustomerManage;
