import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Icon from '../../assets/images/Location.svg';

import { Item, Image, Content } from './LocationInfo.style';

import { countries } from '../../utils/countries';

function LocationInfo({ data = {} }) {
  const intl = useIntl();
  const isLocation =
    data.street || data.postalCode || data.city || data.country || data.place;
  return (
    <>
      {isLocation && (
        <Item>
          <Image
            src={Icon}
            alt={intl.formatMessage({
              id: 'LOCATION_INFO.LOCATION'
            })}
          />
          <Content>
            {data.street && <span>{data.street}</span>}
            {data.city && (
              <span>
                {data.postalCode
                  ? `${data.postalCode}, ${data.city}`
                  : data.city}
              </span>
            )}
            {data.country && (
              <span>
                {intl.formatMessage({
                  id: `COUNTRY.COUNTRY.${(() => {
                    const country = countries.find(
                      country => country.id === data.country
                    );
                    return country && country.name;
                  })()}`,
                  defaultMessage: data.country
                })}
              </span>
            )}
            {data.place && <span>{data.place}</span>}
          </Content>
        </Item>
      )}
    </>
  );
}

LocationInfo.propTypes = {
  data: PropTypes.object
};

export default memo(LocationInfo);
