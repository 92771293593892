import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { injectIntl } from 'react-intl';

import CloseIcon from '../../assets/images/Close.svg';

import { Theme } from '../../assets/Theme.style';

import {
  Wrapper,
  Holder,
  Modal,
  Close,
  Image,
  Title,
  Content,
  PreviewBox,
  PreviewImageBox,
  PreviewImage,
  Navigation,
  Button
} from './PhotoCrop.style';

class PhotoCrop extends Component {
  state = {
    tempPhoto: null,
    blob: null
  };

  static propsTypes = {
    theme: PropTypes.object,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    crop: PropTypes.object,
    sourcePhoto: PropTypes.string,
    photoOptions: PropTypes.object,
    handlePhoto: PropTypes.func,
    handleCropChange: PropTypes.func
  };

  static defaultProps = {
    theme: {
      ...Theme.modal,
      alert: Theme.alert,
      button: Theme.button
    },
    open: false,
    handleClose: () => {},
    sourcePhoto: null,
    photoOptions: {},
    crop: {},
    handlePhoto: () => {},
    handleCropChange: () => {}
  };

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    const { photoOptions } = this.props;
    this.makeClientCrop(crop, photoOptions);
  };

  confirmPhoto = () => {
    const { tempPhoto, blob } = this.state;
    this.props.handlePhoto({ tempPhoto, blob });
  };

  async makeClientCrop(crop, settings) {
    if (this.imageRef && crop.width && crop.height) {
      const image = await this.getCroppedImg(
        this.imageRef,
        crop,
        'tempPhoto.jpg',
        settings
      );
      this.setState({ tempPhoto: image.photo, blob: image.blob });
    }
  }

  getCroppedImg(image, crop, fileName, settings) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = settings.width ? settings.width : crop.width;
    canvas.height = settings.height ? settings.height : crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      settings.width ? settings.width : crop.width,
      settings.height ? settings.height : crop.height
    );

    return new Promise(resolve => {
      const imageDataURL = canvas.toDataURL('image/jpeg');
      resolve({ photo: imageDataURL, blob: this.dataURLtoBlob(imageDataURL) });
    });
  }

  dataURLtoBlob(dataURL) {
    var arr = dataURL.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  render() {
    const { tempPhoto, blob } = this.state;
    const {
      theme,
      handleClose,
      open,
      sourcePhoto,
      crop,
      handleCropChange,
      intl
    } = this.props;
    return (
      <Wrapper>
        <Holder
          theme={theme.holder}
          data-open={open ? true : undefined}
          data-close={!open ? true : undefined}
        >
          <Modal
            theme={theme.content.default}
            data-open={open ? true : undefined}
            data-close={!open ? true : undefined}
          >
            <Close
              onClick={handleClose}
              title={intl.formatMessage({
                id: 'CROP_IMAGE.MENU.CLOSE'
              })}
            >
              <Image
                src={CloseIcon}
                alt={intl.formatMessage({
                  id: 'CROP_IMAGE.MENU.CLOSE'
                })}
              />
            </Close>
            <Title>
              {intl.formatMessage({
                id: 'CROP_IMAGE.CROP_IMAGE'
              })}
            </Title>
            <Content>
              <PreviewBox>
                {sourcePhoto && (
                  <ReactCrop
                    src={sourcePhoto}
                    crop={crop}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={handleCropChange}
                    keepSelection={true}
                  />
                )}

                {tempPhoto && (
                  <PreviewImageBox>
                    <PreviewImage
                      src={tempPhoto}
                      alt={intl.formatMessage({
                        id: 'CROP_IMAGE.MENU.PREVIEW'
                      })}
                    />
                  </PreviewImageBox>
                )}
              </PreviewBox>

              <Navigation>
                <Button
                  type='button'
                  theme={theme.button.default}
                  disabled={!tempPhoto || !blob}
                  onClick={this.confirmPhoto}
                >
                  {intl.formatMessage({
                    id: 'CROP_IMAGE.MENU.SET_PHOTO'
                  })}
                </Button>
                <Button
                  type='button'
                  theme={theme.button.alternative}
                  onClick={handleClose}
                >
                  {intl.formatMessage({
                    id: 'CROP_IMAGE.MENU.CANCEL'
                  })}
                </Button>
              </Navigation>
            </Content>
          </Modal>
        </Holder>
      </Wrapper>
    );
  }
}

export default injectIntl(PhotoCrop);
