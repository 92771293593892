import React from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import SuccessIcon from '../../assets/images/Success.svg';
import ErrorIcon from '../../assets/images/Error.svg';
import CloseIcon from '../../assets/images/Close.svg';

import { Theme } from '../../assets/Theme.style';

import {
  Wrapper,
  Holder,
  Modal,
  Close,
  Image,
  Title,
  Content,
  Navigation,
  Button,
  Alertbox,
  Alert,
  AlertImage,
  AlertContent,
  AlertTitle
} from './UserRemove.style';

function UserRemove({
  theme = {
    ...Theme.modal,
    alert: Theme.alert,
    button: Theme.button
  },
  store = {},
  onSubmit = () => {},
  handleClose = () => {},
  open = false
}) {
  const intl = useIntl();
  return (
    <Wrapper>
      <Holder
        theme={theme.holder}
        data-open={open ? true : undefined}
        data-close={!open ? true : undefined}
      >
        <Modal
          theme={theme.content.default}
          data-open={open ? true : undefined}
          data-close={!open ? true : undefined}
        >
          <Close
            onClick={handleClose}
            title={intl.formatMessage({ id: 'USERS.MANAGE.MENU.CLOSE' })}
          >
            <Image
              src={CloseIcon}
              alt={intl.formatMessage({ id: 'USERS.MANAGE.MENU.CLOSE' })}
            />
          </Close>
          <Title>
            {intl.formatMessage({ id: 'USERS.MANAGE.REMOVE_USER' })}
          </Title>
          <Content>
            <Form
              onSubmit={onSubmit}
              initialValues={{ ...store.notes }}
              render={({ handleSubmit, submitting, pristine, invalid }) => (
                <form onSubmit={handleSubmit}>
                  {intl.formatMessage({
                    id: 'USERS.MANAGE.REMOVE_USER.INFO'
                  })}
                  ?
                  {store.isError ? (
                    <Alertbox>
                      <Alert>
                        <AlertImage
                          src={ErrorIcon}
                          alt={intl.formatMessage({
                            id: 'USERS.MANAGE.ALERT.SOMETHING_WENT_WRONG'
                          })}
                        />
                        <AlertContent>
                          <AlertTitle theme={theme.alert.error.title}>
                            {intl.formatMessage({
                              id: 'USERS.MANAGE.ALERT.SOMETHING_WENT_WRONG'
                            })}
                          </AlertTitle>
                          {store.error}
                        </AlertContent>
                      </Alert>
                    </Alertbox>
                  ) : (
                    store.isSuccess && (
                      <Alertbox>
                        <Alert>
                          <AlertImage
                            src={SuccessIcon}
                            alt={intl.formatMessage({
                              id: 'USERS.MANAGE.ALERT.SUCCESS'
                            })}
                          />
                          <AlertContent>
                            <AlertTitle theme={theme.alert.success.title}>
                              {intl.formatMessage({
                                id: 'USERS.MANAGE.ALERT.SUCCESS'
                              })}
                            </AlertTitle>
                            {intl.formatMessage({
                              id: 'USERS.MANAGE.ALERT.USER_REMOVED'
                            })}
                          </AlertContent>
                        </Alert>
                      </Alertbox>
                    )
                  )}
                  <Navigation>
                    <Button
                      theme={theme.button.default}
                      disabled={
                        submitting || invalid || (store && store.isLoading)
                      }
                    >
                      {store && store.isLoading
                        ? intl.formatMessage({
                            id: 'USERS.MANAGE.MENU.PLEASE_WAIT'
                          })
                        : intl.formatMessage({
                            id: 'USERS.MANAGE.MENU.REMOVE_USER'
                          })}
                    </Button>
                    <Button
                      type='button'
                      theme={theme.button.alternative}
                      onClick={handleClose}
                    >
                      {intl.formatMessage({
                        id: 'USERS.MANAGE.MENU.CANCEL'
                      })}
                    </Button>
                  </Navigation>
                </form>
              )}
            />
          </Content>
        </Modal>
      </Holder>
    </Wrapper>
  );
}

UserRemove.propTypes = {
  theme: PropTypes.object,
  store: PropTypes.object,
  onSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool
};

export default UserRemove;
