import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import { withTheme } from '../../assets/Theme.style';
import { Section, Wrapper, Content, Footer } from './SignOutPage.style';

import { signOut } from '../../actions/Auth/AuthActions';

class SignOutPage extends Component {
  state = {};

  componentDidMount() {
    this.props.signOut();
  }

  render() {
    const { firebaseStore } = this.props;
    const theme = this.props.theme;

    return (
      <>
        <Helmet>
          <title>Sign out | BIN-E Smart Waste Bin</title>
        </Helmet>

        <Section>
          <Wrapper theme={theme.sign.wrapper.default}>
            <Content>Goodbye, {firebaseStore.auth.email}</Content>
            <Footer theme={theme.sign.footer.default}>
              By signing in, you accept{' '}
              <a
                href='http://www.bine.world'
                target='_blank'
                rel='noopener noreferrer'
              >
                Privacy policy
              </a>{' '}
              <br />
              (including Use of Cookies and Other
              <br /> Technologies) and{' '}
              <a
                href='http://www.bine.world'
                target='_blank'
                rel='noopener noreferrer'
              >
                Terms of use
              </a>
            </Footer>
          </Wrapper>
        </Section>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { firebaseStore: state.firebase };
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut())
  };
};

export default compose(
  withTheme,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SignOutPage);
