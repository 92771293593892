//TODO: NEED REFACTOR
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withTimeout from 'react-timeout';
import { injectIntl } from 'react-intl';

import LogoImage from '../../assets/images/Logo.svg';
import ProfileImage from '../../assets/images/ProfileImage.svg';

import ReportProblem from '../ReportProblem/ReportProblem';

import { Theme } from '../../assets/Theme.style';
import { Breakpoints } from '../../assets/Variables.style';
import {
  Headerbox,
  Hamburgerbox,
  Hamburger,
  HamburgerButton,
  Logobox,
  Menubox,
  MenuboxInner,
  Profilebox,
  Profile,
  ProfileButton,
  ProfileContent,
  InternalLink,
  ProfileTitle,
  ProfileOverlap
} from './Header.style';

import { sendReportInit, sendReport } from '../../actions/Report/ReportActions';

import { roles } from '../../utils/roles';

class Header extends Component {
  state = {
    visibleProfileMenu: false,
    openProfileMenu: false,
    width: window.innerWidth,
    visibleModalReport: false,
    openModalReport: false
  };

  static propsTypes = {
    theme: PropTypes.object,
    currentPath: PropTypes.string,
    profile: PropTypes.object,
    filter: PropTypes.bool,
    handleToggleFilter: PropTypes.func
  };

  static defaultProps = {
    theme: {
      background: Theme.header.background,
      border: Theme.header.border,
      hamburger: Theme.header.hamburger,
      menu: Theme.header.menu,
      menuInner: Theme.header.menuInner,
      profile: Theme.header.profile,
      modal: {
        ...Theme.modal,
        alert: Theme.alert,
        button: Theme.button
      }
    },
    currentPath: '/',
    profile: {},
    filter: false,
    handleToggleFilter: () => {}
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  toggleProfileMenu = () => {
    if (!this.state.openProfileMenu) {
      this.setState({
        visibleProfileMenu: true,
        openProfileMenu: !this.state.openProfileMenu
      });
    } else {
      this.setState({
        openProfileMenu: !this.state.openProfileMenu
      });
      this.props.setTimeout(() => {
        this.setState({
          visibleProfileMenu: false
        });
      }, 200);
    }
  };

  openModal = param => {
    if (param === 'report') this.props.sendReportInit();
    this.setState({
      visibleModalReport: param === 'report' ? true : false,
      openModalReport: param === 'report' ? true : false
    });
  };

  closeModal = () => {
    this.setState({
      openModalReport: false
    });
    this.props.setTimeout(() => {
      this.setState({
        visibleModalReport: false
      });
    }, 200);
  };

  handleSubmit = async data => {
    const { firebaseStore } = this.props;
    const { type, values } = data;

    switch (type) {
      case 'REPORT':
        await this.props.sendReport({
          profile: { uid: firebaseStore.auth.uid, ...firebaseStore.profile },
          ...values
        });
        break;
      default:
        return false;
    }
    return true;
  };

  render() {
    const { width, visibleModalReport, openModalReport } = this.state;
    const {
      theme,
      currentPath,
      filter,
      handleToggleFilter,
      firebaseStore,
      reportStore,
      profile,
      intl
    } = this.props;
    const isMobile = width <= Breakpoints.lg;
    const authRole = roles.find(el => {
      const profileRole =
        firebaseStore &&
        firebaseStore.profile &&
        firebaseStore.profile.role &&
        Object.entries(firebaseStore.profile.role).find(el => el[1] === true);
      return profileRole && el.id === profileRole[0];
    });

    return (
      <>
        <Headerbox theme={theme}>
          {isMobile && (
            <Hamburgerbox>
              {filter && (
                <Hamburger>
                  <HamburgerButton
                    theme={theme.hamburger.button}
                    onClick={handleToggleFilter}
                  >
                    <i>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='44'
                        height='44'
                        viewBox='0 0 44 44'
                      >
                        <g transform='translate(-5836 -138)'>
                          <rect
                            width='44'
                            height='44'
                            transform='translate(5836 138)'
                            fill='none'
                          />
                          <g transform='translate(40.5 -3.5)'>
                            <line
                              x2='23'
                              transform='translate(5806.5 163.5)'
                              fill='none'
                              strokeLinecap='round'
                              strokeWidth='3'
                            />
                            <line
                              x2='23'
                              transform='translate(5806.5 155.5)'
                              fill='none'
                              strokeLinecap='round'
                              strokeWidth='3'
                            />
                            <line
                              x2='23'
                              transform='translate(5806.5 171.5)'
                              fill='none'
                              strokeLinecap='round'
                              strokeWidth='3'
                            />
                          </g>
                        </g>
                      </svg>
                    </i>
                  </HamburgerButton>
                </Hamburger>
              )}
            </Hamburgerbox>
          )}
          <Logobox>
            <Link to='/'>
              <img src={LogoImage} alt='BIN-E Smart Waste Bin' />
            </Link>
          </Logobox>
          <Menubox theme={theme.menu}>
            <MenuboxInner theme={theme.menuInner}>
              {authRole &&
                authRole.privileges &&
                authRole.privileges['VIEW_FACTORIES'] && (
                  <Link
                    to='/factories'
                    data-active={
                      currentPath.startsWith('/factories') ? true : undefined
                    }
                  >
                    <i>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='34.121'
                        height='30.909'
                        viewBox='0 0 34.121 30.909'
                      >
                        <g
                          id='Group_2226'
                          data-name='Group 2226'
                          transform='translate(-796.914 -3041)'
                        >
                          <g
                            id='Group_2224'
                            data-name='Group 2224'
                            transform='translate(800.804 3041)'
                          >
                            <path
                              id='Path_1710'
                              data-name='Path 1710'
                              d='M78.55,77.037h3.59a1.922,1.922,0,0,0,1.922-1.922v-3.59A1.922,1.922,0,0,0,82.141,69.6H78.55a1.922,1.922,0,0,0-1.922,1.922v3.59A1.922,1.922,0,0,0,78.55,77.037Z'
                              transform='translate(-76.628 -60.325)'
                            />
                            <path
                              id='Path_1712'
                              data-name='Path 1712'
                              d='M111.52,77.037h3.591a1.922,1.922,0,0,0,1.922-1.922v-3.59A1.922,1.922,0,0,0,115.11,69.6H111.52a1.922,1.922,0,0,0-1.922,1.922v3.59A1.922,1.922,0,0,0,111.52,77.037Z'
                              transform='translate(-99.912 -60.325)'
                            />
                            <path
                              id='Path_1713'
                              data-name='Path 1713'
                              d='M111.52,45.6h3.591a1.922,1.922,0,0,0,1.922-1.922v-3.59a1.922,1.922,0,0,0-1.922-1.922H111.52a1.922,1.922,0,0,0-1.922,1.922v3.59A1.922,1.922,0,0,0,111.52,45.6Z'
                              transform='translate(-99.912 -38.166)'
                            />
                            <path
                              id='Path_1714'
                              data-name='Path 1714'
                              d='M144.49,77.037h3.591A1.922,1.922,0,0,0,150,75.115v-3.59A1.922,1.922,0,0,0,148.08,69.6H144.49a1.922,1.922,0,0,0-1.922,1.922v3.59A1.922,1.922,0,0,0,144.49,77.037Z'
                              transform='translate(-123.195 -60.325)'
                            />
                            <path
                              id='Path_1715'
                              data-name='Path 1715'
                              d='M144.49,45.6h3.591A1.922,1.922,0,0,0,150,43.678v-3.59a1.922,1.922,0,0,0-1.922-1.922H144.49a1.922,1.922,0,0,0-1.922,1.922v3.59A1.922,1.922,0,0,0,144.49,45.6Z'
                              transform='translate(-123.195 -38.166)'
                            />
                          </g>
                          <g
                            id='noun_production_2051410_000000'
                            transform='translate(796.914 3060.877)'
                          >
                            <path
                              id='Path_1716'
                              data-name='Path 1716'
                              d='M36.482,52.823H12.753a5.2,5.2,0,0,0-5.2,5.2v.64a5.2,5.2,0,0,0,5.2,5.2H36.482a5.2,5.2,0,0,0,5.2-5.2v-.64A5.2,5.2,0,0,0,36.482,52.823Zm-22.69,8.388a2.872,2.872,0,1,1,2.872-2.872A2.872,2.872,0,0,1,13.792,61.211Zm10.825,0a2.872,2.872,0,1,1,2.872-2.872A2.872,2.872,0,0,1,24.618,61.211Zm10.825,0a2.872,2.872,0,1,1,2.872-2.872A2.872,2.872,0,0,1,35.443,61.211Z'
                              transform='translate(-7.557 -52.823)'
                            />
                            <circle
                              id='Ellipse_107'
                              data-name='Ellipse 107'
                              cx='0.992'
                              cy='0.992'
                              r='0.992'
                              transform='translate(5.243 4.524)'
                            />
                            <circle
                              id='Ellipse_108'
                              data-name='Ellipse 108'
                              cx='0.992'
                              cy='0.992'
                              r='0.992'
                              transform='translate(16.069 4.524)'
                            />
                            <circle
                              id='Ellipse_109'
                              data-name='Ellipse 109'
                              cx='0.992'
                              cy='0.992'
                              r='0.992'
                              transform='translate(26.894 4.524)'
                            />
                          </g>
                        </g>
                      </svg>
                    </i>
                    <span>
                      {intl.formatMessage({ id: 'HEADER.FACTORIES' })}
                    </span>
                  </Link>
                )}
              {authRole &&
                authRole.privileges &&
                authRole.privileges['VIEW_DEVICES'] && (
                  <Link
                    to='/devices'
                    data-active={
                      currentPath === '/' || currentPath.startsWith('/devices')
                        ? true
                        : undefined
                    }
                  >
                    <i>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='30.233'
                        height='25.914'
                        viewBox='0 0 30.233 25.914'
                      >
                        <path
                          d='M9.44,966.362A1.387,1.387,0,0,0,8,967.7v17.479a1.387,1.387,0,0,0,1.44,1.338H36.793a1.387,1.387,0,0,0,1.44-1.338V967.7a1.387,1.387,0,0,0-1.44-1.338H9.44Zm13.317,4.679a3.984,3.984,0,0,1,3.959,3.6,3.619,3.619,0,0,1,0,7.2h-7.2a3.426,3.426,0,0,1-3.239-3.6,3.6,3.6,0,0,1,2.486-3.6A3.925,3.925,0,0,1,22.757,971.041Zm-2.16,16.2-1.44,2.879h7.918L26,987.237Zm-3.959,3.6a.72.72,0,0,0,0,1.44H29.6a.72.72,0,0,0,0-1.44H16.638Z'
                          transform='translate(-8 -966.362)'
                        />
                      </svg>
                    </i>
                    <span>{intl.formatMessage({ id: 'HEADER.DEVICES' })}</span>
                  </Link>
                )}
              {authRole &&
                authRole.privileges &&
                authRole.privileges['VIEW_DISTRIBUTORS'] && (
                  <Link
                    to='/distributors'
                    data-active={
                      currentPath.startsWith('/distributors') ? true : undefined
                    }
                  >
                    <i>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='25.437'
                        height='33.572'
                        viewBox='0 0 25.437 33.572'
                      >
                        <path
                          d='M22.486,15.4c-.545-2.093-1.034-2.506-.539-2.5a.6.6,0,0,1,.232.028V12.88s-1.466-4.892,2.06-7.043a1.611,1.611,0,0,1-.572-1.225s1.617.183,3.166.187a10.036,10.036,0,0,0,1.912-.141,2.738,2.738,0,0,1,.624-.055,3.408,3.408,0,0,1,3.095,2.266s.021,0,.061,0c.46,0,3.349.22,1.963,6.033l0,.022a1.1,1.1,0,0,1,.208-.024c.5-.006,1.012.4.466,2.5-.437,1.677-.841,2.109-1.141,2.108a.4.4,0,0,1-.263-.115,6.52,6.52,0,0,1-2.132,4.065,5.423,5.423,0,0,1-6.589.051,6.477,6.477,0,0,1-2.188-4.08.369.369,0,0,1-.226.079C22.32,17.5,22.919,17.063,22.486,15.4Zm5.873,22.693,2.329-2.554-1.7-7.513s2.712-3.726-.665-3.683c-3.377-.043-.665,3.683-.665,3.683l-1.7,7.513,2.329,2.554v.08l.037-.04.037.04Zm12.66-8.124c.1-1.357-.018-4.1-2.4-4.709a19.784,19.784,0,0,1-5.78-2.635L30.7,29.4l1.321,5.836a1.386,1.386,0,0,1,.027.164,22.441,22.441,0,0,0,8.706-2.782,10.168,10.168,0,0,0,.276-2.653ZM24.624,35.238,25.945,29.4l-.121-.383h0L23.8,22.626a19.767,19.767,0,0,1-5.78,2.635c-2.377.6-2.492,3.348-2.4,4.706A10.169,10.169,0,0,0,15.9,32.62,22.432,22.432,0,0,0,24.6,35.4,1.279,1.279,0,0,1,24.624,35.238Z'
                          transform='translate(-15.604 -4.602)'
                        />
                      </svg>
                    </i>
                    <span>
                      {intl.formatMessage({ id: 'HEADER.DISTRIBUTORS' })}
                    </span>
                  </Link>
                )}
              {authRole &&
                authRole.privileges &&
                authRole.privileges['VIEW_CUSTOMERS'] && (
                  <Link
                    to='/customers'
                    data-active={
                      currentPath.startsWith('/customers') ? true : undefined
                    }
                  >
                    <i>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='29.257'
                        height='28.342'
                        viewBox='0 0 29.257 28.342'
                      >
                        <path
                          d='M30.571,31.842a.686.686,0,0,0,.686-.686V29.785a.686.686,0,0,0-.686-.686H29.428V13.328a.686.686,0,0,0-.686-.686H23.028V4.186a.686.686,0,0,0-.686-.686H10.914a.686.686,0,0,0-.686.686V8.071H4.514a.686.686,0,0,0-.686.686V29.1H2.686A.686.686,0,0,0,2,29.785v1.371a.686.686,0,0,0,.686.686ZM9.314,27.271H6.571V24.528H9.314Zm0-4.571H6.571V19.957H9.314Zm0-4.571H6.571V15.386H9.314Zm0-4.571H6.571V10.814H9.314Zm6.4,13.714H12.971V24.528h2.743Zm0-4.571H12.971V19.957h2.743Zm0-4.571H12.971V15.386h2.743Zm0-4.571H12.971V10.814h2.743Zm0-4.571H12.971V6.243h2.743Zm4.571,18.285H17.543V24.528h2.743Zm0-4.571H17.543V19.957h2.743Zm0-4.571H17.543V15.386h2.743Zm0-4.571H17.543V10.814h2.743Zm0-4.571H17.543V6.243h2.743Zm6.4,18.285H23.943V24.528h2.743Zm0-4.571H23.943V19.957h2.743Zm0-4.571H23.943V15.386h2.743Z'
                          transform='translate(-2 -3.5)'
                        />
                      </svg>
                    </i>
                    <span>
                      {intl.formatMessage({ id: 'HEADER.CUSTOMERS' })}
                    </span>
                  </Link>
                )}
              {authRole &&
                authRole.privileges &&
                authRole.privileges['VIEW_USERS'] && (
                  <Link
                    to='/users'
                    data-active={
                      currentPath.startsWith('/users') ? true : undefined
                    }
                  >
                    <i>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='31.934'
                        height='21.932'
                        viewBox='0 0 31.934 21.932'
                      >
                        <g transform='translate(0 0)'>
                          <g transform='translate(0 0)'>
                            <path
                              d='M16.593,35.834c1.524,0,3.16-2.025,3.16-4.105a3.171,3.171,0,1,0-6.321,0C13.433,33.809,15.02,35.834,16.593,35.834Z'
                              transform='translate(-10.519 -25.08)'
                            />
                            <path
                              d='M72.017,35.834c1.524,0,3.16-2.025,3.16-4.105a3.171,3.171,0,1,0-6.321,0C68.857,33.809,70.444,35.834,72.017,35.834Z'
                              transform='translate(-46.159 -25.08)'
                            />
                            <path
                              d='M41.418,30.832c2.337,0,4.846-3.106,4.846-6.3s-2.17-5.258-4.846-5.258-4.846,2.067-4.846,5.258S39,30.832,41.418,30.832Z'
                              transform='translate(-25.398 -19.279)'
                            />
                            <path
                              d='M42.035,59.729a9.118,9.118,0,0,0-1.808-2.573c-.687-.715-2.739-1.722-4.064-1.279-1.056.231-1.106.84-2.8.84s-1.742-.608-2.8-.84c-1.324-.443-3.376.564-4.064,1.279A9.108,9.108,0,0,0,24.7,59.729c-.294.582-.906,2.28-.526,3.011.48.924,1.65,1.307,4.865,1.7a39.925,39.925,0,0,0,8.661,0c3.215-.393,4.384-.776,4.865-1.7C42.942,62.009,42.329,60.311,42.035,59.729Z'
                              transform='translate(-17.346 -42.743)'
                            />
                            <g transform='translate(21.741 11.713)'>
                              <path
                                d='M75.947,54.676A5.936,5.936,0,0,0,74.769,53a3.2,3.2,0,0,0-2.65-.834c-.69.151-.721.548-1.825.548s-1.136-.4-1.825-.548a3.028,3.028,0,0,0-2.293.546,6.121,6.121,0,0,1,1.992,1.264,10.312,10.312,0,0,1,2.012,2.86,8.081,8.081,0,0,1,.425,1.065c.519-.008,1.6-.039,2.512-.151,2.1-.256,2.86-.506,3.173-1.109C76.539,56.163,76.139,55.056,75.947,54.676Z'
                                transform='translate(-66.177 -52.093)'
                              />
                            </g>
                            <g transform='translate(0 11.713)'>
                              <path
                                d='M11.565,56.835a10.3,10.3,0,0,1,2.011-2.86A6.061,6.061,0,0,1,15.5,52.739a3.056,3.056,0,0,0-2.333-.574c-.689.151-.721.548-1.824.548s-1.136-.4-1.825-.548A3.2,3.2,0,0,0,6.87,53a5.953,5.953,0,0,0-1.179,1.678c-.191.379-.59,1.487-.343,1.963.313.6,1.076.852,3.172,1.109.981.12,2.148.146,2.615.152A8.671,8.671,0,0,1,11.565,56.835Z'
                                transform='translate(-5.269 -52.094)'
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </i>
                    <span>{intl.formatMessage({ id: 'HEADER.USERS' })}</span>
                  </Link>
                )}
            </MenuboxInner>
          </Menubox>
          <Profilebox>
            <Profile>
              <ProfileButton
                theme={theme.profile.button}
                onClick={this.toggleProfileMenu}
                title={
                  profile
                    ? profile.displayName
                      ? `${profile.displayName}, ${profile.email}`
                      : profile.email
                    : ''
                }
              >
                <img
                  src={
                    profile && profile.photoURL
                      ? profile.photoURL
                      : ProfileImage
                  }
                  alt={profile && profile.email}
                />
              </ProfileButton>
              {this.state.visibleProfileMenu ? (
                <ProfileContent
                  theme={theme.profile.content}
                  data-open={this.state.openProfileMenu ? true : undefined}
                  data-close={!this.state.openProfileMenu ? true : undefined}
                >
                  {!isMobile ? (
                    <div>
                      <Link
                        to='/profile-settings'
                        data-active={
                          currentPath.startsWith('/profile-settings')
                            ? true
                            : undefined
                        }
                      >
                        {intl.formatMessage({ id: 'HEADER.ACCOUNT_SETTINGS' })}
                      </Link>
                      <InternalLink
                        theme={theme.profile.content.link}
                        onClick={() => this.openModal('report')}
                      >
                        {intl.formatMessage({ id: 'HEADER.REPORT_PROBLEM' })}
                      </InternalLink>
                      <Link to='/sign-out'>
                        {intl.formatMessage({ id: 'HEADER.SIGN_OUT' })}
                      </Link>
                    </div>
                  ) : (
                    <div>
                      <ProfileTitle theme={theme.profile.content.title}>
                        {intl.formatMessage({
                          id: 'HEADER.ACCOUNT_SETTINGS_ASIDE'
                        })}
                      </ProfileTitle>
                      <Link
                        to='/profile-settings'
                        data-active={
                          currentPath.startsWith('/profile-settings')
                            ? true
                            : undefined
                        }
                      >
                        {intl.formatMessage({ id: 'HEADER.PROFILE_SETTINGS' })}
                      </Link>
                      <Link
                        to='/profile-notifications'
                        data-active={
                          currentPath.startsWith('/profile-notifications')
                            ? true
                            : undefined
                        }
                      >
                        {intl.formatMessage({
                          id: 'HEADER.PROFILE_NOTIFICATIONS'
                        })}
                      </Link>
                      <Link
                        to='/application-settings'
                        data-active={
                          currentPath.startsWith('/application-settings')
                            ? true
                            : undefined
                        }
                      >
                        {intl.formatMessage({
                          id: 'HEADER.APPLICATION_SETTINGS'
                        })}
                      </Link>
                      <InternalLink
                        theme={theme.profile.content.link}
                        onClick={() => this.openModal('report')}
                      >
                        {intl.formatMessage({ id: 'HEADER.REPORT_PROBLEM' })}
                      </InternalLink>
                      <Link to='/sign-out'>
                        {intl.formatMessage({ id: 'HEADER.SIGN_OUT' })}
                      </Link>
                    </div>
                  )}
                </ProfileContent>
              ) : (
                ''
              )}
              {this.state.visibleProfileMenu && isMobile && (
                <ProfileOverlap
                  theme={theme.profile.overlap}
                  data-open={this.state.openProfileMenu ? true : undefined}
                  data-close={!this.state.openProfileMenu ? true : undefined}
                  onClick={this.toggleProfileMenu}
                />
              )}
            </Profile>
          </Profilebox>
        </Headerbox>
        {visibleModalReport && (
          <ReportProblem
            store={reportStore}
            onSubmit={values => {
              this.handleSubmit({ type: 'REPORT', values });
            }}
            handleClose={this.closeModal}
            open={openModalReport}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    firebaseStore: state.firebase,
    reportStore: state.reportStore
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendReportInit: data => dispatch(sendReportInit(data)),
    sendReport: data => dispatch(sendReport(data))
  };
};

export default compose(
  injectIntl,
  withTimeout,
  connect(mapStateToProps, mapDispatchToProps)
)(Header);
