import styled from 'styled-components';
import Selectrix from 'react-selectrix';

import {
  Selectbox as commonSelectbox,
  Select as commonSelect
} from '../../assets/common/Select.style';

export const Selectbox = styled.div`
  ${commonSelectbox}
`;

export const Select = styled(Selectrix)`
  ${commonSelect}
`;
