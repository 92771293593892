import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import useWindowSize from '@rehooks/window-size';

import moment from 'moment';
import de from 'date-fns/locale/de';
import DayPickerInput, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Theme } from '../../assets/Theme.style';
import { Breakpoints } from '../../assets/Variables.style';
import {
  Box,
  Item,
  Title,
  Content,
  Header,
  HeaderPair,
  Navigation,
  More,
  Selectbox,
  Select,
  DayPicker
} from './DeviceHistory.style';

import {
  setDeviceHistoryReset,
  setDeviceHistoryLimit,
  setDeviceHistoryTo,
  setDeviceHistoryFrom,
  setDeviceHistoryStatus
} from '../../actions/Device/DeviceActions';

import DeviceHistoryModal from '../DeviceHistoryModal/DeviceHistoryModal';

import statuses from './statuses';

registerLocale('de', de);

function DeviceHistory({
  theme = {
    ...Theme.content.details.history,
    modal: {
      ...Theme.modal,
      button: Theme.button
    }
  },
  serial = '',
  data = [],
  allowedDetails = false,
  deviceHistoryStore = {},
  setDeviceHistoryReset = () => {},
  setDeviceHistoryLimit = () => {},
  setDeviceHistoryTo = () => {},
  setDeviceHistoryFrom = () => {},
  setDeviceHistoryStatus = () => {}
}) {
  let windowSize = useWindowSize();
  const isMobile = windowSize.innerWidth <= Breakpoints.lg;
  const intl = useIntl();
  const { limit, from, to, status } = deviceHistoryStore;
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModalTimer, setVisibleModalTimer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [dataModal, setDataModal] = useState(undefined);

  const handleIncreaseLimit = () => {
    setDeviceHistoryLimit(limit + 4);
  };

  const handleFromChange = from => {
    setDeviceHistoryLimit(4);
    setDeviceHistoryFrom(
      from ? moment(from).format('YYYY-MM-DDTHH:mm:ssZ') : undefined
    );
  };

  const handleToChange = to => {
    setDeviceHistoryLimit(4);
    setDeviceHistoryTo(
      to ? moment(to).format('YYYY-MM-DDTHH:mm:ssZ') : undefined
    );
  };

  const handleStatusChange = status => {
    setDeviceHistoryLimit(4);
    setDeviceHistoryStatus(status);
  };

  const handleOpenModal = data => {
    setDataModal(data);
    setVisibleModal(true);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setVisibleModalTimer(
      setTimeout(() => {
        setDataModal(undefined);
        setVisibleModal(false);
      }, 200)
    );
  };

  useEffect(() => {
    return () => {
      if (visibleModalTimer) clearTimeout(visibleModalTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDeviceHistoryReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serial]);

  return (
    <>
      <Header>
        <Selectbox theme={theme.selectbox.default}>
          <Select
            key={status}
            defaultValue={status || false}
            theme={theme.selectbox.selectrix}
            placeholder={intl.formatMessage({
              id: 'DEVICES.STATUS'
            })}
            arrow={false}
            customScrollbar={true}
            searchable={false}
            noResultsMessage={intl.formatMessage({
              id: 'DEVICES.STATUS.NO_STATUS_MATCH'
            })}
            options={
              statuses &&
              statuses.map(status => {
                return {
                  key: status,
                  label: intl.formatMessage({
                    id: `DEVICES.STATUS.${status}`,
                    defaultMessage: status
                  })
                };
              })
            }
            onChange={status => handleStatusChange(status.key)}
          />
        </Selectbox>

        <HeaderPair>
          <DayPicker theme={theme.daypicker.default}>
            <DayPickerInput
              selected={from && moment(from).toDate()}
              maxDate={to && moment(to).toDate()}
              placeholderText={intl.formatMessage({
                id: 'DEVICES.HISTORY.FROM'
              })}
              dateFormat='yyyy/MM/dd HH:mm'
              showTimeSelect
              timeCaption={intl.formatMessage({
                id: 'DEVICES.HISTORY.TIME'
              })}
              locale={intl.locale}
              timeFormat='HH:mm'
              timeIntervals={5}
              onChange={from => handleFromChange(from)}
              withPortal={isMobile ? true : undefined}
              popperPlacement='bottom-start'
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: 'viewport'
                }
              }}
            />
          </DayPicker>
          <DayPicker theme={theme.daypicker.default}>
            <DayPickerInput
              selected={to && moment(to).toDate()}
              minDate={from && moment(from).toDate()}
              placeholderText={intl.formatMessage({
                id: 'DEVICES.HISTORY.TO'
              })}
              dateFormat='yyyy/MM/dd HH:mm'
              showTimeSelect
              timeCaption={intl.formatMessage({
                id: 'DEVICES.HISTORY.TIME'
              })}
              locale={intl.locale}
              timeFormat='HH:mm'
              timeIntervals={5}
              onChange={to => handleToChange(to)}
              withPortal={isMobile ? true : undefined}
              popperPlacement='bottom-start'
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: 'viewport'
                }
              }}
            />
          </DayPicker>
        </HeaderPair>
      </Header>
      {data && data.length > 0 ? (
        <>
          <Box>
            {data
              .sort(
                (historyA, historyB) =>
                  historyA.value &&
                  historyA.value.timestamp &&
                  historyB.value &&
                  historyB.value.timestamp &&
                  moment(historyB.value.timestamp).unix() -
                    moment(historyA.value.timestamp).unix()
              )
              .map((history, key) => (
                <Item key={key}>
                  <Title>
                    {history.value && history.value.timestamp
                      ? `${moment(history.value.timestamp).format(
                          'YYYY/MM/DD HH:mm'
                        )}`
                      : intl.formatMessage({
                          id: 'DEVICES.STATUS.NO_TIME'
                        })}
                  </Title>
                  <Content>
                    {history.value && history.value.status
                      ? intl.formatMessage({
                          id: `DEVICES.STATUS.${history.value.status}`,
                          defaultMessage: history.value.status
                        })
                      : intl.formatMessage({
                          id: 'DEVICES.STATUS.NO_STATUS'
                        })}

                    {allowedDetails && (
                      <More
                        theme={theme.more}
                        onClick={() => handleOpenModal(history)}
                      >
                        <i className='material-icons'>info</i>
                      </More>
                    )}
                  </Content>
                </Item>
              ))}

            <Navigation>
              <More theme={theme.more} onClick={() => handleIncreaseLimit()}>
                {intl.formatMessage({ id: 'DEVICES.HISTORY.SEE_MORE' })}{' '}
                <i className='material-icons'>keyboard_arrow_down</i>
              </More>
            </Navigation>
          </Box>
          {allowedDetails && visibleModal && dataModal && (
            <DeviceHistoryModal
              data={dataModal}
              handleClose={() => handleCloseModal()}
              open={openModal}
            />
          )}
        </>
      ) : (
        intl.formatMessage({
          id: 'DEVICES.HISTORY.NO_HISTORY'
        })
      )}
    </>
  );
}

DeviceHistory.propsTypes = {
  theme: PropTypes.object,
  serial: PropTypes.string,
  data: PropTypes.array,
  allowedDetails: PropTypes.bool,
  deviceHistoryStore: PropTypes.object,
  setDeviceHistoryReset: PropTypes.func,
  setDeviceHistoryLimit: PropTypes.func,
  setDeviceHistoryTo: PropTypes.func,
  setDeviceHistoryFrom: PropTypes.func,
  setDeviceHistoryStatus: PropTypes.func
};

const mapStateToProps = state => {
  return {
    deviceHistoryStore: state.deviceHistoryStore
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setDeviceHistoryReset: () => dispatch(setDeviceHistoryReset()),
    setDeviceHistoryLimit: limit => dispatch(setDeviceHistoryLimit(limit)),
    setDeviceHistoryTo: to => dispatch(setDeviceHistoryTo(to)),
    setDeviceHistoryFrom: from => dispatch(setDeviceHistoryFrom(from)),
    setDeviceHistoryStatus: status => dispatch(setDeviceHistoryStatus(status))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DeviceHistory
);
