import styled from 'styled-components';

export const Item = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  text-align: left;
`;

export const Count = styled.div`
  display: block;
  flex-shrink: 0;
  color: ${props => props.theme.color};
  font-size: 34px;
  font-weight: 700;
  line-height: 1.2;
  cursor: default;
`;

export const Content = styled.div`
  display: block;
  width: 100%;
  padding: 5px 15px 5px;
`;

export const Title = styled.div`
  display: block;
  width: 100%;
  color: ${props => props.theme.color};
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  cursor: default;
`;

export const Link = styled.div`
  display: inline-block;
  transition: 0.2s color;
  color: ${props => props.theme.color};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colorOnHover};
  }
`;
