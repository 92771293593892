import styled, { keyframes } from 'styled-components';

import { Media } from '../../assets/Mixins.style';

import fadeIn from 'react-animations/lib/fade-in';
import fadeOut from 'react-animations/lib/fade-out';
import slideInRight from 'react-animations/lib/slide-in-right';
import slideOutRight from 'react-animations/lib/slide-out-right';

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeOutAnimation = keyframes`${fadeOut}`;
const slideInRightAnimation = keyframes`${slideInRight}`;
const slideOutRightAnimation = keyframes`${slideOutRight}`;

export const Headerbox = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  padding: 0 110px;
  background: ${(props) => props.theme.background};
  border-bottom: 1px solid ${(props) => props.theme.border};

  ${Media.xlg`
    padding: 0 45px;
  `}

  ${Media.lg`
    padding: 0 20px;
  `}
`;

export const Hamburgerbox = styled.div`
  position: relative;
  display: none;
  max-width: initial;
  width: auto;
  min-width: 62px;
  padding: 5px 5px 5px 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  ${Media.lg`
    display: inline-flex;
  `}
`;

export const Hamburger = styled.div`
  display: inline-block;
`;
export const HamburgerButton = styled.a`
  display: flex;
  width: 56px;
  height: 56px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  svg line {
    transition: 0.2s stroke;
    stroke: ${(props) => props.theme.default.color};
  }

  &:hover svg line {
    stroke: ${(props) => props.theme.default.colorOnHover};
  }

  &[data-active] svg line {
    stroke: ${(props) => props.theme.default.colorOnActive};
  }
`;

export const Logobox = styled.div`
  display: inline-flex;
  flex-shrink: 0;
  max-width: 183px;
  align-items: center;
  justify-content: center;
  padding: 15px 15px 15px 0;

  ${Media.lg`
    max-width: 100%;
    width: 100%;
    flex-shrink: 1;
  `}

  a {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
  }
`;

export const Menubox = styled.div`
  display: block;
  text-align: center;
  overflow: auto;

  ${Media.lg`
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    height: 80px;
    background: ${(props) => props.theme.background};
    border-top: 1px solid ${(props) => props.theme.border};
    z-index: 1;
  `}

  &::-webkit-scrollbar {
    width:  6px
    height: 6px
  }

  &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.thumb}
  }

  &::-webkit-scrollbar-track {
      background: ${(props) => props.theme.track}
  }
`;

export const MenuboxInner = styled.div`
  display: inline-flex;
  height: 100%;
  align-items: stretch;
  justify-content: center;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 6px;
    padding: 5px;

    &:hover {
      svg path {
        fill: ${(props) => props.theme.icon.default.colorOnHover};
      }
      span {
        color: ${(props) => props.theme.text.default.colorOnHover};
      }
    }

    &[data-active] {
      svg path {
        fill: ${(props) => props.theme.icon.default.colorOnActive};
      }
      span {
        color: ${(props) => props.theme.text.default.colorOnActive};
      }
    }

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    svg path,
    circle {
      transition: 0.2s fill;
      fill: ${(props) => props.theme.icon.default.color};
    }

    span {
      color: ${(props) => props.theme.text.default.color};
      font-size: 12px;
      font-weight: 500;
    }
  }
`;

export const Profilebox = styled.div`
  position: relative;
  display: inline-flex;
  min-width: 62px;
  width: auto;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 0 15px 15px;

  ${Media.lg`
    max-width: initial;
    width: auto;
    padding: 5px;
  `}
`;

export const Profile = styled.div`
  display: inline-block;
`;

export const ProfileButton = styled.a`
  display: flex;
  width: 46px;
  height: 46px;
  margin: 0;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: ${(props) => props.theme.background};
  overflow: hidden;
  cursor: pointer;

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`;

export const ProfileContent = styled.div`
  position: absolute;
  display: block;
  top: 100%;
  right: 0;
  width: 200px;
  padding: 7px 0;
  background: ${(props) => props.theme.background};
  border: 1px solid ${(props) => props.theme.border};
  z-index: 2;
  opacity: 1;

  ${Media.lg`
    position: fixed;
    top: 80px;
    right: 0;
    bottom: 0;
    background: ${(props) => props.theme.backgroundMobile};
    border: 0;
    padding: 25px 15px 45px 30px;
    transform: translate(200px, 0);
  `}

  &[data-open] {
    animation: 0.2s ${fadeInAnimation};

    ${Media.lg`
      transform: translate(0, 0);
      animation: 0.2s ${slideInRightAnimation};
    `}
  }

  &[data-close] {
    opacity: 0;
    animation: 0.2s ${fadeOutAnimation};

    ${Media.lg`
      opacity: 1;
      animation: 0.2s ${slideOutRightAnimation};
    `}
  }

  a {
    display: block;
    width: 100%;
    padding: 7px 20px;
    transition: 0.2s color;
    color: ${(props) => props.theme.link.default.color};
    font-size: 14px;
    font-weight: 700;
    line-height: 1;

    ${Media.lg`
      padding: 7px 0;
      font-weight: 500;
    `}

    &:hover {
      color: ${(props) => props.theme.link.default.colorOnHover};
    }

    &[data-active] {
      color: ${(props) => props.theme.link.default.colorOnActive};
      ${Media.lg`
        color: ${(props) => props.theme.link.default.colorMobileOnActive};
        font-weight: 700;
      `}
    }
  }
`;

export const InternalLink = styled.a`
  display: block;
  width: 100%;
  padding: 7px 20px;
  transition: 0.2s color;
  border: 0;
  background: transparent;
  color: ${(props) => props.theme.default.color};
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  text-align: left;
  outline: none;
  cursor: pointer;

  ${Media.lg`
      padding: 7px 0;
      font-weight: 500;
    `}

  &:hover {
    color: ${(props) => props.theme.default.colorOnHover};
  }

  &[data-active] {
    color: ${(props) => props.theme.default.colorOnActive};
    ${Media.lg`
        color: ${(props) => props.theme.default.colorMobileOnActive};
        font-weight: 700;
      `}
  }
`;

export const ProfileTitle = styled.h1`
  display: block;
  margin-bottom: 15px;
  color: ${(props) => props.theme.color};
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  cursor: default;

  ${Media.lg`
      display: block;
    `}
`;

export const ProfileOverlap = styled.div`
  display: block;
  position: fixed;
  top: 80px;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${(props) => props.theme.background};
  z-index: 1;
  opacity: 1;

  &[data-open] {
    animation: 0.2s ${fadeInAnimation};
  }

  &[data-close] {
    opacity: 0;
    animation: 0.2s ${fadeOutAnimation};
  }
`;
