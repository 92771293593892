import styled from 'styled-components';

import { Media } from '../../assets/Mixins.style';

export const Photobox = styled.label`
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;

  ${Media.sm`
    margin: 0 auto;
  `}
`;

export const Photoholder = styled.div`
  display: flex;
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: ${props => props.theme.background};
  overflow: hidden;
`;

export const Photo = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
`;

export const Text = styled.span`
  display: inline-block;
  transition: 0.2s color;
  margin-bottom: 5px;
  color: ${props => props.theme.color};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  word-break: break-word;

  label:hover & {
    color: ${props => props.theme.colorOnHover};
  }
`;

export const Input = styled.input`
  display: none;
`;

export const Info = styled.span`
  display: inline-block;
  font-size: 10px;
  font-weight: 300;
  line-height: 1.4;
  text-align: left;
  cursor: default;

  ${Media.sm`
    padding: 5px 10px;
  `}
`;
