import moment from 'moment';
import { createCommand } from './commands';

/*
// ACTION INIT DEVICE COMMAND
*/
export const initDeviceCommand = ({ id, type }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const requestID = firebase
      .database()
      .ref()
      .child(`/devices/${id}/commands`)
      .push().key;

    dispatch({
      type: `DEVICE_GET_${type}_INIT`,
      payload: {
        requestID,
      },
    });
  };
};

/*
// ACTION REQUEST DEVICE COMMAND
*/
export const requestDeviceCommand = ({
  id,
  requestID,
  type,
  operation,
  action,
  object,
  responseTimeout = 15,
}) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const command = createCommand({ requestID, operation, action, object });
    let updates = {};
    updates[`/devices/${id}/commands/${requestID}`] = command;

    firebase.database().ref().update(updates);

    dispatch({
      type: `DEVICE_GET_${type}_REQUEST`,
      payload: {
        timeout: responseTimeout,
      },
    });
  };
};

/*
// ACTION RESPONSE DEVICE COMMAND
*/
export const responseDeviceCommand = ({ id, requestID, response, type }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    firebase.database().ref(`/devices/${id}/commands/${requestID}`).remove();
    firebase.database().ref(`/devices/${id}/response/${requestID}`).remove();
    dispatch({
      type: `DEVICE_GET_${type}_RESPONSE`,
      payload: {
        response,
        responseAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
      },
    });
  };
};

/*
// ACTION TIMEOUT DEVICE COMMAND
*/
export const timeoutDeviceCommand = ({ id, requestID, type }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    firebase.database().ref(`/devices/${id}/commands/${requestID}`).remove();
    firebase.database().ref(`/devices/${id}/response/${requestID}`).remove();
    dispatch({ type: `DEVICE_GET_${type}_TIMEOUT` });
  };
};

/*
// ACTION FLUSH DEVICE COMMAND
*/
export const flushDeviceCommand = ({ id, requestID, type }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    firebase.database().ref(`/devices/${id}/commands/${requestID}`).remove();
    firebase.database().ref(`/devices/${id}/response/${requestID}`).remove();
    dispatch({ type: `DEVICE_GET_${type}_FLUSH` });
  };
};

/*
// ACTION MANAGE DEVICE INIT
*/
export const manageDeviceInit = (data) => {
  return (dispatch) => {
    dispatch({ type: 'DEVICE_MANAGE_INIT', data });
  };
};

/*
// ACTION UPDATE DEVICE
// Update device and assign device to users if users selected
*/
export const updateDevice = (id, data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'DEVICE_MANAGE_PENDING' });
    const firestore = getFirestore();

    const deviceRef = await firestore.collection('devices').doc(id);
    let deviceDistributorRef;
    let deviceCustomerRef;
    if (data.distributor)
      deviceDistributorRef = await firestore
        .collection('distributors')
        .doc(data.distributor);
    if (data.customer)
      deviceCustomerRef = await firestore
        .collection('customers')
        .doc(data.customer);
    await deviceRef
      .update({
        ...data.device,
        distributor: deviceDistributorRef ? deviceDistributorRef : '',
        customer: deviceCustomerRef ? deviceCustomerRef : '',
        updatedAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
      })
      .then(async () => {
        if (data.users) {
          await firestore
            .collection('users')
            .where('bines', 'array-contains', deviceRef)
            .get()
            .then(async (users) => {
              await users.forEach(async (user) => {
                if (!data.users.includes(user.id))
                  await firestore
                    .collection('users')
                    .doc(user.id)
                    .update({
                      bines: firestore.FieldValue.arrayRemove(deviceRef),
                      updatedAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                    });
              });
            })
            .catch((err) => {
              throw err;
            });

          await data.users.forEach(async (id) => {
            await firestore
              .collection('users')
              .doc(id)
              .update({
                bines: firestore.FieldValue.arrayUnion(deviceRef),
                updatedAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
              });
          });
        }

        dispatch({ type: 'DEVICE_MANAGE_SUCCESS', data: { id } });
      })
      .catch((err) => dispatch({ type: 'DEVICE_MANAGE_ERROR', err }));
  };
};

/*
// ACTION SET DEVICE HISTORY RESET
*/
export const setDeviceHistoryReset = () => {
  return (dispatch) => {
    dispatch({ type: 'DEVICE_HISTORY_RESET' });
  };
};

/*
// ACTION SET DEVICE HISTORY LIMIT
*/
export const setDeviceHistoryLimit = (limit) => {
  return (dispatch) => {
    dispatch({ type: 'DEVICE_HISTORY_SET_LIMIT', limit });
  };
};

/*
// ACTION SET DEVICE HISTORY TO DATE
*/
export const setDeviceHistoryTo = (to) => {
  return (dispatch) => {
    dispatch({ type: 'DEVICE_HISTORY_TO_DATE', to });
  };
};

/*
// ACTION SET DEVICE HISTORY FROM DATE
*/
export const setDeviceHistoryFrom = (from) => {
  return (dispatch) => {
    dispatch({ type: 'DEVICE_HISTORY_FROM_DATE', from });
  };
};

/*
// ACTION SET DEVICE HISTORY STATUS
*/
export const setDeviceHistoryStatus = (status) => {
  return (dispatch) => {
    dispatch({ type: 'DEVICE_HISTORY_STATUS', status });
  };
};

/*
// ACTION SET DEVICE STATISTICS RESET
*/
export const setDeviceStatisticsReset = () => {
  return (dispatch) => {
    dispatch({ type: 'DEVICE_STATISTICS_RESET' });
  };
};

/*
// ACTION SET DEVICE STATISTICS TO DATE
*/
export const setDeviceStatisticsTo = (to) => {
  return (dispatch) => {
    dispatch({ type: 'DEVICE_STATISTICS_TO_DATE', to });
  };
};

/*
// ACTION SET DEVICE STATISTICS FROM DATE
*/
export const setDeviceStatisticsFrom = (from) => {
  return (dispatch) => {
    dispatch({ type: 'DEVICE_STATISTICS_FROM_DATE', from });
  };
};
