import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Icon from '../../assets/images/Plus.svg';

import { Theme } from '../../assets/Theme.style';
import { Label, Image, Content } from './DistributorsAdd.style';

function DistributorsAdd({
  theme = Theme.content.add,
  handleClick = () => {}
}) {
  const intl = useIntl();
  return (
    <Label theme={theme.label} onClick={handleClick}>
      <Image src={Icon} alt={intl.formatMessage({ id: 'DISTRIBUTORS.ADD' })} />
      <Content>
        {intl.formatMessage({ id: 'DISTRIBUTORS.ADD_NEW_DISTRIBUTOR' })}
      </Content>
    </Label>
  );
}

DistributorsAdd.propTypes = {
  theme: PropTypes.object,
  handleClick: PropTypes.func
};

export default memo(DistributorsAdd);
