import styled from 'styled-components';

import {
  Wrapper as commonWrapper,
  Holder as commonHolder,
  Modal as commonModal,
  Close as commonClose,
  Image as commonImage,
  Title as commonTitle,
  Content as commonContent,
  Subtitle as commonSubtitle,
  Navigation as commonNavigation
} from '../../assets/common/Modal.style';
import { Inputbox as commonInputbox } from '../../assets/common/Inputbox.style';
import { Selectbox as commonSelectbox } from '../../assets/common/Select.style';
import { InlineButton as commonButton } from '../../assets/common/Button.style';
import {
  Alert as commonAlert,
  AlertImage as commonAlertImage,
  AlertContent as commonAlertContent,
  AlertTitle as commonAlertTitle
} from '../../assets/common/Alert.style';

export const Wrapper = styled.div`
  ${commonWrapper}
`;

export const Holder = styled.div`
  ${commonHolder}
`;

export const Modal = styled.div`
  ${commonModal}
`;

export const Close = styled.span`
  ${commonClose}
`;

export const Image = styled.img`
  ${commonImage}
`;

export const Title = styled.h1`
  ${commonTitle}
`;

export const Content = styled.div`
  ${commonContent}
`;

export const Subtitle = styled.h1`
  ${commonSubtitle}
`;

export const Box = styled.div`
  display: block;
  width: 100%;
`;

export const Photobox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
`;

export const Inputbox = styled.div`
  ${commonInputbox}
`;

export const Selectbox = styled.div`
  ${commonSelectbox}
`;

export const Inputholder = styled.label`
  position: relative;
  display: inline-block;
  width: 100%;
  cursor: text;

  img {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
`;

export const Navigation = styled.div`
  ${commonNavigation}
`;

export const Button = styled.button`
  ${commonButton}
  margin: 5px;
`;

export const Alertbox = styled.div`
  display: block;
  width: 100%;

  &:not(:empty) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const Alert = styled.div`
  ${commonAlert}
`;

export const AlertImage = styled.img`
  ${commonAlertImage}
`;

export const AlertContent = styled.h1`
  ${commonAlertContent}
`;

export const AlertTitle = styled.div`
  ${commonAlertTitle}
`;
