import styled, { css } from 'styled-components';
import { Media } from '../../assets/Mixins.style';

import { Daypickerbox as commonDaypickerbox } from '../../assets/common/Inputbox.style';

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  width: 100%;
  margin-bottom: 15px;
`;
export const HeaderPair = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  width: 100%;
`;

export const DayPicker = styled.div`
  ${commonDaypickerbox}
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;

  & > div {
    width: 100%;
  }
`;

export const Box = styled.div`
  display: block;
  width: 100%;
`;

export const Item = styled.div`
  display: block;
  max-width: 100%;
  margin-bottom: 10px;
`;

export const Title = styled.div`
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  cursor: default;
`;

export const Content = styled.div`
  display: block;
  width: 100%;
`;

export const Holder = styled.div`
  display: block;
  width: 100%;
  height: 250px;
`;

export const Statuses = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px 10px;
  width: 100%;

  ${Media.sm`
    grid-template-columns: 1fr;
  `}
`;

export const Status = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  cursor: ${(props) => props.cursor || 'default'};

  ${(props) =>
    props.active &&
    css`
      background: ${(props) => props.theme.background};
    `}
`;

export const StatusName = styled.span`
  display: inline-block;
`;

export const StatusValue = styled.span`
  display: inline-block;
  font-weight: 700;
`;
