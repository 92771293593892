import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/de';
import { useIntl } from 'react-intl';

import { Theme } from '../../assets/Theme.style';

import { Box, Item, Title, Button } from './SoftwareInfo.style';

import ConfigureFileModal from '../ConfigureFileModal/ConfigureFileModal';

function SoftwareInfo({
  theme = {
    modal: {
      ...Theme.modal,
      button: Theme.button,
    },
  },
  data = {},
  displayNaturalNetworkVersion = false,
  displayHeartbeat = false,
  displayConfigureFile = false,
}) {
  const intl = useIntl();
  moment.locale(intl.locale);
  const [, setTick] = useState(moment().format());
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModalTimer, setVisibleModalTimer] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setVisibleModal(true);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setVisibleModalTimer(
      setTimeout(() => {
        setVisibleModal(false);
      }, 200)
    );
  };

  useEffect(() => {
    const timerID = setInterval(() => setTick(moment().format()), 1000);
    return () => {
      clearInterval(timerID);
      if (visibleModalTimer) clearTimeout(visibleModalTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box>
        <Item>
          <Title>
            {intl.formatMessage({
              id: 'DEVICES.DEVICES_DETAILS.SOFTWARE_VERSION',
            })}
            :
          </Title>
          {data.softwareVersion}
        </Item>

        <Item>
          <Title>
            {intl.formatMessage({
              id: 'DEVICES.DEVICES_DETAILS.DEVICE_VERSION',
            })}
            :
          </Title>
          {data.deviceVersion}
        </Item>

        {displayNaturalNetworkVersion && (
          <Item>
            <Title>
              {intl.formatMessage({
                id: 'DEVICES.DEVICES_DETAILS.NEURAL_NETWORK_VERSION',
              })}
              :
            </Title>
            {data.neuralVersion}
          </Item>
        )}

        {displayHeartbeat && (
          <Item>
            <Title>
              {intl.formatMessage({
                id: 'DEVICES.DEVICES_DETAILS.HEARTBEAT',
              })}
              :
            </Title>
            {data.heartbeat && moment(data.heartbeat).isValid()
              ? moment(data.heartbeat).fromNow()
              : intl.formatMessage({
                  id: 'DEVICES.DEVICES_DETAILS.HEARTBEAT.NO_DATA',
                })}
          </Item>
        )}

        <Item>
          <Title>
            {intl.formatMessage({
              id: 'DEVICES.DEVICES_DETAILS.CONNECTION_TYPE',
            })}
            :
          </Title>
          {data.internetConnectType
            ? data.internetConnectType
            : intl.formatMessage({
                id: 'DEVICES.DEVICES_DETAILS.CONNECTION_TYPE.NO_DATA',
              })}
        </Item>

        {displayConfigureFile && (
          <Item>
            <Title>
              {intl.formatMessage({
                id: 'DEVICES.DEVICES_DETAILS.CONFIGURATION_FILE',
              })}
              :
            </Title>
            {data.configurationFile &&
              (typeof data.configurationFile === 'object' ? (
                <Button
                  theme={theme.modal.button.default}
                  onClick={() => handleOpenModal()}
                >
                  {intl.formatMessage({
                    id: 'DEVICES.DEVICES_DETAILS.CONFIGURATION_FILE.OPEN',
                  })}
                </Button>
              ) : (
                data.configurationFile
              ))}
          </Item>
        )}
      </Box>
      {visibleModal && (
        <ConfigureFileModal
          data={data.configurationFile}
          handleClose={() => handleCloseModal()}
          open={openModal}
        />
      )}
    </>
  );
}

SoftwareInfo.propsTypes = {
  theme: PropTypes.object,
  data: PropTypes.object,
  displayNaturalNetworkVersion: PropTypes.bool,
  displayHeartbeat: PropTypes.bool,
  displayConfigureFile: PropTypes.bool,
};

export default SoftwareInfo;
