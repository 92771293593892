const defaultState = {
  limit: 3,
  notes: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: ''
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'NOTES_SET_LIMIT': {
      return {
        ...state,
        limit: action.limit
      };
    }
    case 'NOTES_MANAGE_INIT': {
      return {
        ...state,
        notes: (action && action.data && action.data.notes) || {},
        isLoading: false,
        isSuccess: false,
        isError: false,
        error: ''
      };
    }
    case 'NOTES_REMOVE_PENDING': {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
        error: ''
      };
    }
    case 'NOTES_REMOVE_SUCCESS': {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        error: ''
      };
    }
    case 'NOTES_REMOVE_ERROR': {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        error:
          action && action.err && action.err.message ? action.err.message : ''
      };
    }
    case 'NOTES_ADD_PENDING': {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
        error: ''
      };
    }
    case 'NOTES_ADD_SUCCESS': {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        error: ''
      };
    }
    case 'NOTES_ADD_ERROR': {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        error:
          action && action.err && action.err.message ? action.err.message : ''
      };
    }
    case 'NOTES_EDIT_PENDING': {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
        error: ''
      };
    }
    case 'NOTES_EDIT_SUCCESS': {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        error: ''
      };
    }
    case 'NOTES_EDIT_ERROR': {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        error:
          action && action.err && action.err.message ? action.err.message : ''
      };
    }
    default:
      return state;
  }
};
