import moment from 'moment';
import { actionTypes } from 'redux-firestore';

/*
// ACTION SIGN IN INIT
*/
export const signInInit = () => {
  return (dispatch) => {
    dispatch({ type: 'AUTH_SIGNIN_INIT' });
  };
};

/*
// ACTION SIGN IN
// Set persistance then sign in your with email and password then update user firestore loggedIn
*/
export const signIn = (credentials) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'AUTH_SIGNIN_PENDING' });
    const firebase = getFirebase();
    const firestore = getFirestore();
    await firebase
      .auth()
      .setPersistence(
        process.env.REACT_APP_CLIENT_ENV === 'test'
          ? firebase.auth.Auth.Persistence.NONE
          : credentials.remember
          ? firebase.auth.Auth.Persistence.LOCAL
          : firebase.auth.Auth.Persistence.SESSION
      )
      .then(async () => {
        await firebase
          .login({
            email: credentials.email,
            password: credentials.password,
          })
          .then((auth) => {
            firestore
              .collection('users')
              .doc(auth.user.user.uid)
              .update({
                loggedIn: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
              });
            dispatch({ type: 'AUTH_SIGNIN_SUCCESS' });
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => dispatch({ type: 'AUTH_SIGNIN_ERROR', err }));
  };
};

/*
// ACTION SIGN OUT
*/
export const signOut = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    return await firebase
      .logout()
      .then(() => {
        dispatch({ type: 'AUTH_SIGNOUT_SUCCESS' });
        dispatch({ type: actionTypes.CLEAR_DATA });
      })
      .catch((err) => dispatch({ type: 'AUTH_SIGNOUT_ERROR', err }));
  };
};

/*
// ACTION FORGOT PASSWORD INIT
*/
export const forgotPasswordInit = () => {
  return (dispatch) => {
    dispatch({ type: 'AUTH_FORGOTPASSWORD_INIT' });
  };
};

/*
// ACTION FORGOT PASSWORD
// Send password reset email
*/
export const forgotPassword = (credentials) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'AUTH_FORGOTPASSWORD_PENDING' });
    const firebase = getFirebase();
    await firebase
      .auth()
      .sendPasswordResetEmail(credentials.email)
      .then(() => dispatch({ type: 'AUTH_FORGOTPASSWORD_SUCCESS' }))
      .catch((err) => dispatch({ type: 'AUTH_FORGOTPASSWORD_ERROR', err }));
  };
};

/*
// ACTION RESET PASSWORD INIT
*/
export const resetPasswordInit = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'AUTH_RESETPASSWORD_INIT' });
  };
};

/*
// ACTION RESET PASSWORD CHECK
// Verify password reset code
*/
export const resetPasswordCheck = (credentials) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    await firebase
      .verifyPasswordResetCode(credentials.code)
      .then((email) => dispatch({ type: 'AUTH_RESETPASSWORD_VALID', email }))
      .catch((err) => dispatch({ type: 'AUTH_RESETPASSWORD_INVALID', err }));
  };
};

/*
// ACTION SIGN IN INIT
// Verify password reset code then confirm password reset
*/
export const resetPassword = (credentials) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'AUTH_RESETPASSWORD_PENDING' });
    const firebase = getFirebase();
    await firebase
      .verifyPasswordResetCode(credentials.code)
      .then(async () => {
        await firebase
          .confirmPasswordReset(credentials.code, credentials.password)
          .then(() => dispatch({ type: 'AUTH_RESETPASSWORD_SUCCESS' }))
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => dispatch({ type: 'AUTH_RESETPASSWORD_ERROR', err }));
  };
};
