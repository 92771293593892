import React from 'react';
import PropTypes from 'prop-types';
import JSONViewer from 'react-json-view';
import { useIntl } from 'react-intl';

import CloseIcon from '../../assets/images/Close.svg';

import { Theme } from '../../assets/Theme.style';

import {
  Wrapper,
  Holder,
  Modal,
  Close,
  Image,
  Title,
  Content
} from './ConfigureFileModal.style';

function ConfigureFileModal({
  theme = {
    ...Theme.modal,
    button: Theme.button
  },
  data = {},
  handleClose = () => {},
  open = false
}) {
  const intl = useIntl();

  return (
    <Wrapper>
      <Holder
        theme={theme.holder}
        data-open={open ? true : undefined}
        data-close={!open ? true : undefined}
      >
        <Modal
          theme={theme.content.alternative}
          data-open={open ? true : undefined}
          data-close={!open ? true : undefined}
        >
          <Close
            onClick={handleClose}
            title={intl.formatMessage({
              id: 'DEVICES.DEVICES_DETAILS.CONFIGURATION_FILE.CLOSE'
            })}
          >
            <Image
              src={CloseIcon}
              alt={intl.formatMessage({
                id: 'DEVICES.DEVICES_DETAILS.CONFIGURATION_FILE.CLOSE'
              })}
            />
          </Close>
          <Title theme={theme.title.success}>
            {intl.formatMessage({
              id: 'DEVICES.DEVICES_DETAILS.CONFIGURATION_FILE.CONFIGURE_FILE'
            })}
          </Title>
          <Content>
            {data && Object.keys(data).length > 0 && (
              <JSONViewer name={false} src={data} enableClipboard={false} />
            )}
          </Content>
        </Modal>
      </Holder>
    </Wrapper>
  );
}

ConfigureFileModal.propsTypes = {
  theme: PropTypes.object,
  data: PropTypes.object,
  handleClose: PropTypes.func,
  open: PropTypes.bool
};

export default ConfigureFileModal;
