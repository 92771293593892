import moment from 'moment';

/*
// ACTION MANAGE DISTRIBUTOR
*/
export const manageDistributorInit = (data) => {
  return (dispatch) => {
    dispatch({ type: 'DISTRIBUTOR_MANAGE_INIT', data });
  };
};

/*
// ACTION ADD DISTRIBUTOR
// Create distributor and send profile avatar if exist photo data
*/
export const addDistributor = (data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'DISTRIBUTOR_MANAGE_PENDING' });
    const firebase = getFirebase();
    const firestore = getFirestore();

    await firestore
      .collection('distributors')
      .add({
        ...data.distributor,
        createdAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
      })
      .then(async (distributor) => {
        if (data.photoData) {
          const photoStorage = firebase
            .storage()
            .ref('Distributors/' + distributor.id + '/photo.jpg');
          await photoStorage
            .put(data.photoData)
            .then(async (snapshot) => {
              await snapshot.ref.getDownloadURL().then(async (url) => {
                await distributor
                  .update({
                    photoURL: url,
                    updatedAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                  })
                  .then(() =>
                    dispatch({
                      type: 'DISTRIBUTOR_MANAGE_SUCCESS',
                      data: { id: distributor.id },
                    })
                  )
                  .catch((err) => {
                    throw err;
                  });
              });
            })
            .catch((err) => {
              throw err;
            });
        } else
          dispatch({
            type: 'DISTRIBUTOR_MANAGE_SUCCESS',
            data: { id: distributor.id },
          });
      })
      .catch((err) => dispatch({ type: 'DISTRIBUTOR_MANAGE_ERROR', err }));
  };
};

/*
// ACTION UPDATE DISTRIBUTOR
// Update distributor and send profile avatar if exist photo data
*/
export const updateDistributor = (id, data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'DISTRIBUTOR_MANAGE_PENDING' });
    const firebase = getFirebase();
    const firestore = getFirestore();
    const photoStorage = firebase
      .storage()
      .ref('Distributors/' + id + '/photo.jpg');

    if (data.photoData) {
      await photoStorage
        .put(data.photoData)
        .then(async (snapshot) => {
          await snapshot.ref.getDownloadURL().then(async (url) => {
            await firestore
              .collection('distributors')
              .doc(id)
              .update({
                ...data.distributor,
                photoURL: url,
                updatedAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
              })
              .then(() =>
                dispatch({ type: 'DISTRIBUTOR_MANAGE_SUCCESS', data: { id } })
              )
              .catch((err) => {
                throw err;
              });
          });
        })
        .catch((err) => dispatch({ type: 'DISTRIBUTOR_MANAGE_ERROR', err }));
    } else {
      await firestore
        .collection('distributors')
        .doc(id)
        .update({
          ...data.distributor,
          updatedAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
        })
        .then(() =>
          dispatch({ type: 'DISTRIBUTOR_MANAGE_SUCCESS', data: { id } })
        )
        .catch((err) => dispatch({ type: 'DISTRIBUTOR_MANAGE_ERROR', err }));
    }
  };
};

/*
// ACTION MANAGE DISTRIBUTOR DEVICE INIT
*/
export const manageDistributorDeviceInit = (data) => {
  return (dispatch) => {
    dispatch({ type: 'DISTRIBUTOR_DEVICE_MANAGE_INIT', data });
  };
};

/*
// ACTION UPDATE DISTRIBUTOR DEVICE
*/
export const updateDistributorDevice = (id, data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'DISTRIBUTOR_DEVICE_MANAGE_PENDING' });
    const firestore = getFirestore();

    const deviceRef = await firestore.collection('devices').doc(id);
    let deviceDistributorRef;
    let deviceCustomerRef;
    if (data.distributor)
      deviceDistributorRef = await firestore
        .collection('distributors')
        .doc(data.distributor);
    if (data.customer)
      deviceCustomerRef = await firestore
        .collection('customers')
        .doc(data.customer);
    await deviceRef
      .update({
        ...data.device,
        distributor: deviceDistributorRef ? deviceDistributorRef : '',
        customer: deviceCustomerRef ? deviceCustomerRef : '',
        updatedAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
      })
      .then(async () => {
        if (data.users) {
          await firestore
            .collection('users')
            .where('bines', 'array-contains', deviceRef)
            .get()
            .then((users) => {
              users.forEach(async (user) => {
                if (!data.users.includes(user.id))
                  await firestore
                    .collection('users')
                    .doc(user.id)
                    .update({
                      bines: firestore.FieldValue.arrayRemove(deviceRef),
                      updatedAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                    });
              });
            })
            .catch((err) => {
              throw err;
            });

          data.users.forEach(async (id) => {
            await firestore
              .collection('users')
              .doc(id)
              .update({
                bines: firestore.FieldValue.arrayUnion(deviceRef),
                updatedAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
              });
          });
        }
        dispatch({ type: 'DISTRIBUTOR_DEVICE_MANAGE_SUCCESS' });
      })
      .catch((err) =>
        dispatch({ type: 'DISTRIBUTOR_DEVICE_MANAGE_ERROR', err })
      );
  };
};

/*
// ACTION LOAD DISTRIBUTOR DEVICE USERS
*/
export const loadDistributorDeviceUsers = (data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'DISTRIBUTOR_DEVICE_MANAGE_USERS_PENDING', data });
    const firestore = getFirestore();
    const deviceRef = await firestore.collection('devices').doc(data.device.id);
    await firestore
      .collection('users')
      .where('bines', 'array-contains', deviceRef)
      .get()
      .then((snapshot) => {
        const users = [];
        snapshot.forEach((user) => {
          const userData = user.data();
          userData.id = user.id;
          users.push(userData);
        });
        dispatch({
          type: 'DISTRIBUTOR_DEVICE_MANAGE_USERS_SUCCESS',
          data: { deviceUsers: users },
        });
      })
      .catch((err) =>
        dispatch({ type: 'DISTRIBUTOR_DEVICE_MANAGE_USERS_ERROR', err })
      );
  };
};
