/*
//   STYLE-COMPONENT: CHECKBOX
*/

import { css } from 'styled-components';
import { Media } from '../Mixins.style';

export const Checkbox = css`
display: inline-block;
position: relative;

${Media.xs`
  width: 100%;
  margin-bottom: 25px;
`}

input[type='checkbox'] {
  display: none;

  &:checked + label:before {
    color: ${props => props.theme.iconColor};
    font-family: 'Material Icons';
    content: '\\e5ca';
    font-size: 22px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
  }
}

label {
  display: inline-block;
  position: relative;
  font-size: 14px;
  padding-left: 35px;
  cursor: pointer;

  &:before {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 0px;
    width: 20px;
    height: 20px;
    transform: translate(0, -50%);
    border: 2px solid ${props => props.theme.borderColor};
    border-radius: 6px
    margin-right: 10px;
    content: '';
  }
}
`;
