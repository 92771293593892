/*
//   STYLE-COMPONENT: CONTENT
*/

import { css } from 'styled-components';
import { Media } from '../Mixins.style';

export const Wrapper = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Content = css`
  position: relative;
  display: flex;
  width: 100%;
  height: calc(100% - 80px);

  ${Media.lg`
    height: calc(100% - 80px - 80px);
  `}
`;

export const Panel = css`
  display: flex;
  width: 100%;
  padding: 55px 110px 0 70px;
  background: ${props => props.theme.background};
  overflow: auto;
  transition: 0.2s padding;

  ${Media.xlg`
    padding: 25px 45px 0;
  `}

  ${Media.lg`
    padding: 0;
  `}
`;

export const PanelInner = css`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Title = css`
  display: block;
  width: 100%;
  margin-bottom: 30px;
  color: ${props => props.theme.color};
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  cursor: default;

  ${Media.lg`
    margin-top: 20px;
    padding: 0 20px 0;
  `}
`;

export const Box = css`
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
`;

export const List = css`
  display: block;
  width: 320px;
  height: 100%;
  flex-shrink: 0;
  padding-right: 15px;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.thumb};
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.track};
  }

  ${Media.lg`
    width: 100%;
    padding: 0 20px 0;
  `}
`;

export const Category = css`
  display: block;
  width: 100%;
  margin-bottom: 15px;
`;

export const Subtitle = css`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 1.2;
  cursor: default;
`;

export const Details = css`
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.thumb};
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.track};
  }

  ${Media.lg`
    width: 100%;
    padding: 0 20px 0;
  `}
`;

export const Navigation = css`
  display: block;
  width: 100%;
  text-align: right;
`;

export const Button = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 39px;
  height: 39px;
  cursor: pointer;
`;

export const Image = css`
  display: block;
  max-width: 100%;
  height: auto;
`;
