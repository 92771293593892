import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

import AuthSignInReducer from './Auth/AuthSignInReducer';
import AuthForgotPasswordReducer from './Auth/AuthForgotPasswordReducer';
import AuthResetPasswordReducer from './Auth/AuthResetPasswordReducer';
import ProfileSettingsReducer from './Profile/ProfileSettingsReducer';
import ProfileNotificationsReducer from './Profile/ProfileNotificationsReducer';
import ApplicationSettingsReducer from './Profile/ApplicationSettingsReducer';
import FactoryManageReducer from './Factory/FactoryManageReducer';
import DeviceInformationReducer from './Device/DeviceInformationReducer';
import DeviceFillReducer from './Device/DeviceFillReducer';
import DeviceManageReducer from './Device/DeviceManageReducer';
import DeviceHistoryReducer from './Device/DeviceHistoryReducer';
import DeviceStatisticsReducer from './Device/DeviceStatisticsReducer';
import DistributorManageReducer from './Distributor/DistributorManageReducer';
import DistributorDeviceManageReducer from './Distributor/DistributorDeviceManageReducer';
import CustomerManageReducer from './Customer/CustomerManageReducer';
import UserManageReducer from './User/UserManageReducer';
import UserSetPasswordReducer from './User/UserSetPasswordReducer';
import ReportReducer from './Report/ReportReducer';
import NotesReducer from './Notes/NotesReducer';

const reducers = {
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  authSignInStore: AuthSignInReducer,
  authForgotPasswordStore: AuthForgotPasswordReducer,
  authResetPasswordStore: AuthResetPasswordReducer,
  profileSettingsStore: ProfileSettingsReducer,
  profileNotificationsStore: ProfileNotificationsReducer,
  applicationSettingsStore: ApplicationSettingsReducer,
  factoryManageStore: FactoryManageReducer,
  deviceInformationStore: DeviceInformationReducer,
  deviceFillStore: DeviceFillReducer,
  deviceManageStore: DeviceManageReducer,
  deviceHistoryStore: DeviceHistoryReducer,
  deviceStatisticsStore: DeviceStatisticsReducer,
  distributorManageStore: DistributorManageReducer,
  distributorDeviceManageStore: DistributorDeviceManageReducer,
  customerManageStore: CustomerManageReducer,
  userManageStore: UserManageReducer,
  userSetPasswordStore: UserSetPasswordReducer,
  reportStore: ReportReducer,
  notesStore: NotesReducer
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
