const defaultState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: ''
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'REPORT_SEND_INT': {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: false,
        error: ''
      };
    }
    case 'REPORT_SEND_PENDING': {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
        error: ''
      };
    }
    case 'REPORT_SEND_SUCCESS': {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        error: ''
      };
    }
    case 'REPORT_SEND_ERROR': {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        error:
          action && action.err && action.err.message ? action.err.message : ''
      };
    }
    default:
      return state;
  }
};
