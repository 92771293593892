import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import moment from 'moment';
import DayPickerInput from 'react-datepicker';
import { injectIntl } from 'react-intl';

import SuccessIcon from '../../assets/images/Success.svg';
import ErrorIcon from '../../assets/images/Error.svg';

import 'react-datepicker/dist/react-datepicker.css';
import { Theme } from '../../assets/Theme.style';
import { Breakpoints } from '../../assets/Variables.style';
import {
  Wrapper,
  Content,
  Switchbox,
  InputPairBox,
  InputPair,
  DayPicker,
  Spacer,
  Selectbox,
  Select,
  Button,
  Alertbox,
  Alert,
  AlertImage,
  AlertContent,
  AlertTitle
} from './ProfileNotifications.style';

import timezones from './timezones';

const validate = values => {
  const errors = {};
  if (values.allow) {
    if (!values.timeFrom)
      errors.timeFrom = 'PROFILE_SETTINGS.MANAGE.VALIDATION.REQUIRED';
    if (!values.timeTo)
      errors.timeTo = 'PROFILE_SETTINGS.MANAGE.VALIDATION.REQUIRED';
    if (!values.zone)
      errors.zone = 'PROFILE_SETTINGS.MANAGE.VALIDATION.REQUIRED';
  }

  return errors;
};

class ProfileNotifications extends Component {
  state = {
    width: window.innerWidth
  };

  static propsTypes = {
    theme: PropTypes.object,
    store: PropTypes.object,
    notifications: PropTypes.object,
    onSubmit: PropTypes.func
  };

  static defaultProps = {
    theme: {
      ...Theme.content.settings,
      alert: Theme.alert,
      button: Theme.button
    },
    store: {},
    notifications: {},
    onSubmit: () => {}
  };

  render() {
    const { width } = this.state;
    const { theme, store, notifications, onSubmit, intl } = this.props;
    const isMobile = width <= Breakpoints.lg;

    return (
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{
          allow: notifications.allow || false,
          timeFrom: notifications.timeFrom || '',
          timeTo: notifications.timeTo || '',
          zone: notifications.zone || ''
        }}
        mutators={{}}
        render={({ handleSubmit, submitting, pristine, values, invalid }) => (
          <form onSubmit={handleSubmit}>
            <Wrapper>
              <Content>
                <Field name='allow'>
                  {({ input, meta }) => (
                    <Switchbox>
                      <label>
                        {intl.formatMessage({
                          id: 'PROFILE_NOTIFICATIONS.ALLOW_NOTIFICATIONS'
                        })}
                      </label>
                      <Switch
                        onColor={theme.switcher.default.color}
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow={`0px 1px 5px ${theme.switcher.default.shadow}`}
                        activeBoxShadow={`0px 0px 1px 5px ${theme.switcher.default.shadowHandler}`}
                        height={20}
                        width={48}
                        onChange={value => input.onChange(value)}
                        checked={input.value}
                      />
                    </Switchbox>
                  )}
                </Field>
                <InputPairBox>
                  <label>
                    {intl.formatMessage({
                      id: 'PROFILE_NOTIFICATIONS.NOTIFICATION_TIME'
                    })}
                  </label>
                  <InputPair>
                    <Field name='timeFrom'>
                      {({ input, meta }) => (
                        <DayPicker theme={theme.daypicker.default}>
                          <DayPickerInput
                            selected={
                              (input.value &&
                                input.value.length > 0 &&
                                moment(input.value, 'HH:mm').toDate()) ||
                              undefined
                            }
                            placeholderText={intl.formatMessage({
                              id: 'PROFILE_NOTIFICATIONS.NOTIFICATION_TIME.FROM'
                            })}
                            showTimeSelect
                            showTimeSelectOnly
                            timeCaption={intl.formatMessage({
                              id: 'PROFILE_NOTIFICATIONS.NOTIFICATION_TIME.TIME'
                            })}
                            dateFormat='HH:mm'
                            timeFormat='HH:mm'
                            timeIntervals={5}
                            disabled={!values.allow ? true : undefined}
                            onChange={value => {
                              const timestampTo =
                                values.timeTo &&
                                moment(values.timeTo, 'HH:mm').unix();
                              const timestampFrom = moment(value).unix();
                              input.onChange(
                                timestampTo && timestampTo < timestampFrom
                                  ? moment(values.timeTo, 'HH:mm').format(
                                      'HH:mm'
                                    )
                                  : value
                                  ? moment(value).format('HH:mm')
                                  : ''
                              );
                            }}
                            withPortal={isMobile ? true : undefined}
                            popperPlacement='bottom-start'
                            popperModifiers={{
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: 'viewport'
                              }
                            }}
                          />
                        </DayPicker>
                      )}
                    </Field>
                    <Spacer>-</Spacer>
                    <Field name='timeTo'>
                      {({ input, meta }) => (
                        <DayPicker theme={theme.daypicker.default}>
                          <DayPickerInput
                            selected={
                              (input.value &&
                                input.value.length > 0 &&
                                moment(input.value, 'HH:mm').toDate()) ||
                              undefined
                            }
                            placeholderText={intl.formatMessage({
                              id: 'PROFILE_NOTIFICATIONS.NOTIFICATION_TIME.TO'
                            })}
                            showTimeSelect
                            showTimeSelectOnly
                            timeCaption={intl.formatMessage({
                              id: 'PROFILE_NOTIFICATIONS.NOTIFICATION_TIME.TIME'
                            })}
                            dateFormat='HH:mm'
                            timeFormat='HH:mm'
                            timeIntervals={5}
                            disabled={!values.allow ? true : undefined}
                            onChange={value => {
                              const timestampFrom =
                                values.timeFrom &&
                                moment(values.timeFrom, 'HH:mm').unix();
                              const timestampTo = moment(value).unix();
                              input.onChange(
                                timestampFrom && timestampFrom > timestampTo
                                  ? moment(values.timeFrom, 'HH:mm').format(
                                      'HH:mm'
                                    )
                                  : value
                                  ? moment(value).format('HH:mm')
                                  : ''
                              );
                            }}
                            withPortal={isMobile ? true : undefined}
                            popperPlacement='bottom-start'
                            popperModifiers={{
                              preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: 'viewport'
                              }
                            }}
                          />
                        </DayPicker>
                      )}
                    </Field>
                  </InputPair>
                </InputPairBox>
                <InputPairBox>
                  <label>
                    {intl.formatMessage({
                      id: 'PROFILE_NOTIFICATIONS.NOTIFICATION_ZONE'
                    })}
                  </label>
                  <Field name='zone'>
                    {({ input, meta }) => (
                      <Selectbox
                        data-nomargin={true}
                        theme={theme.selectbox.default}
                      >
                        <Select
                          defaultValue={(input && input.value) || false}
                          theme={theme.selectbox.selectrix}
                          placeholder={intl.formatMessage({
                            id:
                              'PROFILE_NOTIFICATIONS.NOTIFICATION_ZONE.TIMEZONE'
                          })}
                          arrow={false}
                          customScrollbar={true}
                          searchable={false}
                          options={
                            timezones &&
                            timezones.map(timezone => {
                              return {
                                ...timezone,
                                key: timezone.id,
                                label: timezone.name
                              };
                            })
                          }
                          disabled={!values.allow ? true : undefined}
                          onChange={value => {
                            input.onChange(value.id);
                          }}
                        />
                        <span />
                      </Selectbox>
                    )}
                  </Field>
                </InputPairBox>

                {store.isError ? (
                  <Alertbox>
                    {store.isError && (
                      <Alert>
                        <AlertImage
                          src={ErrorIcon}
                          alt={intl.formatMessage({
                            id:
                              'PROFILE_NOTIFICATIONS.ALERT.SOMETHING_WENT_WRONG'
                          })}
                        />
                        <AlertContent>
                          <AlertTitle theme={theme.alert.error.title}>
                            {intl.formatMessage({
                              id:
                                'PROFILE_NOTIFICATIONS.ALERT.SOMETHING_WENT_WRONG'
                            })}
                          </AlertTitle>
                          {store.error}
                        </AlertContent>
                      </Alert>
                    )}
                  </Alertbox>
                ) : (
                  store.isSuccess && (
                    <Alertbox>
                      <Alert>
                        <AlertImage
                          src={SuccessIcon}
                          alt={intl.formatMessage({
                            id: 'PROFILE_NOTIFICATIONS.ALERT.SUCCESS'
                          })}
                        />
                        <AlertContent>
                          <AlertTitle theme={theme.alert.success.title}>
                            {intl.formatMessage({
                              id: 'PROFILE_NOTIFICATIONS.ALERT.SUCCESS'
                            })}
                          </AlertTitle>
                          {intl.formatMessage({
                            id:
                              'PROFILE_NOTIFICATIONS.ALERT.PROFILE_NOTIFICATIONS_UPDATED'
                          })}
                        </AlertContent>
                      </Alert>
                    </Alertbox>
                  )
                )}

                <Button
                  disabled={
                    submitting ||
                    pristine ||
                    invalid ||
                    (store && store.isLoading)
                  }
                  theme={theme.button.default}
                >
                  {store && store.isLoading
                    ? intl.formatMessage({
                        id: 'PROFILE_SETTINGS.MENU.PLEASE_WAIT'
                      })
                    : intl.formatMessage({
                        id: 'PROFILE_SETTINGS.MENU.SAVE_CHANGES'
                      })}
                </Button>
              </Content>
            </Wrapper>
          </form>
        )}
      />
    );
  }
}

export default injectIntl(ProfileNotifications);
