import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import CloseIcon from '../../assets/images/Close.svg';

import { Theme } from '../../assets/Theme.style';

import {
  Wrapper,
  Holder,
  Modal,
  Close,
  Image,
  Title,
  Content,
  Navigation,
  Button,
  Information
} from './DevicesAssigned.style';

import DevicesItem from '../../components/DevicesItem/DevicesItem';

function DevicesAssigned({
  theme = {
    ...Theme.modal,
    button: Theme.button
  },
  data = {},
  handleClose = () => {},
  open = false
}) {
  const intl = useIntl();
  return (
    <Wrapper>
      <Holder
        theme={theme.holder}
        data-open={open ? true : undefined}
        data-close={!open ? true : undefined}
      >
        <Modal
          theme={theme.content.alternative}
          data-open={open ? true : undefined}
          data-close={!open ? true : undefined}
        >
          <Close
            onClick={handleClose}
            title={intl.formatMessage({
              id: 'DISTRIBUTORS.DEVICES.ASSIGN.MENU.CLOSE'
            })}
          >
            <Image
              src={CloseIcon}
              alt={intl.formatMessage({
                id: 'DISTRIBUTORS.DEVICES.ASSIGN.MENU.CLOSE'
              })}
            />
          </Close>
          <Title theme={theme.title.success}>
            {intl.formatMessage({ id: 'DISTRIBUTORS.DEVICES.ASSIGN.ASSIGNED' })}
          </Title>
          <Content>
            {data && data.bines && Object.values(data.bines).length > 0 ? (
              Object.values(data.bines).map((el, key) => (
                <Link key={key} to={`/devices/${el.id}`}>
                  <DevicesItem
                    theme={theme.content.item}
                    data={{
                      ...el,
                      heartbeat:
                        data.binesData &&
                        data.binesData[el.id] &&
                        data.binesData[el.id].heartbeat
                    }}
                    isActive={false}
                  />
                </Link>
              ))
            ) : (
              <Information>
                {intl.formatMessage({
                  id: 'DISTRIBUTORS.DEVICES.ASSIGN.NO_ASSIGNED_DEVICES'
                })}
              </Information>
            )}
            <Navigation>
              <Button
                type='button'
                theme={theme.button.alternative}
                onClick={handleClose}
              >
                {intl.formatMessage({
                  id: 'DISTRIBUTORS.DEVICES.ASSIGN.MENU.CLOSE'
                })}
              </Button>
            </Navigation>
          </Content>
        </Modal>
      </Holder>
    </Wrapper>
  );
}

DevicesAssigned.propsTypes = {
  theme: PropTypes.object,
  data: PropTypes.object,
  handleClose: PropTypes.func,
  open: PropTypes.bool
};

export default withRouter(DevicesAssigned);
