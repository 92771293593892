import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  firebaseConnect,
  firestoreConnect,
  isLoaded,
  isEmpty
} from 'react-redux-firebase';
import { Helmet } from 'react-helmet-async';
import withTimeout from 'react-timeout';
import queryString from 'query-string';
import { injectIntl } from 'react-intl';

import Icon from '../../assets/images/Close.svg';

import { withTheme } from '../../assets/Theme.style';
import { Breakpoints } from '../../assets/Variables.style';
import {
  Wrapper,
  Content,
  Panel,
  PanelInner,
  Title,
  Box,
  List,
  Category,
  Subtitle,
  Details,
  Navigation,
  Button,
  Image,
  Information
} from './UsersPage.style';

import Header from '../../components/Header/Header';
import Sider from '../../components/Sider/Sider';
import UsersItem from '../../components/UsersItem/UsersItem';
import UserDetails from '../../components/UserDetails/UserDetails';
import UsersAdd from '../../components/UsersAdd/UsersAdd';
import UserManage from '../../components/UserManage/UserManage';
import UserRemove from '../../components/UserRemove/UserRemove';
import DevicesAssigned from '../../components/DevicesAssigned/DevicesAssigned';

import { roles } from '../../utils/roles';

import {
  manageUserInit,
  addUser,
  updateUser,
  removeUser
} from '../../actions/User/UserActions';

class UsersPage extends Component {
  state = {
    filter: true,
    filterOptions: [
      {
        name: this.props.intl.formatMessage({ id: 'SIDEBAR.SEARCH' }),
        type: 'search',
        filterInput: true
      },
      {
        name: this.props.intl.formatMessage({ id: 'SIDEBAR.SHOW_ALL' }),
        type: 'none',
        filter: 'none'
      },
      {
        name: this.props.intl.formatMessage({ id: 'SIDEBAR.NAME' }),
        type: 'name',
        filter: 'none'
      },
      {
        name: this.props.intl.formatMessage({ id: 'SIDEBAR.CLIENTS' }),
        type: 'distributor',
        filter: 'none'
      },
      {
        name: this.props.intl.formatMessage({ id: 'SIDEBAR.STATUS' }),
        type: 'status',
        filter: 'none',
        items: [
          {
            name: this.props.intl.formatMessage({
              id: 'SIDEBAR.STATUS.ASSIGNED'
            }),
            type: 'status',
            filter: 'assigned'
          },
          {
            name: this.props.intl.formatMessage({
              id: 'SIDEBAR.STATUS.UNASSIGNED'
            }),
            type: 'status',
            filter: 'unassigned'
          }
        ]
      },
      {
        name: this.props.intl.formatMessage({
          id: 'SIDEBAR.ROLES'
        }),
        type: 'role',
        filter: 'none',
        items: roles.map(el => {
          return {
            name: this.props.intl.formatMessage({
              id: `SIDEBAR.ROLES.${el.name}`,
              defaultMessage: el.name
            }),
            type: 'role',
            filter: el.id
          };
        })
      }
    ],
    filterType: 'none',
    filterValue: 'none',
    visibleFilter: false,
    openFilter: false,
    width: window.innerWidth,
    visibleModal: false,
    visibleModalAdd: false,
    openModalAdd: false,
    visibleModalEdit: false,
    openModalEdit: false,
    visibleModalRemove: false,
    openModalRemove: false,
    visibleModalAssigned: false,
    openModalAssigned: false,
    photoData: null
  };

  componentDidMount() {
    const { location } = this.props;
    const params = queryString.parse(location.search);
    window.addEventListener('resize', this.handleWindowSizeChange);

    if (params && params.filter && params.type)
      this.setFilter({ filter: params.filter, type: params.type });
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const params = queryString.parse(location.search);
    const prevParams = queryString.parse(prevProps.location.search);

    if (
      prevParams &&
      params &&
      (params.filter !== prevParams.filter || params.type !== prevParams.type)
    )
      this.setFilter({ filter: params.filter, type: params.type });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
    this.checkFilter();
  };

  toggleFilter = () => {
    if (!this.state.openFilter) {
      this.setState({
        visibleFilter: true,
        openFilter: !this.state.openFilter
      });
    } else {
      this.setState({
        openFilter: !this.state.openFilter
      });
      this.props.setTimeout(() => {
        this.setState({
          visibleFilter: false
        });
      }, 200);
    }
  };

  checkFilter = () => {
    if (this.state.width > Breakpoints.lg && this.state.openFilter) {
      this.setState({
        visibleFilter: false,
        openFilter: false
      });
    }
  };

  setFilter = ({ filter, type }) => {
    this.setState({
      filterValue: filter,
      filterType: type
    });
  };

  openModal = param => {
    const { user, userDistributor } = this.props;
    if (param === 'edit' || param === 'add') {
      this.props.manageUserInit({
        user: param === 'edit' && user,
        userDistributor: param === 'edit' && userDistributor
      });
    } else if (param === 'remove') {
      this.props.manageUserInit({
        user
      });
    }
    this.setState({
      visibleModalAdd: param === 'add' ? true : false,
      visibleModalEdit: param === 'edit' ? true : false,
      visibleModalRemove: param === 'remove' ? true : false,
      visibleModalAssigned: param === 'assigned' ? true : false,
      openModalAdd: param === 'add' ? true : false,
      openModalEdit: param === 'edit' ? true : false,
      openModalRemove: param === 'remove' ? true : false,
      openModalAssigned: param === 'assigned' ? true : false,
      photoData: null
    });
  };

  closeModal = () => {
    this.setState({
      openModalAdd: false,
      openModalEdit: false,
      openModalRemove: false,
      openModalAssigned: false
    });
    this.props.setTimeout(() => {
      this.setState({
        visibleModalAdd: false,
        visibleModalEdit: false,
        visibleModalRemove: false,
        visibleModalAssigned: false,
        photoData: null
      });
    }, 200);
  };

  handleSubmit = async data => {
    const { photoData } = this.state;
    const { type, values } = data;

    const newRoles = {};
    for (const role of roles)
      newRoles[role.id] = role.id === values.role ? true : false;

    const user = {
      email: values.email || '',
      role: newRoles,
      customer: values.customer || '',
      displayName: values.displayName || '',
      distributor: values.distributor || '',
      position: values.position || '',
      phoneNo: values.phoneNo || ''
    };

    switch (type) {
      case 'USER_ADD':
        await this.props
          .addUser({
            user,
            photoData
          })
          .then(() => {
            const { userManageStore } = this.props;
            if (userManageStore.isSuccess) this.closeModal();
          });
        break;
      case 'USER_UPDATE':
        await this.props
          .updateUser(this.props.user.id, {
            user,
            photoData
          })
          .then(() => {
            this.closeModal();
          });
        break;
      case 'USER_REMOVE':
        await this.props.removeUser(this.props.user.id).then(() => {
          this.closeModal();
        });
        break;
      default:
        return false;
    }
    return true;
  };

  handlePhotoData = data => {
    this.setState({ photoData: data });
  };

  render() {
    const {
      filter,
      filterOptions,
      filterType,
      filterValue,
      visibleFilter,
      openFilter,
      width,
      visibleModalAdd,
      openModalAdd,
      visibleModalEdit,
      openModalEdit,
      visibleModalRemove,
      openModalRemove,
      visibleModalAssigned,
      openModalAssigned
    } = this.state;
    const {
      firebaseStore,
      location,
      history,
      users,
      user,
      userDistributor,
      userCustomer,
      userDevices,
      userDevicesData,
      userManageStore,
      distributors,
      customers,
      currentUserDistributor,
      intl
    } = this.props;
    const theme = this.props.theme;
    const isMobile = width <= Breakpoints.lg;
    const authRole = roles.find(el => {
      const profileRole =
        firebaseStore.profile.role &&
        Object.entries(firebaseStore.profile.role).find(el => el[1] === true);
      return profileRole && el.id === profileRole[0];
    });

    let distributorsID;
    let extendedFilterOptions;
    let usersFiltered;

    extendedFilterOptions = filterOptions.map(el => {
      switch (el.type) {
        case 'distributor':
          distributorsID =
            (distributors &&
              Object.values(distributors) &&
              Array.from(
                new Set(Object.values(distributors).map(el => el.id))
              )) ||
            [];
          return {
            ...el,
            items:
              (distributors &&
                distributors.map(e => {
                  return {
                    name: e.name,
                    type: 'distributor',
                    filter: e.id,
                    items:
                      (customers &&
                        Object.values(customers) &&
                        Object.values(customers)
                          .filter(
                            el => el.distributor && el.distributor.id === e.id
                          )
                          .map(e => {
                            return {
                              name: e.name,
                              type: 'customer',
                              filter: e.id
                            };
                          })) ||
                      []
                  };
                })) ||
              []
          };
        default:
          return el;
      }
    });

    switch (filterType) {
      case 'search':
        usersFiltered = [
          {
            name: filterValue,
            items:
              users &&
              Object.values(users) &&
              Object.values(users).filter(el => {
                const distributor =
                  el.distributor &&
                  distributors &&
                  distributors.find(
                    distributor => distributor.id === el.distributor.id
                  );
                const customer =
                  el.customer &&
                  customers &&
                  customers.find(customer => customer.id === el.customer.id);
                return (
                  (el.displayName &&
                    el.displayName
                      .toLowerCase()
                      .includes(filterValue.toLowerCase())) ||
                  (distributor &&
                    distributor.name &&
                    distributor.name
                      .toLowerCase()
                      .includes(filterValue.toLowerCase())) ||
                  (customer &&
                    customer.name &&
                    customer.name
                      .toLowerCase()
                      .includes(filterValue.toLowerCase()))
                );
              })
          }
        ];
        break;
      case 'name':
        usersFiltered =
          (users &&
            Object.values(users) &&
            Array.from(
              new Set(
                Object.values(users)
                  .filter(
                    el => el.displayName !== undefined && el.displayName !== ''
                  )
                  .map(el => el.displayName[0])
              )
            )) ||
          [];
        usersFiltered = usersFiltered.map(e => {
          return {
            name: e,
            items: Object.values(users).filter(
              el => el.displayName && el.displayName[0] === e
            )
          };
        });
        usersFiltered.sort((a, b) => a.name.localeCompare(b.name));
        if (
          users &&
          Object.values(users) &&
          Object.values(users).find(
            el => el.displayName === undefined || el.displayName === ''
          )
        ) {
          usersFiltered.push({
            name: intl.formatMessage({ id: 'SIDEBAR.UNCATEGORIZED' }),
            items: Object.values(users).filter(
              el => el.displayName === undefined || el.displayName === ''
            )
          });
        }
        break;
      case 'distributor':
        switch (filterValue) {
          case 'none':
            usersFiltered =
              distributorsID &&
              distributorsID
                .map(el => {
                  return {
                    name: el,
                    items:
                      (users &&
                        Object.values(users) &&
                        Object.values(users).filter(
                          e => e.distributor && e.distributor.id === el
                        )) ||
                      []
                  };
                })
                .filter(el => el.items.length > 0);
            if (
              users &&
              Object.values(users) &&
              Object.values(users).find(
                el => el.distributor === undefined || el.distributor === ''
              )
            ) {
              usersFiltered.push({
                name: intl.formatMessage({ id: 'SIDEBAR.UNCATEGORIZED' }),
                items: Object.values(users).filter(
                  el => el.distributor === undefined || el.distributor === ''
                )
              });
            }
            break;
          default:
            usersFiltered =
              distributorsID &&
              distributorsID
                .map(el => {
                  return {
                    name: el,
                    items:
                      (users &&
                        Object.values(users) &&
                        Object.values(users).filter(
                          e =>
                            e.distributor &&
                            e.distributor.id === el &&
                            e.distributor.id === filterValue
                        )) ||
                      []
                  };
                })
                .filter(el => el.items.length > 0);
        }
        break;
      case 'customer':
        if (filterValue) {
          const customer =
            customers && customers.find(el => el.id === filterValue);
          usersFiltered = [];
          if (
            customer &&
            users &&
            Object.values(users) &&
            Object.values(users).find(
              e =>
                e.customer &&
                e.customer.id === customer.id &&
                e.customer.id === filterValue
            )
          ) {
            usersFiltered.push({
              name: customer.id,
              items:
                (users &&
                  Object.values(users) &&
                  Object.values(users).filter(
                    e =>
                      e.customer &&
                      e.customer.id === customer.id &&
                      e.customer.id === filterValue
                  )) ||
                []
            });
          }
        }
        break;
      case 'status':
        if (users && Object.values(users) && Object.values(users).length > 0) {
          switch (filterValue) {
            case 'none':
              usersFiltered = [
                {
                  name: intl.formatMessage({ id: 'SIDEBAR.STATUS.ASSIGNED' }),
                  items:
                    (users &&
                      Object.values(users) &&
                      Object.values(users).filter(
                        e => e.bines && e.bines.length > 0
                      )) ||
                    []
                },
                {
                  name: intl.formatMessage({ id: 'SIDEBAR.STATUS.UNASSIGNED' }),
                  items:
                    (users &&
                      Object.values(users) &&
                      Object.values(users).filter(
                        e => e.bines && e.bines.length === 0
                      )) ||
                    []
                }
              ].filter(el => el.items.length > 0);
              break;
            case 'assigned':
              usersFiltered = [
                {
                  name: intl.formatMessage({ id: 'SIDEBAR.STATUS.ASSIGNED' }),
                  items:
                    (users &&
                      Object.values(users) &&
                      Object.values(users).filter(
                        e => e.bines && e.bines.length > 0
                      )) ||
                    []
                }
              ].filter(el => el.items.length > 0);
              break;
            case 'unassigned':
              usersFiltered = [
                {
                  name: intl.formatMessage({ id: 'SIDEBAR.STATUS.UNASSIGNED' }),
                  items:
                    (users &&
                      Object.values(users) &&
                      Object.values(users).filter(
                        e => e.bines && e.bines.length === 0
                      )) ||
                    []
                }
              ].filter(el => el.items.length > 0);
              break;
            default:
          }
        }
        break;
      case 'role':
        switch (filterValue) {
          case 'none':
            usersFiltered = roles
              .map(el => {
                return {
                  name: intl.formatMessage({
                    id: `SIDEBAR.ROLES.${el.name}`,
                    defaultMessage: el.name
                  }),
                  items:
                    (users &&
                      Object.values(users) &&
                      Object.values(users).filter(
                        e => e.role && e.role[el.id] === true
                      )) ||
                    []
                };
              })
              .filter(el => el.items.length > 0);
            if (
              users &&
              Object.values(users) &&
              Object.values(users).find(
                el =>
                  el.role === undefined ||
                  el.role === '' ||
                  (el.role &&
                    !Object.entries(el.role).find(el => el[1] === true))
              )
            ) {
              usersFiltered.push({
                name: intl.formatMessage({ id: 'SIDEBAR.UNCATEGORIZED' }),
                items: Object.values(users).filter(
                  el =>
                    el.role === undefined ||
                    el.role === '' ||
                    (el.role &&
                      !Object.entries(el.role).find(el => el[1] === true))
                )
              });
            }
            break;
          default:
            usersFiltered = roles
              .filter(el => el.id === filterValue)
              .map(el => {
                return {
                  name: intl.formatMessage({
                    id: `SIDEBAR.ROLES.${el.name}`,
                    defaultMessage: el.name
                  }),
                  items:
                    (users &&
                      Object.values(users) &&
                      Object.values(users).filter(
                        e => e.role && e.role[el.id] === true
                      )) ||
                    []
                };
              })
              .filter(el => el.items.length > 0);
        }
        break;
      default:
    }

    return (
      <>
        <Helmet>
          <title>
            {user
              ? `${user.displayName ? user.displayName : user.id}`
              : intl.formatMessage({ id: 'PAGE_TITLE.USERS' })}{' '}
            | BIN-E Smart Waste Bin
          </title>
        </Helmet>

        <Wrapper>
          <Header
            currentPath={location.pathname}
            profile={firebaseStore.profile}
            filter={isMobile && user ? false : filter}
            handleToggleFilter={this.toggleFilter}
          />
          <Content>
            {(!isMobile || (isMobile && !user)) && (
              <Sider
                options={extendedFilterOptions}
                location={location}
                history={history}
                visible={visibleFilter}
                open={openFilter}
                handleToggleFilter={this.toggleFilter}
                handleChangeFilter={this.setFilter}
              />
            )}

            <Panel
              theme={
                isMobile && user
                  ? theme.content.panel.alternative
                  : theme.content.panel.default
              }
            >
              <PanelInner>
                {(!isMobile || (isMobile && !user)) && (
                  <Title theme={theme.content.title}>
                    {' '}
                    {intl.formatMessage({ id: 'USERS.USERS' })}
                  </Title>
                )}

                {authRole &&
                authRole.privileges &&
                ((authRole.privileges['RESPECT_PROFILE_MEMBERSHIP'] &&
                  firebaseStore.profile.distributor) ||
                  !authRole.privileges['RESPECT_PROFILE_MEMBERSHIP']) &&
                !isLoaded(users) ? (
                  <Box>
                    <Information>
                      {intl.formatMessage({ id: 'USERS.LOADING' })}...
                    </Information>
                  </Box>
                ) : (
                  <Box>
                    {(!isMobile || (isMobile && !user)) && (
                      <List theme={theme.scroll}>
                        {authRole &&
                          authRole.privileges &&
                          ((authRole.privileges['RESPECT_PROFILE_MEMBERSHIP'] &&
                            firebaseStore.profile.distributor) ||
                            !authRole.privileges[
                              'RESPECT_PROFILE_MEMBERSHIP'
                            ]) && (
                            <UsersAdd
                              theme={theme.content.add}
                              handleClick={() => this.openModal('add')}
                            />
                          )}
                        {!isEmpty(users) ? (
                          usersFiltered ? (
                            usersFiltered.map((el, key) => (
                              <Category key={key}>
                                <Subtitle>
                                  {filterType === 'search'
                                    ? ''
                                    : filterType === 'manager'
                                    ? (users &&
                                        users.find(e => e.id === el.name) &&
                                        users.find(e => e.id === el.name)
                                          .displayName) ||
                                      el.name
                                    : filterType === 'distributor'
                                    ? distributors &&
                                      distributors.find(e => e.id === el.name)
                                      ? distributors.find(e => e.id === el.name)
                                          .name
                                      : el.name
                                    : (filterType === 'customer' &&
                                        customers &&
                                        customers.find(e => e.id === el.name) &&
                                        customers.find(e => e.id === el.name)
                                          .name) ||
                                      el.name}
                                </Subtitle>
                                {el.items.map(el => (
                                  <Link
                                    to={`/users/${el.id}${
                                      location.search ? location.search : ''
                                    }`}
                                    key={el.id}
                                  >
                                    <UsersItem
                                      theme={theme.content.item}
                                      data={{
                                        ...el,
                                        distributorData:
                                          el.distributor &&
                                          distributors &&
                                          distributors.find(
                                            e => e.id === el.distributor.id
                                          ),
                                        customerData:
                                          el.customer &&
                                          customers &&
                                          customers.find(
                                            e => e.id === el.customer.id
                                          )
                                      }}
                                      isActive={user && el.id === user.id}
                                    />
                                  </Link>
                                ))}
                              </Category>
                            ))
                          ) : (
                            Object.values(users).map(el => (
                              <Link
                                to={`/users/${el.id}${
                                  location.search ? location.search : ''
                                }`}
                                key={el.id}
                              >
                                <UsersItem
                                  theme={theme.content.item}
                                  data={{
                                    ...el,
                                    distributorData:
                                      el.distributor &&
                                      distributors &&
                                      distributors.find(
                                        e => e.id === el.distributor.id
                                      ),
                                    customerData:
                                      el.customer &&
                                      customers &&
                                      customers.find(
                                        e => e.id === el.customer.id
                                      )
                                  }}
                                  isActive={user && el.id === user.id}
                                />
                              </Link>
                            ))
                          )
                        ) : (
                          <Information>
                            {intl.formatMessage({ id: 'USERS.NO_ASSIGNED' })}
                          </Information>
                        )}
                      </List>
                    )}
                    {user && (
                      <Details theme={theme.scroll}>
                        {isMobile && (
                          <Navigation>
                            <Link
                              to={`/users${
                                location.search ? location.search : ''
                              }`}
                            >
                              <Button
                                title={intl.formatMessage({
                                  id: 'USERS.CLOSE'
                                })}
                              >
                                <Image
                                  src={Icon}
                                  alt={intl.formatMessage({
                                    id: 'USERS.CLOSE'
                                  })}
                                />
                              </Button>
                            </Link>
                          </Navigation>
                        )}
                        <UserDetails
                          theme={theme.content.details}
                          data={{
                            ...user,
                            distributorData: userDistributor,
                            customerData: userCustomer
                          }}
                          handleClick={param => this.openModal(param)}
                        />
                      </Details>
                    )}
                  </Box>
                )}
              </PanelInner>
            </Panel>
          </Content>
        </Wrapper>
        {visibleModalAdd && (
          <UserManage
            store={userManageStore}
            onSubmit={values => {
              this.handleSubmit({ type: 'USER_ADD', values });
            }}
            onSetPhotoData={this.handlePhotoData}
            handleClose={this.closeModal}
            open={openModalAdd}
            authRole={authRole}
            userDistributor={currentUserDistributor}
          />
        )}
        {visibleModalEdit && (
          <UserManage
            store={userManageStore}
            onSubmit={values => {
              this.handleSubmit({ type: 'USER_UPDATE', values });
            }}
            onSetPhotoData={this.handlePhotoData}
            handleClose={this.closeModal}
            open={openModalEdit}
            authRole={authRole}
            userDistributor={currentUserDistributor}
          />
        )}
        {visibleModalRemove && (
          <UserRemove
            store={userManageStore}
            onSubmit={values => {
              this.handleSubmit({ type: 'USER_REMOVE', values });
            }}
            handleClose={this.closeModal}
            open={openModalRemove}
          />
        )}
        {visibleModalAssigned && (
          <DevicesAssigned
            data={{
              bines: isLoaded(userDevices)
                ? userDevices &&
                  Object.entries(userDevices) &&
                  Object.entries(userDevices).map(e => {
                    return { id: e[0], ...e[1] };
                  })
                : undefined,
              binesData: userDevicesData || undefined
            }}
            handleClose={this.closeModal}
            open={openModalAssigned}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const userID = ownProps.match.params.id;
  const users =
    state.firestore && state.firestore.ordered && state.firestore.ordered.users;
  const user = users && users.find(e => e.id === userID);

  const userDistributorID = user && user.distributor && user.distributor.id;
  const distributors =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.distributors;
  const userDistributor =
    userDistributorID &&
    distributors &&
    distributors.find(e => e.id === userDistributorID);

  const userCustomerID = user && user.customer && user.customer.id;
  const customers =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.customers;
  const userCustomer =
    userCustomerID && customers && customers.find(e => e.id === userCustomerID);

  const userDevices =
    state.firestore &&
    state.firestore.composite &&
    state.firestore.composite.devices &&
    Object.entries(state.firestore.composite.devices).map(el => {
      return { ...el[1], id: el[0] };
    });

  const userDevicesData =
    state.firebase && state.firebase.ordered && state.firebase.ordered.devices;

  const currentUserDistributorID =
    state.firebase.profile &&
    state.firebase.profile.distributor &&
    state.firebase.profile.distributor.id;

  const currentUserDistributor =
    currentUserDistributorID &&
    distributors &&
    distributors.find(e => e.id === currentUserDistributorID);

  return {
    firebaseStore: state.firebase,
    users,
    user,
    userDistributor,
    userCustomer,
    userDevices,
    userDevicesData,
    userManageStore: state.userManageStore,
    distributors,
    customers,
    currentUserDistributor
  };
};

const mapDispatchToProps = dispatch => {
  return {
    manageUserInit: data => dispatch(manageUserInit(data)),
    addUser: data => dispatch(addUser(data)),
    updateUser: (id, data) => dispatch(updateUser(id, data)),
    removeUser: id => dispatch(removeUser(id))
  };
};

export default compose(
  injectIntl,
  withTheme,
  withRouter,
  withTimeout,
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect(props => {
    const listener = [];
    if (props.userDevices) {
      props.userDevices.forEach(device => {
        listener.push({
          path: `/devices/${device.id}/heartbeat`,
          queryParams: ['limitToLast=1']
        });
      });
    }
    return listener;
  }),
  firestoreConnect((props, ownProps) => {
    const listener = [];

    const authRole = roles.find(el => {
      const profileRole =
        props.firebaseStore.profile.role &&
        Object.entries(props.firebaseStore.profile.role).find(
          el => el[1] === true
        );
      return profileRole && el.id === profileRole[0];
    });

    if (
      authRole &&
      authRole.privileges &&
      authRole.privileges['RESPECT_PROFILE_MEMBERSHIP']
    ) {
      const currentUserDistributor = props.firebaseStore.profile.distributor;

      if (currentUserDistributor) {
        const distributorRef = ownProps.firestore
          .collection('distributors')
          .doc(currentUserDistributor.id);
        listener.push(
          {
            collection: 'users',
            where: [['distributor', '==', distributorRef]]
          },
          { collection: 'distributors', doc: currentUserDistributor.id },
          {
            collection: 'customers',
            where: [['distributor', '==', distributorRef]]
          }
        );
      }
    } else {
      listener.push(
        { collection: 'users' },
        { collection: 'distributors' },
        { collection: 'customers' }
      );
    }

    const userID = props.match.params.id;
    if (props.user && userID && props.user.id === userID && props.user.bines) {
      props.user.bines.forEach(bine => {
        listener.push({
          collection: 'devices',
          where: [['serialNo', '==', bine.id]]
        });
      });
    }
    return listener;
  })
)(UsersPage);
