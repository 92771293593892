/*
//   STYLE-COMPONENT: ALERT
*/

import { css } from 'styled-components';

export const Alert = css`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
`;

export const AlertImage = css`
  display: block;
  flex-shrink: 0;
  max-width: 100%;
  height: auto;
  margin: 0;
`;

export const AlertContent = css`
  display: block;
  width: 100%;
  padding-left: 15px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
  cursor: default;
`;

export const AlertTitle = css`
  display: block;
  width: 100%;
  color: ${props => props.theme.color};
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
`;
