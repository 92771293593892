import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Theme } from '../../assets/Theme.style';

import { Item, Count, Content, Title, Link } from './DeviceAssigned.style';

function DeviceAssigned({
  theme = Theme.content.details.assign,
  title = 'Sold',
  status = 0,
  count = 0,
  handleClick = () => {}
}) {
  const intl = useIntl();
  return (
    <Item>
      <Count
        theme={
          status === 1
            ? theme.count.assigned
            : status === 2
            ? theme.count.unassigned
            : theme.count.default
        }
      >
        {count}
      </Count>
      <Content>
        <Title
          theme={
            status === 1
              ? theme.title.assigned
              : status === 2
              ? theme.title.unassigned
              : theme.title.default
          }
        >
          {title}
        </Title>
        {status === 1 ? (
          <Link theme={theme.link} onClick={() => handleClick('assigned')}>
            {intl.formatMessage({ id: 'DISTRIBUTORS.DEVICES.SEE_DETAILS' })}
          </Link>
        ) : (
          status === 2 && (
            <Link theme={theme.link} onClick={() => handleClick('unassigned')}>
              {intl.formatMessage({ id: 'DISTRIBUTORS.DEVICES.ASSIGN' })}
            </Link>
          )
        )}
      </Content>
    </Item>
  );
}

DeviceAssigned.propTypes = {
  theme: PropTypes.object,
  status: PropTypes.number,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleClick: PropTypes.func
};

export default memo(DeviceAssigned);
