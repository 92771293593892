import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useIntl } from 'react-intl';

import ModifyIcon from '../../assets/images/Modify.svg';
import RemoveIcon from '../../assets/images/Remove.svg';
import ProfileIcon from '../../assets/images/ProfileImage.svg';

import { Theme } from '../../assets/Theme.style';
import {
  Box,
  Item,
  Panel,
  Columns,
  Main,
  Aside,
  Imageholder,
  Image,
  Header,
  Name,
  Menu,
  Button,
  ButtonIcon,
  Content,
  Title,
  Additional,
  Details
} from './UserDetails.style';

import DeviceAssigned from '../DeviceAssigned/DeviceAssigned';

import { roles } from '../../utils/roles';

function UserDetails({
  theme = Theme.content.details,
  data = {},
  handleClick = () => {}
}) {
  const intl = useIntl();
  const [, setTick] = useState(moment().format());
  const [photoVisible, setPhotoVisible] = useState(false);

  useEffect(() => {
    const timerID = setInterval(() => setTick(moment().format()), 1000);
    return () => clearInterval(timerID);
  }, []);

  useEffect(() => {
    return () => {
      setPhotoVisible(false);
    };
  }, [data.photoURL]);

  moment.locale(intl.locale);

  const currentUserRole =
    data.role && Object.entries(data.role).find(el => el[1]);
  const userRole =
    currentUserRole && roles.find(el => el.id === currentUserRole[0]);

  return (
    <>
      {data && Object.keys(data).length > 0 && (
        <Box>
          <Item>
            <Panel theme={theme.panel}>
              <Columns>
                <Aside>
                  <Imageholder theme={theme.photo}>
                    <Image
                      src={data.photoURL || ProfileIcon}
                      data-visible={photoVisible ? true : undefined}
                      onLoad={() => setPhotoVisible(true)}
                      onError={() => setPhotoVisible(false)}
                      alt={data.displayName ? data.displayName : data.id}
                    />
                  </Imageholder>
                </Aside>
                <Main>
                  <Header>
                    <Name>{data.displayName ? data.displayName : data.id}</Name>
                    <Menu>
                      <Button
                        onClick={() => handleClick('edit')}
                        title={intl.formatMessage({
                          id: 'USERS.EDIT_USER'
                        })}
                      >
                        <ButtonIcon
                          src={ModifyIcon}
                          alt={intl.formatMessage({
                            id: 'USERS.EDIT_USER'
                          })}
                        />
                      </Button>
                      <Button
                        onClick={() => handleClick('remove')}
                        title={intl.formatMessage({
                          id: 'USERS.REMOVE_USER'
                        })}
                      >
                        <ButtonIcon
                          src={RemoveIcon}
                          alt={intl.formatMessage({
                            id: 'USERS.REMOVE_USER'
                          })}
                        />
                      </Button>
                    </Menu>
                  </Header>
                  <Content theme={theme.content}>
                    <Details data-info>
                      {data.position && <Title>{data.position}</Title>}
                      <Additional theme={theme.additional}>
                        {intl.formatMessage({
                          id: 'USERS.CREATED_AT'
                        })}
                        :{' '}
                        {data.loggedIn
                          ? moment(data.createdAt).format('YYYY-MM-DD')
                          : intl.formatMessage({
                              id: 'USERS.CREATED_AT.NO_DATA'
                            })}
                      </Additional>
                      <Additional theme={theme.additional}>
                        {intl.formatMessage({
                          id: 'USERS.LAST_LOGIN'
                        })}
                        :{' '}
                        {data.loggedIn
                          ? moment(data.loggedIn).fromNow()
                          : intl.formatMessage({
                              id: 'USERS.LAST_LOGIN.NO_DATA'
                            })}
                      </Additional>
                    </Details>
                    <br />
                    {(data.email || data.phoneNo) && (
                      <>
                        <Details>
                          <Title theme={theme.title}>
                            <strong>
                              {intl.formatMessage({
                                id: 'USERS.CONTACT'
                              })}
                              :
                            </strong>
                          </Title>
                          {data.email && <span>{data.email}</span>}
                          {data.phoneNo && <span>{data.phoneNo}</span>}
                        </Details>
                        <br />
                      </>
                    )}
                    <Details>
                      <Title>
                        {intl.formatMessage({
                          id: 'USERS.ROLE'
                        })}
                      </Title>
                      {userRole
                        ? intl.formatMessage({
                            id: `USERS.ROLE.${userRole.name}`,
                            defaultMessage: userRole.name
                          })
                        : intl.formatMessage({
                            id: 'USERS.ROLE.NO_ROLE'
                          })}
                    </Details>
                    <Details>
                      <Title>
                        {intl.formatMessage({
                          id: 'USERS.CUSTOMER'
                        })}
                      </Title>
                      {data.customer
                        ? data.customerData
                          ? data.customerData.name
                          : `${intl.formatMessage({
                              id: 'USERS.CUSTOMER.ASSESSING_INFORMATION'
                            })}...`
                        : intl.formatMessage({
                            id: 'USERS.CUSTOMER.NO_CUSTOMER'
                          })}
                    </Details>
                    <Details>
                      <Title>
                        {intl.formatMessage({
                          id: 'USERS.DISTRIBUTOR'
                        })}
                      </Title>
                      {data.distributor
                        ? data.distributorData
                          ? data.distributorData.name
                          : `${intl.formatMessage({
                              id: 'USERS.DISTRIBUTOR.ASSESSING_INFORMATION'
                            })}...`
                        : intl.formatMessage({
                            id: 'USERS.DISTRIBUTOR.NO_DISTRIBUTOR'
                          })}
                    </Details>
                    <br />
                    <Details>
                      <DeviceAssigned
                        theme={theme.assign}
                        title={intl.formatMessage({
                          id: 'USERS.DEVICES.ASSIGNED_DEVICES'
                        })}
                        status={1}
                        count={data.bines && data.bines.length}
                        handleClick={param => handleClick(param)}
                      />
                    </Details>
                  </Content>
                </Main>
              </Columns>
            </Panel>
          </Item>
        </Box>
      )}
    </>
  );
}

UserDetails.propsTypes = {
  theme: PropTypes.object,
  data: PropTypes.object,
  handleClick: PropTypes.func
};

export default memo(UserDetails);
