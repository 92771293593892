import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Theme } from '../../assets/Theme.style';

import { Select } from './RoleAssign.style';

import { roles } from '../../utils/roles';
function RoleAssign({
  theme = {
    ...Theme.modal.selectbox
  },
  input = {},
  authRole = {}
}) {
  const intl = useIntl();
  return (
    <Select
      defaultValue={(input && input.value) || false}
      theme={theme.selectrix}
      placeholder={intl.formatMessage({
        id: 'USERS.ASSIGN.ROLE'
      })}
      arrow={false}
      customScrollbar={true}
      searchable={false}
      options={
        roles &&
        roles
          .filter(role => {
            return authRole.nomination && authRole.nomination.includes(role.id);
          })
          .map(role => {
            return {
              ...role,
              key: role.id,
              label: intl.formatMessage({
                id: `USERS.ASSIGN.ROLE.${role.name}`,
                defaultMessage: role.name
              })
            };
          })
      }
      onChange={value => {
        input.onChange(value.id);
      }}
    />
  );
}

RoleAssign.propsTypes = {
  theme: PropTypes.object,
  input: PropTypes.object,
  authRole: PropTypes.object
};

export default RoleAssign;
