import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  firebaseConnect,
  firestoreConnect,
  isLoaded,
  isEmpty,
} from 'react-redux-firebase';
import { Helmet } from 'react-helmet-async';
import withTimeout from 'react-timeout';
import queryString from 'query-string';
import { injectIntl } from 'react-intl';

import Icon from '../../assets/images/Close.svg';

import { withTheme } from '../../assets/Theme.style';
import { Breakpoints } from '../../assets/Variables.style';
import {
  Wrapper,
  Content,
  Panel,
  PanelInner,
  Title,
  Box,
  List,
  Category,
  Subtitle,
  Details,
  Navigation,
  Button,
  Image,
  Information,
} from './DistrubutorsPage.style';

import Header from '../../components/Header/Header';
import Sider from '../../components/Sider/Sider';
import DistributorsItem from '../../components/DistributorsItem/DistributorsItem';
import DistributorDetails from '../../components/DistributorDetails/DistributorDetails';
import DistributorsAdd from '../../components/DistributorsAdd/DistributorsAdd';
import DistributorManage from '../../components/DistributorManage/DistributorManage';
import DistributorUnassigned from '../../components/DistributorUnassigned/DistributorUnassigned';
import DevicesAssigned from '../../components/DevicesAssigned/DevicesAssigned';

import {
  manageDistributorInit,
  addDistributor,
  updateDistributor,
  manageDistributorDeviceInit,
  loadDistributorDeviceUsers,
  updateDistributorDevice,
} from '../../actions/Distributor/DistributorActions';

import { roles } from '../../utils/roles';

class DistributorsPage extends Component {
  state = {
    filter: true,
    filterOptions: [
      {
        name: this.props.intl.formatMessage({ id: 'SIDEBAR.SHOW_ALL' }),
        type: 'none',
        filter: 'none',
      },
      {
        name: this.props.intl.formatMessage({ id: 'SIDEBAR.ACCOUNT_MANAGERS' }),
        type: 'manager',
        filter: 'none',
      },
    ],
    filterType: 'none',
    filterValue: 'none',
    visibleFilter: false,
    openFilter: false,
    width: window.innerWidth,
    visibleModal: false,
    visibleModalAdd: false,
    openModalAdd: false,
    visibleModalEdit: false,
    openModalEdit: false,
    visibleModalUnassigned: false,
    openModalUnassigned: false,
    visibleModalAssigned: false,
    openModalAssigned: false,
    photoData: null,
  };

  componentDidMount() {
    const { location } = this.props;
    const params = queryString.parse(location.search);

    window.addEventListener('resize', this.handleWindowSizeChange);

    if (params && params.filter && params.type)
      this.setFilter({ filter: params.filter, type: params.type });
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const params = queryString.parse(location.search);
    const prevParams = queryString.parse(prevProps.location.search);

    if (
      prevParams &&
      params &&
      (params.filter !== prevParams.filter || params.type !== prevParams.type)
    )
      this.setFilter({ filter: params.filter, type: params.type });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
    this.checkFilter();
  };

  toggleFilter = () => {
    if (!this.state.openFilter) {
      this.setState({
        visibleFilter: true,
        openFilter: !this.state.openFilter,
      });
    } else {
      this.setState({
        openFilter: !this.state.openFilter,
      });
      this.props.setTimeout(() => {
        this.setState({
          visibleFilter: false,
        });
      }, 200);
    }
  };

  checkFilter = () => {
    if (this.state.width > Breakpoints.lg && this.state.openFilter) {
      this.setState({
        visibleFilter: false,
        openFilter: false,
      });
    }
  };

  setFilter = ({ filter, type }) => {
    this.setState({
      filterValue: filter,
      filterType: type,
    });
  };

  openModal = (param) => {
    const { distributor } = this.props;
    if (param === 'edit' || param === 'add')
      this.props.manageDistributorInit({
        distributor: param === 'edit' && distributor,
      });
    this.setState({
      visibleModalAdd: param === 'add' ? true : false,
      visibleModalEdit: param === 'edit' ? true : false,
      visibleModalUnassigned: param === 'unassigned' ? true : false,
      visibleModalAssigned: param === 'assigned' ? true : false,
      openModalAdd: param === 'add' ? true : false,
      openModalEdit: param === 'edit' ? true : false,
      openModalUnassigned: param === 'unassigned' ? true : false,
      openModalAssigned: param === 'assigned' ? true : false,
      photoData: null,
    });
  };

  closeModal = () => {
    this.setState({
      openModalAdd: false,
      openModalEdit: false,
      openModalUnassigned: false,
      openModalAssigned: false,
    });
    this.props.setTimeout(() => {
      this.setState({
        visibleModalAdd: false,
        visibleModalEdit: false,
        visibleModalUnassigned: false,
        visibleModalAssigned: false,
        photoData: null,
      });
    }, 200);
  };

  handleSubmit = async (data) => {
    const { type, values } = data;
    let distributor;
    switch (type) {
      case 'DISTRIBUTOR_ADD':
        distributor = {
          name: values.name || '',
          street: values.street || '',
          city: values.city || '',
          postalCode: values.postalCode || '',
          country: values.country || '',
          contactEmail: values.contactEmail || '',
          contactNo: values.contactNo || '',
        };
        await this.props
          .addDistributor({ photoData: this.state.photoData, distributor })
          .then(() => {
            this.closeModal();
          });
        break;
      case 'DISTRIBUTOR_UPDATE':
        distributor = {
          name: values.name || '',
          street: values.street || '',
          city: values.city || '',
          postalCode: values.postalCode || '',
          country: values.country || '',
          contactEmail: values.contactEmail || '',
          contactNo: values.contactNo || '',
        };
        await this.props
          .updateDistributor(this.props.distributor.id, {
            photoData: this.state.photoData,
            distributor,
          })
          .then(() => {
            this.closeModal();
          });
        break;
      case 'DISTRIBUTOR_DEVICE_UPDATE':
        await this.props
          .updateDistributorDevice(
            this.props.distributorDeviceManageStore.device.id,
            {
              distributor: this.props.distributor.id,
              customer: values.customer || '',
              users: values.users,
            }
          )
          .then(() => {
            this.closeModal();
          });
        break;
      default:
        return false;
    }
    return true;
  };

  handlePhotoData = (data) => {
    this.setState({ photoData: data });
  };

  handleManageDistributorDeviceInit = (data) => {
    this.props.manageDistributorDeviceInit({ device: data.device });
  };

  handleLoadDistributorDeviceUsers = (data) => {
    this.props.loadDistributorDeviceUsers({ device: data.device });
  };

  render() {
    const {
      filter,
      filterOptions,
      filterType,
      filterValue,
      visibleFilter,
      openFilter,
      width,
      visibleModalAdd,
      openModalAdd,
      visibleModalEdit,
      openModalEdit,
      visibleModalUnassigned,
      openModalUnassigned,
      visibleModalAssigned,
      openModalAssigned,
    } = this.state;
    const {
      firebaseStore,
      location,
      history,
      distributors,
      customers,
      users,
      distributor,
      distributorCustomers,
      distributorUsers,
      distributorDevices,
      distributorDevicesData,
      distributorManageStore,
      intl,
    } = this.props;
    const theme = this.props.theme;
    const isMobile = width <= Breakpoints.lg;
    const authRole = roles.find((el) => {
      const profileRole =
        firebaseStore.profile.role &&
        Object.entries(firebaseStore.profile.role).find((el) => el[1] === true);
      return profileRole && el.id === profileRole[0];
    });

    let managersID = [];
    const extendedFilterOptions = filterOptions.map((el) => {
      switch (el.type) {
        case 'manager':
          managersID =
            (customers &&
              Object.values(customers) &&
              Array.from(
                new Set(
                  Object.values(customers)
                    .filter((el, i, a) => i === a.indexOf(el) && el.manager)
                    .map((el) => el.manager && el.manager.id)
                )
              )) ||
            [];
          return {
            ...el,
            items: managersID.map((e) => {
              const user = users && users.find((item) => item.id === e);
              return {
                name: user && user.displayName ? user.displayName : e,
                type: 'manager',
                filter: e,
              };
            }),
          };
        default:
          return el;
      }
    });

    let distributorsFiltered;
    switch (filterType) {
      case 'manager':
        switch (filterValue) {
          case 'none':
            distributorsFiltered = managersID
              .map((el) => {
                return {
                  name: el,
                  items:
                    (distributors &&
                      customers &&
                      Object.values(distributors) &&
                      Object.values(distributors).filter((e) => {
                        const customer = customers.find(
                          (item) =>
                            item.manager &&
                            item.manager.id === el &&
                            item.distributor &&
                            item.distributor.id === e.id
                        );
                        return (
                          customer &&
                          customer.distributor &&
                          customer.distributor.id === e.id
                        );
                      })) ||
                    [],
                };
              })
              .filter((el) => el.items.length > 0);
            if (
              distributors &&
              customers &&
              Object.values(distributors) &&
              Object.values(distributors).find((el) => {
                const customer = customers.find(
                  (item) =>
                    item.manager &&
                    item.distributor &&
                    item.distributor.id === el.id
                );
                return !customer;
              })
            ) {
              distributorsFiltered.push({
                name: intl.formatMessage({ id: 'SIDEBAR.UNCATEGORIZED' }),
                items: Object.values(distributors).filter((el) => {
                  const customer = customers.find(
                    (item) =>
                      item.manager &&
                      item.distributor &&
                      item.distributor.id === el.id
                  );
                  return !customer;
                }),
              });
            }
            break;
          default:
            distributorsFiltered = managersID
              .map((el) => {
                return {
                  name: el,
                  items:
                    (distributors &&
                      customers &&
                      Object.values(distributors) &&
                      Object.values(distributors).filter((e) => {
                        const customer = customers.find(
                          (item) =>
                            item.manager &&
                            item.manager.id === el &&
                            item.manager.id === filterValue &&
                            item.distributor &&
                            item.distributor.id === e.id
                        );
                        return (
                          customer &&
                          customer.distributor &&
                          customer.distributor.id === e.id
                        );
                      })) ||
                    [],
                };
              })
              .filter((el) => el.items.length > 0);
        }
        break;
      default:
    }

    return (
      <>
        <Helmet>
          <title>
            {distributor
              ? `${distributor.name ? distributor.name : distributor.id}`
              : intl.formatMessage({ id: 'PAGE_TITLE.DISTRIBUTORS' })}{' '}
            | BIN-E Smart Waste Bin
          </title>
        </Helmet>

        <Wrapper>
          <Header
            currentPath={location.pathname}
            profile={firebaseStore.profile}
            filter={isMobile && distributor ? false : filter}
            handleToggleFilter={this.toggleFilter}
          />
          <Content>
            {(!isMobile || (isMobile && !distributor)) && (
              <Sider
                options={extendedFilterOptions}
                location={location}
                history={history}
                visible={visibleFilter}
                open={openFilter}
                handleToggleFilter={this.toggleFilter}
              />
            )}
            <Panel
              theme={
                isMobile && distributor
                  ? theme.content.panel.alternative
                  : theme.content.panel.default
              }
            >
              <PanelInner>
                {(!isMobile || (isMobile && !distributor)) && (
                  <Title theme={theme.content.title}>
                    {intl.formatMessage({ id: 'DISTRIBUTORS.DISTRIBUTORS' })}
                  </Title>
                )}
                {!isLoaded(distributors) ? (
                  <Box>
                    <Information>
                      {intl.formatMessage({ id: 'DISTRIBUTORS.LOADING' })}...
                    </Information>
                  </Box>
                ) : (
                  <Box>
                    {(!isMobile || (isMobile && !distributor)) && (
                      <List theme={theme.scroll}>
                        <DistributorsAdd
                          theme={theme.content.add}
                          handleClick={() => this.openModal('add')}
                        />
                        {!isEmpty(distributors)
                          ? distributorsFiltered
                            ? distributorsFiltered.map((el, key) => (
                                <Category key={key}>
                                  <Subtitle>
                                    {(users &&
                                      users.find((e) => e.id === el.name) &&
                                      users.find((e) => e.id === el.name)
                                        .displayName) ||
                                      el.name}
                                  </Subtitle>
                                  {el.items.map((el) => (
                                    <Link
                                      to={`/distributors/${el.id}${
                                        location.search ? location.search : ''
                                      }`}
                                      key={el.id}
                                    >
                                      <DistributorsItem
                                        theme={theme.content.item}
                                        data={el}
                                        isActive={
                                          distributor &&
                                          el.id === distributor.id
                                        }
                                      />
                                    </Link>
                                  ))}
                                </Category>
                              ))
                            : Object.values(distributors).map((el) => (
                                <Link
                                  to={`/distributors/${el.id}${
                                    location.search ? location.search : ''
                                  }`}
                                  key={el.id}
                                >
                                  <DistributorsItem
                                    theme={theme.content.item}
                                    data={el}
                                    isActive={
                                      distributor && el.id === distributor.id
                                    }
                                  />
                                </Link>
                              ))
                          : ''}
                      </List>
                    )}
                    {distributor && (
                      <Details theme={theme.scroll}>
                        {isMobile && (
                          <Navigation>
                            <Link
                              to={`/distributors${
                                location.search ? location.search : ''
                              }`}
                            >
                              <Button
                                title={intl.formatMessage({
                                  id: 'DISTRIBUTORS.CLOSE',
                                })}
                              >
                                <Image
                                  src={Icon}
                                  alt={intl.formatMessage({
                                    id: 'DISTRIBUTORS.CLOSE',
                                  })}
                                />
                              </Button>
                            </Link>
                          </Navigation>
                        )}
                        <DistributorDetails
                          theme={theme.content.details}
                          data={{
                            ...distributor,
                            bines: isLoaded(distributorDevices)
                              ? distributorDevices
                              : undefined,
                            customers: isLoaded(distributorCustomers)
                              ? distributorCustomers
                              : undefined,
                            users: isLoaded(distributorUsers)
                              ? distributorUsers
                              : undefined,
                          }}
                          handleClick={(param) => this.openModal(param)}
                        />
                      </Details>
                    )}
                  </Box>
                )}
              </PanelInner>
            </Panel>
          </Content>
        </Wrapper>
        {visibleModalAdd && (
          <DistributorManage
            store={distributorManageStore}
            onSubmit={(values) => {
              this.handleSubmit({ type: 'DISTRIBUTOR_ADD', values });
            }}
            onSetPhotoData={this.handlePhotoData}
            handleClose={this.closeModal}
            open={openModalAdd}
          />
        )}
        {visibleModalEdit && (
          <DistributorManage
            store={distributorManageStore}
            onSubmit={(values) => {
              this.handleSubmit({ type: 'DISTRIBUTOR_UPDATE', values });
            }}
            onSetPhotoData={this.handlePhotoData}
            handleClose={this.closeModal}
            open={openModalEdit}
          />
        )}
        {visibleModalUnassigned && (
          <DistributorUnassigned
            data={{
              bines: isLoaded(distributorDevices)
                ? distributorDevices &&
                  Object.entries(distributorDevices) &&
                  Object.entries(distributorDevices)
                    .map((e) => {
                      return { id: e[0], ...e[1] };
                    })
                    .filter((e) => (e.customer ? false : true))
                : undefined,
            }}
            onSubmit={(values) => {
              this.handleSubmit({
                type: 'DISTRIBUTOR_DEVICE_UPDATE',
                values,
              });
            }}
            handleClose={this.closeModal}
            open={openModalUnassigned}
            handleManageDistributorDeviceInit={
              this.handleManageDistributorDeviceInit
            }
            handleLoadDistributorDeviceUsers={
              this.handleLoadDistributorDeviceUsers
            }
            authRole={authRole}
          />
        )}
        {visibleModalAssigned && (
          <DevicesAssigned
            data={{
              bines: isLoaded(distributorDevices)
                ? distributorDevices &&
                  Object.entries(distributorDevices) &&
                  Object.entries(distributorDevices)
                    .map((e) => {
                      return { id: e[0], ...e[1] };
                    })
                    .filter((e) => (e.customer ? true : false))
                : undefined,
              binesData: distributorDevicesData || undefined,
            }}
            handleClose={this.closeModal}
            open={openModalAssigned}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const distributorID = ownProps.match.params.id;
  const distributors =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.distributors;
  const customers =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.customers;
  const users =
    state.firestore && state.firestore.ordered && state.firestore.ordered.users;
  const distributor =
    distributors && distributors.find((e) => e.id === distributorID);
  const distributorCustomers =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.distributorCustomers;
  const distributorUsers =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.distributorUsers;
  const distributorDevices =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.distributorDevices;

  const distributorDevicesData =
    state.firebase && state.firebase.ordered && state.firebase.ordered.devices;
  return {
    firebaseStore: state.firebase,
    distributors,
    customers,
    users,
    distributor,
    distributorCustomers,
    distributorUsers,
    distributorDevices,
    distributorDevicesData,
    distributorManageStore: state.distributorManageStore,
    distributorDeviceManageStore: state.distributorDeviceManageStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    manageDistributorInit: (data) => dispatch(manageDistributorInit(data)),
    addDistributor: (data) => dispatch(addDistributor(data)),
    updateDistributor: (id, data) => dispatch(updateDistributor(id, data)),
    manageDistributorDeviceInit: (data) =>
      dispatch(manageDistributorDeviceInit(data)),
    updateDistributorDevice: (id, data) =>
      dispatch(updateDistributorDevice(id, data)),
    loadDistributorDeviceUsers: (data) =>
      dispatch(loadDistributorDeviceUsers(data)),
  };
};

export default compose(
  injectIntl,
  withTheme,
  withRouter,
  withTimeout,
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect((props) => {
    const listener = [];
    if (props.distributorDevices) {
      props.distributorDevices.forEach((device) => {
        listener.push({
          path: `/devices/${device.id}/heartbeat`,
          queryParams: ['limitToLast=1'],
        });
      });
    }
    return listener;
  }),
  firestoreConnect((props, ownProps) => {
    const listener = [
      { collection: 'distributors' },
      { collection: 'customers' },
      { collection: 'users' },
    ];
    const distributorID = props.match.params.id;
    if (distributorID) {
      const distributorRef = ownProps.firestore
        .collection('distributors')
        .doc(distributorID);
      listener.push(
        {
          collection: 'customers',
          where: [['distributor', '==', distributorRef]],
          storeAs: 'distributorCustomers',
        },
        {
          collection: 'users',
          where: [['distributor', '==', distributorRef]],
          storeAs: 'distributorUsers',
        },
        {
          collection: 'devices',
          where: [['distributor', '==', distributorRef]],
          storeAs: 'distributorDevices',
        }
      );
    }

    return listener;
  })
)(DistributorsPage);
