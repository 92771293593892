/*
//   STYLE-COMPONENT: VARIABLES
*/

export const Breakpoints = {
  xs: 480,
  sm: 767,
  md: 969,
  lg: 1024,
  xlg: 1440
};

export const Colors = {
  apple: '#3CB649',
  boulder: '#757575',
  scorpion: '#575757',
  silverChalice: '#B1B1B1',
  mercury: '#E9E9E9',
  mercuryTwo: '#E2E2E2',
  alto: '#D5D5D5',
  concrete: '#F2F2F2',
  wildSand: '#F7F7F7',
  cranberry: '#DB4D84',
  barberry: '#DCE81F',
  curiousBlue: '#29ABE2',
  flamingo: '#F15A23',
  treePoppy: '#FBA61B',
  white: '#FFF',
  blackAlfa8: 'rgba(0,0,0,0.8)',
  blackAlfa2: 'rgba(0,0,0,0.2)',
  transparent: 'transparent'
};
