import React, { useEffect, useContext } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { IntlContext } from './IntlContext';

// Routes
import Routes from './routes';

// Roles
import { roles } from './utils/roles';

// Actions
import {
  registerProfileActivity,
  addProfileNotificationsToken
} from './actions/Profile/ProfileActions';

// Set client configuration
const env = process.env.REACT_APP_CLIENT_ENV || 'development';
const config = require(`./config.json`)[env];

function System({
  history,
  firebase,
  firebaseStore: { profile, auth },
  registerProfileActivity = () => {},
  addProfileNotificationsToken = () => {}
}) {
  const intl = useIntl();
  const { switchLanguage } = useContext(IntlContext);
  const authRole = roles.find(el => {
    const profileRole =
      profile &&
      profile.role &&
      Object.entries(profile.role).find(el => el[1] === true);
    return profileRole && el.id === profileRole[0];
  });

  const checkProfileActivity = () => {
    if (
      profile &&
      (!profile.loggedIn ||
        (profile.loggedIn && moment().diff(profile.loggedIn, 'minutes') >= 15))
    )
      registerProfileActivity();
  };

  const checkApplicationLanguage = () => {
    if (
      profile &&
      profile.settings &&
      profile.settings.lang &&
      profile.settings.lang !== intl.locale
    )
      switchLanguage(profile.settings.lang);
    else if (profile && !profile.settings) switchLanguage(config.lang);
  };

  const checkMessageSender = async history => {
    try {
      if (firebase.messaging.isSupported()) {
        const permissionRequest = await Notification.requestPermission();
        if (permissionRequest === 'denied' || permissionRequest === 'default')
          return;
        if (profile.notifications.allow) {
          const token = await firebase.messaging().getToken();
          if (token && (!profile.tokens || !profile.tokens.includes(token)))
            addProfileNotificationsToken(token);

          firebase.messaging().onMessage(payload => {
            const notificationTitle = payload.data.title;
            const notificationOptions = {
              body: payload.data.body,
              icon: 'assets/images/icons/icon-96x96.png',
              data: {
                click_action: payload.data.click_action
              }
            };
            const notification = new Notification(
              notificationTitle,
              notificationOptions
            );
            notification.addEventListener('click', event => {
              event.currentTarget.close();
              history.replace(event.currentTarget.data.click_action);
            });
          });
        }
      }
    } catch {
      console.warn('Warning: The notification permission was not granted.');
    }
  };

  useEffect(() => {
    let timerID;
    if (
      auth &&
      auth.isLoaded &&
      profile &&
      profile.isLoaded &&
      !auth.isEmpty &&
      !profile.isEmpty
    )
      timerID = setInterval(() => checkProfileActivity(), 1000);
    return () => {
      if (timerID) clearInterval(timerID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isLoaded, profile.isLoaded, profile.loggedIn]);

  useEffect(() => {
    if (
      auth &&
      auth.isLoaded &&
      profile &&
      profile.isLoaded &&
      !auth.isEmpty &&
      !profile.isEmpty
    )
      checkApplicationLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isLoaded, profile.isLoaded, profile.settings]);

  useEffect(() => {
    if (
      auth &&
      auth.isLoaded &&
      profile &&
      profile.isLoaded &&
      !auth.isEmpty &&
      !profile.isEmpty
    )
      checkMessageSender(history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isLoaded, profile.isLoaded, profile.notifications]);

  return (
    <>
      {auth && auth.isLoaded && profile && profile.isLoaded && (
        <Switch>
          {!auth.isEmpty &&
            Routes.filter(
              prop =>
                prop.auth === true &&
                (!prop.privileges ||
                  (prop.privileges &&
                    authRole &&
                    authRole.privileges &&
                    authRole.privileges[prop.privileges]))
            ).map((prop, key) => {
              return (
                <Route
                  key={key}
                  path={prop.path}
                  name={prop.name}
                  exact={prop.exact}
                  component={prop.component}
                />
              );
            })}
          {Routes.filter(prop => prop.auth === false).map((prop, key) => {
            return (
              <Route
                key={key}
                path={prop.path}
                name={prop.name}
                exact={prop.exact}
                component={prop.component}
              />
            );
          })}
        </Switch>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    firebaseStore: state.firebase
  };
}

const mapDispatchToProps = dispatch => {
  return {
    registerProfileActivity: () => dispatch(registerProfileActivity()),
    addProfileNotificationsToken: data =>
      dispatch(addProfileNotificationsToken(data))
  };
};

export default compose(
  withFirebase,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(System);
