import styled from 'styled-components';

import {
  Wrapper as commonWrapper,
  Content as commonContent,
  Panel as commonPanel,
  PanelInner as commonPanelInner,
  Title as commonTitle,
  Box as commonBox,
  List as commonList,
  Category as commonCategory,
  Subtitle as commonSubtitle,
  Details as commonDetails,
  Navigation as commonNavigation,
  Button as commonButton,
  Image as commonImage
} from '../../assets/common/Content.style';

import { Information as commonInformation } from '../../assets/common/Information.style';

export const Wrapper = styled.div`
  ${commonWrapper}
`;

export const Content = styled.div`
  ${commonContent}
`;

export const Panel = styled.div`
  ${commonPanel}
`;

export const PanelInner = styled.div`
  ${commonPanelInner}
`;

export const Title = styled.h1`
  ${commonTitle}
`;

export const Box = styled.div`
  ${commonBox}
`;

export const List = styled.div`
  ${commonList}
`;

export const Category = styled.div`
  ${commonCategory}
`;

export const Subtitle = styled.h2`
  ${commonSubtitle}
`;

export const Details = styled.div`
  ${commonDetails}
`;

export const Navigation = styled.div`
  ${commonNavigation}
`;

export const Button = styled.span`
  ${commonButton}
`;

export const Image = styled.img`
  ${commonImage}
`;

export const Information = styled.div`
  ${commonInformation}
  text-align: center;
`;
