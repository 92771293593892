import styled from 'styled-components';

import {
  Wrapper as commonWrapper,
  Holder as commonHolder,
  Modal as commonModal,
  Close as commonClose,
  Image as commonImage,
  Title as commonTitle,
  Content as commonContent,
  Navigation as commonNavigation
} from '../../assets/common/Modal.style';
import { InlineButton as commonButton } from '../../assets/common/Button.style';
import { Information as commonInformation } from '../../assets/common/Information.style';

export const Wrapper = styled.div`
  ${commonWrapper}
`;

export const Holder = styled.div`
  ${commonHolder}
`;

export const Modal = styled.div`
  ${commonModal}
`;

export const Close = styled.span`
  ${commonClose}
`;

export const Image = styled.img`
  ${commonImage}
`;

export const Title = styled.h1`
  ${commonTitle}
`;

export const Content = styled.div`
  ${commonContent}
`;

export const Navigation = styled.div`
  ${commonNavigation}
`;

export const Button = styled.button`
  ${commonButton}
  margin: 5px;
`;

export const Information = styled.div`
  ${commonInformation}
`;
