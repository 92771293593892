import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Icon from '../../assets/images/Modify.svg';

import { Theme } from '../../assets/Theme.style';

import {
  Box,
  Item,
  Itembox,
  Panel,
  Header,
  Name,
  Menu,
  Button,
  ButtonIcon,
  Title,
  Content,
  Details
} from './CustomerDetails.style';

import ContactInfo from '../ContactInfo/ContactInfo';
import DeviceAssigned from '../DeviceAssigned/DeviceAssigned';
import DeviceUsers from '../DeviceUsers/DeviceUsers';

import { countries } from '../../utils/countries';

function CustomerDetails({
  theme = Theme.content.details,
  data = {},
  handleClick = () => {}
}) {
  const intl = useIntl();
  const users = data && data.users && Object.values(data.users);

  return (
    <>
      {data && Object.keys(data).length > 0 && (
        <Box>
          <Item data-half={true}>
            <Panel theme={theme.panel}>
              <Header>
                <Name>{data.name}</Name>
                <Menu>
                  <Button
                    onClick={() => handleClick('edit')}
                    title={intl.formatMessage({
                      id: 'CUSTOMERS.EDIT_CUSTOMER'
                    })}
                  >
                    <ButtonIcon
                      src={Icon}
                      alt={intl.formatMessage({
                        id: 'CUSTOMERS.EDIT_CUSTOMER'
                      })}
                    />
                  </Button>
                </Menu>
              </Header>
              <Content theme={theme.content}>
                <Details>
                  <span>
                    {data.distributor
                      ? data.distributorData
                        ? data.distributorData.name
                        : `${intl.formatMessage({
                            id: 'CUSTOMERS.ASSESSING_INFORMATION'
                          })}...`
                      : intl.formatMessage({
                          id: 'CUSTOMERS.NO_DISTRIBUTOR'
                        })}
                  </span>
                </Details>
                <Details>
                  {data.street && <span>{data.street}</span>}
                  {data.city && (
                    <span>
                      {data.postalCode
                        ? `${data.postalCode}, ${data.city}`
                        : data.city}
                    </span>
                  )}
                  {data.country && (
                    <span>
                      {intl.formatMessage({
                        id: `COUNTRY.COUNTRY.${(() => {
                          const country = countries.find(
                            country => country.id === data.country
                          );
                          return country && country.name;
                        })()}`,
                        defaultMessage: data.country
                      })}
                    </span>
                  )}
                  {data.contactNo && <span>{data.contactNo}</span>}
                  {data.contactEmail && <span>{data.contactEmail}</span>}
                </Details>
              </Content>
            </Panel>
          </Item>

          <Item data-half={true}>
            <Itembox>
              <Panel theme={theme.panel}>
                <Content theme={theme.content}>
                  <DeviceAssigned
                    theme={theme.assign}
                    title={
                      data.bines !== undefined
                        ? intl.formatMessage({
                            id: 'CUSTOMERS.DEVICES.ASSIGNED_DEVICES'
                          })
                        : `${intl.formatMessage({
                            id: 'CUSTOMERS.DEVICES.ASSESSING_INFORMATION'
                          })}...`
                    }
                    status={data.bines !== undefined ? 1 : 0}
                    count={
                      data.bines !== undefined
                        ? data.bines &&
                          Object.keys(data.bines) &&
                          Object.keys(data.bines).length
                        : ''
                    }
                    handleClick={param => handleClick(param)}
                  />
                </Content>
              </Panel>

              <Panel theme={theme.panel}>
                <Title>
                  {intl.formatMessage({
                    id: 'CUSTOMERS.ACCOUNT_MANAGER'
                  })}
                </Title>
                <Content theme={theme.content}>
                  {data.managerData !== undefined ? (
                    data.managerData &&
                    Object.keys(data.managerData).length > 0 ? (
                      <ContactInfo
                        theme={theme.contact}
                        data={{
                          contactPerson: data.managerData.displayName,
                          contactPosition: data.managerData.position,
                          contactNo: data.managerData.phoneNo,
                          contactEmail: data.managerData.email,
                          contactPhotoURL: data.managerData.photoURL,
                          contactStatus: data.managerData.loggedIn
                        }}
                      />
                    ) : (
                      intl.formatMessage({
                        id: 'CUSTOMERS.ACCOUNT_MANAGER.NO_DATA'
                      })
                    )
                  ) : (
                    `${intl.formatMessage({
                      id: 'CUSTOMERS.ACCOUNT_MANAGER.ASSESSING_INFORMATION'
                    })}...`
                  )}
                </Content>
              </Panel>
            </Itembox>
          </Item>

          <Item>
            <Panel theme={theme.panel}>
              <Title>
                {intl.formatMessage({
                  id: 'CUSTOMERS.USERS'
                })}
              </Title>
              <Content theme={theme.content}>
                {users !== undefined ? (
                  users && users.length > 0 ? (
                    <DeviceUsers serial={data.id} data={Object.values(users)} />
                  ) : (
                    intl.formatMessage({
                      id: 'CUSTOMERS.USERS.NO_DATA'
                    })
                  )
                ) : (
                  `${intl.formatMessage({
                    id: 'CUSTOMERS.USERS.ASSESSING_INFORMATION'
                  })}...`
                )}
              </Content>
            </Panel>
          </Item>
        </Box>
      )}
    </>
  );
}

CustomerDetails.propsTypes = {
  theme: PropTypes.object,
  data: PropTypes.object,
  handleClick: PropTypes.func
};

export default memo(CustomerDetails);
