/*
//   STYLE-COMPONENT: PANEL
*/

import { css } from 'styled-components';
import { Media } from '../Mixins.style';

export const Box = css`
  display: flex;
  flex-wrap: wrap;
  margin: -15px;

  ${Media.lg`
    margin: 0;
  `}
`;

export const Item = css`
  display: flex;
  align-items: stretch;
  width: 100%;
  padding: 15px;

  ${Media.lg`
    padding: 10px 0;
  `}

  &[data-list] {
    align-items: flex-start;
  }

  &[data-half] {
    width: 50%;

    ${Media.xlg`
      width: 100%;
    `}

    ${Media.lg`
      width: 50%;
    `}

    ${Media.sm`
      width: 100%;
    `}
  }
`;

export const Panel = css`
  display: block;
  width: 100%;
  padding: 13px 20px;
  border-radius: 8px;
  background: ${props => props.theme.background};

  ${Media.lg`
    padding: 0;
    background: ${props => props.theme.backgroundMobile};
  `}
`;

export const Header = css`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  ${Media.xs`
    padding-right: 39px;
    flex-wrap: wrap;
  `}
`;

export const Name = css`
  display: block;
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  cursor: default;
`;

export const Menu = css`
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
`;

export const Number = css`
  font-size: 24px;
  font-weight: 500;
  cursor: default;
`;

export const Button = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 39px;
  cursor: pointer;

  ${Media.xs`
    position: absolute;
    top: 0;
    right: 0;
  `}
`;

export const Image = css`
  display: block;
  max-width: 100%;
  height: auto;
`;

export const Content = css`
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  word-break: break-all;
  cursor: default;

  a {
    font-weight: 500;
    transition: 0.2s color;
    text-decoration: underline;
    color: ${props => props.theme.link.color};

    &:hover {
      color: ${props => props.theme.link.colorOnHover};
    }
  }
`;

export const Details = css`
  display: block;
  width: 100%;
  margin-bottom: 8px
  font-size: 14px;
  font-weight: 700;
  word-break: break-all;
  cursor: default;

  &[data-info] {
    ${Media.lg`
      text-align: center;
  `}
  }

  span {
    display: block;
    font-weight: 500;
  }
`;

export const Additional = css`
  display: block;
  width: 100%;
  margin-bottom: 8px
  color: ${props => props.theme.color};
  font-size: 12px;
  font-weight: 400;
  word-break: break-all;
  cursor: default;
`;

export const Title = css`
  display: block;
  width: 100%;
  margin-bottom: 10px;
  color: ${props => props.theme.color};
  font-size: 14px;
  font-weight: 400;
  cursor: default;
`;
