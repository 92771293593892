import styled from 'styled-components';

import { Media } from '../../assets/Mixins.style';

import {
  Box as commonBox,
  Item as commonItem,
  Panel as commonPanel,
  Header as commonHeader,
  Name as commonName,
  Menu as commonMenu,
  Number as commonNumber,
  Button as commonButton,
  Image as commonImage,
  Title as commonTitle,
  Content as commonContent,
  Details as commonDetails,
  Additional as commonAdditional
} from '../../assets/common/Panel.style';

export const Box = styled.div`
  ${commonBox}
`;

export const Imageholder = styled.div`
  display: flex;
  width: 96px;
  height: 96px;
  margin: 0;
  flex-shrink: 0
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: ${props => props.theme.background};
  overflow: hidden;

  ${Media.lg`
    margin: 0 auto 15px;
  `}
`;

export const Image = styled.img`
  display: none;
  max-width: 100%;
  max-height: 100%;

  &[data-visible] {
    display: block;
  }
`;

export const Item = styled.div`
  ${commonItem}
`;

export const Panel = styled.div`
  ${commonPanel}
`;

export const Columns = styled.div`
  display: flex;

  ${Media.lg`
    position: relative;
    flex-wrap: wrap;
  `}
`;

export const Main = styled.div`
  display: block;
  width: 100%;
  padding-left: 15px;

  ${Media.lg`
    width: 100%;
    padding-left: 0;
  `}
`;

export const Aside = styled.div`
  display: block;
  width: 96px;
  flex-shrink: 0;

  ${Media.lg`
    width: 100%;
  `}
`;

export const Header = styled.div`
  ${commonHeader}
`;

export const Name = styled.h1`
  ${commonName}

  ${Media.lg`
    text-align: center;
    margin-bottom: 5px;
  `}
`;

export const Menu = styled.div`
  ${commonMenu}

  ${Media.lg`
    position: absolute;
    top: 0;
    right: 0;
  `}
`;

export const Number = styled.div`
  ${commonNumber}
`;

export const Button = styled.span`
  ${commonButton}
`;

export const ButtonIcon = styled.img`
  ${commonImage}
`;

export const Content = styled.div`
  ${commonContent}
`;

export const Details = styled.div`
  ${commonDetails}
`;

export const Additional = styled.div`
  ${commonAdditional}
`;

export const Title = styled.h2`
  ${commonTitle}

  ${Details} & {
    margin-bottom: 0;
  }
`;
