/*
//   STYLE-COMPONENT: BUTTON
*/

import { css } from 'styled-components';

export const Button = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border: 0;
  outline: none
  transition: 0.2s background;
  background: ${props => props.theme.background};
  color: ${props => props.theme.color};
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.backgroundOnHover};
  }

  &[disabled] {
    background: ${props => props.theme.backgroundOnDisabled};
    cursor: default;
  }
`;

export const InlineButton = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  min-width: 160px;
  height: 50px;
  border: 0;
  outline: none;
  transition: 0.2s background;
  background: ${props => props.theme.background};
  color: ${props => props.theme.color};
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.backgroundOnHover};
  }

  &[disabled] {
    background: ${props => props.theme.backgroundOnDisabled};
    cursor: default;
  }
`;

export const SmallButton = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 36px;
  height: 36px;
  border: 0;
  outline: none;
  transition: 0.2s background;
  background: ${props => props.theme.background};
  color: ${props => props.theme.color};
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.backgroundOnHover};
  }

  &[disabled] {
    background: ${props => props.theme.backgroundOnDisabled};
    cursor: default;
  }
`;
