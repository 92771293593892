const defaultState = {
  distributor: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: ''
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'DISTRIBUTOR_MANAGE_INIT': {
      return {
        ...state,
        distributor: (action && action.data && action.data.distributor) || {},
        isLoading: false,
        isSuccess: false,
        isError: false,
        error: ''
      };
    }
    case 'DISTRIBUTOR_MANAGE_PENDING': {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
        error: ''
      };
    }
    case 'DISTRIBUTOR_MANAGE_SUCCESS': {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        error: ''
      };
    }
    case 'DISTRIBUTOR_MANAGE_ERROR': {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        error:
          action && action.err && action.err.message ? action.err.message : ''
      };
    }
    default:
      return state;
  }
};
