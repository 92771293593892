//TODO: NEED REFACTOR
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withTimeout from 'react-timeout';
import moment from 'moment';
import { injectIntl } from 'react-intl';

import DeviceNotesRemove from '../DeviceNotesRemove/DeviceNotesRemove';
import DeviceNotesManage from '../DeviceNotesManage/DeviceNotesManage';

import { Theme } from '../../assets/Theme.style';
import {
  Box,
  Item,
  ItemInner,
  Title,
  Content,
  Header,
  Button,
  Navigation,
  More,
  Menu,
  MenuButton
} from './DeviceNotes.style';

import {
  manageNotesInit,
  removeNotes,
  addNotes,
  editNotes,
  setNotesLimit
} from '../../actions/Notes/NotesActions';

class DeviceNotes extends Component {
  state = {
    limit: 3,
    width: window.innerWidth,
    visibleModalAdd: false,
    openModalAdd: false,
    visibleModalEdit: false,
    openModalEdit: false,
    visibleModalRemove: false,
    openModalRemove: false
  };

  static propsTypes = {
    theme: PropTypes.object,
    serial: PropTypes.string,
    data: PropTypes.array
  };

  static defaultProps = {
    theme: {
      ...Theme.content.details.history,
      modal: {
        ...Theme.modal,
        alert: Theme.alert,
        button: Theme.button
      }
    },
    serial: '',
    data: []
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
    this.props.setNotesLimit(3);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.serial !== this.props.serial) {
      this.props.setNotesLimit(3);
      this.setState({ limit: 3 });
    }
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  increaseLimit = () => {
    const { limit } = this.state;
    this.props.setNotesLimit(limit + 3);
    this.setState({ limit: limit + 3 });
  };

  openModal = (param, data) => {
    this.props.manageNotesInit({ notes: data });
    this.setState({
      visibleModalAdd: param === 'add' ? true : false,
      visibleModalEdit: param === 'edit' ? true : false,
      visibleModalRemove: param === 'remove' ? true : false,
      openModalAdd: param === 'add' ? true : false,
      openModalEdit: param === 'edit' ? true : false,
      openModalRemove: param === 'remove' ? true : false
    });
  };

  closeModal = () => {
    this.setState({
      openModalAdd: false,
      openModalEdit: false,
      openModalRemove: false
    });
    this.props.setTimeout(() => {
      this.setState({
        visibleModalAdd: false,
        visibleModalEdit: false,
        visibleModalRemove: false
      });
    }, 200);
  };

  handleSubmit = async data => {
    const { serial } = this.props;
    const { type, values } = data;

    const notes = {
      title: values.title || '',
      details: values.details || ''
    };

    switch (type) {
      case 'REMOVE_NOTES':
        await this.props.removeNotes(values.id).then(result => {
          if (!result.err) this.closeModal();
        });
        break;
      case 'ADD_NOTES':
        await this.props.addNotes({ notes, bine: serial }).then(result => {
          if (!result.err) this.closeModal();
        });
        break;
      case 'EDIT_NOTES':
        await this.props
          .editNotes(values.id, { notes, bine: serial })
          .then(result => {
            if (!result.err) this.closeModal();
          });
        break;
      default:
        return false;
    }
    return true;
  };

  render() {
    const {
      limit,
      visibleModalRemove,
      openModalRemove,
      visibleModalAdd,
      openModalAdd,
      visibleModalEdit,
      openModalEdit
    } = this.state;
    const { theme, data, notesStore, intl } = this.props;
    const notes = data && data.length > 0 ? data : [];

    return (
      <>
        <Header>
          <Button
            theme={theme.modal.button.default}
            onClick={() => {
              this.openModal('add');
            }}
          >
            {intl.formatMessage({ id: 'DEVICES.NOTES.ADD_NOTES' })}
          </Button>
        </Header>
        {notes && notes.length > 0 ? (
          <>
            <Box>
              {notes.slice(0, limit).map((el, key) => (
                <Item key={key}>
                  <ItemInner>
                    <Title>
                      {el.updatedAt
                        ? `${moment(el.createdAt).format('YYYY/MM/DD HH:mm')} ${
                            el.updatedAt !== el.createdAt
                              ? `(${intl.formatMessage({
                                  id: 'DEVICES.NOTES.LAST_MODIFICATION'
                                })}: ${moment(el.updatedAt).format(
                                  'YYYY/MM/DD HH:mm'
                                )})`
                              : ''
                          }`
                        : el.createdAt
                        ? `${moment(el.createdAt).format('YYYY/MM/DD HH:mm')}`
                        : intl.formatMessage({ id: 'DEVICES.NOTES.NO_DATE' })}
                    </Title>
                    <Content>
                      {el.title
                        ? el.title
                        : intl.formatMessage({ id: 'DEVICES.NOTES.NO_TITLE' })}
                    </Content>
                  </ItemInner>
                  <Menu>
                    <MenuButton
                      theme={theme.modal.button.alternative}
                      title={intl.formatMessage({
                        id: 'DEVICES.NOTES.EDIT_NOTE'
                      })}
                      onClick={() => {
                        this.openModal('edit', el);
                      }}
                    >
                      <i className='material-icons'>edit</i>
                    </MenuButton>
                    <MenuButton
                      theme={theme.modal.button.alternative}
                      title={intl.formatMessage({
                        id: 'DEVICES.NOTES.REMOVE_NOTE'
                      })}
                      onClick={() => {
                        this.openModal('remove', el);
                      }}
                    >
                      <i className='material-icons'>close</i>
                    </MenuButton>
                  </Menu>
                </Item>
              ))}

              {limit < notes.length && (
                <Navigation>
                  <More theme={theme.more} onClick={this.increaseLimit}>
                    {intl.formatMessage({
                      id: 'DEVICES.NOTES.SEE_MORE'
                    })}{' '}
                    <i className='material-icons'>keyboard_arrow_down</i>
                  </More>
                </Navigation>
              )}
            </Box>
          </>
        ) : (
          intl.formatMessage({
            id: 'DEVICES.NOTES.NO_NOTES'
          })
        )}
        {visibleModalRemove && (
          <DeviceNotesRemove
            theme={theme.modal}
            store={notesStore}
            onSubmit={values => {
              this.handleSubmit({ type: 'REMOVE_NOTES', values });
            }}
            handleClose={this.closeModal}
            open={openModalRemove}
          />
        )}
        {visibleModalAdd && (
          <DeviceNotesManage
            theme={theme.modal}
            store={notesStore}
            onSubmit={values => {
              this.handleSubmit({ type: 'ADD_NOTES', values });
            }}
            handleClose={this.closeModal}
            open={openModalAdd}
          />
        )}
        {visibleModalEdit && (
          <DeviceNotesManage
            theme={theme.modal}
            store={notesStore}
            onSubmit={values => {
              this.handleSubmit({ type: 'EDIT_NOTES', values });
            }}
            handleClose={this.closeModal}
            open={openModalEdit}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    firebaseStore: state.firebase,
    notesStore: state.notesStore
  };
};

const mapDispatchToProps = dispatch => {
  return {
    manageNotesInit: data => dispatch(manageNotesInit(data)),
    removeNotes: id => dispatch(removeNotes(id)),
    addNotes: data => dispatch(addNotes(data)),
    editNotes: (id, data) => dispatch(editNotes(id, data)),
    setNotesLimit: limit => dispatch(setNotesLimit(limit))
  };
};

export default compose(
  injectIntl,
  withTimeout,
  connect(mapStateToProps, mapDispatchToProps)
)(DeviceNotes);
