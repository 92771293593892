import styled from 'styled-components';

import {
  Box as commonBox,
  Item as commonItem,
  Panel as commonPanel,
  Header as commonHeader,
  Name as commonName,
  Menu as commonMenu,
  Number as commonNumber,
  Button as commonButton,
  Image as commonImage,
  Title as commonTitle,
  Content as commonContent
} from '../../assets/common/Panel.style';

export const Box = styled.div`
  ${commonBox}
`;

export const Item = styled.div`
  ${commonItem}
`;

export const Panel = styled.div`
  ${commonPanel}
`;

export const Header = styled.div`
  ${commonHeader}
`;

export const Name = styled.h1`
  ${commonName}
`;

export const Menu = styled.div`
  ${commonMenu}
`;

export const Number = styled.div`
  ${commonNumber}
`;

export const Button = styled.span`
  ${commonButton}
`;

export const Image = styled.img`
  ${commonImage}
`;

export const Title = styled.h2`
  ${commonTitle}
`;

export const Content = styled.div`
  ${commonContent}

  pre {
    white-space: pre-line;
  }
`;
