import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import Image from 'react-image-autofit-frame';
import Placeholder from '../../assets/images/SignInBackground.jpg';

import { withTheme } from '../../assets/Theme.style';
import {
  Section,
  Wrapper,
  Content,
  Imagebox,
  Footer
} from './SignInPage.style';

import SignInPanel from '../../components/SignInPanel/SignInPanel';

import { signInInit, signIn } from '../../actions/Auth/AuthActions';

class SignInPage extends Component {
  state = {};

  componentDidMount() {
    this.props.signInInit();
  }

  handleSubmit = values => {
    this.props.signIn({
      email: values.email,
      password: values.password,
      remember: values.remember ? true : false
    });
  };

  render() {
    const { firebaseStore, signInStore } = this.props;
    const theme = this.props.theme;

    return (
      <>
        <Helmet>
          <title>Sign in | BIN-E Smart Waste Bin</title>
        </Helmet>
        {!firebaseStore.auth.isEmpty ? (
          <Redirect to={{ pathname: '/' }} />
        ) : (
          <Section>
            <Wrapper theme={theme.sign.wrapper.default}>
              <Content>
                <SignInPanel store={signInStore} onSubmit={this.handleSubmit} />
              </Content>
              <Footer theme={theme.sign.footer.default}>
                By signing in, you accept{' '}
                <a
                  href='http://www.bine.world'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Privacy policy
                </a>{' '}
                <br />
                (including Use of Cookies and Other
                <br /> Technologies) and{' '}
                <a
                  href='http://www.bine.world'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Terms of use
                </a>
              </Footer>
            </Wrapper>
            <Wrapper hideMedium theme={theme.sign.wrapper.alternative}>
              <Imagebox>
                <Image imgSrc={Placeholder} />
              </Imagebox>
            </Wrapper>
          </Section>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return { firebaseStore: state.firebase, signInStore: state.authSignInStore };
};

const mapDispatchToProps = dispatch => {
  return {
    signInInit: () => dispatch(signInInit()),
    signIn: credentials => dispatch(signIn(credentials))
  };
};

export default compose(
  withTheme,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SignInPage);
