import styled from 'styled-components';

import { Media } from '../../assets/Mixins.style';

import { InlineButton as commonButton } from '../../assets/common/Button.style';

export const Box = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const Item = styled.div`
  display: block;
  width: 50%;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 500;

  ${Media.xlg`
    width: 100%;
  `}

  ${Media.lg`
    width: 50%;
  `}

  ${Media.sm`
    width: 100%;
  `}
`;

export const Title = styled.h3`
  display: block;
  width: 100%;
  font-weight: 700;
`;

export const Button = styled.button`
  ${commonButton}
`;
