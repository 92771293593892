import styled from 'styled-components';
import {
  Panel as commonPanel,
  Logobox as commonLogobox,
  Textbox as commonTextbox,
  Error as commonError,
  Formbox as commonFormbox,
  Footerbox as commonFooterbox
} from '../../assets/common/SignPanel.style';

import { Inputbox as commonInputbox } from '../../assets/common/Inputbox.style';

import { Button as commonButton } from '../../assets/common/Button.style';

export const Panel = styled.div`
  ${commonPanel}
`;

export const Logobox = styled.div`
  ${commonLogobox}
`;

export const Textbox = styled.div`
  ${commonTextbox}
`;

export const Error = styled.div`
  ${commonError}
`;

export const Successbox = styled.div`
  display: block;
  text-align: center;
  margin: 80px 0;
`;

export const Formbox = styled.div`
  ${commonFormbox}
`;

export const Inputbox = styled.div`
  ${commonInputbox}
`;

export const Button = styled.button`
  ${commonButton}
  margin: 35px 0 25px;
`;

export const Footerbox = styled.div`
  ${commonFooterbox}
`;
