import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { useIntl } from 'react-intl';

import { Box, Item, Content } from './DeviceUsersAssigned.style';

function DeviceUsersAssigned({ assignedUsers = [], users, customers }) {
  const intl = useIntl();
  return (
    <Box>
      {assignedUsers &&
        assignedUsers.map((id, key) => {
          const user = users && users.find(user => user.id === id);
          return (
            <Item key={key}>
              <Content>
                {user && (
                  <>
                    <strong>{user.displayName ? user.displayName : id}</strong>
                    {user.position && `, ${user.position}`}
                    {user.customer
                      ? customers &&
                        customers.find(
                          customer => customer.id === user.customer.id
                        )
                        ? `, ${
                            customers.find(
                              customer => customer.id === user.customer.id
                            ).name
                          }`
                        : `, ${intl.formatMessage({
                            id: 'DEVICE_USERS_ASSIGNED.ASSESSING_INFORMATION'
                          })}...`
                      : ''}
                  </>
                )}
              </Content>
            </Item>
          );
        })}
    </Box>
  );
}

DeviceUsersAssigned.propsTypes = {
  assignedUsers: PropTypes.array,
  distributor: PropTypes.string,
  customer: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  const users =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.deviceUsersAssignedUsers;
  const customers =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.deviceUsersAssignedCustomers;
  return {
    users,
    customers
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props, ownProps) => {
    const listener = [];
    if (props.distributor && props.customer) {
      const distributorRef = ownProps.firestore
        .collection('distributors')
        .doc(props.distributor);
      const customerRef = ownProps.firestore
        .collection('customers')
        .doc(props.customer);
      if (distributorRef && customerRef)
        listener.push(
          {
            collection: 'users',
            where: [
              ['distributor', '==', distributorRef],
              ['customer', '==', customerRef]
            ],
            storeAs: 'deviceUsersAssignedUsers'
          },
          {
            collection: 'customers',
            doc: props.customer,
            storeAs: 'deviceUsersAssignedCustomers'
          }
        );
    } else if (props.distributor && !props.customer) {
      const distributorRef = ownProps.firestore
        .collection('distributors')
        .doc(props.distributor);
      if (distributorRef)
        listener.push(
          {
            collection: 'users',
            where: [['distributor', '==', distributorRef]],
            storeAs: 'deviceUsersAssignedUsers'
          },
          {
            collection: 'customers',
            where: [['distributor', '==', distributorRef]],
            storeAs: 'deviceUsersAssignedCustomers'
          }
        );
    } else {
      listener.push(
        { collection: 'users', storeAs: 'deviceUsersAssignedUsers' },
        { collection: 'customers', storeAs: 'deviceUsersAssignedCustomers' }
      );
    }

    return listener;
  })
)(DeviceUsersAssigned);
