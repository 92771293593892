import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Theme } from '../../assets/Theme.style';

import { Wrapper, Content, Title, Item, Option } from './AccountSider.style';

function AccountSider({ theme = Theme.accountSider, currentPath = '' }) {
  const intl = useIntl();
  return (
    <Wrapper theme={theme.wrapper}>
      <Content>
        <Item>
          <Title theme={theme.title}>
            {intl.formatMessage({ id: 'HEADER.ACCOUNT_SETTINGS_ASIDE' })}
          </Title>
        </Item>
        <Item>
          <Link to='/profile-settings'>
            <Option
              theme={theme.option}
              data-active={
                currentPath === '/profile-settings' ? true : undefined
              }
            >
              {intl.formatMessage({ id: 'HEADER.PROFILE_SETTINGS' })}
            </Option>
          </Link>
        </Item>
        <Item>
          <Link to='/profile-notifications'>
            <Option
              theme={theme.option}
              data-active={
                currentPath === '/profile-notifications' ? true : undefined
              }
            >
              {intl.formatMessage({
                id: 'HEADER.PROFILE_NOTIFICATIONS'
              })}
            </Option>
          </Link>
        </Item>
        <Item>
          <Link to='/application-settings'>
            <Option
              theme={theme.option}
              data-active={
                currentPath === '/application-settings' ? true : undefined
              }
            >
              {intl.formatMessage({
                id: 'HEADER.APPLICATION_SETTINGS'
              })}
            </Option>
          </Link>
        </Item>
      </Content>
    </Wrapper>
  );
}

AccountSider.propTypes = {
  theme: PropTypes.object,
  currentPath: PropTypes.string
};

export default AccountSider;
