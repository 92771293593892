import styled from 'styled-components';
import Selectrix from 'react-selectrix';

import {
  Selectbox as commonSelectbox,
  Select as commonSelect
} from '../../assets/common/Select.style';

export const Box = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 15px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 35px;
  text-align: left;
`;

export const Menu = styled.div`
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
`;

export const Button = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 25px;
  height: 25px;
  cursor: pointer;
`;

export const ButtonIcon = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0;
`;

export const Content = styled.div`
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  cursor: default;
`;

export const Selectbox = styled.div`
  ${commonSelectbox}
`;

export const Select = styled(Selectrix)`
  ${commonSelect}
`;
