import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';

import { IntlProviderWrapper } from './IntlContext';
import { ThemeProvider } from './assets/Theme.style';

import store from './store';

import * as serviceWorker from './serviceWorker';

import System from './System';

const history = createBrowserHistory();

store.firebaseAuthIsReady.then(() => {
  ReactDOM.render(
    <HelmetProvider>
      <IntlProviderWrapper>
        <ThemeProvider>
          <Router history={history}>
            <Provider store={store}>
              <System />
            </Provider>
          </Router>
        </ThemeProvider>
      </IntlProviderWrapper>
    </HelmetProvider>,
    document.getElementById('root')
  );
});

serviceWorker.register();
