import styled from 'styled-components';

import {
  Wrapper as commonWrapper,
  Holder as commonHolder,
  Modal as commonModal,
  Close as commonClose,
  Image as commonImage,
  Title as commonTitle,
  Content as commonContent,
  Navigation as commonNavigation
} from '../../assets/common/Modal.style';
import { InlineButton as commonButton } from '../../assets/common/Button.style';
import {
  Alert as commonAlert,
  AlertImage as commonAlertImage,
  AlertContent as commonAlertContent,
  AlertTitle as commonAlertTitle
} from '../../assets/common/Alert.style';

export const Wrapper = styled.div`
  ${commonWrapper}
`;

export const Holder = styled.div`
  ${commonHolder}
`;

export const Modal = styled.div`
  ${commonModal}
`;

export const Close = styled.span`
  ${commonClose}
`;

export const Image = styled.img`
  ${commonImage}
`;

export const Title = styled.h1`
  ${commonTitle}
`;

export const Content = styled.div`
  ${commonContent}
`;

export const Navigation = styled.div`
  ${commonNavigation}
`;

export const Button = styled.button`
  ${commonButton}
  margin: 5px;
`;

export const Alertbox = styled.div`
  display: block;
  width: 100%;

  &:not(:empty) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const Alert = styled.div`
  ${commonAlert}
`;

export const AlertImage = styled.img`
  ${commonAlertImage}
`;

export const AlertContent = styled.h1`
  ${commonAlertContent}
`;

export const AlertTitle = styled.div`
  ${commonAlertTitle}
`;
