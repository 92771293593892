const defaultState = {
  email: '',
  isLoading: false,
  isInvalid: false,
  isValid: false,
  isError: false,
  isSuccess: false,
  error: ''
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'AUTH_RESETPASSWORD_INIT': {
      return {
        ...state,
        email: '',
        isLoading: false,
        isInvalid: false,
        isValid: false,
        isError: false,
        isSuccess: false,
        error: ''
      };
    }
    case 'AUTH_RESETPASSWORD_VALID': {
      return {
        ...state,
        isInvalid: false,
        isValid: true,
        email: action.email,
        error: ''
      };
    }
    case 'AUTH_RESETPASSWORD_INVALID': {
      return {
        ...state,
        isInvalid: true,
        isValid: false,
        email: '',
        error:
          action && action.err && action.err.message ? action.err.message : ''
      };
    }
    case 'AUTH_RESETPASSWORD_PENDING': {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
        error: ''
      };
    }
    case 'AUTH_RESETPASSWORD_SUCCESS': {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        error: ''
      };
    }
    case 'AUTH_RESETPASSWORD_ERROR': {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        error:
          action && action.err && action.err.message ? action.err.message : ''
      };
    }
    default:
      return state;
  }
};
