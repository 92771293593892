import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  withFirebase,
  firebaseConnect,
  firestoreConnect,
  isLoaded,
  isEmpty,
} from 'react-redux-firebase';
import { Helmet } from 'react-helmet-async';
import withTimeout from 'react-timeout';
import queryString from 'query-string';
import moment from 'moment';
import { injectIntl } from 'react-intl';

import Icon from '../../assets/images/Close.svg';

import { withTheme } from '../../assets/Theme.style';
import { Breakpoints } from '../../assets/Variables.style';
import {
  Wrapper,
  Content,
  Panel,
  PanelInner,
  Title,
  Box,
  List,
  Category,
  Subtitle,
  Details,
  Navigation,
  Button,
  Image,
  Information,
} from './DevicesPage.style';

import Header from '../../components/Header/Header';
import Sider from '../../components/Sider/Sider';
import DevicesItem from '../../components/DevicesItem/DevicesItem';
import DeviceDetails from '../../components/DeviceDetails/DeviceDetails';
import DeviceManage from '../../components/DeviceManage/DeviceManage';

import {
  initDeviceCommand,
  requestDeviceCommand,
  responseDeviceCommand,
  timeoutDeviceCommand,
  flushDeviceCommand,
  manageDeviceInit,
  updateDevice,
} from '../../actions/Device/DeviceActions';

import { roles } from '../../utils/roles';

class DevicesPage extends Component {
  state = {
    informationTimeout: false,
    informationTimeoutId: null,
    fillTimeout: false,
    fillTimeoutId: null,
    filter: true,
    filterOptions: [
      {
        name: this.props.intl.formatMessage({ id: 'SIDEBAR.SHOW_ALL' }),
        type: 'none',
        filter: 'none',
      },
      {
        name: this.props.intl.formatMessage({ id: 'SIDEBAR.FACTORIES' }),
        type: 'factory',
        filter: 'none',
        privileges: 'VIEW_FACTORIES',
      },
      {
        name: this.props.intl.formatMessage({ id: 'SIDEBAR.CLIENTS' }),
        type: 'distributor',
        filter: 'none',
        privileges: 'VIEW_CUSTOMERS',
      },
      {
        name: this.props.intl.formatMessage({ id: 'SIDEBAR.CONNECTION' }),
        type: 'connect',
        filter: 'none',
        items: [
          {
            name: this.props.intl.formatMessage({
              id: 'SIDEBAR.CONNECTION.CONNECTED',
            }),
            type: 'connect',
            filter: 'yes',
          },
          {
            name: this.props.intl.formatMessage({
              id: 'SIDEBAR.CONNECTION.NOT_CONNECTED',
            }),
            type: 'connect',
            filter: 'no',
          },
        ],
      },
    ],
    filterType: 'none',
    filterValue: 'none',
    visibleFilter: false,
    openFilter: false,
    width: window.innerWidth,
    visibleModal: false,
    visibleModalEdit: false,
    openModalEdit: false,
    historyLimit: 3,
  };

  componentDidMount() {
    const { location, initDeviceCommand, device } = this.props;
    const params = queryString.parse(location.search);

    window.addEventListener('resize', this.handleWindowSizeChange);
    window.addEventListener('beforeunload', this.handleLeavePage);

    if (device) {
      initDeviceCommand({ id: device.id, type: 'INFORMATION' });
      initDeviceCommand({ id: device.id, type: 'FILL' });
    }

    if (params && params.filter && params.type)
      this.setFilter({ filter: params.filter, type: params.type });
  }

  componentDidUpdate(prevProps) {
    const {
      informationTimeout,
      informationTimeoutId,
      fillTimeout,
      fillTimeoutId,
    } = this.state;
    const {
      location,
      firebase,
      initDeviceCommand,
      flushDeviceCommand,
      device,
      deviceInformationStore,
      deviceFillStore,
    } = this.props;
    const params = queryString.parse(location.search);
    const prevParams = queryString.parse(prevProps.location.search);

    if (
      prevProps.device &&
      ((device && prevProps.device.id !== device.id) || !device)
    ) {
      if (informationTimeout) {
        this.props.clearTimeout(informationTimeoutId);
        this.setState({
          informationTimeout: false,
          informationTimeoutId: null,
        });
      }

      firebase.unWatchEvent(
        'value',
        `devices/${prevProps.device.id}/response/${prevProps.deviceInformationStore.requestID}`
      );
      flushDeviceCommand({
        id: prevProps.device.id,
        requestID: prevProps.deviceInformationStore.requestID,
        type: 'INFORMATION',
      });

      if (fillTimeout) {
        this.props.clearTimeout(fillTimeoutId);
        this.setState({
          fillTimeout: false,
          fillTimeoutId: null,
        });
      }

      firebase.unWatchEvent(
        'value',
        `devices/${prevProps.device.id}/response/${prevProps.deviceFillStore.requestID}`
      );
      flushDeviceCommand({
        id: prevProps.device.id,
        requestID: prevProps.deviceFillStore.requestID,
        type: 'FILL',
      });
    }

    if (
      (!prevProps.device && device) ||
      (prevProps.device && device && prevProps.device.id !== device.id)
    ) {
      initDeviceCommand({ id: device.id, type: 'INFORMATION' });
      initDeviceCommand({ id: device.id, type: 'FILL' });
    }

    if (device) {
      if (
        deviceInformationStore.requestID &&
        (deviceInformationStore.requestID !==
          prevProps.deviceInformationStore.requestID ||
          !prevProps.deviceInformationStore.requestID)
      ) {
        firebase.watchEvent(
          'value',
          `devices/${device.id}/response/${deviceInformationStore.requestID}`
        );
      }

      if (
        deviceFillStore.requestID &&
        (deviceFillStore.requestID !== prevProps.deviceFillStore.requestID ||
          !prevProps.deviceFillStore.requestID)
      ) {
        firebase.watchEvent(
          'value',
          `devices/${device.id}/response/${deviceFillStore.requestID}`
        );
      }
    }

    if (device) {
      this.requestDeviceData(device.id);
      this.timeoutDeviceData(device.id);
      this.responseDeviceData(device.id);
    }

    if (
      prevParams &&
      params &&
      (params.filter !== prevParams.filter || params.type !== prevParams.type)
    )
      this.setFilter({ filter: params.filter, type: params.type });
  }

  componentWillUnmount() {
    const {
      firebase,
      flushDeviceCommand,
      device,
      deviceInformationStore,
      deviceFillStore,
    } = this.props;
    window.removeEventListener('resize', this.handleWindowSizeChange);
    window.removeEventListener('beforeunload', this.handleLeavePage);

    if (device) {
      if (deviceInformationStore.requestID) {
        firebase.unWatchEvent(
          'value',
          `devices/${device.id}/response/${deviceInformationStore.requestID}`
        );
        flushDeviceCommand({
          id: device.id,
          requestID: deviceInformationStore.requestID,
          type: 'INFORMATION',
        });
      }

      if (deviceFillStore.requestID) {
        firebase.unWatchEvent(
          'value',
          `devices/${device.id}/response/${deviceFillStore.requestID}`
        );
        flushDeviceCommand({
          id: device.id,
          requestID: deviceFillStore.requestID,
          type: 'FILL',
        });
      }
    }
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
    this.checkFilter();
  };

  handleLeavePage = () => {
    const {
      flushDeviceCommand,
      device,
      deviceInformationStore,
      deviceFillStore,
    } = this.props;
    if (device) {
      flushDeviceCommand({
        id: device.id,
        requestID: deviceInformationStore.requestID,
        type: 'INFORMATION',
      });
      flushDeviceCommand({
        id: device.id,
        requestID: deviceFillStore.requestID,
        type: 'FILL',
      });
    }
  };

  requestDeviceData = (id) => {
    const {
      deviceInformationStore,
      deviceFillStore,
      requestDeviceCommand,
    } = this.props;
    if (
      deviceInformationStore.requestID &&
      !deviceInformationStore.isRequest &&
      !deviceInformationStore.isResponse &&
      !deviceInformationStore.isTimeout &&
      !deviceInformationStore.isFlush
    )
      requestDeviceCommand({
        id,
        requestID: deviceInformationStore.requestID,
        type: 'INFORMATION',
        operation: 'Backend.getConfigFile',
      });

    if (
      deviceFillStore.requestID &&
      !deviceFillStore.isRequest &&
      !deviceFillStore.isResponse &&
      !deviceFillStore.isTimeout &&
      !deviceFillStore.isFlush
    ) {
      requestDeviceCommand({
        id,
        requestID: deviceFillStore.requestID,
        type: 'FILL',
        operation: 'AL.readBinLevels',
      });
    }
  };

  timeoutDeviceData = (id) => {
    const { informationTimeout, fillTimeout } = this.state;
    const {
      firebase,
      device,
      deviceInformationStore,
      deviceFillStore,
      timeoutDeviceCommand,
    } = this.props;
    const lastDevice = device.id;
    if (
      deviceInformationStore.isRequest &&
      !deviceInformationStore.isResponse &&
      !deviceInformationStore.isTimeout &&
      !deviceInformationStore.isFlush &&
      !informationTimeout
    ) {
      const informationTimeoutId = this.props.setTimeout(() => {
        const { device, deviceInformationStore } = this.props;
        if (
          device.id === lastDevice &&
          deviceInformationStore.isRequest &&
          !deviceInformationStore.isResponse &&
          !deviceInformationStore.isTimeout &&
          !deviceInformationStore.isFlush
        ) {
          firebase.unWatchEvent(
            'value',
            `devices/${device.id}/response/${deviceInformationStore.requestID}`
          );
          timeoutDeviceCommand({
            id,
            requestID: deviceInformationStore.requestID,
            type: 'INFORMATION',
          });
          this.setState({
            informationTimeout: false,
            informationTimeoutId: null,
          });
        }
      }, deviceInformationStore.timeout * 1000);
      this.setState({
        informationTimeout: true,
        informationTimeoutId: informationTimeoutId,
      });
    }

    if (
      deviceFillStore.isRequest &&
      !deviceFillStore.isResponse &&
      !deviceFillStore.isTimeout &&
      !deviceFillStore.isFlush &&
      !fillTimeout
    ) {
      const fillTimeoutId = this.props.setTimeout(() => {
        const { device, deviceFillStore } = this.props;
        if (
          device.id === lastDevice &&
          deviceFillStore.isRequest &&
          !deviceFillStore.isResponse &&
          !deviceFillStore.isTimeout &&
          !deviceFillStore.isFlush
        ) {
          firebase.unWatchEvent(
            'value',
            `devices/${device.id}/response/${deviceFillStore.requestID}`
          );
          timeoutDeviceCommand({
            id,
            requestID: deviceFillStore.requestID,
            type: 'FILL',
          });
          this.setState({ fillTimeout: false, fillTimeoutId: null });
        }
      }, deviceFillStore.timeout * 1000);
      this.setState({ fillTimeout: true, fillTimeoutId: fillTimeoutId });
    }
  };

  responseDeviceData = (id) => {
    const {
      firebase,
      devicesData,
      deviceInformationStore,
      deviceFillStore,
      responseDeviceCommand,
    } = this.props;
    const device =
      devicesData && Object.entries(devicesData).find((e) => e[0] === id);
    if (device) {
      if (
        deviceInformationStore.isRequest &&
        !deviceInformationStore.isResponse &&
        !deviceInformationStore.isTimeout &&
        !deviceInformationStore.isFlush
      ) {
        const response =
          device[1] &&
          device[1].response &&
          Object.entries(device[1].response).find(
            (e) => e[0] === deviceInformationStore.requestID
          );
        if (response && response[1]) {
          firebase.unWatchEvent(
            'value',
            `devices/${device.id}/response/${deviceInformationStore.requestID}`
          );
          responseDeviceCommand({
            id: device[0],
            requestID: deviceInformationStore.requestID,
            response: response[1],
            type: 'INFORMATION',
          });
        }
      }

      if (
        deviceFillStore.isRequest &&
        !deviceFillStore.isResponse &&
        !deviceFillStore.isTimeout &&
        !deviceFillStore.isFlush
      ) {
        const response =
          device[1] &&
          device[1].response &&
          Object.entries(device[1].response).find(
            (e) => e[0] === deviceFillStore.requestID
          );
        if (response && response[1]) {
          firebase.unWatchEvent(
            'value',
            `devices/${device.id}/response/${deviceFillStore.requestID}`
          );
          responseDeviceCommand({
            id: device[0],
            requestID: deviceFillStore.requestID,
            response: response[1],
            type: 'FILL',
          });
        }
      }
    }
  };

  toggleFilter = () => {
    if (!this.state.openFilter) {
      this.setState({
        visibleFilter: true,
        openFilter: !this.state.openFilter,
      });
    } else {
      this.setState({
        openFilter: !this.state.openFilter,
      });
      this.props.setTimeout(() => {
        this.setState({
          visibleFilter: false,
        });
      }, 200);
    }
  };

  checkFilter = () => {
    if (this.state.width > Breakpoints.lg && this.state.openFilter) {
      this.setState({
        visibleFilter: false,
        openFilter: false,
      });
    }
  };

  setFilter = ({ filter, type }) => {
    this.setState({
      filterValue: filter,
      filterType: type,
    });
  };

  openModal = (param) => {
    const { device, deviceUsers } = this.props;
    this.props.manageDeviceInit({
      device: device,
      deviceUsers:
        deviceUsers &&
        deviceUsers.map((user) => {
          return {
            id: user.id,
            displayName: user.displayName,
            position: user.position,
            customer: user.customer,
          };
        }),
    });
    this.setState({
      visibleModalEdit: param === 'edit' ? true : false,
      openModalEdit: param === 'edit' ? true : false,
      photoData: null,
    });
  };

  closeModal = () => {
    this.setState({
      openModalEdit: false,
    });
    this.props.setTimeout(() => {
      this.setState({
        visibleModalEdit: false,
        photoData: null,
      });
    }, 200);
  };

  handleSubmit = async (data) => {
    const { type, values } = data;

    switch (type) {
      case 'DEVICE_UPDATE':
        await this.props
          .updateDevice(this.props.device.id, {
            device: {
              name: values.name || '',
              street: values.street || '',
              city: values.city || '',
              postalCode: values.postalCode || '',
              country: values.country || '',
              place: values.place || '',
            },
            users: values.users,
            distributor: values.distributor || '',
            customer: values.customer || '',
          })
          .then(() => {
            this.closeModal();
          });
        break;
      default:
        return false;
    }
    return true;
  };

  render() {
    const {
      filter,
      filterOptions,
      filterType,
      filterValue,
      visibleFilter,
      openFilter,
      width,
      visibleModalEdit,
      openModalEdit,
    } = this.state;
    const {
      firebaseStore,
      location,
      history,
      devices,
      devicesData,
      device,
      deviceUsers,
      deviceDistributor,
      deviceCustomer,
      deviceManageStore,
      deviceInformationStore,
      deviceFillStore,
      distributors,
      customers,
      factories,
      deviceNotes,
      intl,
      initDeviceCommand,
    } = this.props;
    const theme = this.props.theme;
    const isMobile = width <= Breakpoints.lg;
    const authRole = roles.find((el) => {
      const profileRole =
        firebaseStore.profile.role &&
        Object.entries(firebaseStore.profile.role).find((el) => el[1] === true);
      return profileRole && el.id === profileRole[0];
    });

    let factoriesID = [];
    let distributorsID = [];
    let extendedFilterOptions;

    extendedFilterOptions = filterOptions.map((el) => {
      switch (el.type) {
        case 'factory':
          factoriesID =
            (factories &&
              Object.values(factories) &&
              Array.from(
                new Set(Object.values(factories).map((el) => el.id))
              )) ||
            [];
          return {
            ...el,
            items:
              (factories &&
                factories.map((e) => {
                  return {
                    name: e.name,
                    type: 'factory',
                    filter: e.id,
                  };
                })) ||
              [],
          };
        case 'distributor':
          distributorsID =
            (distributors &&
              Object.values(distributors) &&
              Array.from(
                new Set(Object.values(distributors).map((el) => el.id))
              )) ||
            [];
          return {
            ...el,
            items:
              (distributors &&
                distributors.map((e) => {
                  return {
                    name: e.name,
                    type: 'distributor',
                    filter: e.id,
                    items:
                      (customers &&
                        Object.values(customers) &&
                        Object.values(customers)
                          .filter(
                            (el) => el.distributor && el.distributor.id === e.id
                          )
                          .map((e) => {
                            return {
                              name: e.name,
                              type: 'customer',
                              filter: e.id,
                            };
                          })) ||
                      [],
                  };
                })) ||
              [],
          };
        default:
          return el;
      }
    });

    let devicesFiltered;
    switch (filterType) {
      case 'factory':
        switch (filterValue) {
          case 'none':
            devicesFiltered = factoriesID
              .map((el) => {
                return {
                  name: el,
                  items:
                    (devices &&
                      Object.values(devices) &&
                      Object.values(devices).filter(
                        (e) => e.factory && e.factory.id === el
                      )) ||
                    [],
                };
              })
              .filter((el) => el.items.length > 0);
            if (
              devices &&
              Object.values(devices) &&
              Object.values(devices).find(
                (el) =>
                  el.factory === undefined ||
                  el.factory === '' ||
                  el.factory === 'unassigned'
              )
            ) {
              devicesFiltered.push({
                name: intl.formatMessage({ id: 'SIDEBAR.UNCATEGORIZED' }),
                items: Object.values(devices).filter(
                  (el) =>
                    el.factory === undefined ||
                    el.factory === '' ||
                    el.factory === 'unassigned'
                ),
              });
            }
            break;
          default:
            devicesFiltered = factoriesID
              .map((el) => {
                return {
                  name: el,
                  items:
                    (devices &&
                      Object.values(devices) &&
                      Object.values(devices).filter(
                        (e) =>
                          e.factory &&
                          e.factory.id === el &&
                          e.factory.id === filterValue
                      )) ||
                    [],
                };
              })
              .filter((el) => el.items.length > 0);
        }
        break;
      case 'distributor':
        switch (filterValue) {
          case 'none':
            devicesFiltered =
              distributorsID &&
              distributorsID
                .map((el) => {
                  return {
                    name: el,
                    items:
                      (devices &&
                        Object.values(devices) &&
                        Object.values(devices).filter(
                          (e) => e.distributor && e.distributor.id === el
                        )) ||
                      [],
                  };
                })
                .filter((el) => el.items.length > 0);
            if (
              devices &&
              Object.values(devices) &&
              Object.values(devices).find(
                (el) => el.distributor === undefined || el.distributor === ''
              )
            ) {
              devicesFiltered.push({
                name: intl.formatMessage({ id: 'SIDEBAR.UNCATEGORIZED' }),
                items: Object.values(devices).filter(
                  (el) => el.distributor === undefined || el.distributor === ''
                ),
              });
            }
            break;
          default:
            devicesFiltered =
              distributorsID &&
              distributorsID
                .map((el) => {
                  return {
                    name: el,
                    items:
                      (devices &&
                        Object.values(devices) &&
                        Object.values(devices).filter(
                          (e) =>
                            e.distributor &&
                            e.distributor.id === el &&
                            e.distributor.id === filterValue
                        )) ||
                      [],
                  };
                })
                .filter((el) => el.items.length > 0);
        }
        break;
      case 'customer':
        if (filterValue) {
          const customer =
            customers && customers.find((el) => el.id === filterValue);
          devicesFiltered = [];
          if (
            customer &&
            devices &&
            Object.values(devices) &&
            Object.values(devices).find(
              (e) =>
                e.customer &&
                e.customer.id === customer.id &&
                e.customer.id === filterValue
            )
          ) {
            devicesFiltered.push({
              name: customer.id,
              items:
                (devices &&
                  Object.values(devices) &&
                  Object.values(devices).filter(
                    (e) =>
                      e.customer &&
                      e.customer.id === customer.id &&
                      e.customer.id === filterValue
                  )) ||
                [],
            });
          }
        }
        break;
      case 'connect':
        if (
          devices &&
          Object.values(devices) &&
          Object.values(devices).length > 0
        ) {
          switch (filterValue) {
            case 'none':
              devicesFiltered = [
                {
                  name: intl.formatMessage({
                    id: 'SIDEBAR.CONNECTION.CONNECTED',
                  }),
                  items:
                    Object.values(devices).filter((device) => {
                      const heartbeat =
                        firebaseStore.ordered.devices &&
                        firebaseStore.ordered.devices[device.id] &&
                        firebaseStore.ordered.devices[device.id].heartbeat;
                      return (
                        heartbeat &&
                        heartbeat.length > 0 &&
                        Math.abs(
                          moment().diff(
                            moment(
                              heartbeat[heartbeat.length - 1].value.timestamp
                            ),
                            'minutes'
                          )
                        ) <= 15
                      );
                    }) || [],
                },
                {
                  name: intl.formatMessage({
                    id: 'SIDEBAR.CONNECTION.NOT_CONNECTED',
                  }),
                  items:
                    Object.values(devices).filter((device) => {
                      const heartbeat =
                        firebaseStore.ordered.devices &&
                        firebaseStore.ordered.devices[device.id] &&
                        firebaseStore.ordered.devices[device.id].heartbeat;
                      return (
                        !heartbeat ||
                        (heartbeat &&
                          (heartbeat.length <= 0 ||
                            (heartbeat.length > 0 &&
                              Math.abs(
                                moment().diff(
                                  moment(
                                    heartbeat[heartbeat.length - 1].value
                                      .timestamp
                                  ),
                                  'minutes'
                                )
                              ) > 15))) ||
                        !heartbeat
                      );
                    }) || [],
                },
              ].filter((el) => el.items.length > 0);
              break;
            case 'yes':
              devicesFiltered = [
                {
                  name: intl.formatMessage({
                    id: 'SIDEBAR.CONNECTION.CONNECTED',
                  }),
                  items:
                    Object.values(devices).filter((device) => {
                      const heartbeat =
                        firebaseStore.ordered.devices &&
                        firebaseStore.ordered.devices[device.id] &&
                        firebaseStore.ordered.devices[device.id].heartbeat;
                      return (
                        heartbeat &&
                        heartbeat.length > 0 &&
                        Math.abs(
                          moment().diff(
                            moment(
                              heartbeat[heartbeat.length - 1].value.timestamp
                            ),
                            'minutes'
                          )
                        ) <= 15
                      );
                    }) || [],
                },
              ].filter((el) => el.items.length > 0);
              break;
            case 'no':
              devicesFiltered = [
                {
                  name: intl.formatMessage({
                    id: 'SIDEBAR.CONNECTION.NOT_CONNECTED',
                  }),
                  items:
                    Object.values(devices).filter((device) => {
                      const heartbeat =
                        firebaseStore.ordered.devices &&
                        firebaseStore.ordered.devices[device.id] &&
                        firebaseStore.ordered.devices[device.id].heartbeat;
                      return (
                        !heartbeat ||
                        (heartbeat &&
                          (heartbeat.length <= 0 ||
                            (heartbeat.length > 0 &&
                              Math.abs(
                                moment().diff(
                                  moment(
                                    heartbeat[heartbeat.length - 1].value
                                      .timestamp
                                  ),
                                  'minutes'
                                )
                              ) > 15))) ||
                        !heartbeat
                      );
                    }) || [],
                },
              ].filter((el) => el.items.length > 0);
              break;
            default:
          }
        }
        break;
      default:
    }

    return (
      <>
        <Helmet>
          <title>
            {device
              ? `${device.name ? device.name : device.serialNo}`
              : intl.formatMessage({ id: 'PAGE_TITLE.DEVICES' })}{' '}
            | BIN-E Smart Waste Bin
          </title>
        </Helmet>

        <Wrapper>
          <Header
            currentPath={location.pathname}
            profile={firebaseStore.profile}
            filter={isMobile && device ? false : filter}
            handleToggleFilter={this.toggleFilter}
          />
          <Content>
            {(!isMobile || (isMobile && !device)) && (
              <Sider
                options={extendedFilterOptions}
                location={location}
                history={history}
                visible={visibleFilter}
                open={openFilter}
                handleToggleFilter={this.toggleFilter}
                authRole={authRole}
              />
            )}

            <Panel
              theme={
                isMobile && device
                  ? theme.content.panel.alternative
                  : theme.content.panel.default
              }
            >
              <PanelInner>
                {(!isMobile || (isMobile && !device)) && (
                  <Title theme={theme.content.title}>
                    {intl.formatMessage({ id: 'DEVICES.DEVICES' })}
                  </Title>
                )}
                {authRole &&
                authRole.privileges &&
                ((authRole.privileges['RESPECT_PROFILE_MEMBERSHIP'] &&
                  firebaseStore.profile.distributor &&
                  ((authRole.privileges['VIEW_ONLY_ASSIGNED_DEVICES'] &&
                    firebaseStore.profile.bines &&
                    firebaseStore.profile.bines.length > 0) ||
                    !authRole.privileges['VIEW_ONLY_ASSIGNED_DEVICES'])) ||
                  !authRole.privileges['RESPECT_PROFILE_MEMBERSHIP']) &&
                !isLoaded(devices) ? (
                  <Box>
                    <Information>
                      {intl.formatMessage({ id: 'DEVICES.LOADING' })}...
                    </Information>
                  </Box>
                ) : (
                  <Box>
                    {(!isMobile || (isMobile && !device)) && (
                      <List theme={theme.scroll}>
                        {!isEmpty(devices) ? (
                          devicesFiltered ? (
                            devicesFiltered.map((el, key) => (
                              <Category key={key}>
                                <Subtitle>
                                  {filterType === 'factory'
                                    ? (factories &&
                                        factories.find(
                                          (e) => e.id === el.name
                                        ) &&
                                        factories.find((e) => e.id === el.name)
                                          .name) ||
                                      el.name
                                    : filterType === 'distributor'
                                    ? distributors &&
                                      distributors.find((e) => e.id === el.name)
                                      ? distributors.find(
                                          (e) => e.id === el.name
                                        ).name
                                      : el.name
                                    : (filterType === 'customer' &&
                                        customers &&
                                        customers.find(
                                          (e) => e.id === el.name
                                        ) &&
                                        customers.find((e) => e.id === el.name)
                                          .name) ||
                                      el.name}
                                </Subtitle>
                                {el.items.map((el) => (
                                  <Link
                                    to={`/devices/${el.id}${
                                      location.search ? location.search : ''
                                    }`}
                                    key={el.id}
                                  >
                                    <DevicesItem
                                      theme={theme.content.item}
                                      data={{
                                        ...el,
                                        heartbeat:
                                          firebaseStore.ordered.devices &&
                                          firebaseStore.ordered.devices[
                                            el.id
                                          ] &&
                                          firebaseStore.ordered.devices[el.id]
                                            .heartbeat,
                                      }}
                                      isActive={device && el.id === device.id}
                                    />
                                  </Link>
                                ))}
                              </Category>
                            ))
                          ) : (
                            Object.values(devices).map((el) => (
                              <Link
                                to={`/devices/${el.id}${
                                  location.search ? location.search : ''
                                }`}
                                key={el.id}
                              >
                                <DevicesItem
                                  theme={theme.content.item}
                                  data={{
                                    ...el,
                                    heartbeat:
                                      firebaseStore.ordered.devices &&
                                      firebaseStore.ordered.devices[el.id] &&
                                      firebaseStore.ordered.devices[el.id]
                                        .heartbeat,
                                  }}
                                  isActive={device && el.id === device.id}
                                />
                              </Link>
                            ))
                          )
                        ) : (
                          <Information>
                            {intl.formatMessage({ id: 'DEVICES.NO_ASSIGNED' })}
                          </Information>
                        )}
                      </List>
                    )}
                    {device && (
                      <Details theme={theme.scroll}>
                        {isMobile && (
                          <Navigation>
                            <Link
                              to={`/devices${
                                location.search ? location.search : ''
                              }`}
                            >
                              <Button
                                title={intl.formatMessage({
                                  id: 'DEVICES.CLOSE',
                                })}
                              >
                                <Image
                                  src={Icon}
                                  alt={intl.formatMessage({
                                    id: 'DEVICES.CLOSE',
                                  })}
                                />
                              </Button>
                            </Link>
                          </Navigation>
                        )}
                        <DeviceDetails
                          data={{
                            ...device,
                            history: {
                              info:
                                (devicesData &&
                                  devicesData[device.id] &&
                                  devicesData[device.id].history &&
                                  devicesData[device.id].history.info &&
                                  devicesData[device.id].history.info) ||
                                [],
                              success:
                                (devicesData &&
                                  devicesData[device.id] &&
                                  devicesData[device.id].history &&
                                  devicesData[device.id].history.success &&
                                  devicesData[device.id].history.success) ||
                                [],
                              error:
                                (devicesData &&
                                  devicesData[device.id] &&
                                  devicesData[device.id].history &&
                                  devicesData[device.id].history.error &&
                                  devicesData[device.id].history.error) ||
                                [],
                            },
                            statistics: {
                              info:
                                (devicesData &&
                                  devicesData[device.id] &&
                                  devicesData[device.id].statistics &&
                                  devicesData[device.id].statistics.info &&
                                  devicesData[device.id].statistics.info) ||
                                [],
                              success:
                                (devicesData &&
                                  devicesData[device.id] &&
                                  devicesData[device.id].statistics &&
                                  devicesData[device.id].statistics.success &&
                                  devicesData[device.id].statistics.success) ||
                                [],
                              error:
                                (devicesData &&
                                  devicesData[device.id] &&
                                  devicesData[device.id].statistics &&
                                  devicesData[device.id].statistics.error &&
                                  devicesData[device.id].statistics.error) ||
                                [],
                            },
                            users: deviceUsers,
                            distributorData: deviceDistributor,
                            customerData: deviceCustomer,
                            cb: {
                              fill: deviceFillStore,
                            },
                            ir: {
                              info: deviceInformationStore,
                              heartbeat:
                                firebaseStore.ordered.devices &&
                                firebaseStore.ordered.devices[device.id] &&
                                firebaseStore.ordered.devices[device.id]
                                  .heartbeat,
                            },
                            notes: deviceNotes,
                          }}
                          handleClick={() => this.openModal('edit')}
                          authRole={authRole}
                          addons={firebaseStore.profile.addons}
                          reloadDeviceCommand={(type) => {
                            initDeviceCommand({ id: device.id, type });
                          }}
                        />
                      </Details>
                    )}
                  </Box>
                )}
              </PanelInner>
            </Panel>
          </Content>
        </Wrapper>
        {visibleModalEdit && (
          <DeviceManage
            store={deviceManageStore}
            onSubmit={(values) => {
              this.handleSubmit({ type: 'DEVICE_UPDATE', values });
            }}
            handleClose={this.closeModal}
            open={openModalEdit}
            authRole={authRole}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const authRole = roles.find((el) => {
    const profileRole =
      state.firebase.profile.role &&
      Object.entries(state.firebase.profile.role).find((el) => el[1] === true);
    return profileRole && el.id === profileRole[0];
  });

  let devices;
  if (
    authRole &&
    authRole.privileges &&
    authRole.privileges['VIEW_ONLY_ASSIGNED_DEVICES']
  ) {
    devices = [];
    const currentUserBines =
      state.firebase.profile && state.firebase.profile.bines;
    if (currentUserBines)
      currentUserBines.forEach((device) => {
        const userDevice =
          state.firestore &&
          state.firestore.ordered &&
          state.firestore.ordered[`devices/${device.id}`];

        if (userDevice) {
          devices.push(...userDevice);
        }
      });
  } else {
    devices =
      state.firestore &&
      state.firestore.ordered &&
      state.firestore.ordered.devices;
  }

  const devicesData =
    state.firebase && state.firebase.ordered && state.firebase.ordered.devices;

  const distributors =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.distributors;

  const customers =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.customers;

  const factories =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.factories;

  const deviceID = ownProps.match.params.id;

  const device = devices && devices.find((e) => e.id === deviceID);

  const deviceUsers =
    state.firestore && state.firestore.ordered && state.firestore.ordered.users;

  const deviceDistributorID =
    device && device.distributor && device.distributor.id;
  const deviceCustomerID = device && device.customer && device.customer.id;

  const deviceDistributor =
    deviceDistributorID &&
    distributors &&
    distributors.find((e) => e.id === deviceDistributorID);

  const deviceCustomer =
    deviceCustomerID &&
    customers &&
    customers.find((e) => e.id === deviceCustomerID);

  const deviceNotes =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.deviceNotes;

  return {
    firebaseStore: state.firebase,
    devices,
    devicesData,
    distributors,
    customers,
    factories,
    device,
    deviceUsers,
    deviceDistributor,
    deviceCustomer,
    deviceManageStore: state.deviceManageStore,
    deviceInformationStore: state.deviceInformationStore,
    deviceFillStore: state.deviceFillStore,
    deviceHistoryStore: state.deviceHistoryStore,
    deviceStatisticsStore: state.deviceStatisticsStore,
    deviceNotes: deviceNotes,
    deviceNotesStore: state.notesStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initDeviceCommand: ({ id, type }) =>
      dispatch(initDeviceCommand({ id, type })),
    requestDeviceCommand: ({
      id,
      requestID,
      type,
      operation,
      action,
      object,
    }) =>
      dispatch(
        requestDeviceCommand({ id, requestID, type, operation, action, object })
      ),
    responseDeviceCommand: ({ id, requestID, response, type }) =>
      dispatch(responseDeviceCommand({ id, requestID, response, type })),
    timeoutDeviceCommand: ({ id, requestID, type }) =>
      dispatch(timeoutDeviceCommand({ id, requestID, type })),
    flushDeviceCommand: ({ id, requestID, type }) =>
      dispatch(flushDeviceCommand({ id, requestID, type })),
    manageDeviceInit: (data) => dispatch(manageDeviceInit(data)),
    updateDevice: (id, data) => dispatch(updateDevice(id, data)),
  };
};

export default compose(
  injectIntl,
  withFirebase,
  withTheme,
  withRouter,
  withTimeout,
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect(
    ({
      firebaseStore: { profile },
      match,
      devices,
      deviceHistoryStore,
      deviceStatisticsStore,
    }) => {
      const listener = [];
      const deviceID = match.params.id;

      if (deviceID) {
        const historyQueryParams = [
          `orderByChild=${deviceHistoryStore.type}`,
          `limitToLast=${Math.round(deviceHistoryStore.limit)}`,
        ];

        if (deviceHistoryStore.from)
          historyQueryParams.push(`startAt=${deviceHistoryStore.from}`);
        if (deviceHistoryStore.to)
          historyQueryParams.push(`endAt=${deviceHistoryStore.to}`);
        if (deviceHistoryStore.status)
          historyQueryParams.push(`equalTo=${deviceHistoryStore.status}`);

        listener.push(
          {
            path: `/devices/${deviceID}/info`,
            queryParams: historyQueryParams,
            storeAs: `/devices/${deviceID}/history/info`,
          },
          {
            path: `/devices/${deviceID}/success`,
            queryParams: historyQueryParams,
            storeAs: `/devices/${deviceID}/history/success`,
          },
          {
            path: `/devices/${deviceID}/error`,
            queryParams: historyQueryParams,
            storeAs: `/devices/${deviceID}/history/error`,
          }
        );

        if (profile && profile.addons && profile.addons.statistics) {
          const statisticsQueryParams = [
            `orderByChild=${deviceStatisticsStore.type}`,
          ];

          if (deviceStatisticsStore.from)
            statisticsQueryParams.push(`startAt=${deviceStatisticsStore.from}`);
          if (deviceStatisticsStore.to)
            statisticsQueryParams.push(`endAt=${deviceStatisticsStore.to}`);

          listener.push(
            {
              path: `/devices/${deviceID}/info`,
              queryParams: statisticsQueryParams,
              storeAs: `/devices/${deviceID}/statistics/info`,
            },
            {
              path: `/devices/${deviceID}/success`,
              queryParams: statisticsQueryParams,
              storeAs: `/devices/${deviceID}/statistics/success`,
            },
            {
              path: `/devices/${deviceID}/error`,
              queryParams: statisticsQueryParams,
              storeAs: `/devices/${deviceID}/statistics/error`,
            }
          );
        }
      }

      if (devices) {
        devices.forEach((device) => {
          listener.push({
            path: `/devices/${device.id}/heartbeat`,
            queryParams: ['limitToLast=1'],
          });
        });
      }

      return listener;
    }
  ),
  firestoreConnect(
    (
      { match, firebaseStore: { profile }, deviceNotesStore },
      { firestore }
    ) => {
      const deviceID = match.params.id;
      const listener = [];

      const authRole = roles.find((role) => {
        const profileRole =
          profile.role &&
          Object.entries(profile.role).find((role) => role[1] === true);
        return profileRole && role.id === profileRole[0];
      });

      if (
        authRole &&
        authRole.privileges &&
        authRole.privileges['RESPECT_PROFILE_MEMBERSHIP']
      ) {
        const currentUserDistributor = profile.distributor;
        if (currentUserDistributor) {
          const distributorRef = firestore
            .collection('distributors')
            .doc(currentUserDistributor.id);

          if (authRole.privileges['VIEW_ONLY_ASSIGNED_DEVICES']) {
            const currentUserBines = profile.bines;

            if (currentUserBines) {
              currentUserBines.forEach((device) => {
                listener.push({
                  collection: 'devices',
                  doc: device.id,
                  storeAs: `devices/${device.id}`,
                });
              });
            }
          } else {
            listener.push({
              collection: 'devices',
              where: [['distributor', '==', distributorRef]],
            });
          }
          listener.push(
            { collection: 'distributors', doc: currentUserDistributor.id },
            {
              collection: 'customers',
              where: [['distributor', '==', distributorRef]],
            }
          );
        }
      } else {
        listener.push(
          { collection: 'devices' },
          { collection: 'distributors' },
          { collection: 'customers' }
        );
      }

      if (
        authRole &&
        authRole.privileges &&
        authRole.privileges['VIEW_FACTORIES']
      )
        listener.push({ collection: 'factories' });

      if (
        deviceID &&
        authRole &&
        authRole.privileges &&
        authRole.privileges['VIEW_DEVICE_NOTES']
      ) {
        const deviceRef = firestore.collection('devices').doc(deviceID);
        listener.push({
          collection: 'notes',
          orderBy: [
            ['createdAt', 'desc'],
            ['updatedAt', 'desc'],
          ],
          where: [['bine', '==', deviceRef]],
          limit: deviceNotesStore.limit + 1,
          storeAs: `deviceNotes`,
        });
      }

      if (deviceID) {
        const deviceRef = firestore.collection('devices').doc(deviceID);
        listener.push({
          collection: 'users',
          where: [['bines', 'array-contains', deviceRef]],
        });
      }
      return listener;
    }
  )
)(DevicesPage);
