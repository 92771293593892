//TODO: NEED REFACTOR

import { createTheming } from '@callstack/react-theme-provider';
import { Colors } from './Variables.style';

const Theme = {
  header: {
    background: Colors.white,
    border: Colors.mercuryTwo,
    hamburger: {
      button: {
        default: {
          color: Colors.scorpion,
          colorOnHover: Colors.apple,
          colorOnActive: Colors.apple,
        },
      },
    },
    menu: {
      background: Colors.white,
      border: Colors.mercuryTwo,
      thumb: Colors.apple,
      track: Colors.wildSand,
    },
    menuInner: {
      icon: {
        default: {
          color: Colors.silverChalice,
          colorOnHover: Colors.apple,
          colorOnActive: Colors.apple,
        },
      },
      text: {
        default: {
          color: Colors.silverChalice,
          colorOnHover: Colors.apple,
          colorOnActive: Colors.apple,
        },
      },
    },
    profile: {
      button: {
        background: Colors.mercuryTwo,
      },
      content: {
        background: Colors.white,
        backgroundMobile: Colors.scorpion,
        border: Colors.mercuryTwo,
        title: {
          color: Colors.white,
        },
        link: {
          default: {
            color: Colors.silverChalice,
            colorOnHover: Colors.apple,
            colorOnActive: Colors.apple,
            colorMobileOnActive: Colors.silverChalice,
          },
        },
      },
      overlap: {
        background: Colors.blackAlfa8,
      },
    },
  },
  sign: {
    wrapper: {
      default: {
        background: Colors.white,
      },
      alternative: {
        background: Colors.alto,
      },
    },
    text: {
      default: {
        color: Colors.scorpion,
        colorOnHover: Colors.apple,
      },
    },
    footer: {
      default: {
        color: Colors.scorpion,
        colorOnHover: Colors.apple,
      },
    },
    panel: {
      default: {
        background: Colors.white,
      },
      error: {
        default: {
          color: Colors.cranberry,
        },
      },
      inputbox: {
        default: {
          color: Colors.scorpion,
          colorOnInvalid: Colors.cranberry,
          borderColor: Colors.mercury,
          borderColorOnInvalid: Colors.cranberry,
          borderColorOnValid: Colors.apple,
          alertColor: Colors.cranberry,
        },
      },
      checkbox: {
        default: {
          borderColor: Colors.mercury,
          iconColor: Colors.apple,
        },
      },
      linkbox: {
        default: {
          color: Colors.scorpion,
          colorOnHover: Colors.apple,
        },
      },
      footerbox: {
        default: {
          color: Colors.cranberry,
        },
      },
    },
  },
  sider: {
    wrapper: {
      background: Colors.white,
      thumb: Colors.apple,
      track: Colors.wildSand,
    },
    label: {
      color: Colors.scorpion,
    },
    inputbox: {
      default: {
        color: Colors.scorpion,
        colorOnInvalid: Colors.cranberry,
        borderColor: Colors.mercury,
        borderColorOnInvalid: Colors.cranberry,
        borderColorOnValid: Colors.apple,
        alertColor: Colors.cranberry,
      },
    },
    option: {
      color: Colors.scorpion,
      colorOnHover: Colors.apple,
    },
    overlap: {
      background: Colors.blackAlfa8,
    },
  },
  accountSider: {
    wrapper: {
      background: Colors.scorpion,
      thumb: Colors.apple,
      track: Colors.wildSand,
    },
    title: {
      color: Colors.white,
    },
    option: {
      color: Colors.alto,
      colorOnHover: Colors.apple,
      colorOnActive: Colors.silverChalice,
    },
  },
  content: {
    panel: {
      default: {
        background: Colors.concrete,
      },
      alternative: {
        background: Colors.white,
      },
    },
    title: {
      color: Colors.apple,
    },
    settings: {
      inputbox: {
        default: {
          color: Colors.scorpion,
          colorOnInvalid: Colors.cranberry,
          borderColor: Colors.mercury,
          borderColorOnInvalid: Colors.cranberry,
          borderColorOnValid: Colors.apple,
          alertColor: Colors.cranberry,
        },
      },
      selectbox: {
        default: {
          alertColor: Colors.cranberry,
        },
        selectrix: {
          color: Colors.scorpion,
          colorPlaceholder: Colors.boulder,
          borderColor: Colors.mercury,
          borderColorOnFocus: Colors.apple,
        },
      },
      daypicker: {
        default: {
          color: Colors.scorpion,
          colorOnInvalid: Colors.cranberry,
          borderColor: Colors.mercury,
          borderColorOnInvalid: Colors.cranberry,
          borderColorOnValid: Colors.apple,
          alertColor: Colors.cranberry,
          popperTriangleBorder: Colors.white,
          popperHeaderBorder: Colors.apple,
          popperHeaderBg: Colors.apple,
          popperHeaderColor: Colors.white,
          popperColor: Colors.scorpion,
          popperSelectedBg: Colors.apple,
          popperSelectedColor: Colors.white,
          popperBorder: Colors.wildSand,
          popperShadow: Colors.blackAlfa2,
          popperTimeThumb: Colors.apple,
          popperTimeTrack: Colors.wildSand,
        },
      },
      switcher: {
        default: {
          color: Colors.apple,
          shadow: Colors.blackAlfa8,
          shadowHandler: Colors.blackAlfa2,
        },
      },
    },
    settingsPhoto: {
      photo: {
        background: Colors.mercuryTwo,
      },
      text: {
        color: Colors.scorpion,
        colorOnHover: Colors.apple,
      },
    },
    add: {
      label: {
        background: Colors.alto,
        color: Colors.scorpion,
      },
    },
    item: {
      label: {
        background: Colors.white,
        backgroundOnHover: Colors.apple,
        backgroundOnActive: Colors.apple,
        backgroundOutOfOrder: Colors.white,
        backgroundOutOfOrderOnHover: Colors.flamingo,
        backgroundOutOfOrderOnActive: Colors.flamingo,
        backgroundMaintenance: Colors.white,
        backgroundMaintenanceOnHover: Colors.treePoppy,
        backgroundMaintenanceOnActive: Colors.treePoppy,
        color: Colors.scorpion,
        colorOnHover: Colors.white,
        colorOnActive: Colors.white,
        colorOutOfOrder: Colors.scorpion,
        colorOutOfOrderOnHover: Colors.white,
        colorOutOfOrderOnActive: Colors.white,
        colorMaintenance: Colors.scorpion,
        colorMaintenanceOnHover: Colors.white,
        colorMaintenanceOnActive: Colors.white,
      },
      status: {
        background: Colors.apple,
        backgroundOnHover: Colors.white,
        backgroundOnActive: Colors.white,
        backgroundMaintenance: Colors.treePoppy,
        backgroundMaintenanceOnHover: Colors.white,
        backgroundMaintenanceOnActive: Colors.white,
        backgroundOutOfOrder: Colors.flamingo,
        backgroundOutOfOrderOnHover: Colors.white,
        backgroundOutOfOrderOnActive: Colors.white,
        icon: Colors.white,
        iconOnHover: Colors.apple,
        iconOnActive: Colors.apple,
        iconMaintenance: Colors.white,
        iconMaintenanceOnHover: Colors.treePoppy,
        iconMaintenanceOnActive: Colors.treePoppy,
        iconOutOfOrder: Colors.white,
        iconOutOfOrderOnHover: Colors.flamingo,
        iconOutOfOrderOnActive: Colors.flamingo,
      },
      connect: {
        background: Colors.white,
        fill: Colors.mercury,
        fillOnHover: Colors.mercury,
        fillOnActive: Colors.mercury,
        fillConnect: Colors.apple,
        fillConnectOnHover: Colors.apple,
        fillConnectOnActive: Colors.apple,
      },
      photo: {
        background: Colors.mercuryTwo,
      },
      orb: {
        border: Colors.mercuryTwo,
        backgroundOnOffline: Colors.silverChalice,
        backgroundOnOnline: Colors.apple,
        backgroundOnAway: Colors.treePoppy,
      },
    },
    details: {
      panel: {
        background: Colors.white,
        backgroundMobile: Colors.transparent,
      },
      content: {
        link: {
          color: Colors.scorpion,
          colorOnHover: Colors.apple,
        },
      },
      contact: {
        photo: {
          background: Colors.mercuryTwo,
        },
        orb: {
          border: Colors.mercuryTwo,
          backgroundOnOffline: Colors.silverChalice,
          backgroundOnOnline: Colors.apple,
          backgroundOnAway: Colors.treePoppy,
        },
      },
      fillLevel: {
        levels: {
          default: {
            fill: Colors.apple,
          },
          empty: {
            fill: Colors.alto,
          },
          full: {
            fill: Colors.treePoppy,
          },
        },
      },
      users: {
        more: {
          color: Colors.scorpion,
          colorOnHover: Colors.apple,
        },
      },
      customers: {
        title: {
          color: Colors.apple,
        },
        link: {
          color: Colors.scorpion,
          colorOnHover: Colors.apple,
        },
        more: {
          color: Colors.scorpion,
          colorOnHover: Colors.apple,
        },
      },
      history: {
        selectbox: {
          default: {
            alertColor: Colors.cranberry,
          },
          selectrix: {
            color: Colors.scorpion,
            colorPlaceholder: Colors.boulder,
            borderColor: Colors.mercury,
            borderColorOnFocus: Colors.apple,
          },
        },
        daypicker: {
          default: {
            color: Colors.scorpion,
            colorOnInvalid: Colors.cranberry,
            borderColor: Colors.mercury,
            borderColorOnInvalid: Colors.cranberry,
            borderColorOnValid: Colors.apple,
            alertColor: Colors.cranberry,
            popperTriangleBorder: Colors.white,
            popperHeaderBorder: Colors.apple,
            popperHeaderBg: Colors.apple,
            popperHeaderColor: Colors.white,
            popperColor: Colors.scorpion,
            popperSelectedBg: Colors.apple,
            popperSelectedColor: Colors.white,
            popperBorder: Colors.wildSand,
            popperShadow: Colors.blackAlfa2,
            popperTimeThumb: Colors.apple,
            popperTimeTrack: Colors.wildSand,
          },
        },
        more: {
          color: Colors.scorpion,
          colorOnHover: Colors.apple,
        },
      },
      statistics: {
        daypicker: {
          default: {
            color: Colors.scorpion,
            colorOnInvalid: Colors.cranberry,
            borderColor: Colors.mercury,
            borderColorOnInvalid: Colors.cranberry,
            borderColorOnValid: Colors.apple,
            alertColor: Colors.cranberry,
            popperTriangleBorder: Colors.white,
            popperHeaderBorder: Colors.apple,
            popperHeaderBg: Colors.apple,
            popperHeaderColor: Colors.white,
            popperColor: Colors.scorpion,
            popperSelectedBg: Colors.apple,
            popperSelectedColor: Colors.white,
            popperBorder: Colors.wildSand,
            popperShadow: Colors.blackAlfa2,
            popperTimeThumb: Colors.apple,
            popperTimeTrack: Colors.wildSand,
          },
        },
        chart: {
          status: {
            background: Colors.wildSand,
          },

          bar: {
            default: {
              fill: Colors.apple,
            },
            selected: {
              fill: Colors.treePoppy,
            },
          },

          pie: {
            default: {
              fill: Colors.apple,
              text: Colors.scorpion,
            },
            selected: {
              fill: Colors.treePoppy,
            },
          },
        },
      },
      title: {
        color: Colors.apple,
      },
      additional: {
        color: Colors.silverChalice,
      },
      photo: {
        background: Colors.mercuryTwo,
      },
      assign: {
        count: {
          default: {
            color: Colors.scorpion,
          },
          assigned: { color: Colors.apple },
          unassigned: { color: Colors.cranberry },
        },
        title: {
          default: {
            color: Colors.scorpion,
          },
          assigned: { color: Colors.apple },
          unassigned: { color: Colors.cranberry },
        },
        link: {
          color: Colors.scorpion,
          colorOnHover: Colors.apple,
        },
      },
    },
  },
  alert: {
    info: {
      title: {
        color: Colors.treePoppy,
      },
    },
    success: {
      title: {
        color: Colors.apple,
      },
    },
    error: {
      title: {
        color: Colors.flamingo,
      },
    },
  },
  button: {
    default: {
      background: Colors.apple,
      backgroundOnHover: Colors.apple,
      backgroundOnDisabled: Colors.scorpion,
      color: Colors.white,
    },
    alternative: {
      background: Colors.alto,
      backgroundOnHover: Colors.silverChalice,
      backgroundOnDisabled: Colors.scorpion,
      color: Colors.white,
    },
  },
  modal: {
    content: {
      default: {
        background: Colors.white,
      },
      alternative: {
        background: Colors.wildSand,
      },
    },
    title: {
      default: {
        color: Colors.scorpion,
      },
      alternative: {
        color: Colors.cranberry,
      },
      success: {
        color: Colors.apple,
      },
    },
    inputbox: {
      default: {
        color: Colors.scorpion,
        colorPlaceholder: Colors.boulder,
        colorOnInvalid: Colors.cranberry,
        borderColor: Colors.mercury,
        borderColorOnInvalid: Colors.cranberry,
        borderColorOnValid: Colors.apple,
        alertColor: Colors.cranberry,
      },
    },
    checkbox: {
      default: {
        borderColor: Colors.mercury,
        iconColor: Colors.apple,
      },
    },
    selectbox: {
      default: {
        alertColor: Colors.cranberry,
      },
      selectrix: {
        color: Colors.scorpion,
        colorPlaceholder: Colors.boulder,
        borderColor: Colors.mercury,
        borderColorOnFocus: Colors.apple,
      },
    },
    contactPhoto: {
      photo: {
        background: Colors.mercuryTwo,
      },
      text: {
        color: Colors.scorpion,
        colorOnHover: Colors.apple,
      },
    },
    distributorPhoto: {
      photo: {
        background: Colors.mercuryTwo,
      },
      text: {
        color: Colors.scorpion,
        colorOnHover: Colors.apple,
      },
    },
    holder: {
      background: Colors.blackAlfa8,
      thumb: Colors.apple,
      track: Colors.wildSand,
    },
  },
  scroll: {
    thumb: Colors.apple,
    track: Colors.wildSand,
  },
};

const { ThemeProvider, withTheme } = createTheming(Theme);

export { ThemeProvider, withTheme, Theme };
