import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { injectIntl } from 'react-intl';

import { withTheme } from '../../assets/Theme.style';
import { Breakpoints } from '../../assets/Variables.style';
import {
  Wrapper,
  Content,
  Panel,
  PanelInner,
  Title,
  Box,
  Details,
  Information
} from './ProfileNotificationsPage.style';

import Header from '../../components/Header/Header';
import AccountSider from '../../components/AccountSider/AccountSider';
import ProfileNotifications from '../../components/ProfileNotifications/ProfileNotifications';

import {
  updateProfileNotificationsInit,
  updateProfileNotifications
} from '../../actions/Profile/ProfileActions';

class ProfileNotificationsPage extends Component {
  state = {
    width: window.innerWidth
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    this.props.updateProfileNotificationsInit();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleSubmit = values => {
    const notifications = {
      allow: values.allow || false,
      timeFrom: values.timeFrom || '',
      timeTo: values.timeTo || '',
      zone: values.zone || ''
    };

    this.props.updateProfileNotifications(notifications);
  };

  render() {
    const { width } = this.state;
    const {
      location,
      firebaseStore,
      profileNotificationsStore,
      intl
    } = this.props;
    const theme = this.props.theme;
    const isMobile = width <= Breakpoints.lg;

    return (
      <>
        <Helmet>
          <title>
            {intl.formatMessage({ id: 'PAGE_TITLE.PROFILE_NOTIFICATIONS' })} |
            BIN-E Smart Waste Bin
          </title>
        </Helmet>

        <Wrapper>
          <Header
            currentPath={location.pathname}
            profile={firebaseStore.profile}
          />
          <Content>
            {!isMobile && (
              <AccountSider
                theme={theme.accountSider}
                currentPath={location.pathname}
              />
            )}
            <Panel theme={theme.content.panel.alternative}>
              <PanelInner>
                <Title theme={theme.content.title}>
                  {intl.formatMessage({
                    id: 'PROFILE_NOTIFICATIONS.PROFILE_NOTIFICATIONS'
                  })}
                </Title>
                <Box>
                  <Details theme={theme.scroll}>
                    {firebaseStore.profile.isLoaded ? (
                      <ProfileNotifications
                        store={profileNotificationsStore}
                        notifications={firebaseStore.profile.notifications}
                        onSubmit={this.handleSubmit}
                      />
                    ) : (
                      <Information>
                        {intl.formatMessage({
                          id: 'PROFILE_NOTIFICATIONS.LOADING'
                        })}
                        ...
                      </Information>
                    )}
                  </Details>
                </Box>
              </PanelInner>
            </Panel>
          </Content>
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    firebaseStore: state.firebase,
    profileNotificationsStore: state.profileNotificationsStore
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProfileNotificationsInit: () =>
      dispatch(updateProfileNotificationsInit()),
    updateProfileNotifications: data =>
      dispatch(updateProfileNotifications(data))
  };
};

export default compose(
  injectIntl,
  withTheme,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ProfileNotificationsPage);
