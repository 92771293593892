import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';

import { Theme } from '../../assets/Theme.style';
import {
  Panel,
  Logobox,
  Textbox,
  Error,
  Formbox,
  Inputbox,
  Button,
  Successbox,
  Footerbox
} from './ForgotPasswordPanel.style';

import Logo from '../../assets/images/Logo.svg';
import Check from '../../assets/images/Check.svg';

function ForgotPasswordPanel({
  theme = {
    default: Theme.sign.panel.default,
    inputbox: Theme.sign.panel.inputbox,
    error: Theme.sign.panel.error,
    footerbox: Theme.sign.panel.footerbox,
    button: Theme.button
  },
  store = {},
  onSubmit = () => {}
}) {
  const validate = values => {
    const errors = {};
    if (!values.email) errors.email = 'This field is required.';
    if (
      values.email &&
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        values.email
      )
    )
      errors.email = 'Invalid email address';
    return errors;
  };

  return (
    <Panel theme={theme.default}>
      <Logobox>
        <img src={Logo} alt='BIN-E Smart Waste Bin' />
      </Logobox>

      {store.isError ? (
        <Textbox>
          <Error theme={theme.error.default}>{store.error}</Error>
        </Textbox>
      ) : store.isSuccess ? (
        <div>
          <Textbox>
            <strong>Check your email box!</strong>
          </Textbox>
        </div>
      ) : (
        <Textbox>
          <strong>Forgot your password?</strong>
          <br />
          <br />
          <span>
            Enter you e-mail address and <br /> you will receive reset link.
          </span>
        </Textbox>
      )}

      {store.isSuccess ? (
        <Successbox>
          <img src={Check} alt='Success' />
        </Successbox>
      ) : (
        <Formbox>
          <Form
            onSubmit={onSubmit}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              invalid
            }) => (
              <form onSubmit={handleSubmit}>
                <Field name='email'>
                  {({ input, meta }) => (
                    <Inputbox
                      data-name='E-mail address'
                      data-filled={input.value ? true : undefined}
                      theme={theme.inputbox.default}
                    >
                      <input
                        {...input}
                        type='email'
                        placeholder='E-mail address'
                        autoComplete='on'
                        data-invalid={
                          meta.touched && meta.error ? true : undefined
                        }
                        data-valid={
                          meta.touched && !meta.error ? true : undefined
                        }
                      />
                      <span>{meta.touched && meta.error}</span>
                    </Inputbox>
                  )}
                </Field>

                <Button
                  disabled={
                    submitting ||
                    pristine ||
                    invalid ||
                    (store && store.isLoading)
                  }
                  theme={theme.button.default}
                >
                  {store && store.isLoading ? 'Please wait' : 'Send reset link'}
                </Button>
              </form>
            )}
          />
        </Formbox>
      )}
      <Footerbox theme={theme.footerbox.default}>
        <Link to={`/sign-in`}>Back to sign in.</Link>
      </Footerbox>
    </Panel>
  );
}

ForgotPasswordPanel.propTypes = {
  theme: PropTypes.object,
  store: PropTypes.object,
  onSubmit: PropTypes.func
};

export default ForgotPasswordPanel;
