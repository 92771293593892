import styled from 'styled-components';
import Selectrix from 'react-selectrix';

import { Media } from '../../assets/Mixins.style';

import { Switchbox as commonSwitchbox } from '../../assets/common/Switch.style';
import {
  Inputbox as commonInputbox,
  InputPairBox as commonInputPairBox,
  InputPair as commonInputPair,
  Daypickerbox as commonDaypickerbox
} from '../../assets/common/Inputbox.style';
import {
  Selectbox as commonSelectbox,
  Select as commonSelect
} from '../../assets/common/Select.style';
import { InlineButton as commonButton } from '../../assets/common/Button.style';
import {
  Alert as commonAlert,
  AlertImage as commonAlertImage,
  AlertContent as commonAlertContent,
  AlertTitle as commonAlertTitle
} from '../../assets/common/Alert.style';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;

  ${Media.sm`
    flex-wrap: wrap;
  `}
`;

export const Content = styled.div`
  display: block;
  max-width: 600px;
  width: 100%;

  ${Media.sm`
    max-width: 100%;
    text-align: center;
  `}
`;

export const Switchbox = styled.div`
  ${commonSwitchbox}
`;

export const Inputbox = styled.div`
  ${commonInputbox}
`;

export const InputPairBox = styled.div`
  ${commonInputPairBox}
`;

export const InputPair = styled.div`
  ${commonInputPair}
`;

export const DayPicker = styled.div`
  ${commonDaypickerbox}
`;

export const Spacer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  cursor: default;
`;

export const Selectbox = styled.div`
  ${commonSelectbox}
`;

export const Select = styled(Selectrix)`
  ${commonSelect}
`;

export const Inputholder = styled.label`
  position: relative;
  display: inline-block;
  width: 100%;
  cursor: text;

  img {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
`;

export const Button = styled.button`
  ${commonButton}
  margin-top: 21px;
  margin-bottom: 15px;

  ${Media.sm`
    margin-top: 0;
    margin-bottom: 0;
  `}
`;

export const Alertbox = styled.div`
  display: block;
  width: 100%;

  &:not(:empty) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const Alert = styled.div`
  ${commonAlert}
`;

export const AlertImage = styled.img`
  ${commonAlertImage}
`;

export const AlertContent = styled.h1`
  ${commonAlertContent}
`;

export const AlertTitle = styled.div`
  ${commonAlertTitle}
`;
