import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Icon from '../../assets/images/Modify.svg';

import { Theme } from '../../assets/Theme.style';
import {
  Box,
  Item,
  Panel,
  Header,
  Name,
  Menu,
  Number,
  Button,
  Image,
  Title,
  Content
} from './FactoryDetails.style';

import LocationInfo from '../LocationInfo/LocationInfo';
import ContactInfo from '../ContactInfo/ContactInfo';

function FactoryDetails({
  theme = Theme.content.details,
  data = {},
  handleClick = () => {}
}) {
  const intl = useIntl();

  const isLocation =
    data.street || data.postalCode || data.city || data.country ? true : false;
  const isContact =
    data.contactPerson ||
    data.contactPosition ||
    data.contactNo ||
    data.contactEmail ||
    data.contactPhotoURL
      ? true
      : false;

  return (
    <>
      {data && Object.keys(data).length > 0 && (
        <Box>
          <Item>
            <Panel theme={theme.panel}>
              <Header>
                <Name>{data.name}</Name>
                <Menu>
                  <Number>{data.number}</Number>
                  <Button
                    onClick={handleClick}
                    title={intl.formatMessage({ id: 'FACTORIES.EDIT_FACTORY' })}
                  >
                    <Image
                      src={Icon}
                      alt={intl.formatMessage({ id: 'FACTORIES.EDIT_FACTORY' })}
                    />
                  </Button>
                </Menu>
              </Header>
              <Content theme={theme.content}>
                {data.website && (
                  <a
                    href={data.website}
                    target='_blank'
                    rel='noopener noreferrer'
                    title={data.website}
                  >
                    {intl.formatMessage({
                      id: 'FACTORIES.OPEN_FACTORY_WEBSITE'
                    })}
                  </a>
                )}
              </Content>
            </Panel>
          </Item>
          {isLocation && (
            <Item data-half={isContact ? true : undefined}>
              <Panel theme={theme.panel}>
                <Title>
                  {intl.formatMessage({
                    id: 'FACTORIES.LOCATION'
                  })}
                </Title>
                <Content theme={theme.content}>
                  <LocationInfo
                    data={{
                      street: data.street,
                      postalCode: data.postalCode,
                      city: data.city,
                      country: data.country
                    }}
                  />
                </Content>
              </Panel>
            </Item>
          )}
          {isContact && (
            <Item data-half={isLocation ? true : undefined}>
              <Panel theme={theme.panel}>
                <Title>
                  {intl.formatMessage({
                    id: 'FACTORIES.CONTACT_PERSON'
                  })}
                </Title>
                <Content theme={theme.content}>
                  <ContactInfo
                    theme={theme.contact}
                    data={{
                      contactPerson: data.contactPerson,
                      contactPosition: data.contactPosition,
                      contactNo: data.contactNo,
                      contactEmail: data.contactEmail,
                      contactPhotoURL: data.contactPhotoURL
                    }}
                  />
                </Content>
              </Panel>
            </Item>
          )}
          {data.details ? (
            <Item>
              <Panel theme={theme.panel}>
                <Title>
                  {intl.formatMessage({
                    id: 'FACTORIES.ADDITIONAL_INFORMATION'
                  })}
                </Title>
                <Content theme={theme.content}>
                  <pre>
                    {data.details
                      ? data.details
                      : intl.formatMessage({
                          id: 'FACTORIES.ADDITIONAL_INFORMATION.NO_DATA'
                        })}
                  </pre>
                </Content>
              </Panel>
            </Item>
          ) : (
            ''
          )}
        </Box>
      )}
    </>
  );
}

FactoryDetails.propTypes = {
  theme: PropTypes.object,
  data: PropTypes.object,
  handleClick: PropTypes.func
};

export default memo(FactoryDetails);
