import React from 'react';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import SuccessIcon from '../../assets/images/Success.svg';
import ErrorIcon from '../../assets/images/Error.svg';

import { Theme } from '../../assets/Theme.style';
import {
  Wrapper,
  Side,
  Content,
  Inputbox,
  Selectbox,
  Button,
  Alertbox,
  Alert,
  AlertImage,
  AlertContent,
  AlertTitle
} from './ProfileSettings.style';

import ProfilePhoto from '../ProfilePhoto/ProfilePhoto';
import DistributorAssign from '../DistributorAssign/DistributorAssign';
import CustomerAssign from '../CustomerAssign/CustomerAssign';
import RoleAssign from '../RoleAssign/RoleAssign';

function ProfileSettings({
  theme = {
    ...Theme.content.settings,
    alert: Theme.alert,
    button: Theme.button
  },
  store = {},
  profile = {},
  onSubmit = () => {},
  onSetPhotoData = () => {},
  authRole = {},
  distributor = {},
  customer = {}
}) {
  const intl = useIntl();

  const validate = values => {
    const errors = {};

    if (!values.displayName)
      errors.displayName = 'PROFILE_SETTINGS.MANAGE.VALIDATION.REQUIRED';

    if (
      values.displayName &&
      !/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/.test(
        values.displayName
      )
    )
      errors.displayName =
        'PROFILE_SETTINGS.MANAGE.VALIDATION.INVALID_FULL_NAME_FORMAT';

    if (
      values.phoneNo &&
      !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/im.test(
        values.phoneNo
      )
    )
      errors.phoneNo =
        'PROFILE_SETTINGS.MANAGE.VALIDATION.INVALID_PHONE_NUMBER_FORMAT';

    return errors;
  };

  const WhenFieldChanges = ({ field, becomes, set, to }) => (
    <Field name={set} subscription={{}}>
      {({ input: { onChange } }) => (
        <OnChange name={field}>
          {() => {
            if (becomes) {
              onChange(to);
            }
          }}
        </OnChange>
      )}
    </Field>
  );
  const currentProfileRole =
    profile && profile.role && Object.entries(profile.role).find(el => el[1]);
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={{
        role: currentProfileRole && currentProfileRole[0],
        displayName: profile.displayName,
        customer: profile.customer && profile.customer.id,
        distributor: profile.distributor && profile.distributor.id,
        phoneNo: profile.phoneNo,
        position: profile.position
      }}
      mutators={{}}
      render={({ handleSubmit, submitting, pristine, values, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Wrapper>
            <Side>
              <Field name='photoURL'>
                {({ input, meta }) => (
                  <ProfilePhoto
                    theme={theme.photo}
                    input={input}
                    profile={{
                      email: profile.email,
                      displayName: profile.displayName,
                      photoURL: profile.photoURL
                    }}
                    label={
                      profile.photoURL
                        ? intl.formatMessage({
                            id: 'PROFILE_SETTINGS.CHANGE_PROFILE_PHOTO'
                          })
                        : intl.formatMessage({
                            id: 'PROFILE_SETTINGS.ADD_PROFILE_PHOTO'
                          })
                    }
                    onSetPhotoData={onSetPhotoData}
                  />
                )}
              </Field>
            </Side>
            <Content>
              <Field name='displayName'>
                {({ input, meta }) => (
                  <Inputbox
                    data-name={intl.formatMessage({
                      id: 'PROFILE_SETTINGS.FULL_NAME'
                    })}
                    data-filled={input.value ? true : undefined}
                    theme={theme.inputbox.default}
                  >
                    <input
                      {...input}
                      type='text'
                      placeholder={intl.formatMessage({
                        id: 'PROFILE_SETTINGS.FULL_NAME'
                      })}
                      autoComplete='on'
                      data-big={true}
                      data-invalid={
                        meta.touched && meta.error ? true : undefined
                      }
                      data-valid={
                        meta.touched && !meta.error ? true : undefined
                      }
                    />
                    <span>
                      {meta.touched &&
                        meta.error &&
                        intl.formatMessage({
                          id: meta.error
                        })}
                    </span>
                  </Inputbox>
                )}
              </Field>

              {authRole &&
              authRole.privileges &&
              authRole.privileges['MANAGE_PROFILE_SETTINGS_DISTRIBUTOR'] ? (
                <Field name='distributor'>
                  {({ input, meta }) => (
                    <Selectbox
                      data-name={intl.formatMessage({
                        id: 'PROFILE_SETTINGS.DISTRIBUTOR'
                      })}
                      data-filled={
                        input && input.value && input.value.length > 0
                          ? true
                          : undefined
                      }
                      theme={theme.selectbox.default}
                    >
                      <DistributorAssign input={input} />
                      <span />
                    </Selectbox>
                  )}
                </Field>
              ) : (
                <Inputbox
                  data-name={intl.formatMessage({
                    id: 'PROFILE_SETTINGS.DISTRIBUTOR'
                  })}
                  data-filled={values.distributor ? true : undefined}
                  theme={theme.inputbox.default}
                >
                  <input
                    type='text'
                    readOnly={true}
                    placeholder={intl.formatMessage({
                      id: 'PROFILE_SETTINGS.DISTRIBUTOR'
                    })}
                    key={distributor && distributor.name}
                    defaultValue={distributor && distributor.name}
                    disabled={true}
                  />
                  <span></span>
                </Inputbox>
              )}

              {authRole &&
              authRole.privileges &&
              authRole.privileges['MANAGE_PROFILE_SETTINGS_CUSTOMER'] ? (
                <>
                  <WhenFieldChanges
                    field='distributor'
                    becomes={values.currency !== null}
                    set='customer'
                    to={''}
                  />
                  <Field name='customer'>
                    {({ input, meta }) => (
                      <Selectbox
                        data-name={intl.formatMessage({
                          id: 'PROFILE_SETTINGS.CUSTOMER'
                        })}
                        data-filled={
                          input && input.value && input.value.length > 0
                            ? true
                            : undefined
                        }
                        theme={theme.selectbox.default}
                      >
                        <CustomerAssign
                          input={input}
                          distributor={values.distributor}
                        />
                        <span />
                      </Selectbox>
                    )}
                  </Field>
                </>
              ) : (
                <Inputbox
                  data-name={intl.formatMessage({
                    id: 'PROFILE_SETTINGS.CUSTOMER'
                  })}
                  data-filled={values.customer ? true : undefined}
                  theme={theme.inputbox.default}
                >
                  <input
                    type='text'
                    readOnly={true}
                    placeholder={intl.formatMessage({
                      id: 'PROFILE_SETTINGS.CUSTOMER'
                    })}
                    key={customer && customer.name}
                    defaultValue={customer && customer.name}
                    disabled={true}
                  />
                  <span></span>
                </Inputbox>
              )}

              <Field name='position'>
                {({ input, meta }) => (
                  <Inputbox
                    data-name={intl.formatMessage({
                      id: 'PROFILE_SETTINGS.POSITION'
                    })}
                    data-filled={input.value ? true : undefined}
                    theme={theme.inputbox.default}
                  >
                    <input
                      {...input}
                      type='text'
                      placeholder={intl.formatMessage({
                        id: 'PROFILE_SETTINGS.POSITION'
                      })}
                      autoComplete='on'
                      data-invalid={
                        meta.touched && meta.error ? true : undefined
                      }
                      data-valid={
                        meta.touched && !meta.error ? true : undefined
                      }
                    />
                    <span>
                      {meta.touched &&
                        meta.error &&
                        intl.formatMessage({
                          id: meta.error
                        })}
                    </span>
                  </Inputbox>
                )}
              </Field>
              {authRole &&
              authRole.privileges &&
              authRole.privileges['MANAGE_PROFILE_SETTINGS_ROLE'] ? (
                <Field name='role'>
                  {({ input, meta }) => (
                    <Selectbox
                      data-name={intl.formatMessage({
                        id: 'PROFILE_SETTINGS.ROLE'
                      })}
                      data-filled={
                        input && input.value && input.value.length > 0
                          ? true
                          : undefined
                      }
                      theme={theme.selectbox.default}
                    >
                      <RoleAssign input={input} authRole={authRole} />
                      <span />
                    </Selectbox>
                  )}
                </Field>
              ) : (
                <Inputbox
                  data-name={intl.formatMessage({
                    id: 'PROFILE_SETTINGS.ROLE'
                  })}
                  data-filled={values.role ? true : undefined}
                  theme={theme.inputbox.default}
                >
                  <input
                    type='text'
                    readOnly={true}
                    placeholder={intl.formatMessage({
                      id: 'PROFILE_SETTINGS.ROLE'
                    })}
                    key={
                      authRole &&
                      authRole.name &&
                      intl.formatMessage({
                        id: `USERS.ASSIGN.ROLE.${authRole.name}`,
                        defaultMessage: authRole.name
                      })
                    }
                    defaultValue={
                      authRole &&
                      authRole.name &&
                      intl.formatMessage({
                        id: `USERS.ASSIGN.ROLE.${authRole.name}`,
                        defaultMessage: authRole.name
                      })
                    }
                    disabled={true}
                  />
                  <span></span>
                </Inputbox>
              )}

              <Field name='phoneNo'>
                {({ input, meta }) => (
                  <Inputbox
                    data-name={intl.formatMessage({
                      id: 'PROFILE_SETTINGS.PHONE_NUMBER'
                    })}
                    data-filled={input.value ? true : undefined}
                    theme={theme.inputbox.default}
                  >
                    <input
                      {...input}
                      type='tel'
                      placeholder={intl.formatMessage({
                        id: 'PROFILE_SETTINGS.PHONE_NUMBER'
                      })}
                      autoComplete='on'
                      data-invalid={
                        meta.touched && meta.error ? true : undefined
                      }
                      data-valid={
                        meta.touched && !meta.error ? true : undefined
                      }
                    />
                    <span>
                      {meta.touched &&
                        meta.error &&
                        intl.formatMessage({
                          id: meta.error
                        })}
                    </span>
                  </Inputbox>
                )}
              </Field>

              {store.isError ? (
                <Alertbox>
                  {store.isError && (
                    <Alert>
                      <AlertImage
                        src={ErrorIcon}
                        alt={intl.formatMessage({
                          id:
                            'PROFILE_SETTINGS.MANAGE.ALERT.SOMETHING_WENT_WRONG'
                        })}
                      />
                      <AlertContent>
                        <AlertTitle theme={theme.alert.error.title}>
                          {intl.formatMessage({
                            id:
                              'PROFILE_SETTINGS.MANAGE.ALERT.SOMETHING_WENT_WRONG'
                          })}
                        </AlertTitle>
                        {store.error}
                      </AlertContent>
                    </Alert>
                  )}
                </Alertbox>
              ) : (
                store.isSuccess && (
                  <Alertbox>
                    <Alert>
                      <AlertImage
                        src={SuccessIcon}
                        alt={intl.formatMessage({
                          id: 'PROFILE_SETTINGS.MANAGE.ALERT.SUCCESS'
                        })}
                      />
                      <AlertContent>
                        <AlertTitle theme={theme.alert.success.title}>
                          {intl.formatMessage({
                            id: 'PROFILE_SETTINGS.MANAGE.ALERT.SUCCESS'
                          })}
                        </AlertTitle>
                        {intl.formatMessage({
                          id:
                            'PROFILE_SETTINGS.MANAGE.ALERT.PROFILE_SETTINGS_UPDATED'
                        })}
                      </AlertContent>
                    </Alert>
                  </Alertbox>
                )
              )}

              <Button
                disabled={
                  submitting ||
                  pristine ||
                  invalid ||
                  (store && store.isLoading)
                }
                theme={theme.button.default}
              >
                {store && store.isLoading
                  ? intl.formatMessage({
                      id: 'PROFILE_SETTINGS.MENU.PLEASE_WAIT'
                    })
                  : intl.formatMessage({
                      id: 'PROFILE_SETTINGS.MENU.SAVE_CHANGES'
                    })}
              </Button>
            </Content>
          </Wrapper>
        </form>
      )}
    />
  );
}

ProfileSettings.propTypes = {
  theme: PropTypes.object,
  store: PropTypes.object,
  profile: PropTypes.object,
  onSubmit: PropTypes.func,
  onSetPhotoData: PropTypes.func,
  authRole: PropTypes.object
};

export default ProfileSettings;
