const defaultState = {
  requestID: null,
  timeout: null,
  response: {},
  isRequest: false,
  isResponse: false,
  isTimeout: false,
  isFlush: false
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'DEVICE_GET_INFORMATION_INIT': {
      return {
        ...state,
        requestID: action.payload.requestID,
        timeout: null,
        isRequest: false,
        isResponse: false,
        isTimeout: false,
        isFlush: false
      };
    }
    case 'DEVICE_GET_INFORMATION_REQUEST': {
      return {
        ...state,
        timeout: action.payload.timeout,
        isRequest: true,
        isResponse: false,
        isTimeout: false,
        isFlush: false
      };
    }
    case 'DEVICE_GET_INFORMATION_RESPONSE': {
      return {
        ...state,
        response: action.payload.response,
        timeout: null,
        isRequest: false,
        isResponse: true,
        isTimeout: false,
        isFlush: false
      };
    }
    case 'DEVICE_GET_INFORMATION_TIMEOUT': {
      return {
        ...state,
        isRequest: false,
        isResponse: false,
        isTimeout: true,
        isFlush: false
      };
    }
    case 'DEVICE_GET_INFORMATION_FLUSH': {
      return {
        ...state,
        requestID: null,
        timeout: null,
        response: {},
        isRequest: false,
        isResponse: false,
        isTimeout: false,
        isFlush: true
      };
    }
    default:
      return state;
  }
};
