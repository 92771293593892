import React from 'react';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import SuccessIcon from '../../assets/images/Success.svg';
import ErrorIcon from '../../assets/images/Error.svg';
import CloseIcon from '../../assets/images/Close.svg';

import { Theme } from '../../assets/Theme.style';

import {
  Wrapper,
  Holder,
  Modal,
  Close,
  Image,
  Title,
  Content,
  Subtitle,
  Box,
  Photobox,
  Inputbox,
  Selectbox,
  Navigation,
  Button,
  Alertbox,
  Alert,
  AlertImage,
  AlertContent,
  AlertTitle
} from './UserManage.style';

import ContactPhoto from '../ProfilePhoto/ProfilePhoto';
import DistributorAssign from '../DistributorAssign/DistributorAssign';
import CustomerAssign from '../CustomerAssign/CustomerAssign';
import RoleAssign from '../RoleAssign/RoleAssign';

function UserManage({
  theme = {
    ...Theme.modal,
    alert: Theme.alert,
    button: Theme.button
  },
  store = {},
  onSubmit = () => {},
  onSetPhotoData = () => {},
  handleClose = () => {},
  open = false,
  authRole = {},
  userDistributor = {}
}) {
  const intl = useIntl();
  const validate = values => {
    const errors = {};

    if (!values.displayName)
      errors.displayName = 'USERS.MANAGE.VALIDATION.REQUIRED';
    if (!values.email) errors.email = 'USERS.MANAGE.VALIDATION.REQUIRED';

    if (
      values.displayName &&
      !/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/.test(
        values.displayName
      )
    )
      errors.displayName = 'USERS.MANAGE.VALIDATION.INVALID_FULL_NAME_FORMAT';

    if (
      values.email &&
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        values.email
      )
    )
      errors.email = 'USERS.MANAGE.VALIDATION.INVALID_EMAIL_ADDRESS_FORMAT';

    if (
      values.phoneNo &&
      !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/im.test(
        values.phoneNo
      )
    )
      errors.phoneNo = 'USERS.MANAGE.VALIDATION.INVALID_PHONE_NUMBER_FORMAT';

    return errors;
  };

  const WhenFieldChanges = ({ field, becomes, set, to }) => (
    <Field name={set} subscription={{}}>
      {({ input: { onChange } }) => (
        <OnChange name={field}>
          {() => {
            if (becomes) {
              onChange(to);
            }
          }}
        </OnChange>
      )}
    </Field>
  );

  const currentUserRole =
    store.user &&
    store.user.role &&
    Object.entries(store.user.role).find(el => el[1]);
  return (
    <Wrapper>
      <Holder
        theme={theme.holder}
        data-open={open ? true : undefined}
        data-close={!open ? true : undefined}
      >
        <Modal
          theme={theme.content.default}
          data-open={open ? true : undefined}
          data-close={!open ? true : undefined}
        >
          <Close
            onClick={handleClose}
            title={intl.formatMessage({ id: 'USERS.MANAGE.MENU.CLOSE' })}
          >
            <Image
              src={CloseIcon}
              alt={intl.formatMessage({ id: 'USERS.MANAGE.MENU.CLOSE' })}
            />
          </Close>
          <Title>
            {store.user && store.user.id
              ? intl.formatMessage({ id: 'USERS.MANAGE.EDIT_USER' })
              : intl.formatMessage({ id: 'USERS.MANAGE.ADD_NEW_USER' })}
          </Title>
          <Content>
            <Form
              onSubmit={onSubmit}
              validate={validate}
              initialValues={{
                email: store.user && store.user.email,
                role: currentUserRole && currentUserRole[0],
                displayName: store.user && store.user.displayName,
                customer:
                  store.user && store.user.customer && store.user.customer.id,
                distributor:
                  (store.user &&
                    store.user.distributor &&
                    store.user.distributor.id) ||
                  (authRole &&
                    authRole.privileges &&
                    authRole.privileges['MANAGE_USERS_SET_SELF_DISTRIBUTOR'] &&
                    userDistributor &&
                    userDistributor.id),
                phoneNo: store.user && store.user.phoneNo,
                position: store.user && store.user.position
              }}
              mutators={{}}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                submitting,
                pristine,
                values,
                invalid
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box>
                    <Field name='photoURL'>
                      {({ input, meta }) => (
                        <Photobox>
                          <ContactPhoto
                            theme={theme.contactPhoto}
                            input={input}
                            profile={{
                              email: store.user && store.user.email,
                              displayName: store.user && store.user.displayName,
                              photoURL: store.user && store.user.photoURL
                            }}
                            label={
                              store.user && store.user.photoURL
                                ? intl.formatMessage({
                                    id: 'USERS.MANAGE.EDIT_USER_PHOTO'
                                  })
                                : intl.formatMessage({
                                    id: 'USERS.MANAGE.ADD_USER_PHOTO'
                                  })
                            }
                            onSetPhotoData={onSetPhotoData}
                          />
                        </Photobox>
                      )}
                    </Field>
                    <Field name='displayName'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'USERS.MANAGE.FULL_NAME'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.inputbox.default}
                        >
                          <input
                            {...input}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'USERS.MANAGE.FULL_NAME'
                            })}
                            autoComplete='off'
                            data-big={true}
                            data-invalid={
                              meta.touched && meta.error ? true : undefined
                            }
                            data-valid={
                              meta.touched && !meta.error ? true : undefined
                            }
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Subtitle>
                      {intl.formatMessage({
                        id: 'USERS.MANAGE.ALLOCATION'
                      })}
                    </Subtitle>
                    <Field name='distributor'>
                      {({ input, meta }) => {
                        return authRole &&
                          authRole.privileges &&
                          authRole.privileges['MANAGE_USERS_DISTRIBUTOR'] ? (
                          <>
                            <Selectbox
                              data-name={intl.formatMessage({
                                id: 'USERS.MANAGE.ALLOCATION.DISTRIBUTOR'
                              })}
                              data-filled={
                                input && input.value && input.value.length > 0
                                  ? true
                                  : undefined
                              }
                              theme={theme.selectbox.default}
                            >
                              <DistributorAssign input={input} />
                              <span />
                            </Selectbox>
                          </>
                        ) : (
                          <input
                            {...input}
                            type='hidden'
                            value={input.value}
                            disabled={true}
                          />
                        );
                      }}
                    </Field>
                    <WhenFieldChanges
                      field='distributor'
                      becomes={values.currency !== null}
                      set='customer'
                      to={''}
                    />
                    <Field name='customer'>
                      {({ input, meta }) => (
                        <Selectbox
                          data-name={intl.formatMessage({
                            id: 'USERS.MANAGE.ALLOCATION.CUSTOMER'
                          })}
                          data-filled={
                            input && input.value && input.value.length > 0
                              ? true
                              : undefined
                          }
                          theme={theme.selectbox.default}
                        >
                          <CustomerAssign
                            input={input}
                            distributor={values.distributor}
                          />
                          <span />
                        </Selectbox>
                      )}
                    </Field>
                    <Field name='position'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'USERS.MANAGE.ALLOCATION.POSITION'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.inputbox.default}
                        >
                          <input
                            {...input}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'USERS.MANAGE.ALLOCATION.POSITION'
                            })}
                            autoComplete='off'
                            data-valid={
                              meta.touched && !meta.error ? true : undefined
                            }
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                    <Field name='role'>
                      {({ input, meta }) => (
                        <Selectbox
                          data-name={intl.formatMessage({
                            id: 'USERS.MANAGE.ALLOCATION.ROLE'
                          })}
                          data-filled={
                            input && input.value && input.value.length > 0
                              ? true
                              : undefined
                          }
                          theme={theme.selectbox.default}
                        >
                          <RoleAssign input={input} authRole={authRole} />
                          <span />
                        </Selectbox>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Subtitle>
                      {intl.formatMessage({
                        id: 'USERS.MANAGE.CONTACT'
                      })}
                    </Subtitle>
                    <Field name='email'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'USERS.MANAGE.CONTACT.EMAIL_ADDRESS'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.inputbox.default}
                        >
                          <input
                            {...input}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'USERS.MANAGE.CONTACT.EMAIL_ADDRESS'
                            })}
                            autoComplete='off'
                            data-invalid={
                              meta.touched && meta.error ? true : undefined
                            }
                            data-valid={
                              meta.touched && !meta.error ? true : undefined
                            }
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                    <Field name='phoneNo'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'USERS.MANAGE.CONTACT.PHONE_NUMBER'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.inputbox.default}
                        >
                          <input
                            {...input}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'USERS.MANAGE.CONTACT.PHONE_NUMBER'
                            })}
                            autoComplete='off'
                            data-invalid={
                              meta.touched && meta.error ? true : undefined
                            }
                            data-valid={
                              meta.touched && !meta.error ? true : undefined
                            }
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                  </Box>

                  {store.isError ? (
                    <Alertbox>
                      <Alert>
                        <AlertImage
                          src={ErrorIcon}
                          alt={intl.formatMessage({
                            id: 'USERS.MANAGE.ALERT.SOMETHING_WENT_WRONG'
                          })}
                        />
                        <AlertContent>
                          <AlertTitle theme={theme.alert.error.title}>
                            {intl.formatMessage({
                              id: 'USERS.MANAGE.ALERT.SOMETHING_WENT_WRONG'
                            })}
                          </AlertTitle>
                          {store.error}
                        </AlertContent>
                      </Alert>
                    </Alertbox>
                  ) : (
                    store.isSuccess && (
                      <Alertbox>
                        <Alert>
                          <AlertImage
                            src={SuccessIcon}
                            alt={intl.formatMessage({
                              id: 'USERS.MANAGE.ALERT.SUCCESS'
                            })}
                          />
                          <AlertContent>
                            <AlertTitle theme={theme.alert.success.title}>
                              {intl.formatMessage({
                                id: 'USERS.MANAGE.ALERT.SUCCESS'
                              })}
                            </AlertTitle>{' '}
                            {store.user && store.user.id
                              ? intl.formatMessage({
                                  id: 'USERS.MANAGE.ALERT.USER_UPDATED'
                                })
                              : intl.formatMessage({
                                  id: 'USERS.MANAGE.ALERT.USER_CREATED'
                                })}
                          </AlertContent>
                        </Alert>
                      </Alertbox>
                    )
                  )}

                  <Navigation>
                    <Button
                      theme={theme.button.default}
                      disabled={
                        submitting ||
                        pristine ||
                        invalid ||
                        (store && store.isLoading)
                      }
                    >
                      {store && store.isLoading
                        ? intl.formatMessage({
                            id: 'CUSTOMERS.MANAGE.MENU.PLEASE_WAIT'
                          })
                        : store.user && store.user.id
                        ? intl.formatMessage({
                            id: 'USERS.MANAGE.MENU.EDIT_USER'
                          })
                        : intl.formatMessage({
                            id: 'USERS.MANAGE.MENU.ADD_USER'
                          })}
                    </Button>
                    <Button
                      type='button'
                      theme={theme.button.alternative}
                      onClick={handleClose}
                    >
                      {intl.formatMessage({
                        id: 'USERS.MANAGE.MENU.CANCEL'
                      })}
                    </Button>
                  </Navigation>
                </form>
              )}
            />
          </Content>
        </Modal>
      </Holder>
    </Wrapper>
  );
}

UserManage.propTypes = {
  theme: PropTypes.object,
  store: PropTypes.object,
  onSubmit: PropTypes.func,
  onSetPhotoData: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool
};

export default UserManage;
