/*
//   STYLE-COMPONENT: SELECTBOX
*/

import { css } from 'styled-components';

export const Selectbox = css`
  position: relative;
  display: block;
  width: 100%;
  margin-top: 10px;

  &::before {
    content: attr(data-name);
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    color: ${props => props.theme.color};
    transition: 0.2s;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    opacity: 0;
  }

  &[data-filled]::before {
    top: -10px;
    height: 10px;
    font-size: 10px;
    opacity: 1;
  }

  &[data-nomargin] {
    margin-top: 0;
  }

  span {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding-top: 2px;
    color: ${props => props.theme.alertColor};
    font-size: 12px;
    line-height: 1.2;
    min-height: 20px;
    cursor: default;
  }
`;

export const Select = css`
  display: block;
  width: 100%;

  &.react-selectrix .rs-header {
    border: 0;
    border-radius: 0;
    background-color: transparent;
    color: ${props => props.theme.color};

    input,
    .rs-toggle {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 30px;
      padding-right: 25px;
      border: 0;
      border-radius: 0;
      padding: 0 25px 0 0;
      color: ${props => props.theme.color};
      border-bottom: 2px solid ${props => props.theme.borderColor};
      background: transparent;
      box-shadow: none;
      outline: 0;
      transition: 0.2s border-bottom;
      font-size: 16px;
      font-weight: 500;

      ::-webkit-input-placeholder {
        color: ${props => props.theme.colorPlaceholder};
      }

      &.rs-focused {
        border-bottom: 2px solid ${props => props.theme.borderColorOnFocus};
      }
    }
  }
  &.react-selectrix.rs-base-empty .rs-header {
    color: ${props => props.theme.colorPlaceholder};

    input,
    .rs-toggle {
      color: ${props => props.theme.colorPlaceholder};
      ::-webkit-input-placeholder {
        color: ${props => props.theme.colorPlaceholder};
      }
    }
  }

  &.react-selectrix .rs-reset {
    color: ${props => props.theme.color};
    line-height: 1;
  }

  &.react-selectrix .rs-body {
    .rs-option {
      color: ${props => props.theme.color};
    }
  }
`;
