//TODO: NEED REFACTOR
import React, { memo, Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { Theme } from '../../assets/Theme.style';
import { Box, Item, Navigation, More } from './DeviceUsers.style';

import ContactInfo from '../ContactInfo/ContactInfo';

class DeviceUsers extends Component {
  state = {
    limit: 2
  };

  static propsTypes = {
    theme: PropTypes.object,
    serial: PropTypes.string,
    data: PropTypes.array
  };

  static defaultProps = {
    theme: Theme.content.details.users,
    serial: '',
    data: []
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.serial !== this.props.serial) this.setState({ limit: 2 });
  }

  increaseLimit = () => {
    const { limit } = this.state;
    this.setState({ limit: limit + 2 });
  };

  render() {
    const { limit } = this.state;
    const { theme, data, intl } = this.props;

    return (
      <>
        {data && data.length > 0 && (
          <Box>
            {data.slice(0, limit).map((el, key) => (
              <Item key={key}>
                <ContactInfo
                  data={{
                    contactPerson: el.displayName,
                    contactPosition: el.position,
                    contactNo: el.phoneNo,
                    contactEmail: el.email,
                    contactPhotoURL: el.photoURL,
                    contactStatus: el.loggedIn
                  }}
                />
              </Item>
            ))}

            {limit < Object.keys(data).length && (
              <Navigation>
                <More theme={theme.more} onClick={this.increaseLimit}>
                  {intl.formatMessage({ id: 'DEVICES.USERS.SEE_MORE' })}{' '}
                  <i className='material-icons'>keyboard_arrow_down</i>
                </More>
              </Navigation>
            )}
          </Box>
        )}
      </>
    );
  }
}

DeviceUsers.propTypes = {
  theme: PropTypes.object,
  serial: PropTypes.string,
  data: PropTypes.array
};

export default memo(injectIntl(DeviceUsers));
