import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { Helmet } from 'react-helmet-async';
import withTimeout from 'react-timeout';
import queryString from 'query-string';
import { injectIntl } from 'react-intl';

import Icon from '../../assets/images/Close.svg';

import { withTheme } from '../../assets/Theme.style';
import { Breakpoints } from '../../assets/Variables.style';
import {
  Wrapper,
  Content,
  Panel,
  PanelInner,
  Title,
  Box,
  List,
  Category,
  Subtitle,
  Details,
  Navigation,
  Button,
  Image,
  Information
} from './FactoriesPage.style';

import Header from '../../components/Header/Header';
import Sider from '../../components/Sider/Sider';
import FactoriesItem from '../../components/FactoriesItem/FactoriesItem';
import FactoryDetails from '../../components/FactoryDetails/FactoryDetails';
import FactoriesAdd from '../../components/FactoriesAdd/FactoriesAdd';
import FactoryManage from '../../components/FactoryManage/FactoryManage';

import {
  manageFactoryInit,
  addFactory,
  updateFactory
} from '../../actions/Factory/FactoryActions';

import { countries } from '../../utils/countries';

class FactoriesPage extends Component {
  state = {
    filter: true,
    filterOptions: [
      {
        name: this.props.intl.formatMessage({ id: 'SIDEBAR.SHOW_ALL' }),
        type: 'none',
        filter: 'none'
      },
      {
        name: this.props.intl.formatMessage({ id: 'SIDEBAR.NAME' }),
        type: 'name',
        filter: 'none'
      },
      {
        name: this.props.intl.formatMessage({ id: 'SIDEBAR.COUNTRY' }),
        type: 'country',
        filter: 'none'
      }
    ],
    filterType: 'none',
    filterValue: 'none',
    visibleFilter: false,
    openFilter: false,
    width: window.innerWidth,
    visibleModal: false,
    visibleModalAdd: false,
    openModalAdd: false,
    visibleModalEdit: false,
    openModalEdit: false,
    photoData: null
  };

  componentDidMount() {
    const { location } = this.props;
    window.addEventListener('resize', this.handleWindowSizeChange);
    const params = queryString.parse(location.search);
    if (params && params.filter && params.type)
      this.setFilter({ filter: params.filter, type: params.type });
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const params = queryString.parse(location.search);
    const prevParams = queryString.parse(prevProps.location.search);

    if (
      prevParams &&
      params &&
      (params.filter !== prevParams.filter || params.type !== prevParams.type)
    )
      this.setFilter({ filter: params.filter, type: params.type });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
    this.checkFilter();
  };

  toggleFilter = () => {
    if (!this.state.openFilter) {
      this.setState({
        visibleFilter: true,
        openFilter: !this.state.openFilter
      });
    } else {
      this.setState({
        openFilter: !this.state.openFilter
      });
      this.props.setTimeout(() => {
        this.setState({
          visibleFilter: false
        });
      }, 200);
    }
  };

  checkFilter = () => {
    if (this.state.width > Breakpoints.lg && this.state.openFilter) {
      this.setState({
        visibleFilter: false,
        openFilter: false
      });
    }
  };

  setFilter = ({ filter, type }) => {
    this.setState({
      filterValue: filter,
      filterType: type
    });
  };

  openModal = param => {
    const { factory } = this.props;
    this.props.manageFactoryInit({
      factory: param === 'edit' && factory
    });
    this.setState({
      visibleModalAdd: param === 'add' ? true : false,
      visibleModalEdit: param === 'edit' ? true : false,
      openModalAdd: param === 'add' ? true : false,
      openModalEdit: param === 'edit' ? true : false,
      photoData: null
    });
  };

  closeModal = () => {
    this.setState({
      openModalAdd: false,
      openModalEdit: false
    });
    this.props.setTimeout(() => {
      this.setState({
        visibleModalAdd: false,
        visibleModalEdit: false,
        photoData: null
      });
    }, 200);
  };

  handleSubmit = async data => {
    const { type, values } = data;
    const factory = {
      name: values.factoryName || '',
      number: values.factoryNumber || '',
      street: values.street || '',
      city: values.city || '',
      postalCode: values.postalCode || '',
      country: values.country || '',
      website: values.website || '',
      details: values.details || '',
      contactPerson: values.contactPerson || '',
      contactPosition: values.contactPosition || '',
      contactEmail: values.contactEmail || '',
      contactNo: values.contactNo || ''
    };

    switch (type) {
      case 'FACTORY_ADD':
        await this.props
          .addFactory({ photoData: this.state.photoData, factory })
          .then(() => {
            this.closeModal();
          });
        break;
      case 'FACTORY_UPDATE':
        await this.props
          .updateFactory(this.props.factory.id, {
            photoData: this.state.photoData,
            factory
          })
          .then(() => {
            this.closeModal();
          });
        break;
      default:
        return false;
    }
    return true;
  };

  handlePhotoData = data => {
    this.setState({ photoData: data });
  };

  render() {
    const {
      filter,
      filterOptions,
      filterType,
      visibleFilter,
      openFilter,
      width,
      visibleModalAdd,
      openModalAdd,
      visibleModalEdit,
      openModalEdit
    } = this.state;
    const {
      firebaseStore,
      location,
      history,
      factories,
      factory,
      factoryManageStore,
      intl
    } = this.props;
    const theme = this.props.theme;
    const isMobile = width <= Breakpoints.lg;

    let factoriesFiltered;
    let data;
    switch (filterType) {
      case 'country':
        data =
          (factories &&
            Object.values(factories) &&
            Array.from(
              new Set(
                Object.values(factories)
                  .filter(el => el.country !== undefined && el.country !== '')
                  .map(el => el.country)
              )
            )) ||
          [];
        data = data.map(e => {
          return {
            name: intl.formatMessage({
              id: `COUNTRY.COUNTRY.${(() => {
                const country = countries.find(country => country.id === e);
                return country && country.name;
              })()}`,
              defaultMessage: e
            }),
            items: Object.values(factories).filter(el => el.country === e)
          };
        });
        data.sort((a, b) => a.name.localeCompare(b.name));
        if (
          factories &&
          Object.values(factories) &&
          Object.values(factories).find(
            el => el.country === undefined || el.country === ''
          )
        ) {
          data.push({
            name: intl.formatMessage({ id: 'SIDEBAR.UNCATEGORIZED' }),
            items: Object.values(factories).filter(
              el => el.country === undefined || el.country === ''
            )
          });
        }
        factoriesFiltered = data;
        break;
      case 'name':
        data =
          (factories &&
            Object.values(factories) &&
            Array.from(
              new Set(
                Object.values(factories)
                  .filter(el => el.name !== undefined && el.name !== '')
                  .map(el => el.name[0])
              )
            )) ||
          [];
        data = data.map(e => {
          return {
            name: e,
            items: Object.values(factories).filter(
              el => el.name && el.name[0] === e
            )
          };
        });
        data.sort((a, b) => a.name.localeCompare(b.name));
        if (
          factories &&
          Object.values(factories) &&
          Object.values(factories).find(
            el => el.name === undefined || el.name === ''
          )
        ) {
          data.push({
            name: intl.formatMessage({ id: 'SIDEBAR.UNCATEGORIZED' }),
            items: Object.values(factories).filter(
              el => el.name === undefined || el.name === ''
            )
          });
        }
        factoriesFiltered = data;
        break;
      default:
    }

    return (
      <>
        <Helmet>
          <title>
            {factory
              ? `${factory.name ? factory.name : factory.id}`
              : intl.formatMessage({ id: 'PAGE_TITLE.FACTORIES' })}{' '}
            | BIN-E Smart Waste Bin
          </title>
        </Helmet>

        <Wrapper>
          <Header
            currentPath={location.pathname}
            profile={firebaseStore.profile}
            filter={isMobile && factory ? false : filter}
            handleToggleFilter={this.toggleFilter}
          />
          <Content>
            {(!isMobile || (isMobile && !factory)) && (
              <Sider
                options={filterOptions}
                location={location}
                history={history}
                visible={visibleFilter}
                open={openFilter}
                handleToggleFilter={this.toggleFilter}
              />
            )}

            <Panel
              theme={
                isMobile && factory
                  ? theme.content.panel.alternative
                  : theme.content.panel.default
              }
            >
              <PanelInner>
                {(!isMobile || (isMobile && !factory)) && (
                  <Title theme={theme.content.title}>
                    {intl.formatMessage({ id: 'FACTORIES.FACTORIES' })}
                  </Title>
                )}

                {!isLoaded(factories) ? (
                  <Box>
                    <Information>
                      {intl.formatMessage({ id: 'FACTORIES.LOADING' })}...
                    </Information>
                  </Box>
                ) : (
                  <Box>
                    {(!isMobile || (isMobile && !factory)) && (
                      <List theme={theme.scroll}>
                        <FactoriesAdd
                          theme={theme.content.add}
                          handleClick={() => this.openModal('add')}
                        />
                        {!isEmpty(factories)
                          ? factoriesFiltered
                            ? factoriesFiltered.map((el, key) => (
                                <Category key={key}>
                                  <Subtitle>{el.name}</Subtitle>
                                  {el.items.map(el => (
                                    <Link
                                      to={`/factories/${el.id}${
                                        location.search ? location.search : ''
                                      }`}
                                      key={el.id}
                                    >
                                      <FactoriesItem
                                        theme={theme.content.item}
                                        data={el}
                                        isActive={
                                          factory && el.id === factory.id
                                        }
                                      />
                                    </Link>
                                  ))}
                                </Category>
                              ))
                            : Object.values(factories).map(el => (
                                <Link
                                  to={`/factories/${el.id}${
                                    location.search ? location.search : ''
                                  }`}
                                  key={el.id}
                                >
                                  <FactoriesItem
                                    theme={theme.content.item}
                                    data={el}
                                    isActive={factory && el.id === factory.id}
                                  />
                                </Link>
                              ))
                          : ''}
                      </List>
                    )}
                    {factory && (
                      <Details theme={theme.scroll}>
                        {isMobile && (
                          <Navigation>
                            <Link
                              to={`/factories${
                                location.search ? location.search : ''
                              }`}
                            >
                              <Button
                                title={intl.formatMessage({
                                  id: 'FACTORIES.CLOSE'
                                })}
                              >
                                <Image
                                  src={Icon}
                                  alt={intl.formatMessage({
                                    id: 'FACTORIES.CLOSE'
                                  })}
                                />
                              </Button>
                            </Link>
                          </Navigation>
                        )}
                        <FactoryDetails
                          theme={theme.content.details}
                          data={factory}
                          handleClick={() => this.openModal('edit')}
                        />
                      </Details>
                    )}
                  </Box>
                )}
              </PanelInner>
            </Panel>
          </Content>
        </Wrapper>
        {visibleModalAdd && (
          <FactoryManage
            store={factoryManageStore}
            onSubmit={values => {
              this.handleSubmit({ type: 'FACTORY_ADD', values });
            }}
            onSetPhotoData={this.handlePhotoData}
            handleClose={this.closeModal}
            open={openModalAdd}
          />
        )}
        {visibleModalEdit && (
          <FactoryManage
            store={factoryManageStore}
            onSubmit={values => {
              this.handleSubmit({ type: 'FACTORY_UPDATE', values });
            }}
            onSetPhotoData={this.handlePhotoData}
            handleClose={this.closeModal}
            open={openModalEdit}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const factoryID = ownProps.match.params.id;
  const factories =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.factories;
  const factory = factories && factories.find(e => e.id === factoryID);
  return {
    firebaseStore: state.firebase,
    factories,
    factory,
    factoryManageStore: state.factoryManageStore
  };
};

const mapDispatchToProps = dispatch => {
  return {
    manageFactoryInit: data => dispatch(manageFactoryInit(data)),
    addFactory: data => dispatch(addFactory(data)),
    updateFactory: (id, data) => dispatch(updateFactory(id, data))
  };
};

export default compose(
  injectIntl,
  withTheme,
  withRouter,
  withTimeout,
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: 'factories' }])
)(FactoriesPage);
