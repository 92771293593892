import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/firebase-storage';
import 'firebase/functions';
import 'firebase/messaging';

// Set client configuration
const env = process.env.REACT_APP_CLIENT_ENV || 'development';
const config = require(`./config.json`)[env].firebase;

firebase.initializeApp(config);

if (process.env.NODE_ENV !== 'production')
  firebase.firestore.setLogLevel('debug');

firebase
  .firestore()
  .enablePersistence({ synchronizeTabs: true })
  .catch((error) => {
    console.error(error);
  });
firebase.storage();
firebase.functions();

if (firebase.messaging.isSupported()) {
  firebase.messaging();
}

export default firebase;
