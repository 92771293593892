import moment from 'moment';

/*
// ACTION MANAGER CUSTOMER INIT
*/
export const manageCustomerInit = data => {
  return dispatch => {
    dispatch({ type: 'CUSTOMER_MANAGE_INIT', data });
  };
};

/*
// ACTION ADD CUSTOMER
*/
export const addCustomer = data => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'CUSTOMER_MANAGE_PENDING' });
    const firestore = getFirestore();

    let customerDistributorRef;
    if (data.distributor)
      customerDistributorRef = await firestore
        .collection('distributors')
        .doc(data.distributor);

    let customerManagerRef;
    if (data.manager)
      customerManagerRef = await firestore
        .collection('users')
        .doc(data.manager);

    await firestore
      .collection('customers')
      .add({
        ...data.customer,
        distributor: customerDistributorRef ? customerDistributorRef : '',
        manager: customerManagerRef ? customerManagerRef : '',
        createdAt: moment()
          .utc()
          .format('YYYY-MM-DDTHH:mm:ssZ')
      })
      .then(customer =>
        dispatch({ type: 'CUSTOMER_MANAGE_SUCCESS', data: { id: customer.id } })
      )
      .catch(err => dispatch({ type: 'CUSTOMER_MANAGE_ERROR', err }));
  };
};

/*
// ACTION UPDATE CUSTOMER
*/
export const updateCustomer = (id, data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'CUSTOMER_MANAGE_PENDING' });
    const firestore = getFirestore();

    let customerDistributorRef;
    if (data.distributor)
      customerDistributorRef = await firestore
        .collection('distributors')
        .doc(data.distributor);

    let customerManagerRef;
    if (data.manager)
      customerManagerRef = await firestore
        .collection('users')
        .doc(data.manager);

    await firestore
      .collection('customers')
      .doc(id)
      .update({
        ...data.customer,
        distributor: customerDistributorRef ? customerDistributorRef : '',
        manager: customerManagerRef ? customerManagerRef : '',
        updatedAt: moment()
          .utc()
          .format('YYYY-MM-DDTHH:mm:ssZ')
      })
      .then(() =>
        dispatch({
          type: 'CUSTOMER_MANAGE_SUCCESS',
          data: { id }
        })
      )
      .catch(err => dispatch({ type: 'CUSTOMER_MANAGE_ERROR', err }));
  };
};
