/*
//   STYLE-COMPONENT: SIGN PANEL
*/

import { css } from 'styled-components';
import { Media } from '../Mixins.style';

export const Panel = css`
  display: inline-block;
  max-width: 340px;
  width: 100%;
  padding: 35px 25px;
  background: ${props => props.theme.background};
`;

export const Logobox = css`
  display: block;
  width: 100%;
  margin-bottom: 30px;

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
`;

export const Textbox = css`
  display: block;
  width: 100%;
  margin-bottom: 27px;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
  cursor: default;

  span {
    font-size: 14px;
  }
`;

export const Error = css`
  color: ${props => props.theme.color};
  font-weight: 700;
`;

export const Formbox = css`
  display: block;
  width: 100%;
`;

export const Groupbox = css`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin: 5px 0 35px;

  ${Media.xs`
    justify-content: center;
  `}
`;

export const Linkbox = css`
  display: inline-block;

  a {
    display: inline-block;
    margin: 3px 5px;
    transition: 0.2s color;
    color: ${props => props.theme.color};
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: ${props => props.theme.colorOnHover};
    }
  }
`;

export const Footerbox = css`
  display: block;
  width: 100%;
  margin-top: 26px;
  text-align: center;

  a {
    display: inline-block;
    margin: 3px 5px;
    transition: 0.2s color;
    color: ${props => props.theme.color};
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  }
`;
