import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Theme } from '../../assets/Theme.style';
import { Label, Title, Content } from './CustomersItem.style';

function CustomersItem({
  theme = Theme.content.item,
  data = {},
  isActive = false
}) {
  const intl = useIntl();

  return (
    <>
      {Object.values(data).length > 0 && (
        <Label theme={theme.label} data-active={isActive ? true : undefined}>
          <Title>{data.name}</Title>
          <Content>
            <span>
              {data.distributor
                ? data.distributorData
                  ? data.distributorData.name
                  : `${intl.formatMessage({
                      id: 'CUSTOMERS.ASSESSING_INFORMATION'
                    })}...`
                : intl.formatMessage({ id: 'CUSTOMERS.NO_DISTRIBUTOR' })}
            </span>
          </Content>
        </Label>
      )}
    </>
  );
}

CustomersItem.propTypes = {
  theme: PropTypes.object,
  data: PropTypes.object,
  isActive: PropTypes.bool
};

export default memo(CustomersItem);
