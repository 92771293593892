import styled from 'styled-components';
import {
  Section as commonSection,
  Wrapper as commonWrapper,
  Content as commonContent,
  Footer as commonFooter
} from '../../assets/common/SignWrapper.style';

export const Section = styled.div`
  ${commonSection}
`;

export const Wrapper = styled.div`
  ${commonWrapper}
`;

export const Content = styled.div`
  ${commonContent}
`;

export const Footer = styled.footer`
  ${commonFooter}
`;
