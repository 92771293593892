import styled from 'styled-components';

export const Item = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
`;

export const Image = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0;
  flex-shrink: 0;
`;

export const Content = styled.div`
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.4;
  padding-left: 15px;
  word-break: break-all;
  cursor: default;

  span {
    display: block;
  }
`;
