import SignInPage from '../views/SignInPage/SignInPage';
import SignOutPage from '../views/SignOutPage/SignOutPage';
import ForgotPasswordPage from '../views/ForgotPasswordPage/ForgotPasswordPage';
import AuthPage from '../views/AuthPage/AuthPage';
import WelcomePage from '../views/WelcomePage/WelcomePage';
import FactoriesPage from '../views/FactoriesPage/FactoriesPage';
import DevicesPage from '../views/DevicesPage/DevicesPage';
import DistributorsPage from '../views/DistributorsPage/DistributorsPage';
import CustomersPage from '../views/CustomersPage/CustomersPage';
import UsersPage from '../views/UsersPage/UsersPage';
import ProfileSettingsPage from '../views/ProfileSettingsPage/ProfileSettingsPage';
import ProfileNotificationsPage from '../views/ProfileNotificationsPage/ProfileNotificationsPage';
import ApplicationSettingsPage from '../views/ApplicationSettingsPage/ApplicationSettingsPage';
import BlackHolePage from '../views/BlackHolePage/BlackHolePage';

const Routes = [
  // AUTH ROUTES
  {
    path: '/sign-in',
    name: 'SIGN-IN',
    component: SignInPage,
    exact: true,
    auth: false
  },
  {
    path: '/sign-out',
    name: 'SIGN-OUT',
    component: SignOutPage,
    exact: true,
    auth: true
  },
  {
    path: '/forgot-password',
    name: 'FORGOT-PASSWORD',
    component: ForgotPasswordPage,
    exact: true,
    auth: false
  },
  {
    path: '/auth',
    name: 'AUTH',
    component: AuthPage,
    exact: true,
    auth: false
  },
  {
    path: '/welcome',
    name: 'WELCOME',
    component: WelcomePage,
    exact: true,
    auth: false
  },
  // CLIENT ROUTES
  {
    path: '/profile-settings',
    name: 'PROFILE-SETTINGS',
    component: ProfileSettingsPage,
    exact: true,
    auth: true
  },
  {
    path: '/profile-notifications',
    name: 'PROFILE-NOTIFICATIONS',
    component: ProfileNotificationsPage,
    exact: true,
    auth: true
  },
  {
    path: '/application-settings',
    name: 'APPLICATION-SETTINGS',
    component: ApplicationSettingsPage,
    exact: true,
    auth: true
  },
  {
    path: '/users/:id',
    name: 'USERS-DETAILS',
    component: UsersPage,
    exact: true,
    auth: true,
    privileges: 'VIEW_USERS'
  },
  {
    path: '/users',
    name: 'USERS',
    component: UsersPage,
    exact: true,
    auth: true,
    privileges: 'VIEW_USERS'
  },
  {
    path: '/customers/:id',
    name: 'CUSTOMERS-DETAILS',
    component: CustomersPage,
    exact: true,
    auth: true,
    privileges: 'VIEW_CUSTOMERS'
  },
  {
    path: '/customers',
    name: 'CUSTOMERS',
    component: CustomersPage,
    exact: true,
    auth: true,
    privileges: 'VIEW_CUSTOMERS'
  },
  {
    path: '/distributors/:id',
    name: 'DISTRIBUTORS-DETAILS',
    component: DistributorsPage,
    exact: true,
    auth: true,
    privileges: 'VIEW_DISTRIBUTORS'
  },
  {
    path: '/distributors',
    name: 'DISTRIBUTORS',
    component: DistributorsPage,
    exact: true,
    auth: true,
    privileges: 'VIEW_DISTRIBUTORS'
  },
  {
    path: '/devices/:id',
    name: 'DEVICES-DETAILS',
    component: DevicesPage,
    exact: true,
    auth: true,
    privileges: 'VIEW_DEVICES'
  },
  {
    path: '/devices',
    name: 'DEVICES',
    component: DevicesPage,
    exact: true,
    auth: true,
    privileges: 'VIEW_DEVICES'
  },
  {
    path: '/factories/:id',
    name: 'FACTORIES-DETAILS',
    component: FactoriesPage,
    exact: true,
    auth: true,
    privileges: 'VIEW_FACTORIES'
  },
  {
    path: '/factories',
    name: 'FACTORIES',
    component: FactoriesPage,
    exact: true,
    auth: true,
    privileges: 'VIEW_FACTORIES'
  },
  {
    path: '/',
    name: 'DEVICES',
    component: DevicesPage,
    exact: true,
    auth: true,
    privileges: 'VIEW_DEVICES'
  },
  // OTHER ROUTES
  {
    name: 'BLACK-HOLE',
    component: BlackHolePage,
    auth: false
  }
];

export default Routes;
