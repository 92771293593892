const defaultState = {
  type: 'timestamp',
  limit: 4,
  to: undefined,
  from: undefined,
  status: undefined
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'DEVICE_HISTORY_RESET': {
      return {
        ...defaultState
      };
    }
    case 'DEVICE_HISTORY_SET_LIMIT': {
      return {
        ...state,
        limit: action.limit
      };
    }
    case 'DEVICE_HISTORY_TO_DATE': {
      return {
        ...state,
        type: 'timestamp',
        to: action.to,
        status: undefined
      };
    }
    case 'DEVICE_HISTORY_FROM_DATE': {
      return {
        ...state,
        type: 'timestamp',
        from: action.from,
        status: undefined
      };
    }
    case 'DEVICE_HISTORY_STATUS': {
      return {
        ...state,
        type: action.status ? 'status' : 'timestamp',
        to: undefined,
        from: undefined,
        status: action.status
      };
    }
    default:
      return state;
  }
};
