import React from 'react';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import SuccessIcon from '../../assets/images/Success.svg';
import ErrorIcon from '../../assets/images/Error.svg';
import CloseIcon from '../../assets/images/Close.svg';

import { Theme } from '../../assets/Theme.style';

import {
  Wrapper,
  Holder,
  Modal,
  Close,
  Image,
  Title,
  Content,
  Subtitle,
  Box,
  Photobox,
  Inputbox,
  Selectbox,
  Navigation,
  Button,
  Alertbox,
  Alert,
  AlertImage,
  AlertContent,
  AlertTitle
} from './DistributorManage.style';

import DistributorPhoto from '../DistributorPhoto/DistributorPhoto';
import CountryAssign from '../CountryAssign/CountryAssign';

function DistributorManage({
  theme = {
    ...Theme.modal,
    alert: Theme.alert,
    button: Theme.button
  },
  store = {},
  onSubmit = () => {},
  onSetPhotoData = () => {},
  handleClose = () => {},
  open = false
}) {
  const intl = useIntl();

  const validate = values => {
    const errors = {};

    if (!values.name) errors.name = 'DISTRIBUTORS.MANAGE.VALIDATION.REQUIRED';
    if (!values.street)
      errors.street = 'DISTRIBUTORS.MANAGE.VALIDATION.REQUIRED';
    if (!values.city) errors.city = 'DISTRIBUTORS.MANAGE.VALIDATION.REQUIRED';
    if (!values.postalCode)
      errors.postalCode = 'DISTRIBUTORS.MANAGE.VALIDATION.REQUIRED';
    if (!values.country)
      errors.country = 'DISTRIBUTORS.MANAGE.VALIDATION.REQUIRED';

    if (
      values.contactEmail &&
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        values.contactEmail
      )
    )
      errors.contactEmail =
        'DISTRIBUTORS.MANAGE.VALIDATION.INVALID_EMAIL_ADDRESS_FORMAT';

    if (
      values.contactNo &&
      !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/im.test(
        values.contactNo
      )
    )
      errors.contactNo =
        'DISTRIBUTORS.MANAGE.VALIDATION.INVALID_PHONE_NUMBER_FORMAT';

    return errors;
  };

  return (
    <Wrapper>
      <Holder
        theme={theme.holder}
        data-open={open ? true : undefined}
        data-close={!open ? true : undefined}
      >
        <Modal
          theme={theme.content.default}
          data-open={open ? true : undefined}
          data-close={!open ? true : undefined}
        >
          <Close
            onClick={handleClose}
            title={intl.formatMessage({ id: 'DISTRIBUTORS.MANAGE.MENU.CLOSE' })}
          >
            <Image
              src={CloseIcon}
              alt={intl.formatMessage({ id: 'DISTRIBUTORS.MANAGE.MENU.CLOSE' })}
            />
          </Close>
          <Title>
            {store.distributor && store.distributor.id
              ? intl.formatMessage({
                  id: 'DISTRIBUTORS.MANAGE.EDIT_DISTRIBUTOR'
                })
              : intl.formatMessage({
                  id: 'DISTRIBUTORS.MANAGE.ADD_NEW_DISTRIBUTOR'
                })}
          </Title>
          <Content>
            <Form
              onSubmit={onSubmit}
              validate={validate}
              initialValues={{
                name: store.distributor && store.distributor.name,
                street: store.distributor && store.distributor.street,
                city: store.distributor && store.distributor.city,
                postalCode: store.distributor && store.distributor.postalCode,
                country: store.distributor && store.distributor.country,
                contactEmail:
                  store.distributor && store.distributor.contactEmail,
                contactNo: store.distributor && store.distributor.contactNo
              }}
              keepDirtyOnReinitialize={true}
              render={({ handleSubmit, submitting, pristine, invalid }) => (
                <form onSubmit={handleSubmit}>
                  <Box>
                    <Field name='photoURL'>
                      {({ input, meta }) => (
                        <Photobox>
                          <DistributorPhoto
                            theme={theme.distributorPhoto}
                            input={input}
                            profile={{
                              name: store.distributor && store.distributor.name,
                              photoURL:
                                store.distributor && store.distributor.photoURL
                            }}
                            label={
                              store.distributor && store.distributor.photoURL
                                ? intl.formatMessage({
                                    id:
                                      'DISTRIBUTORS.MANAGE.CHANGE_DISTRIBUTOR_PHOTO'
                                  })
                                : intl.formatMessage({
                                    id:
                                      'DISTRIBUTORS.MANAGE.ADD_DISTRIBUTOR_PHOTO'
                                  })
                            }
                            onSetPhotoData={onSetPhotoData}
                          />
                        </Photobox>
                      )}
                    </Field>
                    <Field name='name'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'DISTRIBUTORS.MANAGE.DISTRIBUTOR_NAME'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.inputbox.default}
                        >
                          <input
                            {...input}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'DISTRIBUTORS.MANAGE.DISTRIBUTOR_NAME'
                            })}
                            autoComplete='off'
                            data-big={true}
                            data-invalid={
                              meta.touched && meta.error ? true : undefined
                            }
                            data-valid={
                              meta.touched && !meta.error ? true : undefined
                            }
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Subtitle>
                      {intl.formatMessage({
                        id: 'DISTRIBUTORS.MANAGE.LOCATION'
                      })}
                    </Subtitle>
                    <Field name='street'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'DISTRIBUTORS.MANAGE.LOCATION.STREET'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.inputbox.default}
                        >
                          <input
                            {...input}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'DISTRIBUTORS.MANAGE.LOCATION.STREET'
                            })}
                            autoComplete='off'
                            data-invalid={
                              meta.touched && meta.error ? true : undefined
                            }
                            data-valid={
                              meta.touched && !meta.error ? true : undefined
                            }
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                    <Field name='city'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'DISTRIBUTORS.MANAGE.LOCATION.CITY'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.inputbox.default}
                        >
                          <input
                            {...input}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'DISTRIBUTORS.MANAGE.LOCATION.CITY'
                            })}
                            autoComplete='off'
                            data-invalid={
                              meta.touched && meta.error ? true : undefined
                            }
                            data-valid={
                              meta.touched && !meta.error ? true : undefined
                            }
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                    <Field name='postalCode'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'DISTRIBUTORS.MANAGE.LOCATION.POSTAL_CODE'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.inputbox.default}
                        >
                          <input
                            {...input}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'DISTRIBUTORS.MANAGE.LOCATION.POSTAL_CODE'
                            })}
                            autoComplete='off'
                            data-invalid={
                              meta.touched && meta.error ? true : undefined
                            }
                            data-valid={
                              meta.touched && !meta.error ? true : undefined
                            }
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                    <Field name='country'>
                      {({ input, meta }) => (
                        <Selectbox
                          data-name={intl.formatMessage({
                            id: 'DISTRIBUTORS.MANAGE.LOCATION.COUNTRY'
                          })}
                          data-filled={
                            input && input.value && input.value.length > 0
                              ? true
                              : undefined
                          }
                          theme={theme.selectbox.default}
                        >
                          <CountryAssign input={input} />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Selectbox>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Subtitle>
                      {intl.formatMessage({
                        id: 'DISTRIBUTORS.MANAGE.CONTACT'
                      })}
                    </Subtitle>
                    <Field name='contactEmail'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'DISTRIBUTORS.MANAGE.CONTACT.EMAIL_ADDRESS'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.inputbox.default}
                        >
                          <input
                            {...input}
                            type='email'
                            placeholder={intl.formatMessage({
                              id: 'DISTRIBUTORS.MANAGE.CONTACT.EMAIL_ADDRESS'
                            })}
                            autoComplete='off'
                            data-invalid={
                              meta.touched && meta.error ? true : undefined
                            }
                            data-valid={
                              meta.touched && !meta.error ? true : undefined
                            }
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                    <Field name='contactNo'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'DISTRIBUTORS.MANAGE.CONTACT.PHONE_NUMBER'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.inputbox.default}
                        >
                          <input
                            {...input}
                            type='tel'
                            placeholder={intl.formatMessage({
                              id: 'DISTRIBUTORS.MANAGE.CONTACT.PHONE_NUMBER'
                            })}
                            autoComplete='off'
                            data-invalid={
                              meta.touched && meta.error ? true : undefined
                            }
                            data-valid={
                              meta.touched && !meta.error ? true : undefined
                            }
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                  </Box>

                  {store.isError ? (
                    <Alertbox>
                      <Alert>
                        <AlertImage
                          src={ErrorIcon}
                          alt={intl.formatMessage({
                            id: 'DISTRIBUTORS.MANAGE.ALERT.SOMETHING_WENT_WRONG'
                          })}
                        />
                        <AlertContent>
                          <AlertTitle theme={theme.alert.error.title}>
                            {intl.formatMessage({
                              id:
                                'DISTRIBUTORS.MANAGE.ALERT.SOMETHING_WENT_WRONG'
                            })}
                          </AlertTitle>
                          {store.error}
                        </AlertContent>
                      </Alert>
                    </Alertbox>
                  ) : (
                    store.isSuccess && (
                      <Alertbox>
                        <Alert>
                          <AlertImage
                            src={SuccessIcon}
                            alt={intl.formatMessage({
                              id: 'DISTRIBUTORS.MANAGE.ALERT.SUCCESS'
                            })}
                          />
                          <AlertContent>
                            <AlertTitle theme={theme.alert.success.title}>
                              {intl.formatMessage({
                                id: 'DISTRIBUTORS.MANAGE.ALERT.SUCCESS'
                              })}
                            </AlertTitle>{' '}
                            {store.distributor && store.distributor.id
                              ? intl.formatMessage({
                                  id:
                                    'DISTRIBUTORS.MANAGE.ALERT.DISTRIBUTOR_UPDATE'
                                })
                              : intl.formatMessage({
                                  id:
                                    'DISTRIBUTORS.MANAGE.ALERT.DISTRIBUTOR_CREATED'
                                })}
                          </AlertContent>
                        </Alert>
                      </Alertbox>
                    )
                  )}

                  <Navigation>
                    <Button
                      theme={theme.button.default}
                      disabled={
                        submitting ||
                        pristine ||
                        invalid ||
                        (store && store.isLoading)
                      }
                    >
                      {store && store.isLoading
                        ? intl.formatMessage({
                            id: 'DISTRIBUTORS.MANAGE.MENU.PLEASE_WAIT'
                          })
                        : store.distributor && store.distributor.id
                        ? intl.formatMessage({
                            id: 'DISTRIBUTORS.MANAGE.MENU.EDIT_DISTRIBUTOR'
                          })
                        : intl.formatMessage({
                            id: 'DISTRIBUTORS.MANAGE.MENU.ADD_DISTRIBUTOR'
                          })}
                    </Button>
                    <Button
                      type='button'
                      theme={theme.button.alternative}
                      onClick={handleClose}
                    >
                      {intl.formatMessage({
                        id: 'DISTRIBUTORS.MANAGE.MENU.CANCEL'
                      })}
                    </Button>
                  </Navigation>
                </form>
              )}
            />
          </Content>
        </Modal>
      </Holder>
    </Wrapper>
  );
}

DistributorManage.propTypes = {
  theme: PropTypes.object,
  store: PropTypes.object,
  onSubmit: PropTypes.func,
  onSetPhotoData: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool
};

export default DistributorManage;
