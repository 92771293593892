import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import { withTheme } from '../../assets/Theme.style';
import { Section, Wrapper, Content, Footer } from './ForgotPasswordPage.style';

import ForgotPasswordPanel from '../../components/ForgotPasswordPanel/ForgotPasswordPanel';

import {
  forgotPasswordInit,
  forgotPassword
} from '../../actions/Auth/AuthActions';

class ForgotPasswordPage extends Component {
  state = {};

  componentDidMount() {
    this.props.forgotPasswordInit();
  }

  handleSubmit = values => {
    this.props.forgotPassword({
      email: values.email
    });
  };

  render() {
    const { firebaseStore, forgotPasswordStore } = this.props;
    const theme = this.props.theme;

    return (
      <>
        <Helmet>
          <title>Forgot password | BIN-E Smart Waste Bin</title>
        </Helmet>
        {!firebaseStore.auth.isEmpty ? (
          <Redirect to={{ pathname: '/' }} />
        ) : (
          <Section>
            <Wrapper theme={theme.sign.wrapper.default}>
              <Content>
                <ForgotPasswordPanel
                  onSubmit={this.handleSubmit}
                  store={forgotPasswordStore}
                />
              </Content>
              <Footer theme={theme.sign.footer.default}>
                By signing in, you accept{' '}
                <a
                  href='http://www.bine.world'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Privacy policy
                </a>{' '}
                <br />
                (including Use of Cookies and Other
                <br /> Technologies) and{' '}
                <a
                  href='http://www.bine.world'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Terms of use
                </a>
              </Footer>
            </Wrapper>
          </Section>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    firebaseStore: state.firebase,
    forgotPasswordStore: state.authForgotPasswordStore
  };
};

const mapDispatchToProps = dispatch => {
  return {
    forgotPasswordInit: () => dispatch(forgotPasswordInit()),
    forgotPassword: credentials => dispatch(forgotPassword(credentials))
  };
};

export default compose(
  withTheme,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ForgotPasswordPage);
