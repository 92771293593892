import React, { createContext, useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import translations from './translations';

// Set client configuration
const env = process.env.REACT_APP_CLIENT_ENV || 'development';
const config = require(`./config.json`)[env];

const Context = createContext();

const IntlProviderWrapper = ({ children }) => {
  const [language, setLanguage] = useState(
    localStorage.getItem('APP_SETTINGS_USER_LANG') || config.lang
  );

  useEffect(() => {
    if (language !== localStorage.getItem('APP_SETTINGS_USER_LANG'))
      localStorage.setItem('APP_SETTINGS_USER_LANG', language, { path: '/' });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <Context.Provider value={{ switchLanguage: setLanguage }}>
      <IntlProvider
        key={
          translations[language]
            ? language
            : translations[language.split('-')[0]]
            ? language.split('-')[0]
            : config.lang
        }
        locale={
          translations[language]
            ? language
            : translations[language.split('-')[0]]
            ? language.split('-')[0]
            : config.lang
        }
        messages={
          translations[language]
            ? translations[language]
            : translations[language.split('-')[0]]
            ? translations[language.split('-')[0]]
            : translations[config.lang]
        }
        defaultLocale={config.lang}
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export { IntlProviderWrapper, Context as IntlContext };
