import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { useIntl } from 'react-intl';

import { Theme } from '../../assets/Theme.style';

import { Select } from './DistributorAssign.style';

function DistributorAssign({
  theme = {
    ...Theme.modal.selectbox
  },
  input = {},
  distributors
}) {
  const intl = useIntl();
  return (
    <Select
      defaultValue={
        input && input.value && input.value.length > 0 ? input.value : false
      }
      theme={theme.selectrix}
      placeholder={intl.formatMessage({
        id: 'DISTRIBUTORS.ASSIGN.DISTRIBUTOR'
      })}
      arrow={false}
      customScrollbar={true}
      searchable={false}
      options={
        distributors &&
        distributors.map(el => {
          return {
            ...el,
            key: el.id,
            label: el.name
          };
        })
      }
      onChange={value => {
        input.onChange(value.id);
      }}
    />
  );
}

DistributorAssign.propsTypes = {
  theme: PropTypes.object,
  input: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  const distributors =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.distributorAssignDistributors;
  return {
    distributors
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    { collection: 'distributors', storeAs: 'distributorAssignDistributors' }
  ])
)(DistributorAssign);
