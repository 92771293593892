import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { useIntl } from 'react-intl';

import CloseIcon from '../../assets/images/Close.svg';

import { Theme } from '../../assets/Theme.style';

import {
  Box,
  Item,
  Menu,
  Button,
  ButtonIcon,
  Content,
  Selectbox,
  Select
} from './DeviceUsersAssign.style';

function DeviceUsersAssign({
  theme = {
    ...Theme.modal.selectbox
  },
  input = {},
  distributor = '',
  customer = '',
  users,
  customers
}) {
  const intl = useIntl();
  const checkUsers = () => {
    if (users !== undefined) {
      const ids = input.value.filter(id => {
        const user = users && users.find(user => user.id === id);
        if (user) {
          if (
            distributor &&
            customer &&
            user.distributor &&
            user.distributor.id === distributor &&
            user.customer &&
            user.customer.id === customer
          ) {
            return true;
          } else if (
            distributor &&
            !customer &&
            user.distributor &&
            user.distributor.id === distributor
          ) {
            return true;
          } else if (!distributor && !customer) {
            return true;
          }
        }
        return false;
      });

      if (input.value.join() !== ids.join()) {
        input.onChange(ids);
      }
    }
  };

  const removeUser = params => e => {
    const user = input.value.find(id => id === params.id);
    if (user) {
      const users = input.value.filter(id => id !== params.id);
      input.onChange(users);
      checkUsers();
    }
  };

  const addUser = params => {
    const user = input.value.find(id => id === params.id);
    if (!user) input.onChange([...input.value, params.id]);
    checkUsers();
  };

  return (
    <>
      <Box>
        {input.value &&
          input.value.map((id, key) => {
            const user = users && users.find(user => user.id === id);

            return (
              <Item key={key}>
                <Menu>
                  <Button
                    onClick={removeUser({ id })}
                    title={intl.formatMessage({
                      id: 'DEVICES.MANAGE.USERS.REMOVE_USER'
                    })}
                  >
                    <ButtonIcon
                      src={CloseIcon}
                      alt={intl.formatMessage({
                        id: 'DEVICES.MANAGE.USERS.REMOVE_USER'
                      })}
                    />
                  </Button>
                </Menu>

                <Content>
                  <>
                    <strong>
                      {user && user.displayName ? user.displayName : id}
                    </strong>
                    {user && user.position && `, ${user.position}`}
                    {user && user.customer
                      ? customers &&
                        customers.find(
                          customer => customer.id === user.customer.id
                        )
                        ? `, ${
                            customers.find(
                              customer => customer.id === user.customer.id
                            ).name
                          }`
                        : `, ${intl.formatMessage({
                            id: 'DEVICES.MANAGE.USERS.ASSESSING_INFORMATION'
                          })}...`
                      : ''}
                  </>
                </Content>
              </Item>
            );
          })}
      </Box>

      <Selectbox theme={theme.default}>
        <Select
          defaultValue={'Search'}
          theme={theme.selectrix}
          placeholder={intl.formatMessage({
            id: 'DEVICES.MANAGE.USERS.SEARCH_USER'
          })}
          arrow={false}
          customScrollbar={true}
          searchable={true}
          noResultsMessage={intl.formatMessage({
            id: 'DEVICES.MANAGE.USERS.NO_USERS_MATCH'
          })}
          options={
            users &&
            Object.values(users)
              .filter(user => {
                if (
                  distributor &&
                  user.distributor &&
                  distributor === user.distributor.id &&
                  customer &&
                  user.customer &&
                  customer === user.customer.id
                )
                  return true;
                else if (
                  distributor &&
                  user.distributor &&
                  distributor === user.distributor.id &&
                  !customer
                )
                  return true;
                else if (!distributor && !customer) return true;
                return false;
              })
              .map(user => {
                return {
                  ...user,
                  key: user.id,
                  label: `${user.displayName ? user.displayName : user}${
                    user.position ? `, ${user.position}` : ''
                  }${
                    user.customer
                      ? customers &&
                        customers.find(
                          customer => customer.id === user.customer.id
                        )
                        ? `, ${
                            customers.find(
                              customer => customer.id === user.customer.id
                            ).name
                          }`
                        : `${intl.formatMessage({
                            id: 'DEVICES.MANAGE.USERS.ASSESSING_INFORMATION'
                          })}...`
                      : ''
                  }`,
                  disabled:
                    input.value && input.value.some(id => id === user.id)
                      ? true
                      : false
                };
              })
          }
          onChange={value => {
            if (value.id) addUser({ id: value.id });
          }}
        />
      </Selectbox>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  let users =
    (state.firestore &&
      state.firestore.ordered &&
      state.firestore.ordered.deviceUsersAssignUsers && [
        ...state.firestore.ordered.deviceUsersAssignUsers
      ]) ||
    [];

  if (ownProps.input && ownProps.input.value) {
    ownProps.input.value.forEach(id => {
      const user =
        state.firestore &&
        state.firestore.ordered &&
        state.firestore.ordered[`deviceUsersAssignUsers-${id}`] &&
        Object.assign(
          {},
          state.firestore.ordered[`deviceUsersAssignUsers-${id}`]
        );
      const isExistUser = users && users.find(user => user.id === id);
      if (user && !isExistUser) users.push(user);
    });
  }

  let customers =
    (state.firestore &&
      state.firestore.ordered &&
      state.firestore.ordered.deviceUsersAssignCustomers && [
        ...state.firestore.ordered.deviceUsersAssignCustomers
      ]) ||
    [];

  if (users) {
    users.forEach(user => {
      if (user.customer) {
        const customer =
          state.firestore &&
          state.firestore.ordered &&
          state.firestore.ordered[
            `deviceUsersAssignCustomers-${user.customer.id}`
          ] &&
          Object.assign(
            {},
            state.firestore.ordered[
              `deviceUsersAssignCustomers-${user.customer.id}`
            ]
          );
        const isExistCustomer =
          customers &&
          customers.find(customer => customer.id === user.customer.id);
        if (customer && !isExistCustomer) customers.push(customer);
      }
    });
  }

  return {
    users,
    customers
  };
};

DeviceUsersAssign.propsTypes = {
  theme: PropTypes.object,
  input: PropTypes.object,
  distributor: PropTypes.string,
  customer: PropTypes.string
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props, ownProps) => {
    const listener = [];

    if (props.distributor && props.customer) {
      const distributorRef = ownProps.firestore
        .collection('distributors')
        .doc(props.distributor);
      const customerRef = ownProps.firestore
        .collection('customers')
        .doc(props.customer);
      if (distributorRef && customerRef)
        listener.push(
          {
            collection: 'users',
            where: [
              ['distributor', '==', distributorRef],
              ['customer', '==', customerRef]
            ],
            storeAs: 'deviceUsersAssignUsers'
          },
          {
            collection: 'customers',
            doc: props.customer,
            storeAs: 'deviceUsersAssignCustomers'
          }
        );
    } else if (props.distributor && !props.customer) {
      const distributorRef = ownProps.firestore
        .collection('distributors')
        .doc(props.distributor);
      if (distributorRef)
        listener.push(
          {
            collection: 'users',
            where: [['distributor', '==', distributorRef]],
            storeAs: 'deviceUsersAssignUsers'
          },
          {
            collection: 'customers',
            where: [['distributor', '==', distributorRef]],
            storeAs: 'deviceUsersAssignCustomers'
          }
        );
    } else {
      listener.push(
        { collection: 'users', storeAs: 'deviceUsersAssignUsers' },
        { collection: 'customers', storeAs: 'deviceUsersAssignCustomers' }
      );
    }

    if (props.input && props.input.value) {
      props.input.value.forEach(id => {
        listener.push({
          collection: 'users',
          doc: id,
          storeAs: `deviceUsersAssignUsers-${id}`
        });
      });
    }

    if (props.users) {
      props.users.forEach(user => {
        if (user.customer && props.customers) {
          const customer = props.customers.find(
            customer => customer.id === user.customer
          );
          if (!customer) {
            listener.push({
              collection: 'customers',
              doc: user.customer.id,
              storeAs: `deviceUsersAssignCustomers-${user.customer.id}`
            });
          }
        }
      });
    }

    return listener;
  })
)(DeviceUsersAssign);
