//TODO: NEED REFACTOR
import React, { memo, Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { Theme } from '../../assets/Theme.style';
import {
  Box,
  Item,
  Title,
  Link,
  Navigation,
  More
} from './DistributorCustomers.style';

class DistributorCustomers extends Component {
  state = {
    limit: 2
  };

  static propsTypes = {
    theme: PropTypes.object,
    serial: PropTypes.string,
    data: PropTypes.array
  };

  static defaultProps = {
    theme: Theme.content.details.customers,
    serial: '',
    data: []
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.serial !== this.props.serial) this.setState({ limit: 2 });
  }

  increaseLimit = () => {
    const { limit } = this.state;
    this.setState({ limit: limit + 2 });
  };

  render() {
    const { limit } = this.state;
    const { theme, data, intl } = this.props;

    return (
      <>
        {data && data.length > 0 && (
          <Box>
            {data.slice(0, limit).map((el, key) => (
              <Item key={key}>
                <Title theme={theme.title}>{el.name}</Title>
                <Link to={`/customers/${el.id}`} theme={theme.link}>
                  {intl.formatMessage({
                    id: 'DISTRIBUTORS.CUSTOMERS.DETAILS'
                  })}
                </Link>
              </Item>
            ))}

            {limit < Object.keys(data).length && (
              <Navigation>
                <More theme={theme.more} onClick={this.increaseLimit}>
                  {intl.formatMessage({
                    id: 'DISTRIBUTORS.CUSTOMERS.SEE_MORE'
                  })}{' '}
                  <i className='material-icons'>keyboard_arrow_down</i>
                </More>
              </Navigation>
            )}
          </Box>
        )}
      </>
    );
  }
}

DistributorCustomers.propTypes = {
  theme: PropTypes.object,
  serial: PropTypes.string,
  data: PropTypes.array
};

export default memo(injectIntl(DistributorCustomers));
