import styled from 'styled-components';

export const Box = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 15px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 25px;
  text-align: left;
`;

export const Content = styled.div`
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  cursor: default;
`;
