import moment from 'moment';

/*
// ACTION UPDATE PROFILE SETTINGS INIT
// Update profile settings init
*/
export const updateProfileSettingsInit = () => {
  return (dispatch) => {
    dispatch({ type: 'PROFILE_SETTINGS_UPDATE_INIT' });
  };
};

/*
// ACTION UPDATE PROFILE SETTINGS
// Update profile settings and send profile avatar if exist photo data
*/
export const updateProfileSettings = (data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'PROFILE_SETTINGS_UPDATE_PENDING' });
    const firebase = getFirebase();
    const firestore = getFirestore();
    const currentUser = firebase.auth().currentUser;
    let distributorRef;
    if (data.distributor)
      distributorRef = await firestore
        .collection('distributors')
        .doc(data.distributor);

    let customerRef;
    if (data.customer)
      customerRef = await firestore.collection('customers').doc(data.customer);

    if (data.photoData) {
      const photoStorage = firebase
        .storage()
        .ref('Users/' + currentUser.uid + '/photo.jpg');
      await photoStorage.put(data.photoData).then(async (snapshot) => {
        await snapshot.ref
          .getDownloadURL()
          .then(async (url) => {
            await firestore
              .collection('users')
              .doc(currentUser.uid)
              .update({
                ...data.profile,
                photoURL: url,
                distributor: distributorRef ? distributorRef : '',
                customer: customerRef ? customerRef : '',
                updatedAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
              })
              .then(() => dispatch({ type: 'PROFILE_SETTINGS_UPDATE_SUCCESS' }))
              .catch((err) => {
                throw err;
              });
          })
          .catch((err) =>
            dispatch({ type: 'PROFILE_SETTINGS_UPDATE_ERROR', err })
          );
      });
    } else {
      await firestore
        .collection('users')
        .doc(currentUser.uid)
        .update({
          ...data.profile,
          distributor: distributorRef ? distributorRef : '',
          customer: customerRef ? customerRef : '',
          updatedAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
        })
        .then(() => dispatch({ type: 'PROFILE_SETTINGS_UPDATE_SUCCESS' }))
        .catch((err) =>
          dispatch({ type: 'PROFILE_SETTINGS_UPDATE_ERROR', err })
        );
    }
  };
};

/*
// ACTION UPDATE PROFILE NOTIFICATIONS INIT
// Update profile notifications init
*/
export const updateProfileNotificationsInit = () => {
  return (dispatch) => {
    dispatch({ type: 'PROFILE_NOTIFICATIONS_UPDATE_INIT' });
  };
};

/*
// ACTION UPDATE PROFILE NOTIFICATIONS
// Update profile notifications
*/
export const updateProfileNotifications = (data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'PROFILE_NOTIFICATIONS_UPDATE_PENDING' });
    const firebase = getFirebase();
    const firestore = getFirestore();
    const currentUser = firebase.auth().currentUser;

    await firestore
      .collection('users')
      .doc(currentUser.uid)
      .update({
        notifications: {
          allow: data.allow,
          timeFrom: data.timeFrom,
          timeTo: data.timeTo,
          zone: data.zone,
        },
        updatedAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
      })
      .then(() => dispatch({ type: 'PROFILE_NOTIFICATIONS_UPDATE_SUCCESS' }))
      .catch((err) =>
        dispatch({ type: 'PROFILE_NOTIFICATIONS_UPDATE_ERROR', err })
      );
  };
};

/*
// ACTION ADD PROFILE NOTIFICATIONS TOKEN
// Add profile notifications token
*/
export const addProfileNotificationsToken = (token) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'PROFILE_ADD_NOTIFICATIONS_TOKEN_PENDING' });
    const firebase = getFirebase();
    const firestore = getFirestore();
    const currentUser = firebase.auth().currentUser;

    await firestore
      .collection('users')
      .doc(currentUser.uid)
      .update({
        tokens: firestore.FieldValue.arrayUnion(token),
        updatedAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
      })
      .then(() => dispatch({ type: 'PROFILE_ADD_NOTIFICATIONS_TOKEN_SUCCESS' }))
      .catch((err) =>
        dispatch({ type: 'PROFILE_ADD_NOTIFICATIONS_TOKEN_ERROR', err })
      );
  };
};

/*
// ACTION UPDATE APPLICATION SETTINGS INIT
// Update application settings init
*/
export const updateApplicationSettingsInit = () => {
  return (dispatch) => {
    dispatch({ type: 'APPLICATION_SETTINGS_UPDATE_INIT' });
  };
};

/*
// ACTION UPDATE APPLICATION SETTINGS
// Update application settings
*/
export const updateApplicationSettings = (data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'APPLICATION_SETTINGS_UPDATE_PENDING' });
    const firebase = getFirebase();
    const firestore = getFirestore();
    const currentUser = firebase.auth().currentUser;

    await firestore
      .collection('users')
      .doc(currentUser.uid)
      .update({
        settings: {
          lang: data.lang,
        },
        updatedAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
      })
      .then(() => dispatch({ type: 'APPLICATION_SETTINGS_UPDATE_SUCCESS' }))
      .catch((err) =>
        dispatch({ type: 'APPLICATION_SETTINGS_UPDATE_ERROR', err })
      );
  };
};

/*
// ACTION REGISTER PROFILE ACTIVITY
// Register profile activity
*/
export const registerProfileActivity = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const currentUser = firebase.auth().currentUser;

    await firestore
      .collection('users')
      .doc(currentUser.uid)
      .update({
        loggedIn: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
      });
  };
};
