//TODO: NEED REFACTOR
import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect, withFirebase } from 'react-redux-firebase';
import { injectIntl } from 'react-intl';

import SuccessIcon from '../../assets/images/Success.svg';
import ErrorIcon from '../../assets/images/Error.svg';
import CloseIcon from '../../assets/images/Close.svg';

import { Theme } from '../../assets/Theme.style';

import {
  Wrapper,
  Holder,
  Modal,
  Close,
  Image,
  Title,
  Content,
  Link,
  Selectbox,
  Navigation,
  Button,
  Information,
  Header,
  Name,
  Number,
  Subtitle,
  Box,
  Alertbox,
  Alert,
  AlertImage,
  AlertContent,
  AlertTitle
} from './DistributorUnassigned.style';

import DevicesItem from '../../components/DevicesItem/DevicesItem';
import LocationInfo from '../../components/LocationInfo/LocationInfo';
import CustomerAssign from '../CustomerAssign/CustomerAssign';
import DeviceUsersAssign from '../DeviceUsersAssign/DeviceUsersAssign';

class DistributorUnassigned extends Component {
  state = {
    selectedDevice: null,
    assigningDevice: false
  };

  static propsTypes = {
    theme: PropTypes.object,
    distributorDeviceManageStore: PropTypes.object,
    data: PropTypes.object,
    onSubmit: PropTypes.func,
    handleClose: PropTypes.func,
    open: PropTypes.bool,
    handleManageDistributorDeviceInit: PropTypes.func,
    handleLoadDistributorDeviceUsers: PropTypes.func,
    authRole: PropTypes.object
  };

  static defaultProps = {
    theme: {
      ...Theme.modal,
      alert: Theme.alert,
      button: Theme.button
    },
    distributorDeviceManageStore: {},
    data: {},
    onSubmit: () => {},
    handleClose: () => {},
    open: false,
    handleManageDistributorDeviceInit: () => {},
    handleLoadDistributorDeviceUsers: () => {},
    authRole: {}
  };

  selectDevice = params => e => {
    if (params && params.device) {
      this.setState({ selectedDevice: params.device });
    }
  };

  setAssigningDevice = params => e => {
    const { selectedDevice } = this.state;
    if (selectedDevice) {
      this.props.handleManageDistributorDeviceInit({
        device: selectedDevice && params.assigning ? selectedDevice : {}
      });
      if (selectedDevice && params.assigning)
        this.props.handleLoadDistributorDeviceUsers({ device: selectedDevice });
      this.setState({ assigningDevice: params.assigning });
    }
  };

  validate = values => {
    const errors = {};

    return errors;
  };

  render() {
    const {
      theme,
      data,
      handleClose,
      open,
      distributorDeviceManageStore,
      onSubmit,
      devicesData,
      intl
    } = this.props;
    const { selectedDevice, assigningDevice } = this.state;
    return (
      <Wrapper>
        <Holder
          theme={theme.holder}
          data-open={open ? true : undefined}
          data-close={!open ? true : undefined}
        >
          <Modal
            theme={theme.content.alternative}
            data-open={open ? true : undefined}
            data-close={!open ? true : undefined}
          >
            <Close
              onClick={handleClose}
              title={intl.formatMessage({
                id: 'DISTRIBUTORS.DEVICES.ASSIGN.MENU.CLOSE'
              })}
            >
              <Image
                src={CloseIcon}
                alt={intl.formatMessage({
                  id: 'DISTRIBUTORS.DEVICES.ASSIGN.MENU.CLOSE'
                })}
              />
            </Close>
            <Title theme={theme.title.alternative}>
              {intl.formatMessage({
                id: 'DISTRIBUTORS.DEVICES.ASSIGN.UNASSIGNED'
              })}
            </Title>
            <Content>
              {!assigningDevice ? (
                data && data.bines && data.bines.length > 0 ? (
                  <>
                    {data.bines.map((device, key) => (
                      <Link key={key} onClick={this.selectDevice({ device })}>
                        <DevicesItem
                          key={key}
                          theme={theme.content.item}
                          data={{
                            ...device,
                            heartbeat:
                              devicesData &&
                              devicesData[device.id] &&
                              devicesData[device.id].heartbeat &&
                              devicesData[device.id].heartbeat.slice(
                                Math.max(
                                  devicesData[device.id].heartbeat.length - 1,
                                  1
                                )
                              )
                          }}
                          isActive={
                            selectedDevice && device.id === selectedDevice.id
                              ? true
                              : false
                          }
                        />
                      </Link>
                    ))}
                    <Navigation>
                      <Button
                        theme={theme.button.default}
                        disabled={!selectedDevice ? true : undefined}
                        onClick={this.setAssigningDevice({ assigning: true })}
                      >
                        {intl.formatMessage({
                          id: 'DISTRIBUTORS.DEVICES.ASSIGN.MENU.SELECT_DEVICE'
                        })}
                      </Button>
                      <Button
                        type='button'
                        theme={theme.button.alternative}
                        onClick={handleClose}
                      >
                        {intl.formatMessage({
                          id: 'DISTRIBUTORS.DEVICES.ASSIGN.MENU.CLOSE'
                        })}
                      </Button>
                    </Navigation>
                  </>
                ) : (
                  <>
                    <Information>
                      {intl.formatMessage({
                        id: 'DISTRIBUTORS.DEVICES.ASSIGN.NO_UNASSIGNED_DEVICES'
                      })}
                    </Information>
                    <Navigation>
                      <Button
                        type='button'
                        theme={theme.button.alternative}
                        onClick={handleClose}
                      >
                        {intl.formatMessage({
                          id: 'DISTRIBUTORS.DEVICES.ASSIGN.MENU.CLOSE'
                        })}
                      </Button>
                    </Navigation>
                  </>
                )
              ) : (
                <>
                  <Header>
                    <Name>
                      {selectedDevice.name
                        ? selectedDevice.name
                        : selectedDevice.serialNo}
                    </Name>
                    {selectedDevice.name && (
                      <Number>{selectedDevice.serialNo}</Number>
                    )}
                  </Header>
                  {(selectedDevice.street ||
                    selectedDevice.postalCode ||
                    selectedDevice.city ||
                    selectedDevice.country ||
                    selectedDevice.place) && (
                    <Subtitle>
                      {intl.formatMessage({
                        id: 'DISTRIBUTORS.DEVICES.ASSIGN.LOCATION'
                      })}
                    </Subtitle>
                  )}
                  <LocationInfo
                    data={{
                      street: selectedDevice.street,
                      postalCode: selectedDevice.postalCode,
                      city: selectedDevice.city,
                      country: selectedDevice.country,
                      place: selectedDevice.place
                    }}
                  />
                  <Form
                    onSubmit={onSubmit}
                    validate={this.validate}
                    initialValues={{
                      customer:
                        distributorDeviceManageStore.device &&
                        distributorDeviceManageStore.device.customer &&
                        distributorDeviceManageStore.device.customer.id,
                      users:
                        distributorDeviceManageStore.deviceUsers &&
                        distributorDeviceManageStore.deviceUsers.map(e => e.id)
                    }}
                    mutators={{}}
                    keepDirtyOnReinitialize={true}
                    render={({
                      handleSubmit,
                      submitting,
                      pristine,
                      values,
                      invalid
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Box>
                          <Subtitle>
                            {intl.formatMessage({
                              id: 'DISTRIBUTORS.DEVICES.ASSIGN.ALLOCATION'
                            })}
                          </Subtitle>
                          <Field name='customer'>
                            {({ input, meta }) => (
                              <Selectbox
                                data-name={intl.formatMessage({
                                  id: 'DISTRIBUTORS.DEVICES.ASSIGN.CUSTOMER'
                                })}
                                data-filled={
                                  input && input.value && input.value.length > 0
                                    ? true
                                    : undefined
                                }
                                theme={theme.selectbox.default}
                              >
                                <CustomerAssign
                                  input={input}
                                  distributor={
                                    distributorDeviceManageStore.device &&
                                    distributorDeviceManageStore.device
                                      .distributor &&
                                    distributorDeviceManageStore.device
                                      .distributor.id
                                  }
                                />
                                <span />
                              </Selectbox>
                            )}
                          </Field>
                        </Box>
                        <Box>
                          <Subtitle>
                            {intl.formatMessage({
                              id: 'DISTRIBUTORS.DEVICES.ASSIGN.USERS'
                            })}
                          </Subtitle>
                          <Field name='users'>
                            {({ input, meta }) => (
                              <DeviceUsersAssign
                                input={input}
                                distributor={
                                  distributorDeviceManageStore.device &&
                                  distributorDeviceManageStore.device
                                    .distributor &&
                                  distributorDeviceManageStore.device
                                    .distributor.id
                                }
                                customer={values.customer}
                              />
                            )}
                          </Field>
                        </Box>

                        {distributorDeviceManageStore.isError ? (
                          <Alertbox>
                            <Alert>
                              <AlertImage
                                src={ErrorIcon}
                                alt={intl.formatMessage({
                                  id:
                                    'DISTRIBUTORS.DEVICES.ASSIGN.ALERT.SOMETHING_WENT_WRONG'
                                })}
                              />
                              <AlertContent>
                                <AlertTitle theme={theme.alert.error.title}>
                                  {intl.formatMessage({
                                    id:
                                      'DISTRIBUTORS.DEVICES.ASSIGN.ALERT.SOMETHING_WENT_WRONG'
                                  })}
                                </AlertTitle>
                                {distributorDeviceManageStore.error}
                              </AlertContent>
                            </Alert>
                          </Alertbox>
                        ) : (
                          distributorDeviceManageStore.isSuccess && (
                            <Alertbox>
                              <Alert>
                                <AlertImage
                                  src={SuccessIcon}
                                  alt={intl.formatMessage({
                                    id:
                                      'DISTRIBUTORS.DEVICES.ASSIGN.ALERT.SUCCESS'
                                  })}
                                />
                                <AlertContent>
                                  <AlertTitle theme={theme.alert.success.title}>
                                    {intl.formatMessage({
                                      id:
                                        'DISTRIBUTORS.DEVICES.ASSIGN.ALERT.SUCCESS'
                                    })}
                                  </AlertTitle>
                                  {intl.formatMessage({
                                    id:
                                      'DISTRIBUTORS.DEVICES.ASSIGN.ALERT.DEVICE_ASSIGNED'
                                  })}
                                </AlertContent>
                              </Alert>
                            </Alertbox>
                          )
                        )}

                        <Navigation>
                          <Button
                            theme={theme.button.default}
                            disabled={
                              submitting ||
                              pristine ||
                              invalid ||
                              (distributorDeviceManageStore &&
                                distributorDeviceManageStore.isLoading)
                            }
                          >
                            {distributorDeviceManageStore &&
                            distributorDeviceManageStore.isLoading
                              ? intl.formatMessage({
                                  id:
                                    'DISTRIBUTORS.DEVICES.ASSIGN.MENU.PLEASE_WAIT'
                                })
                              : intl.formatMessage({
                                  id:
                                    'DISTRIBUTORS.DEVICES.ASSIGN.MENU.ASSIGN_DEVICE'
                                })}
                          </Button>
                          <Button
                            type='button'
                            theme={theme.button.alternative}
                            onClick={this.setAssigningDevice({
                              assigning: false
                            })}
                          >
                            {intl.formatMessage({
                              id: 'DISTRIBUTORS.DEVICES.ASSIGN.MENU.BACK'
                            })}
                          </Button>
                        </Navigation>
                      </form>
                    )}
                  />
                </>
              )}
            </Content>
          </Modal>
        </Holder>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const devicesData =
    state.firebase && state.firebase.ordered && state.firebase.ordered.devices;
  return {
    devicesData,
    distributorDeviceManageStore: state.distributorDeviceManageStore
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default compose(
  injectIntl,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect(props => {
    const listener = [];
    const devices = props.data && props.data.bines;
    if (devices) {
      devices.forEach(device => {
        listener.push({
          path: `/devices/${device.id}/heartbeat`,
          queryParams: ['limitToLast=1']
        });
      });
    }

    return listener;
  })
)(DistributorUnassigned);
