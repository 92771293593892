//TODO: NEED REFACTOR
import React, { memo, Component } from 'react';
import PropTypes from 'prop-types';
import withTimeout from 'react-timeout';

import { Theme } from '../../assets/Theme.style';

import {
  Photobox,
  Photoholder,
  Photo,
  Text,
  Input
} from './DistributorPhoto.style';

import PhotoCrop from '../PhotoCrop/PhotoCrop';

class DistributorPhoto extends Component {
  state = {
    sourcePhoto: null,
    photo: null,
    visibleModalPhotoCrop: false,
    openModalPhotoCrop: false,
    crop: { unit: 'px', width: 250, height: 100 }
  };

  static propsTypes = {
    theme: PropTypes.object,
    input: PropTypes.object,
    auth: PropTypes.object,
    label: PropTypes.string,
    onSetPhotoData: PropTypes.func
  };

  static defaultProps = {
    theme: Theme.modal.distributorPhoto,
    input: {},
    auth: {},
    label: 'Add Distributor',
    onSetPhotoData: () => {}
  };

  selectFile = event => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({ sourcePhoto: reader.result });
        this.openModal('photoCrop');
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  setPhoto = photo => {
    const { input, onSetPhotoData } = this.props;
    this.setState({ photo: photo.tempPhoto });
    onSetPhotoData(photo.blob);
    input.onChange(photo.blob);
    this.closeModal();
  };

  changeCrop = crop => {
    this.setState({ crop });
  };

  openModal = param => {
    this.setState({
      visibleModalPhotoCrop: param === 'photoCrop' ? true : false,
      openModalPhotoCrop: param === 'photoCrop' ? true : false
    });
  };

  closeModal = () => {
    this.setState({
      openModalPhotoCrop: false
    });
    this.props.setTimeout(() => {
      this.setState({
        visibleModalPhotoCrop: false
      });
    }, 200);
  };

  render() {
    const {
      sourcePhoto,
      photo,
      visibleModalPhotoCrop,
      openModalPhotoCrop,
      crop
    } = this.state;
    const { theme, profile, label } = this.props;
    return (
      <>
        <Photobox>
          <Photoholder theme={theme.photo}>
            {(photo || (profile && profile.photoURL)) && (
              <Photo
                src={
                  photo
                    ? photo
                    : profile && profile.photoURL && profile.photoURL
                }
                alt={
                  photo ? photo : profile && profile.name ? profile.name : ''
                }
              />
            )}
          </Photoholder>

          <Text theme={theme.text}>{label}</Text>

          <Input
            type='file'
            accept='image/x-png,image/jpeg'
            onClick={event => {
              event.target.value = null;
            }}
            onChange={this.selectFile}
          />
        </Photobox>
        {visibleModalPhotoCrop && (
          <PhotoCrop
            theme={theme.modal}
            handleClose={this.closeModal}
            open={openModalPhotoCrop}
            sourcePhoto={sourcePhoto}
            crop={crop}
            handlePhoto={this.setPhoto}
            handleCropChange={this.changeCrop}
          />
        )}
      </>
    );
  }
}

export default memo(withTimeout(DistributorPhoto));
