import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';

import { withTheme } from '../../assets/Theme.style';
import { Section, Wrapper, Content, Footer } from './AuthPage.style';

import {
  resetPasswordInit,
  resetPasswordCheck,
  resetPassword
} from '../../actions/Auth/AuthActions';
import ResetPasswordPanel from '../../components/ResetPasswordPanel/ResetPasswordPanel';

class AuthPage extends Component {
  state = {
    code: null,
    mode: null,
    redirectToForgotPassword: false
  };

  componentDidMount() {
    this.props.resetPasswordInit();
    this.checkCode();
  }

  checkCode = () => {
    const params = queryString.parse(this.props.location.search);
    if (
      Object.keys(params).length > 0 &&
      params.oobCode &&
      params.mode &&
      params.mode === 'resetPassword'
    ) {
      this.setState({ code: params.oobCode, mode: params.mode });
      this.props.resetPasswordCheck({ code: params.oobCode });
    } else {
      this.setState({ redirectToForgotPassword: true });
    }
  };

  handleSubmit = values => {
    this.props.resetPassword({
      code: this.state.code,
      password: values.password
    });
  };

  render() {
    const { mode, redirectToForgotPassword } = this.state;
    const { firebaseStore, resetPasswordStore } = this.props;
    const theme = this.props.theme;

    return (
      <>
        <Helmet>
          <title>Reset password | BIN-E Smart Waste Bin</title>
        </Helmet>
        {!firebaseStore.auth.isEmpty ? (
          <Redirect to={{ pathname: '/' }} />
        ) : redirectToForgotPassword ? (
          <Redirect to={{ pathname: '/forgot-password' }} />
        ) : (
          <Section>
            <Wrapper theme={theme.sign.wrapper.default}>
              <Content>
                {mode === 'resetPassword' && (
                  <ResetPasswordPanel
                    onSubmit={this.handleSubmit}
                    store={resetPasswordStore}
                  />
                )}
              </Content>
              <Footer theme={theme.sign.footer.default}>
                By signing in, you accept{' '}
                <a
                  href='http://www.bine.world'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Privacy policy
                </a>{' '}
                <br />
                (including Use of Cookies and Other
                <br /> Technologies) and{' '}
                <a
                  href='http://www.bine.world'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Terms of use
                </a>
              </Footer>
            </Wrapper>
          </Section>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    firebaseStore: state.firebase,
    resetPasswordStore: state.authResetPasswordStore
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPasswordInit: () => dispatch(resetPasswordInit()),
    resetPasswordCheck: credentials =>
      dispatch(resetPasswordCheck(credentials)),
    resetPassword: credentials => dispatch(resetPassword(credentials))
  };
};

export default compose(
  withTheme,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AuthPage);
