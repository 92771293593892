import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirestore, firestoreConnect } from 'react-redux-firebase';
import { Helmet } from 'react-helmet-async';
import { injectIntl } from 'react-intl';

import { withTheme } from '../../assets/Theme.style';
import { Breakpoints } from '../../assets/Variables.style';
import {
  Wrapper,
  Content,
  Panel,
  PanelInner,
  Title,
  Box,
  Details,
  Information
} from './ProfileSettingsPage.style';

import Header from '../../components/Header/Header';
import AccountSider from '../../components/AccountSider/AccountSider';
import ProfileSettings from '../../components/ProfileSettings/ProfileSettings';

import {
  updateProfileSettingsInit,
  updateProfileSettings
} from '../../actions/Profile/ProfileActions';

import { roles } from '../../utils/roles';

class ProfileSettingsPage extends Component {
  state = {
    width: window.innerWidth,
    photoData: null
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    this.props.updateProfileSettingsInit();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleSubmit = values => {
    const { photoData } = this.state;

    const newRoles = {};
    for (const role of roles)
      newRoles[role.id] = role.id === values.role ? true : false;

    const profile = {
      displayName: values.displayName || '',
      position: values.position || '',
      role: newRoles,
      phoneNo: values.phoneNo || ''
    };
    const distributor = values.distributor || '';
    const customer = values.customer || '';

    this.props.updateProfileSettings({
      profile,
      photoData,
      distributor,
      customer
    });
  };

  handlePhotoData = data => {
    this.setState({ photoData: data });
  };

  render() {
    const { width } = this.state;
    const {
      location,
      firebaseStore,
      profileSettingsStore,
      distributor,
      customer,
      intl
    } = this.props;
    const theme = this.props.theme;
    const isMobile = width <= Breakpoints.lg;
    const authRole = roles.find(el => {
      const profileRole =
        firebaseStore.profile.role &&
        Object.entries(firebaseStore.profile.role).find(el => el[1] === true);
      return profileRole && el.id === profileRole[0];
    });

    return (
      <>
        <Helmet>
          <title>
            {intl.formatMessage({ id: 'PAGE_TITLE.PROFILE_SETTINGS' })} | BIN-E
            Smart Waste Bin
          </title>
        </Helmet>

        <Wrapper>
          <Header
            currentPath={location.pathname}
            profile={firebaseStore.profile}
          />
          <Content>
            {!isMobile && (
              <AccountSider
                theme={theme.accountSider}
                currentPath={location.pathname}
              />
            )}
            <Panel theme={theme.content.panel.alternative}>
              <PanelInner>
                <Title theme={theme.content.title}>
                  {intl.formatMessage({
                    id: 'PROFILE_SETTINGS.PROFILE_SETTINGS'
                  })}
                </Title>
                <Box>
                  <Details theme={theme.scroll}>
                    {firebaseStore.profile.isLoaded ? (
                      <ProfileSettings
                        store={profileSettingsStore}
                        profile={{
                          ...firebaseStore.profile
                        }}
                        onSubmit={this.handleSubmit}
                        onSetPhotoData={this.handlePhotoData}
                        authRole={authRole}
                        distributor={distributor}
                        customer={customer}
                      />
                    ) : (
                      <Information>
                        {intl.formatMessage({
                          id: 'PROFILE_SETTINGS.LOADING'
                        })}
                        ...
                      </Information>
                    )}
                  </Details>
                </Box>
              </PanelInner>
            </Panel>
          </Content>
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const userDistributorID =
    state.firebase.profile &&
    state.firebase.profile.distributor &&
    state.firebase.profile.distributor.id;
  const distributors =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.distributors;
  const userDistributor =
    userDistributorID &&
    distributors &&
    distributors.find(e => e.id === userDistributorID);

  const userCustomerID =
    state.firebase.profile &&
    state.firebase.profile.customer &&
    state.firebase.profile.customer.id;
  const customers =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.customers;
  const userCustomer =
    userCustomerID && customers && customers.find(e => e.id === userCustomerID);

  return {
    firebaseStore: state.firebase,
    profileSettingsStore: state.profileSettingsStore,
    distributor: userDistributor,
    customer: userCustomer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProfileSettingsInit: () => dispatch(updateProfileSettingsInit()),
    updateProfileSettings: data => dispatch(updateProfileSettings(data))
  };
};

export default compose(
  injectIntl,
  withFirestore,
  withTheme,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props, ownProps) => {
    const listener = [];

    const currentUserDistributor = props.firebaseStore.profile.distributor;
    if (currentUserDistributor) {
      listener.push({
        collection: 'distributors',
        doc: currentUserDistributor.id
      });
    }

    const currentUserCustomer = props.firebaseStore.profile.customer;
    if (currentUserCustomer) {
      listener.push({ collection: 'customers', doc: currentUserCustomer.id });
    }

    return listener;
  })
)(ProfileSettingsPage);
