import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  firestoreConnect,
  isLoaded,
  isEmpty,
  firebaseConnect,
} from 'react-redux-firebase';
import { Helmet } from 'react-helmet-async';
import withTimeout from 'react-timeout';
import queryString from 'query-string';
import { injectIntl } from 'react-intl';

import Icon from '../../assets/images/Close.svg';

import { withTheme } from '../../assets/Theme.style';
import { Breakpoints } from '../../assets/Variables.style';
import {
  Wrapper,
  Content,
  Panel,
  PanelInner,
  Title,
  Box,
  List,
  Category,
  Subtitle,
  Details,
  Navigation,
  Button,
  Image,
  Information,
} from './CustomersPage.style';

import Header from '../../components/Header/Header';
import Sider from '../../components/Sider/Sider';
import CustomersItem from '../../components/CustomersItem/CustomersItem';
import CustomerDetails from '../../components/CustomerDetails/CustomerDetails';
import CustomersAdd from '../../components/CustomersAdd/CustomersAdd';
import CustomerManage from '../../components/CustomerManage/CustomerManage';
import DevicesAssigned from '../../components/DevicesAssigned/DevicesAssigned';

import {
  manageCustomerInit,
  addCustomer,
  updateCustomer,
} from '../../actions/Customer/CustomerActions';

import { roles } from '../../utils/roles';

class CustomersPage extends Component {
  state = {
    filter: true,
    filterOptions: [
      {
        name: this.props.intl.formatMessage({ id: 'SIDEBAR.SHOW_ALL' }),
        type: 'none',
        filter: 'none',
      },
      {
        name: this.props.intl.formatMessage({ id: 'SIDEBAR.DISTRIBUTORS' }),
        type: 'distributor',
        filter: 'none',
        privileges: 'VIEW_DISTRIBUTORS',
      },
      {
        name: this.props.intl.formatMessage({ id: 'SIDEBAR.ACCOUNT_MANAGERS' }),
        type: 'manager',
        filter: 'none',
      },
    ],
    filterType: 'none',
    filterValue: 'none',
    visibleFilter: false,
    openFilter: false,
    width: window.innerWidth,
    visibleModal: false,
    visibleModalAdd: false,
    openModalAdd: false,
    visibleModalEdit: false,
    openModalEdit: false,
    visibleModalAssigned: false,
    openModalAssigned: false,
  };

  componentDidMount() {
    const { location } = this.props;
    const params = queryString.parse(location.search);

    window.addEventListener('resize', this.handleWindowSizeChange);

    if (params && params.filter && params.type)
      this.setFilter({ filter: params.filter, type: params.type });
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const params = queryString.parse(location.search);
    const prevParams = queryString.parse(prevProps.location.search);

    if (
      prevParams &&
      params &&
      (params.filter !== prevParams.filter || params.type !== prevParams.type)
    )
      this.setFilter({ filter: params.filter, type: params.type });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
    this.checkFilter();
  };

  toggleFilter = () => {
    if (!this.state.openFilter) {
      this.setState({
        visibleFilter: true,
        openFilter: !this.state.openFilter,
      });
    } else {
      this.setState({
        openFilter: !this.state.openFilter,
      });
      this.props.setTimeout(() => {
        this.setState({
          visibleFilter: false,
        });
      }, 200);
    }
  };

  checkFilter = () => {
    if (this.state.width > Breakpoints.lg && this.state.openFilter) {
      this.setState({
        visibleFilter: false,
        openFilter: false,
      });
    }
  };

  setFilter = ({ filter, type }) => {
    this.setState({
      filterValue: filter,
      filterType: type,
    });
  };

  openModal = (param) => {
    const { customer, customerDistributor, customerManager } = this.props;

    if (param === 'edit' || param === 'add')
      this.props.manageCustomerInit({
        customer: param === 'edit' && customer,
        customerDistributor: param === 'edit' && customerDistributor,
        customerManager: param === 'edit' && customerManager,
      });
    this.setState({
      visibleModalAdd: param === 'add' ? true : false,
      visibleModalEdit: param === 'edit' ? true : false,
      visibleModalAssigned: param === 'assigned' ? true : false,
      openModalAdd: param === 'add' ? true : false,
      openModalEdit: param === 'edit' ? true : false,
      openModalAssigned: param === 'assigned' ? true : false,
    });
  };

  closeModal = () => {
    this.setState({
      openModalAdd: false,
      openModalEdit: false,
      openModalAssigned: false,
    });
    this.props.setTimeout(() => {
      this.setState({
        visibleModalAdd: false,
        visibleModalEdit: false,
        visibleModalAssigned: false,
      });
    }, 200);
  };

  handleSubmit = async (data) => {
    const { type, values } = data;
    const customer = {
      name: values.name || '',
      street: values.street || '',
      city: values.city || '',
      postalCode: values.postalCode || '',
      country: values.country || '',
      contactEmail: values.contactEmail || '',
      contactNo: values.contactNo || '',
    };
    const distributor = values.distributor || '';
    const manager = values.manager || '';
    switch (type) {
      case 'CUSTOMER_ADD':
        await this.props
          .addCustomer({
            customer,
            distributor,
            manager,
          })
          .then(() => {
            this.closeModal();
          });
        break;
      case 'CUSTOMER_UPDATE':
        await this.props
          .updateCustomer(this.props.customer.id, {
            customer,
            distributor,
            manager,
          })
          .then(() => {
            this.closeModal();
          });
        break;
      default:
        return false;
    }
    return true;
  };

  render() {
    const {
      filter,
      filterOptions,
      filterType,
      filterValue,
      visibleFilter,
      openFilter,
      width,
      visibleModalAdd,
      openModalAdd,
      visibleModalEdit,
      openModalEdit,
      visibleModalAssigned,
      openModalAssigned,
    } = this.state;
    const {
      firebaseStore,
      location,
      history,
      customers,
      customer,
      customerUsers,
      customerDevices,
      customerDevicesData,
      customerDistributor,
      customerManager,
      customerManageStore,
      distributors,
      users,
      userDistributor,
      intl,
    } = this.props;
    const theme = this.props.theme;
    const isMobile = width <= Breakpoints.lg;
    const authRole = roles.find((el) => {
      const profileRole =
        firebaseStore.profile.role &&
        Object.entries(firebaseStore.profile.role).find((el) => el[1] === true);
      return profileRole && el.id === profileRole[0];
    });

    let distributorsID = [];
    let managersID = [];
    const extendedFilterOptions = filterOptions.map((el) => {
      switch (el.type) {
        case 'distributor':
          distributorsID =
            (distributors &&
              Object.values(distributors) &&
              Array.from(
                new Set(Object.values(distributors).map((el) => el.id))
              )) ||
            [];
          return {
            ...el,
            items:
              (distributors &&
                distributors.map((e) => {
                  return {
                    name: e.name,
                    type: 'distributor',
                    filter: e.id,
                  };
                })) ||
              [],
          };
        case 'manager':
          managersID =
            (customers &&
              Object.values(customers) &&
              Array.from(
                new Set(
                  Object.values(customers)
                    .filter(
                      (el, i, a) =>
                        i === a.indexOf(el) &&
                        el.manager !== undefined &&
                        el.manager !== null &&
                        el.manager !== ''
                    )
                    .map((el) => el.manager && el.manager.id)
                )
              )) ||
            [];
          return {
            ...el,
            items: managersID.map((e) => {
              const user = users && users.find((item) => item.id === e);
              return {
                name: user && user.displayName ? user.displayName : e,
                type: 'manager',
                filter: e,
              };
            }),
          };
        default:
          return el;
      }
    });

    let customersFiltered;
    switch (filterType) {
      case 'distributor':
        switch (filterValue) {
          case 'none':
            customersFiltered = distributorsID
              .map((el) => {
                return {
                  name: el,
                  items:
                    (customers &&
                      Object.values(customers) &&
                      Object.values(customers).filter(
                        (e) => e.distributor && e.distributor.id === el
                      )) ||
                    [],
                };
              })
              .filter((el) => el.items.length > 0);
            if (
              customers &&
              Object.values(customers) &&
              Object.values(customers).find(
                (el) => el.distributor === undefined || el.distributor === ''
              )
            ) {
              customersFiltered.push({
                name: intl.formatMessage({ id: 'SIDEBAR.UNCATEGORIZED' }),
                items: Object.values(customers).filter(
                  (el) => el.distributor === undefined || el.distributor === ''
                ),
              });
            }
            break;
          default:
            customersFiltered = distributorsID
              .map((el) => {
                return {
                  name: el,
                  items:
                    (customers &&
                      Object.values(customers) &&
                      Object.values(customers).filter(
                        (e) =>
                          e.distributor &&
                          e.distributor.id === el &&
                          e.distributor.id === filterValue
                      )) ||
                    [],
                };
              })
              .filter((el) => el.items.length > 0);
        }
        break;
      case 'manager':
        switch (filterValue) {
          case 'none':
            customersFiltered = managersID
              .map((el) => {
                return {
                  name: el,
                  items:
                    (customers &&
                      Object.values(customers) &&
                      Object.values(customers).filter(
                        (e) => e.manager && e.manager.id === el
                      )) ||
                    [],
                };
              })
              .filter((el) => el.items.length > 0);
            if (
              customers &&
              Object.values(customers) &&
              Object.values(customers).find(
                (el) => el.manager === undefined || el.manager === ''
              )
            ) {
              customersFiltered.push({
                name: intl.formatMessage({ id: 'SIDEBAR.UNCATEGORIZED' }),
                items: Object.values(customers).filter(
                  (el) => el.manager === undefined || el.manager === ''
                ),
              });
            }
            break;
          default:
            customersFiltered = managersID
              .map((el) => {
                return {
                  name: el,
                  items:
                    (customers &&
                      Object.values(customers) &&
                      Object.values(customers).filter(
                        (e) =>
                          e.manager &&
                          e.manager.id === el &&
                          e.manager.id === filterValue
                      )) ||
                    [],
                };
              })
              .filter((el) => el.items.length > 0);
        }
        break;
      default:
    }

    return (
      <>
        <Helmet>
          <title>
            {customer
              ? `${customer.name ? customer.name : customer.id}`
              : intl.formatMessage({ id: 'PAGE_TITLE.CUSTOMERS' })}{' '}
            | BIN-E Smart Waste Bin
          </title>
        </Helmet>

        <Wrapper>
          <Header
            currentPath={location.pathname}
            profile={firebaseStore.profile}
            filter={isMobile && customer ? false : filter}
            handleToggleFilter={this.toggleFilter}
          />
          <Content>
            {(!isMobile || (isMobile && !customer)) && (
              <Sider
                options={extendedFilterOptions}
                location={location}
                history={history}
                visible={visibleFilter}
                open={openFilter}
                handleToggleFilter={this.toggleFilter}
                authRole={authRole}
              />
            )}
            <Panel
              theme={
                isMobile && customer
                  ? theme.content.panel.alternative
                  : theme.content.panel.default
              }
            >
              <PanelInner>
                {(!isMobile || (isMobile && !customer)) && (
                  <Title theme={theme.content.title}>
                    {intl.formatMessage({ id: 'CUSTOMERS.CUSTOMERS' })}
                  </Title>
                )}
                {authRole &&
                authRole.privileges &&
                ((authRole.privileges['RESPECT_PROFILE_MEMBERSHIP'] &&
                  firebaseStore.profile.distributor) ||
                  !authRole.privileges['RESPECT_PROFILE_MEMBERSHIP']) &&
                !isLoaded(customers) ? (
                  <Box>
                    <Information>
                      {intl.formatMessage({ id: 'CUSTOMERS.LOADING' })}...
                    </Information>
                  </Box>
                ) : (
                  <Box>
                    {(!isMobile || (isMobile && !customer)) && (
                      <List theme={theme.scroll}>
                        {authRole &&
                          authRole.privileges &&
                          ((authRole.privileges['RESPECT_PROFILE_MEMBERSHIP'] &&
                            firebaseStore.profile.distributor) ||
                            !authRole.privileges[
                              'RESPECT_PROFILE_MEMBERSHIP'
                            ]) && (
                            <CustomersAdd
                              theme={theme.content.add}
                              handleClick={() => this.openModal('add')}
                            />
                          )}
                        {!isEmpty(customers) ? (
                          customersFiltered ? (
                            customersFiltered.map((el, key) => (
                              <Category key={key}>
                                <Subtitle>
                                  {filterType === 'manager'
                                    ? (users &&
                                        users.find((e) => e.id === el.name) &&
                                        users.find((e) => e.id === el.name)
                                          .displayName) ||
                                      el.name
                                    : (filterType === 'distributor' &&
                                        distributors &&
                                        distributors.find(
                                          (e) => e.id === el.name
                                        ) &&
                                        distributors.find(
                                          (e) => e.id === el.name
                                        ).name) ||
                                      el.name}
                                </Subtitle>
                                {el.items.map((el) => (
                                  <Link
                                    to={`/customers/${el.id}${
                                      location.search ? location.search : ''
                                    }`}
                                    key={el.id}
                                  >
                                    <CustomersItem
                                      theme={theme.content.item}
                                      data={{
                                        ...el,
                                        distributorData:
                                          el.distributor &&
                                          distributors &&
                                          distributors.find(
                                            (e) => e.id === el.distributor.id
                                          ),
                                      }}
                                      isActive={
                                        customer && el.id === customer.id
                                      }
                                    />
                                  </Link>
                                ))}
                              </Category>
                            ))
                          ) : (
                            Object.values(customers).map((el) => (
                              <Link
                                to={`/customers/${el.id}${
                                  location.search ? location.search : ''
                                }`}
                                key={el.id}
                              >
                                <CustomersItem
                                  theme={theme.content.item}
                                  data={{
                                    ...el,
                                    distributorData:
                                      el.distributor &&
                                      distributors &&
                                      distributors.find(
                                        (e) => e.id === el.distributor.id
                                      ),
                                  }}
                                  isActive={customer && el.id === customer.id}
                                />
                              </Link>
                            ))
                          )
                        ) : (
                          <Information>
                            {intl.formatMessage({
                              id: 'CUSTOMERS.NO_ASSIGNED',
                            })}
                          </Information>
                        )}
                      </List>
                    )}
                    {customer && (
                      <Details theme={theme.scroll}>
                        {isMobile && (
                          <Navigation>
                            <Link
                              to={`/customers${
                                location.search ? location.search : ''
                              }`}
                            >
                              <Button
                                title={intl.formatMessage({
                                  id: 'CUSTOMERS.CLOSE',
                                })}
                              >
                                <Image
                                  src={Icon}
                                  alt={intl.formatMessage({
                                    id: 'CUSTOMERS.CLOSE',
                                  })}
                                />
                              </Button>
                            </Link>
                          </Navigation>
                        )}
                        <CustomerDetails
                          theme={theme.content.details}
                          data={{
                            ...customer,
                            users: isLoaded(customerUsers)
                              ? customerUsers
                              : undefined,
                            bines: isLoaded(customerDevices)
                              ? customerDevices
                              : undefined,
                            distributorData: isLoaded(customerDistributor)
                              ? customerDistributor
                              : undefined,
                            managerData: isLoaded(customerManager)
                              ? customerManager
                              : undefined,
                          }}
                          handleClick={(param) => this.openModal(param)}
                        />
                      </Details>
                    )}
                  </Box>
                )}
              </PanelInner>
            </Panel>
          </Content>
        </Wrapper>
        {visibleModalAdd && (
          <CustomerManage
            store={customerManageStore}
            onSubmit={(values) => {
              this.handleSubmit({ type: 'CUSTOMER_ADD', values });
            }}
            handleClose={this.closeModal}
            open={openModalAdd}
            authRole={authRole}
            userDistributor={userDistributor}
          />
        )}
        {visibleModalEdit && (
          <CustomerManage
            store={customerManageStore}
            onSubmit={(values) => {
              this.handleSubmit({ type: 'CUSTOMER_UPDATE', values });
            }}
            handleClose={this.closeModal}
            open={openModalEdit}
            authRole={authRole}
            userDistributor={userDistributor}
          />
        )}
        {visibleModalAssigned && (
          <DevicesAssigned
            data={{
              bines: isLoaded(customerDevices)
                ? customerDevices &&
                  Object.entries(customerDevices) &&
                  Object.entries(customerDevices)
                    .map((e) => {
                      return { id: e[0], ...e[1] };
                    })
                    .filter((e) => (e.customer ? true : false))
                : undefined,
              binesData: customerDevicesData || undefined,
            }}
            handleClose={this.closeModal}
            open={openModalAssigned}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const customerID = ownProps.match.params.id;
  const customers =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.customers;
  const customer = customers && customers.find((e) => e.id === customerID);

  const customerDistributorID =
    customer && customer.distributor && customer.distributor.id;
  const distributors =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.distributors;
  const customerDistributor =
    customerDistributorID &&
    distributors &&
    distributors.find((e) => e.id === customerDistributorID);

  const customerManagerID = customer && customer.manager && customer.manager.id;
  const users =
    state.firestore && state.firestore.ordered && state.firestore.ordered.users;
  const customerManager =
    customerManagerID && users && users.find((e) => e.id === customerManagerID);

  const customerUsers =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.customerUsers;

  const customerDevices =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.customerDevices;

  const customerDevicesData =
    state.firebase && state.firebase.ordered && state.firebase.ordered.devices;

  const userDistributorID =
    state.firebase.profile &&
    state.firebase.profile.distributor &&
    state.firebase.profile.distributor.id;

  const userDistributor =
    userDistributorID &&
    distributors &&
    distributors.find((e) => e.id === userDistributorID);

  return {
    firebaseStore: state.firebase,
    customers,
    customer,
    customerDistributor,
    customerManager,
    customerUsers,
    customerDevices,
    customerDevicesData,
    customerManageStore: state.customerManageStore,
    distributors,
    users,
    userDistributor,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    manageCustomerInit: (data) => dispatch(manageCustomerInit(data)),
    addCustomer: (data) => dispatch(addCustomer(data)),
    updateCustomer: (id, data) => dispatch(updateCustomer(id, data)),
  };
};

export default compose(
  injectIntl,
  withTheme,
  withRouter,
  withTimeout,
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect((props) => {
    const listener = [];
    if (props.customerDevices) {
      props.customerDevices.forEach((device) => {
        listener.push({
          path: `/devices/${device.id}/heartbeat`,
          queryParams: ['limitToLast=1'],
        });
      });
    }
    return listener;
  }),
  firestoreConnect((props, ownProps) => {
    const listener = [];

    const authRole = roles.find((el) => {
      const profileRole =
        props.firebaseStore.profile.role &&
        Object.entries(props.firebaseStore.profile.role).find(
          (el) => el[1] === true
        );
      return profileRole && el.id === profileRole[0];
    });

    if (
      authRole &&
      authRole.privileges &&
      authRole.privileges['RESPECT_PROFILE_MEMBERSHIP']
    ) {
      const currentUserDistributor = props.firebaseStore.profile.distributor;
      if (currentUserDistributor) {
        const distributorRef = ownProps.firestore
          .collection('distributors')
          .doc(currentUserDistributor.id);
        listener.push(
          {
            collection: 'users',
            where: [['distributor', '==', distributorRef]],
          },
          { collection: 'distributors', doc: currentUserDistributor.id },
          {
            collection: 'customers',
            where: [['distributor', '==', distributorRef]],
          }
        );
      }
    } else {
      listener.push(
        { collection: 'users' },
        { collection: 'distributors' },
        { collection: 'customers' }
      );
    }

    const customerID = props.match.params.id;
    if (customerID) {
      const customerRef = ownProps.firestore
        .collection('customers')
        .doc(customerID);
      listener.push(
        {
          collection: 'users',
          where: [['customer', '==', customerRef]],
          storeAs: 'customerUsers',
        },
        {
          collection: 'devices',
          where: [['customer', '==', customerRef]],
          storeAs: 'customerDevices',
        }
      );
    }

    return listener;
  })
)(CustomersPage);
