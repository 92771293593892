/*
//   STYLE-COMPONENT: SWITCHBOX
*/

import { css } from 'styled-components';

export const Switchbox = css`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 10px;

  label {
    display: block;
    width: 100%;
    text-align: left;
  }
`;
