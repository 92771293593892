import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import moment from 'moment';

import Icon from '../../assets/images/ProfileImage.svg';

import { Theme } from '../../assets/Theme.style';

import {
  Item,
  Orbholder,
  Orb,
  Imageholder,
  Image,
  Content
} from './ContactInfo.style';

function ContactInfo({ theme = Theme.content.details.contact, data = {} }) {
  const intl = useIntl();
  const [, setTick] = useState(moment().format());
  const [photoVisible, setPhotoVisible] = useState(false);

  useEffect(() => {
    const timerID = setInterval(() => setTick(moment().format()), 1000);
    return () => clearInterval(timerID);
  }, []);

  useEffect(() => {
    return () => {
      setPhotoVisible(false);
    };
  }, [data.contactPhotoURL]);

  const isContact =
    data.contactPerson ||
    data.contactPosition ||
    data.contactNo ||
    data.contactEmail ||
    data.contactPhotoURL ||
    data.contactStatus;

  return (
    <>
      {isContact && (
        <Item>
          <Orbholder>
            <Imageholder theme={theme.photo}>
              <Image
                src={data.contactPhotoURL || Icon}
                data-visible={photoVisible ? true : undefined}
                onLoad={() => setPhotoVisible(true)}
                onError={() => setPhotoVisible(false)}
                alt={
                  data.contactPerson
                    ? data.contactPerson
                    : intl.formatMessage({ id: 'CONTACT_INFO.CONTACT' })
                }
              />
            </Imageholder>
            {data.contactStatus && (
              <Orb
                theme={theme.orb}
                status={(() => {
                  const lastActivity =
                    data.contactStatus &&
                    moment().diff(data.contactStatus, 'minutes');
                  return lastActivity <= 15
                    ? 'online'
                    : lastActivity <= 30
                    ? 'away'
                    : 'offline';
                })()}
              />
            )}
          </Orbholder>
          <Content>
            {data.contactPerson && (
              <span>
                <strong>{data.contactPerson}</strong>
              </span>
            )}
            {data.contactPosition && <span>{data.contactPosition}</span>}
            {data.contactEmail && <span>{data.contactEmail}</span>}
            {data.contactNo && <span>{data.contactNo}</span>}
          </Content>
        </Item>
      )}
    </>
  );
}

ContactInfo.propTypes = {
  theme: PropTypes.object,
  data: PropTypes.object
};

export default memo(ContactInfo);
