import styled from 'styled-components';

export const Label = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 48px;
  margin-bottom: 16px;
  padding: 8px 13px;
  border-radius: 8px;
  transition: 0.2s background, 0.2s color;
  background: ${props => props.theme.background};
  color: ${props => props.theme.color};
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.backgroundOnHover};
    color: ${props => props.theme.colorOnHover};
  }

  &[data-active] {
    background: ${props => props.theme.backgroundOnActive};
    color: ${props => props.theme.colorOnActive};
  }
`;

export const Orbholder = styled.div`
  position: relative;
`;

export const Orb = styled.div`
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  border-radius: 100%;
  border: 1px solid ${props => props.theme.border};
  background: ${props =>
    props.status === 'online'
      ? props.theme.backgroundOnOnline
      : props.status === 'away'
      ? props.theme.backgroundOnAway
      : props.theme.backgroundOnOffline};
  right: 0;
  bottom: 0;
`;

export const Imageholder = styled.div`
  display: flex;
  width: 46px;
  height: 46px;
  margin: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: ${props => props.theme.background};
  overflow: hidden;
`;

export const Image = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;

export const Content = styled.div`
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  padding-left: 15px;
  word-break: break-all;

  span {
    display: block;
  }
`;
