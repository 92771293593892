import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Theme } from '../../assets/Theme.style';
import { Label, Title, Content } from './FactoriesItem.style';

function FactoriesItem({
  theme = Theme.content.item,
  data = {},
  isActive = false
}) {
  return (
    <>
      {Object.values(data).length > 0 && (
        <Label theme={theme.label} data-active={isActive ? true : undefined}>
          <Title>{data.name}</Title>
          <Content>{data.number}</Content>
        </Label>
      )}
    </>
  );
}

FactoriesItem.propTypes = {
  theme: PropTypes.object,
  data: PropTypes.object,
  isActive: PropTypes.bool
};

export default memo(FactoriesItem);
