import styled from 'styled-components';
import Selectrix from 'react-selectrix';

import {
  Selectbox as commonSelectbox,
  Select as commonSelect
} from '../../assets/common/Select.style';
import { Daypickerbox as commonDaypickerbox } from '../../assets/common/Inputbox.style';

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  width: 100%;
  margin-bottom: 15px;
`;

export const HeaderPair = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  width: 100%;
`;

export const Selectbox = styled.div`
  ${commonSelectbox}
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const Select = styled(Selectrix)`
  ${commonSelect}
`;

export const DayPicker = styled.div`
  ${commonDaypickerbox}
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;

  & > div {
    width: 100%;
  }
`;

export const Box = styled.div`
  display: block;
  width: 100%;
`;

export const Item = styled.div`
  display: block;
  max-width: 100%;
  margin-bottom: 10px;
`;

export const Title = styled.div`
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  cursor: default;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  cursor: default;
`;

export const Navigation = styled.div`
  display: block;
  width: 100%;
  text-align: center;
`;

export const More = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  transition: 0.2s color;
  color: ${props => props.theme.color};
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colorOnHover};
  }
`;
