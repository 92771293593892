/*
//   ROLES
*/

export const roles = [
  {
    id: 'isBineAdmin',
    name: 'BINE_ADMIN',
    nomination: ['isBineAdmin', 'isDistributorAdmin', 'isClientUser'],
    privileges: {
      VIEW_FACTORIES: true,
      VIEW_DEVICES: true,
      VIEW_ONLY_ASSIGNED_DEVICES: false,
      VIEW_DEVICE_NATURAL_NETWORK_VERSION: true,
      VIEW_DEVICE_HEARTBEAT: true,
      VIEW_DEVICE_CONFIGURE_FILE: true,
      VIEW_DEVICE_HISTORY: true,
      VIEW_DEVICE_HISTORY_DETAILS: true,
      VIEW_DEVICE_NOTES: true,
      MANAGE_DEVICE_DISTRIBUTOR: true,
      MANAGE_DEVICE_CUSTOMER: true,
      MANAGE_DEVICE_USERS: true,
      VIEW_DISTRIBUTORS: true,
      VIEW_CUSTOMERS: true,
      MANAGE_CUSTOMERS_DISTRIBUTOR: true,
      MANAGE_CUSTOMERS_SET_SELF_DISTRIBUTOR: false,
      VIEW_USERS: true,
      MANAGE_USERS_DISTRIBUTOR: true,
      MANAGE_USERS_SET_SELF_DISTRIBUTOR: false,
      MANAGE_PROFILE_SETTINGS_DISTRIBUTOR: true,
      MANAGE_PROFILE_SETTINGS_CUSTOMER: true,
      MANAGE_PROFILE_SETTINGS_ROLE: true,
      RESPECT_PROFILE_MEMBERSHIP: false
    }
  },
  {
    id: 'isDistributorAdmin',
    name: 'DISTRIBUTOR_ADMIN',
    nomination: ['isDistributorAdmin', 'isClientUser'],
    privileges: {
      VIEW_FACTORIES: false,
      VIEW_DEVICES: true,
      VIEW_ONLY_ASSIGNED_DEVICES: false,
      VIEW_DEVICE_NATURAL_NETWORK_VERSION: false,
      VIEW_DEVICE_HEARTBEAT: true,
      VIEW_DEVICE_CONFIGURE_FILE: false,
      VIEW_DEVICE_HISTORY: true,
      VIEW_DEVICE_HISTORY_DETAILS: false,
      VIEW_DEVICE_NOTES: false,
      MANAGE_DEVICE_DISTRIBUTOR: false,
      MANAGE_DEVICE_CUSTOMER: true,
      MANAGE_DEVICE_USERS: true,
      VIEW_DISTRIBUTORS: false,
      VIEW_CUSTOMERS: true,
      MANAGE_CUSTOMERS_DISTRIBUTOR: false,
      MANAGE_CUSTOMERS_SET_SELF_DISTRIBUTOR: true,
      VIEW_USERS: true,
      MANAGE_USERS_DISTRIBUTOR: false,
      MANAGE_USERS_SET_SELF_DISTRIBUTOR: true,
      MANAGE_PROFILE_SETTINGS_DISTRIBUTOR: false,
      MANAGE_PROFILE_SETTINGS_CUSTOMER: true,
      MANAGE_PROFILE_SETTINGS_ROLE: false,
      RESPECT_PROFILE_MEMBERSHIP: true
    }
  },
  {
    id: 'isClientUser',
    name: 'CLIENT_USER',
    nomination: [],
    privileges: {
      VIEW_FACTORIES: false,
      VIEW_DEVICES: true,
      VIEW_ONLY_ASSIGNED_DEVICES: true,
      VIEW_DEVICE_NATURAL_NETWORK_VERSION: false,
      VIEW_DEVICE_HEARTBEAT: false,
      VIEW_DEVICE_CONFIGURE_FILE: false,
      VIEW_DEVICE_HISTORY: true,
      VIEW_DEVICE_HISTORY_DETAILS: false,
      VIEW_DEVICE_NOTES: false,
      MANAGE_DEVICE_DISTRIBUTOR: false,
      MANAGE_DEVICE_CUSTOMER: false,
      MANAGE_DEVICE_USERS: false,
      VIEW_DISTRIBUTORS: false,
      VIEW_CUSTOMERS: false,
      MANAGE_CUSTOMERS_DISTRIBUTOR: false,
      MANAGE_CUSTOMERS_SET_SELF_DISTRIBUTOR: false,
      VIEW_USERS: false,
      MANAGE_USERS_DISTRIBUTOR: false,
      MANAGE_USERS_SET_SELF_DISTRIBUTOR: false,
      MANAGE_PROFILE_SETTINGS_DISTRIBUTOR: false,
      MANAGE_PROFILE_SETTINGS_CUSTOMER: false,
      MANAGE_PROFILE_SETTINGS_ROLE: false,
      RESPECT_PROFILE_MEMBERSHIP: true
    }
  }
];
