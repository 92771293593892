import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { useIntl } from 'react-intl';

import { Theme } from '../../assets/Theme.style';

import { Select } from './ManagerAssign.style';

function ManagerAssign({
  theme = {
    ...Theme.modal.selectbox
  },
  input = {},
  distributor = '',
  customers,
  managers
}) {
  const intl = useIntl();
  const options =
    distributor && managers && customers
      ? managers.filter(
          el => el.distributor && el.distributor.id === distributor
        )
      : [];
  return (
    <Select
      defaultValue={
        input && input.value && input.value.length > 0 ? input.value : false
      }
      theme={theme.selectrix}
      placeholder={intl.formatMessage({
        id: 'CUSTOMERS.ASSIGN.ACCOUNT_MANAGER'
      })}
      arrow={false}
      customScrollbar={true}
      searchable={false}
      options={
        options &&
        options.map(el => {
          return {
            ...el,
            key: el.id,
            label: `${el.displayName ? el.displayName : el.id}${
              el.position ? `, ${el.position}` : ''
            }${
              customers && el.customer && customers[el.customer.id]
                ? `, ${customers[el.customer.id].name}`
                : ''
            }`
          };
        })
      }
      onChange={value => {
        input.onChange(value.id);
      }}
    />
  );
}

ManagerAssign.propsTypes = {
  theme: PropTypes.object,
  input: PropTypes.object,
  distributor: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  const managers =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.managerAssignUsers;
  const customers =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.managerAssignCustomers;
  return {
    managers,
    customers
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props, ownProps) => {
    const listener = [];
    if (props.distributor) {
      const distributorRef =
        ownProps.firestore &&
        ownProps.firestore.collection('distributors').doc(props.distributor);
      listener.push(
        {
          collection: 'users',
          where: [['distributor', '==', distributorRef]],
          storeAs: 'managerAssignUsers'
        },
        {
          collection: 'customers',
          where: [['distributor', '==', distributorRef]],
          storeAs: 'managerAssignCustomers'
        }
      );
    }
    return listener;
  })
)(ManagerAssign);
