import styled from 'styled-components';

import { Media } from '../../assets/Mixins.style';

import {
  InlineButton as commonButton,
  SmallButton as commonSmallButton
} from '../../assets/common/Button.style';

export const Box = styled.div`
  display: block;
  width: 100%;
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  max-width: 100%;
  margin-bottom: 10px;
`;

export const ItemInner = styled.div`
  display: block;
  width: 100%;
`;

export const Title = styled.div`
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  cursor: default;
`;

export const Content = styled.div`
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  cursor: default;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 15px;
  text-align: right;

  ${Media.sm`
    text-align: left;
  `}
`;

export const Navigation = styled.div`
  display: block;
  width: 100%;
  text-align: center;
`;

export const More = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  transition: 0.2s color;
  color: ${props => props.theme.color};
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colorOnHover};
  }
`;

export const Button = styled.button`
  ${commonButton}
`;

export const Menu = styled.div`
  display: flex;
`;

export const MenuButton = styled.button`
  ${commonSmallButton};
  margin: 3px;
`;

export const MenuButtonIcon = styled.i``;
