import styled from 'styled-components';
import { Media } from '../../assets/Mixins.style';

import { Information as commonInformation } from '../../assets/common/Information.style';

export const Box = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
`;

export const Item = styled.div`
  display: block;
  width: 25%;
  margin-bottom: 10px;

  ${Media.xlg`
    width: 50%;
  `}

  ${Media.lg`
    width: 25%;
  `}

  ${Media.xs`
    width: 50%;
  `}
`;

export const Holder = styled.div`
  position: relative;
  display: block;
  width: 96px;
  height: 96px;
  margin: 0 auto 5px;

  ${Media.sm`
    transform: scale(0.8);
  `}
`;

export const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0;
`;

export const Title = styled.div`
  display: block;
  width: 100%;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
`;

export const Information = styled.div`
  ${commonInformation}
`;

export const Navigation = styled.div`
  display: block;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  text-align: right;
`;

export const ReloadButton = styled.a`
  display: inline-block;
  cursor: pointer;
`;
