import styled from 'styled-components';

import { Media } from '../../assets/Mixins.style';

export const Box = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const Item = styled.div`
  display: block;
  width: 50%;
  min-height: 86px;

  ${Media.xlg`
    width: 100%;
  `}

  ${Media.lg`
    width: 50%;
  `}

  ${Media.sm`
    width: 100%;
  `}

  [data-half] & {
    width: 100%;
  }
`;

export const Navigation = styled.div`
  display: block;
  width: 100%;
  text-align: center;
`;

export const More = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  transition: 0.2s color;
  color: ${props => props.theme.color};
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colorOnHover};
  }
`;
