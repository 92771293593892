/*
//   STYLE-COMPONENT: INPUTBOX
*/

import { css } from 'styled-components';
import { Media } from '../Mixins.style';

export const InputPairBox = css`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 10px;

  label {
    display: block;
    width: 100%;
    text-align: left;
  }

  ${Media.sm`
    flex-wrap: wrap;
  `}
`;

export const InputPair = css`
  position: relative;
  display: flex;
  width: 100%;
`;

export const Inputbox = css`
  position: relative;
  display: block;
  width: 100%;
  margin-top: 10px;

  &::before {
    content: attr(data-name);
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    color: ${props => props.theme.color};
    transition: 0.2s;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    opacity: 0;
  }

  &[data-filled]::before {
    top: -10px;
    height: 10px;
    font-size: 10px;
    opacity: 1;
  }

  &[data-nomargin] {
    margin-top: 0;
  }

  input[type='text'],
  input[type='url'],
  input[type='password'],
  input[type='email'],
  input[type='tel'] {
    position: relative;
    display: block;
    width: 100%;
    height: 30px;
    border: 0;
    border-radius: 0;
    color: ${props => props.theme.color};
    border-bottom: 2px solid ${props => props.theme.borderColor};
    background: transparent;
    outline: 0;
    transition: 0.2s border-bottom;
    font-size: 16px;
    font-weight: 500;

    ::-webkit-input-placeholder {
      color: ${props => props.theme.colorPlaceholder};
    }

    &:focus {
      border-bottom: 2px solid ${props => props.theme.borderColorOnValid};
    }

    &[data-invalid] {
      color: ${props => props.theme.colorOnInvalid};
      border-bottom: 2px solid ${props => props.theme.borderColorOnInvalid};
    }

    &[data-valid] {
      border-bottom: 2px solid ${props => props.theme.borderColorOnValid};
    }

    &[data-big] {
      font-size: 24px;
      font-weight: 700;
    }

    &[disabled] {
      opacity: 0.7;
    }
  }

  textarea {
    display: block;
    width: 100%;
    min-height: 120px;
    border: 0;
    border-radius: 0;
    color: ${props => props.theme.color};
    border-bottom: 2px solid ${props => props.theme.borderColor};
    background: transparent;
    outline: 0;
    transition: 0.2s border-bottom;
    font-size: 16px;
    font-weight: 500;
    resize: vertical;

    &:focus {
      border-bottom: 2px solid ${props => props.theme.borderColorOnValid};
    }

    &[data-invalid] {
      color: ${props => props.theme.colorOnInvalid};
      border-bottom: 2px solid ${props => props.theme.borderColorOnInvalid};
    }

    &[data-valid] {
      border-bottom: 2px solid ${props => props.theme.borderColorOnValid};
    }

    &[data-big] {
      font-size: 24px;
      font-weight: 700;
    }
  }

  span {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding-top: 2px;
    color: ${props => props.theme.alertColor};
    font-size: 12px;
    line-height: 1.2;
    min-height: 20px;
    cursor: default;
  }
`;

export const Daypickerbox = css`
  display: inline-block;
  width: auto;
  margin: 0 3px;

  input {
    display: block;
    width: 100%;
    height: 30px;
    border: 0;
    border-radius: 0;
    color: ${props => props.theme.color};
    border-bottom: 2px solid ${props => props.theme.borderColor};
    background: transparent;
    outline: 0;
    transition: 0.2s border-bottom;
    font-size: 16px;
    font-weight: 500;

    &:focus {
      border-bottom: 2px solid ${props => props.theme.borderColorOnValid};
    }

    &[data-invalid] {
      color: ${props => props.theme.colorOnInvalid};
      border-bottom: 2px solid ${props => props.theme.borderColorOnInvalid};
    }

    &[data-valid] {
      border-bottom: 2px solid ${props => props.theme.borderColorOnValid};
    }

    &[data-big] {
      font-size: 24px;
      font-weight: 700;
    }

    &[disabled] {
      opacity: 0.7;
    }
  }

  ${Media.sm`
    display: block;
    width: 100%;
    padding: 5px 0;
  `}

  .react-datepicker {
    display: flex;
    border-radius: 8px;
    border: 0;
    max-width: 325px;
    /* min-width: 310px; */
    box-shadow: 0px 0px 15px 0px ${props => props.theme.popperShadow};
  }

  .react-datepicker__triangle {
    &::before {
      border-top-color: ${props => props.theme.popperTriangleBorder} !important;
    }
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__header {
    background-color: ${props => props.theme.popperHeaderBg};
    border: 1px solid ${props => props.theme.popperHeaderBorder};
    border-radius: 0;
    padding: 0;
  }

  .react-datepicker__month-container {
    overflow: hidden;
    border-radius: 8px;
  }

  .react-datepicker__current-month {
    display: block;
    padding: 10px 10px 0 10px;
    color: ${props => props.theme.popperHeaderColor};
    font-size: 18px !important;
    line-height: 1;
  }

  .react-datepicker__navigation--previous {
    width: 20px;
    height: 20px;
    border: 10px solid transparent !important;
    border-right-color: ${props => props.theme.popperHeaderColor} !important;
  }

  .react-datepicker__navigation--next {
    width: 20px;
    height: 20px;
    border: 10px solid transparent !important;
    border-left-color: ${props => props.theme.popperHeaderColor} !important;
  }

  .react-datepicker__day-names {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  .react-datepicker__day-name {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 35px !important;
    height: 35px !important;
    margin: 0;
    color: ${props => props.theme.popperHeaderColor};
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
  }

  .react-datepicker__month {
    display: grid;
    margin: 0;
  }

  .react-datepicker__week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  .react-datepicker__day {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 35px !important;
    height: 35px !important;
    margin: 0;
    color: ${props => props.theme.popperColor};
    font-size: 14px;
    font-weight: 400;
    line-height: 1;

    &.react-datepicker__day--disabled {
      opacity: 0.5;
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${props => props.theme.popperSelectedBg};
    color: ${props => props.theme.popperSelectedColor};
    font-weight: 400 !important;
    &:hover {
      background-color: ${props => props.theme.popperSelectedBg};
    }
  }

  .react-datepicker__time-container {
    overflow: hidden;
    border-radius: 8px;
    border-left: 1px solid ${props => props.theme.popperBorder};
  }

  .react-datepicker-time__header {
    padding: 10px;
    border-radius: 0;
    color: ${props => props.theme.popperHeaderColor};
    font-size: 14px;
    line-height: 1;
  }

  .react-datepicker__time-list {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${props => props.theme.popperTimeThumb};
    }

    &::-webkit-scrollbar-track {
      background: ${props => props.theme.popperTimeTrack};
    }
  }

  li.react-datepicker__time-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: ${props => props.theme.popperColor};
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
  }

  li.react-datepicker__time-list-item--selected {
    background-color: ${props => props.theme.popperSelectedBg} !important;
    color: ${props => props.theme.popperSelectedColor};
    font-weight: 400 !important;
    &:hover {
      background-color: ${props => props.theme.popperSelectedBg} !important;
    }
  }
`;
