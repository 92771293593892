import styled from 'styled-components';
import { Media } from '../../assets/Mixins.style';

export const Wrapper = styled.div`
  display: block;
  flex-shrink: 0;
  width: 300px;
  transition: 0.2s width;
  background: ${props => props.theme.background};
  border: 0;
  overflow: auto;

  &::-webkit-scrollbar {
    width:  6px
    height: 6px
  }

  &::-webkit-scrollbar-thumb {
      background: ${props => props.theme.thumb}
  }

  &::-webkit-scrollbar-track {
      background: ${props => props.theme.track}
  }

  ${Media.xlg`
    width: 200px;
  `}
`;

export const Content = styled.div`
  display: block;
  width: 100%;
  padding: 55px 15px 0 84px;
  transition: 0.2s padding;

  ${Media.xlg`
    padding: 25px 15px 0;
  `}
`;

export const Title = styled.div`
  display: block;
  margin-bottom: 15px;
  color: ${props => props.theme.color};
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  word-break: break-word;
  cursor: default;
`;

export const Item = styled.div`
  display: block;
  width: 100%;
  padding: 0 0 0 25px;
`;

export const Option = styled.div`
  display: block;
  width: 100%;
  transition: 0.2s color;
  color: ${props => props.theme.color};
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colorOnHover};
  }

  &[data-active] {
    color: ${props => props.theme.color};
    font-weight: 600;
  }
`;
