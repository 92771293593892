import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import useWindowSize from '@rehooks/window-size';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';

import moment from 'moment';
import de from 'date-fns/locale/de';
import DayPickerInput, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Theme } from '../../assets/Theme.style';
import { Breakpoints } from '../../assets/Variables.style';
import {
  Header,
  HeaderPair,
  DayPicker,
  Box,
  Item,
  Title,
  Content,
  Holder,
  Statuses,
  Status,
  StatusName,
  StatusValue,
} from './DeviceStatistics.style';

import {
  setDeviceStatisticsReset,
  setDeviceStatisticsTo,
  setDeviceStatisticsFrom,
} from '../../actions/Device/DeviceActions';

registerLocale('de', de);

function DeviceStatistics({
  theme = {
    ...Theme.content.details.statistics,
  },
  serial = '',
  data = [],
  deviceStatisticsStore = {},
  setDeviceStatisticsReset = () => {},
  setDeviceStatisticsFrom = () => {},
  setDeviceStatisticsTo = () => {},
}) {
  let windowSize = useWindowSize();
  const [selectedEventInstance, setSelectedEventInstance] = useState(undefined);
  const [selectedFractionInstance, setSelectedFractionInstance] = useState(
    undefined
  );
  const isMobile = windowSize.innerWidth <= Breakpoints.lg;
  const intl = useIntl();
  const { from, to } = deviceStatisticsStore;

  const handleFromChange = (from) => {
    setDeviceStatisticsFrom(
      from
        ? moment(from).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
        : moment(to).startOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
    );
  };

  const handleToChange = (to) => {
    setDeviceStatisticsTo(
      to
        ? moment(to).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
        : moment(from).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
    );
  };

  useEffect(() => {
    setDeviceStatisticsReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serial]);

  const chartEventDistributionData =
    (data &&
      Object.entries(
        data.reduce((currentState, { value: { status } }) => {
          if (currentState[status || 'NO_STATUS'])
            currentState[status || 'NO_STATUS']++;
          else currentState[status || 'NO_STATUS'] = 1;
          return currentState;
        }, {})
      )
        .reduce((currentState, [key, value]) => {
          currentState.push({ key, value });
          return currentState;
        }, [])
        .map(({ key, value }) => ({
          key,
          name: intl.formatMessage({
            id: `DEVICES.STATUS.${key}`,
            defaultMessage: key,
          }),
          value,
        }))) ||
    [];

  const chartFractionDistributionData = chartEventDistributionData
    .filter(({ key }) => key.includes('CB.info.cb.dropBin'))
    .map(({ key, name, value }, index, array) => {
      const sum = array.reduce((sum, { value }) => sum + value, 0);
      const binMatch = key.match(/Bin([0-9]+)/);
      const binNumber = binMatch && binMatch[1];
      const binKey = `bin${binNumber ? binNumber : ''}`;
      const fractionMatch = key.match(/\((.*1)\)/);
      const fractionKey = fractionMatch && fractionMatch[1];
      return {
        key: binKey,
        name: fractionKey
          ? intl.formatMessage({
              id: `FRACTION.TYPE.${fractionKey}`,
              defaultMessage: fractionKey,
            })
          : binNumber
          ? intl.formatMessage(
              {
                id: 'DEVICES.STATISTIC.BIN',
              },
              { id: binNumber }
            )
          : binKey,
        value: Math.round((value * 100) / sum),
      };
    })
    .sort((instanceA, instanceB) =>
      instanceA.key.localeCompare(instanceB.key, undefined, { numeric: true })
    );

  const chartBinFrequencyData =
    (data &&
      Object.entries(
        data.reduce((currentState, { value: { timestamp, status } }) => {
          if (status.includes('CB.info.cb.dropBin')) {
            const key = moment(timestamp).format('YYYY/MM/DD');
            const binMatch = status.match(/Bin([0-9]+)/);
            const binKey = `bin${binMatch && binMatch[1]}`;
            if (!currentState[key]) currentState[key] = {};
            if (currentState[key][binKey]) currentState[key][binKey]++;
            else currentState[key][binKey] = 1;
          }
          return currentState;
        }, {})
      )
        .reduce((currentState, [key, value]) => {
          currentState.push({ key, value });
          return currentState;
        }, [])
        .map(({ key, value }) => ({
          key,
          name: key,
          ...value,
        }))) ||
    [];

  const chartBinAverageOfFullFrequencyData =
    (data &&
      Object.entries(
        data.reduce((currentState, { value: { timestamp, status, data } }) => {
          if (status.includes('CB.info.bineIsFull')) {
            data &&
              data.bins &&
              data.bins.forEach((bin) => {
                const binKey = `bin${bin}`;
                if (!currentState[binKey]) currentState[binKey] = [];
                const binIsFullTime = moment(timestamp)
                  .startOf('hour')
                  .format('YYYY-MM-DDTHH:mm:ssZ');
                if (!currentState[binKey].includes(binIsFullTime))
                  currentState[binKey].push(binIsFullTime);
              });
          }
          return currentState;
        }, {})
      )
        .reduce((currentState, [key, value]) => {
          currentState.push({ key, value });
          return currentState;
        }, [])
        .map(({ key, value }) => {
          const binMatch = key.match(/bin([0-9]+)/);
          const binNumber = binMatch && binMatch[1];
          const binKey = `bin${binNumber ? binNumber : ''}`;
          const hoursBetweenBinFull = value
            .map((timestamp, index, array) => {
              const nextTimestamp = array[index + 1];
              return nextTimestamp
                ? moment(nextTimestamp).diff(moment(timestamp), 'hours')
                : undefined;
            })
            .filter((hour) => hour > 0);
          let sumHoursBetweenBinFull;
          let avgHoursBetweenBinFull;
          if (hoursBetweenBinFull.length > 0) {
            sumHoursBetweenBinFull = hoursBetweenBinFull.reduce(
              (currentState, hour) => currentState + hour,
              0
            );
            avgHoursBetweenBinFull =
              sumHoursBetweenBinFull / hoursBetweenBinFull.length;
          }

          return {
            key: binKey,
            name: binNumber
              ? intl.formatMessage(
                  {
                    id: 'DEVICES.STATISTIC.BIN.AVERAGE_OF_FULL',
                  },
                  { id: binNumber }
                )
              : binKey,
            value: avgHoursBetweenBinFull
              ? moment.duration(avgHoursBetweenBinFull, 'hours').humanize()
              : undefined,
          };
        })
        .filter((data) => data.value)
        .sort((instanceA, instanceB) =>
          instanceA.key.localeCompare(instanceB.key, undefined, {
            numeric: true,
          })
        )) ||
    [];

  const chartBinPredictionOfFullFrequencyData =
    (data &&
      Object.entries(
        data.reduce((currentState, { value: { timestamp, status, data } }) => {
          if (status.includes('CB.info.bineIsFull')) {
            data &&
              data.bins &&
              data.bins.forEach((bin) => {
                const binKey = `bin${bin}`;
                if (!currentState[binKey]) currentState[binKey] = [];
                const binIsFullTime = moment(timestamp)
                  .startOf('hour')
                  .format('YYYY-MM-DDTHH:mm:ssZ');
                if (!currentState[binKey].includes(binIsFullTime))
                  currentState[binKey].push(binIsFullTime);
              });
          }
          return currentState;
        }, {})
      )
        .reduce((currentState, [key, value]) => {
          currentState.push({ key, value });
          return currentState;
        }, [])
        .map(({ key, value }) => {
          const binMatch = key.match(/bin([0-9]+)/);
          const binNumber = binMatch && binMatch[1];
          const binKey = `bin${binNumber ? binNumber : ''}`;
          const lastBinFull = value[value.length - 1];
          const hoursBetweenBinFull = value
            .map((timestamp, index, array) => {
              const nextTimestamp = array[index + 1];
              return nextTimestamp
                ? moment(nextTimestamp).diff(moment(timestamp), 'hours')
                : undefined;
            })
            .filter((hour) => hour > 0);
          let sumHoursBetweenBinFull;
          let avgHoursBetweenBinFull;
          if (hoursBetweenBinFull.length > 0) {
            sumHoursBetweenBinFull = hoursBetweenBinFull.reduce(
              (currentState, hour) => currentState + hour,
              0
            );
            avgHoursBetweenBinFull =
              sumHoursBetweenBinFull / hoursBetweenBinFull.length;
          }

          return {
            key: binKey,
            name: binNumber
              ? intl.formatMessage(
                  {
                    id: 'DEVICES.STATISTIC.BIN.PREDICTION_OF_FULL',
                  },
                  { id: binNumber }
                )
              : binKey,
            value:
              lastBinFull &&
              avgHoursBetweenBinFull &&
              moment(lastBinFull)
                .add(avgHoursBetweenBinFull, 'hours')
                .diff(moment()) > 0
                ? moment(lastBinFull)
                    .add(avgHoursBetweenBinFull, 'hours')
                    .fromNow()
                : undefined,
          };
        })
        .filter((data) => data.value)
        .sort((instanceA, instanceB) =>
          instanceA.key.localeCompare(instanceB.key, undefined, {
            numeric: true,
          })
        )) ||
    [];

  const chartHourlyUsageData =
    (data &&
      Object.entries(
        data.reduce((currentState, { value: { timestamp } }) => {
          if (timestamp) {
            const hourStart = moment(timestamp).startOf('hour').format('H:mm');
            const hourEnd = moment(timestamp).endOf('hour').format('H:mm');
            if (currentState[`${hourStart}-${hourEnd}`])
              currentState[`${hourStart}-${hourEnd}`]++;
            else currentState[`${hourStart}-${hourEnd}`] = 1;
          }
          return currentState;
        }, {})
      ).reduce((currentState, [key, value]) => {
        currentState.push({ key, name: key, value });
        return currentState;
      }, [])) ||
    [];

  const chartHourlyDistributionData = [...Array(24)].map((hour, index) => {
    const hourStart = moment()
      .startOf('day')
      .startOf('hour')
      .add(index, 'hours')
      .format('H:mm');
    const hourEnd = moment()
      .startOf('day')
      .endOf('hour')
      .add(index, 'hours')
      .format('H:mm');
    const key = `${hourStart}-${hourEnd}`;
    const usage = chartHourlyUsageData.find((usage) => usage.key === key);
    return {
      key,
      name: key,
      value: (usage && usage.value) || 0,
    };
  });

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={theme.chart.pie.default.text}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline='central'
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <>
      <Header>
        <HeaderPair>
          <DayPicker theme={theme.daypicker.default}>
            <DayPickerInput
              selected={from && moment(from).toDate()}
              maxDate={to && moment(to).toDate()}
              placeholderText={intl.formatMessage({
                id: 'DEVICES.STATISTICS.FROM',
              })}
              dateFormat='yyyy/MM/dd'
              locale={intl.locale}
              onChange={(from) => handleFromChange(from)}
              withPortal={isMobile ? true : undefined}
              popperPlacement='bottom-start'
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: 'viewport',
                },
              }}
            />
          </DayPicker>
          <DayPicker theme={theme.daypicker.default}>
            <DayPickerInput
              selected={to && moment(to).toDate()}
              minDate={from && moment(from).toDate()}
              placeholderText={intl.formatMessage({
                id: 'DEVICES.STATISTICS.TO',
              })}
              dateFormat='yyyy/MM/dd'
              locale={intl.locale}
              onChange={(to) => handleToChange(to)}
              withPortal={isMobile ? true : undefined}
              popperPlacement='bottom-start'
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: 'viewport',
                },
              }}
            />
          </DayPicker>
        </HeaderPair>
      </Header>
      {data && data.length > 0 ? (
        <Box>
          <Item>
            <Title>
              {intl.formatMessage(
                {
                  id: 'DEVICES.STATISTICS.EVENT_DISTRIBUTION',
                },
                {
                  from: moment(from).format('YYYY/MM/DD'),
                  to: moment(to).format('YYYY/MM/DD'),
                }
              )}
            </Title>
            <Content>
              <Holder>
                <ResponsiveContainer>
                  <BarChart
                    data={chartEventDistributionData}
                    margin={{
                      top: 5,
                      bottom: 5,
                    }}
                    onMouseMove={(chart) => {
                      if (
                        chart.activePayload &&
                        chart.activePayload[0] &&
                        chart.activePayload[0].payload &&
                        chart.activePayload[0].payload.key
                      )
                        setSelectedEventInstance(
                          chart.activePayload[0].payload.key
                        );
                      else setSelectedEventInstance(undefined);
                    }}
                    onMouseLeave={() => {
                      setSelectedEventInstance(undefined);
                    }}
                  >
                    <XAxis
                      dataKey='name'
                      padding={{ left: 10, right: 10 }}
                      tick={false}
                    />
                    <YAxis />
                    <Tooltip />
                    <CartesianGrid strokeDasharray='1 1' />
                    <Bar
                      name={intl.formatMessage({
                        id: 'DEVICES.STATISTICS.RESULTS',
                      })}
                      dataKey='value'
                      fill={theme.chart.bar.default.fill}
                    >
                      {chartEventDistributionData.map(
                        ({ key: eventInstance }, key) => (
                          <Cell
                            cursor='pointer'
                            fill={
                              eventInstance === selectedEventInstance
                                ? theme.chart.bar.selected.fill
                                : theme.chart.bar.default.fill
                            }
                            key={key}
                          />
                        )
                      )}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Holder>

              <Statuses>
                {chartEventDistributionData.map(
                  ({ key: eventInstance, name, value }, key) => (
                    <Status
                      theme={theme.chart.status}
                      key={key}
                      cursor={'pointer'}
                      active={eventInstance === selectedEventInstance}
                      onMouseMove={() => {
                        setSelectedEventInstance(eventInstance);
                      }}
                      onMouseLeave={() => {
                        setSelectedEventInstance(undefined);
                      }}
                    >
                      <StatusName>{name}</StatusName>
                      <StatusValue>{value}</StatusValue>
                    </Status>
                  )
                )}
              </Statuses>
            </Content>
          </Item>
          {chartFractionDistributionData &&
          chartFractionDistributionData.length > 0 ? (
            <Item>
              <Title>
                {intl.formatMessage(
                  {
                    id: 'DEVICES.STATISTICS.FRACTION_DISTRIBUTION',
                  },
                  {
                    from: moment(from).format('YYYY/MM/DD'),
                    to: moment(to).format('YYYY/MM/DD'),
                  }
                )}
              </Title>
              <Content>
                <Holder>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={chartFractionDistributionData}
                        dataKey='value'
                        cx={'50%'}
                        cy={'50%'}
                        startAngle={90}
                        endAngle={-270}
                        innerRadius={66}
                        outerRadius={86}
                        fill={theme.chart.pie.default.fill}
                        label={renderCustomizedLabel}
                        isAnimationActive={false}
                        onMouseMove={(chart) => {
                          if (chart.key) setSelectedFractionInstance(chart.key);
                          else setSelectedFractionInstance(undefined);
                        }}
                        onMouseLeave={() => {
                          setSelectedFractionInstance(undefined);
                        }}
                      >
                        {chartFractionDistributionData.map(
                          ({ key: fractionInstance }, key) => (
                            <Cell
                              cursor='pointer'
                              fill={
                                fractionInstance === selectedFractionInstance
                                  ? theme.chart.pie.selected.fill
                                  : theme.chart.pie.default.fill
                              }
                              key={key}
                            />
                          )
                        )}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </Holder>
                <Statuses>
                  {chartFractionDistributionData.map(
                    ({ key: fractionInstance, name, value }, key) => (
                      <Status
                        theme={theme.chart.status}
                        key={key}
                        cursor={'pointer'}
                        active={fractionInstance === selectedFractionInstance}
                        onMouseMove={() => {
                          setSelectedFractionInstance(fractionInstance);
                        }}
                        onMouseLeave={() => {
                          setSelectedFractionInstance(undefined);
                        }}
                      >
                        <StatusName>{name}</StatusName>
                        <StatusValue>{value}%</StatusValue>
                      </Status>
                    )
                  )}
                </Statuses>
              </Content>
            </Item>
          ) : (
            ''
          )}
          {chartBinFrequencyData && chartBinFrequencyData.length > 0 ? (
            <Item>
              <Title>
                {intl.formatMessage(
                  {
                    id: 'DEVICES.STATISTICS.BIN_FULL_FREQUENCY',
                  },
                  {
                    from: moment(from).format('YYYY/MM/DD'),
                    to: moment(to).format('YYYY/MM/DD'),
                  }
                )}
              </Title>
              <Content>
                <Holder>
                  <ResponsiveContainer>
                    <BarChart
                      data={chartBinFrequencyData}
                      margin={{
                        top: 5,
                        bottom: 5,
                      }}
                    >
                      <XAxis
                        dataKey='name'
                        padding={{ left: 10, right: 10 }}
                        tick={false}
                      />
                      <YAxis />
                      <Tooltip />
                      <CartesianGrid strokeDasharray='1 1' />

                      <Bar
                        name={intl.formatMessage(
                          {
                            id: 'DEVICES.STATISTIC.BIN',
                          },
                          { id: 1 }
                        )}
                        dataKey='bin1'
                        fill={theme.chart.bar.default.fill}
                      />
                      <Bar
                        name={intl.formatMessage(
                          {
                            id: 'DEVICES.STATISTIC.BIN',
                          },
                          { id: 2 }
                        )}
                        dataKey='bin2'
                        fill={theme.chart.bar.default.fill}
                      />
                      <Bar
                        name={intl.formatMessage(
                          {
                            id: 'DEVICES.STATISTIC.BIN',
                          },
                          { id: 3 }
                        )}
                        dataKey='bin3'
                        fill={theme.chart.bar.default.fill}
                      />
                      <Bar
                        name={intl.formatMessage(
                          {
                            id: 'DEVICES.STATISTIC.BIN',
                          },
                          { id: 4 }
                        )}
                        dataKey='bin4'
                        fill={theme.chart.bar.default.fill}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Holder>

                <Statuses>
                  {chartBinAverageOfFullFrequencyData.map(
                    ({ name, value }, key) => (
                      <Status key={key}>
                        <StatusName>{name}</StatusName>
                        <StatusValue>{value}</StatusValue>
                      </Status>
                    )
                  )}
                  {chartBinPredictionOfFullFrequencyData.map(
                    ({ name, value }, key) => (
                      <Status key={key}>
                        <StatusName>{name}</StatusName>
                        <StatusValue>{value}</StatusValue>
                      </Status>
                    )
                  )}
                </Statuses>
              </Content>
            </Item>
          ) : (
            ''
          )}
          {chartHourlyUsageData && chartHourlyUsageData.length > 0 ? (
            <Item>
              <Title>
                {intl.formatMessage(
                  {
                    id: 'DEVICES.STATISTICS.HOURLY_TIMETABLE_USE_DEVICE',
                  },
                  {
                    from: moment(from).format('YYYY/MM/DD'),
                    to: moment(to).format('YYYY/MM/DD'),
                  }
                )}
              </Title>
              <Content>
                <Holder>
                  <ResponsiveContainer>
                    <BarChart
                      data={chartHourlyDistributionData}
                      margin={{
                        top: 5,
                        bottom: 5,
                      }}
                    >
                      <XAxis dataKey='name' padding={{ left: 10, right: 10 }} />
                      <YAxis />
                      <Tooltip />
                      <CartesianGrid strokeDasharray='1 1' />
                      <Bar
                        name={intl.formatMessage({
                          id: 'DEVICES.STATISTICS.RESULTS',
                        })}
                        dataKey='value'
                        fill={theme.chart.bar.default.fill}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Holder>
              </Content>
            </Item>
          ) : (
            ''
          )}
        </Box>
      ) : (
        intl.formatMessage({
          id: 'DEVICES.STATISTICS.NO_STATISTICS',
        })
      )}
    </>
  );
}

DeviceStatistics.propsTypes = {
  theme: PropTypes.object,
  serial: PropTypes.string,
  data: PropTypes.array,
  deviceStatisticsStore: PropTypes.object,
  setDeviceStatisticsReset: PropTypes.func,
  setDeviceStatisticsFrom: PropTypes.func,
  setDeviceStatisticsTo: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    deviceStatisticsStore: state.deviceStatisticsStore,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDeviceStatisticsReset: () => dispatch(setDeviceStatisticsReset()),
    setDeviceStatisticsTo: (to) => dispatch(setDeviceStatisticsTo(to)),
    setDeviceStatisticsFrom: (from) => dispatch(setDeviceStatisticsFrom(from)),
  };
};

export default memo(
  compose(connect(mapStateToProps, mapDispatchToProps))(DeviceStatistics)
);
