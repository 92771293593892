/*
//   LANG
*/

export const lang = [
  {
    id: 'en',
    name: 'English'
  },
  {
    id: 'de',
    name: 'Deutsch'
  }
];
