import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import withTimeout from 'react-timeout';
import { useIntl } from 'react-intl';

import { withTheme } from '../../assets/Theme.style';

import {
  Wrapper,
  Panel,
  PanelInner,
  Box,
  Information
} from './BlackHolePage.style';

import Header from '../../components/Header/Header';

function BlackHolePage({ firebaseStore, location, theme }) {
  const intl = useIntl();
  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'PAGE_TITLE.BLACK_HOLE' })} | BIN-E Smart
          Waste Bin
        </title>
      </Helmet>
      {firebaseStore.auth.isEmpty ? (
        <Redirect to={{ pathname: '/sign-in' }} />
      ) : (
        <Wrapper>
          <Header
            currentPath={location.pathname}
            profile={firebaseStore.profile}
            filter={false}
          />
          <Panel theme={theme.content.panel.default}>
            <PanelInner>
              <Box>
                <Information>
                  {intl.formatMessage({ id: 'BLACK_HOLE.NOT_FOUND' })}
                </Information>
              </Box>
            </PanelInner>
          </Panel>
        </Wrapper>
      )}
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return { firebaseStore: state.firebase };
};

export default compose(
  withTheme,
  withRouter,
  withTimeout,
  connect(mapStateToProps)
)(BlackHolePage);
