import moment from 'moment';

const defaultState = {
  type: 'timestamp',
  to: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ssZ'),
  from: moment()
    .subtract(7, 'days')
    .startOf('day')
    .format('YYYY-MM-DDTHH:mm:ssZ'),
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'DEVICE_STATISTICS_RESET': {
      return {
        ...defaultState,
      };
    }
    case 'DEVICE_STATISTICS_TO_DATE': {
      return {
        ...state,
        type: 'timestamp',
        to: action.to,
      };
    }
    case 'DEVICE_STATISTICS_FROM_DATE': {
      return {
        ...state,
        type: 'timestamp',
        from: action.from,
      };
    }
    default:
      return state;
  }
};
