/*
//   COUNTRIES
*/

export const countries = [
  { id: 'Afghanistan', name: 'AF' },
  { id: 'Åland Islands', name: 'AX' },
  { id: 'Albania', name: 'AL' },
  { id: 'Algeria', name: 'DZ' },
  { id: 'American Samoa', name: 'AS' },
  { id: 'Andorra', name: 'AD' },
  { id: 'Angola', name: 'AO' },
  { id: 'Anguilla', name: 'AI' },
  { id: 'Antarctica', name: 'AQ' },
  { id: 'Antigua and Barbuda', name: 'AG' },
  { id: 'Argentina', name: 'AR' },
  { id: 'Armenia', name: 'AM' },
  { id: 'Aruba', name: 'AW' },
  { id: 'Australia', name: 'AU' },
  { id: 'Austria', name: 'AT' },
  { id: 'Azerbaijan', name: 'AZ' },
  { id: 'Bahamas', name: 'BS' },
  { id: 'Bahrain', name: 'BH' },
  { id: 'Bangladesh', name: 'BD' },
  { id: 'Barbados', name: 'BB' },
  { id: 'Belarus', name: 'BY' },
  { id: 'Belgium', name: 'BE' },
  { id: 'Belize', name: 'BZ' },
  { id: 'Benin', name: 'BJ' },
  { id: 'Bermuda', name: 'BM' },
  { id: 'Bhutan', name: 'BT' },
  { id: 'Bolivia', name: 'BO' },
  { id: 'Bosnia and Herzegovina', name: 'BA' },
  { id: 'Botswana', name: 'BW' },
  { id: 'Bouvet Island', name: 'BV' },
  { id: 'Brazil', name: 'BR' },
  { id: 'British Indian Ocean Territory', name: 'IO' },
  { id: 'Brunei Darussalam', name: 'BN' },
  { id: 'Bulgaria', name: 'BG' },
  { id: 'Burkina Faso', name: 'BF' },
  { id: 'Burundi', name: 'BI' },
  { id: 'Cambodia', name: 'KH' },
  { id: 'Cameroon', name: 'CM' },
  { id: 'Canada', name: 'CA' },
  { id: 'Cape Verde', name: 'CV' },
  { id: 'Cayman Islands', name: 'KY' },
  { id: 'Central African Republic', name: 'CF' },
  { id: 'Chad', name: 'TD' },
  { id: 'Chile', name: 'CL' },
  { id: 'China', name: 'CN' },
  { id: 'Christmas Island', name: 'CX' },
  { id: 'Cocos (Keeling) Islands', name: 'CC' },
  { id: 'Colombia', name: 'CO' },
  { id: 'Comoros', name: 'KM' },
  { id: 'Congo', name: 'CG' },
  { id: 'Congo, The Democratic Republic of the', name: 'CD' },
  { id: 'Cook Islands', name: 'CK' },
  { id: 'Costa Rica', name: 'CR' },
  { id: "Cote D'Ivoire", name: 'CI' },
  { id: 'Croatia', name: 'HR' },
  { id: 'Cuba', name: 'CU' },
  { id: 'Cyprus', name: 'CY' },
  { id: 'Czech Republic', name: 'CZ' },
  { id: 'Denmark', name: 'DK' },
  { id: 'Djibouti', name: 'DJ' },
  { id: 'Dominica', name: 'DM' },
  { id: 'Dominican Republic', name: 'DO' },
  { id: 'Ecuador', name: 'EC' },
  { id: 'Egypt', name: 'EG' },
  { id: 'El Salvador', name: 'SV' },
  { id: 'Equatorial Guinea', name: 'GQ' },
  { id: 'Eritrea', name: 'ER' },
  { id: 'Estonia', name: 'EE' },
  { id: 'Ethiopia', name: 'ET' },
  { id: 'Falkland Islands (Malvinas)', name: 'FK' },
  { id: 'Faroe Islands', name: 'FO' },
  { id: 'Fiji', name: 'FJ' },
  { id: 'Finland', name: 'FI' },
  { id: 'France', name: 'FR' },
  { id: 'French Guiana', name: 'GF' },
  { id: 'French Polynesia', name: 'PF' },
  { id: 'French Southern Territories', name: 'TF' },
  { id: 'Gabon', name: 'GA' },
  { id: 'Gambia', name: 'GM' },
  { id: 'Georgia', name: 'GE' },
  { id: 'Germany', name: 'DE' },
  { id: 'Ghana', name: 'GH' },
  { id: 'Gibraltar', name: 'GI' },
  { id: 'Greece', name: 'GR' },
  { id: 'Greenland', name: 'GL' },
  { id: 'Grenada', name: 'GD' },
  { id: 'Guadeloupe', name: 'GP' },
  { id: 'Guam', name: 'GU' },
  { id: 'Guatemala', name: 'GT' },
  { id: 'Guernsey', name: 'GG' },
  { id: 'Guinea', name: 'GN' },
  { id: 'Guinea-Bissau', name: 'GW' },
  { id: 'Guyana', name: 'GY' },
  { id: 'Haiti', name: 'HT' },
  { id: 'Heard Island and Mcdonald Islands', name: 'HM' },
  { id: 'Holy See (Vatican City State)', name: 'VA' },
  { id: 'Honduras', name: 'HN' },
  { id: 'Hong Kong', name: 'HK' },
  { id: 'Hungary', name: 'HU' },
  { id: 'Iceland', name: 'IS' },
  { id: 'India', name: 'IN' },
  { id: 'Indonesia', name: 'IA' },
  { id: 'Iran, Islamic Republic Of', name: 'IR' },
  { id: 'Iraq', name: 'IQ' },
  { id: 'Ireland', name: 'IE' },
  { id: 'Isle of Man', name: 'IM' },
  { id: 'Israel', name: 'IL' },
  { id: 'Italy', name: 'IT' },
  { id: 'Jamaica', name: 'JM' },
  { id: 'Japan', name: 'JP' },
  { id: 'Jersey', name: 'JE' },
  { id: 'Jordan', name: 'JO' },
  { id: 'Kazakhstan', name: 'KZ' },
  { id: 'Kenya', name: 'KE' },
  { id: 'Kiribati', name: 'KI' },
  { id: "Korea, Democratic People'S Republic of", name: 'KP' },
  { id: 'Korea, Republic of', name: 'KR' },
  { id: 'Kuwait', name: 'KW' },
  { id: 'Kyrgyzstan', name: 'KG' },
  { id: "Lao People'S Democratic Republic", name: 'LA' },
  { id: 'Latvia', name: 'LV' },
  { id: 'Lebanon', name: 'LB' },
  { id: 'Lesotho', name: 'LS' },
  { id: 'Liberia', name: 'LR' },
  { id: 'Libyan Arab Jamahiriya', name: 'LY' },
  { id: 'Liechtenstein', name: 'LI' },
  { id: 'Lithuania', name: 'LT' },
  { id: 'Luxembourg', name: 'LU' },
  { id: 'Macao', name: 'MO' },
  { id: 'Macedonia, The Former Yugoslav Republic of', name: 'MK' },
  { id: 'Madagascar', name: 'MG' },
  { id: 'Malawi', name: 'MW' },
  { id: 'Malaysia', name: 'MY' },
  { id: 'Maldives', name: 'MV' },
  { id: 'Mali', name: 'ML' },
  { id: 'Malta', name: 'MT' },
  { id: 'Marshall Islands', name: 'MH' },
  { id: 'Martinique', name: 'MQ' },
  { id: 'Mauritania', name: 'MR' },
  { id: 'Mauritius', name: 'MU' },
  { id: 'Mayotte', name: 'YT' },
  { id: 'Mexico', name: 'MX' },
  { id: 'Micronesia, Federated States of', name: 'FM' },
  { id: 'Moldova, Republic of', name: 'MD' },
  { id: 'Monaco', name: 'MC' },
  { id: 'Mongolia', name: 'MN' },
  { id: 'Montserrat', name: 'MS' },
  { id: 'Morocco', name: 'MA' },
  { id: 'Mozambique', name: 'MZ' },
  { id: 'Myanmar', name: 'MM' },
  { id: 'Namibia', name: 'NA' },
  { id: 'Nauru', name: 'NR' },
  { id: 'Nepal', name: 'NP' },
  { id: 'Netherlands', name: 'NL' },
  { id: 'Netherlands Antilles', name: 'AN' },
  { id: 'New Caledonia', name: 'NC' },
  { id: 'New Zealand', name: 'NZ' },
  { id: 'Nicaragua', name: 'NI' },
  { id: 'Niger', name: 'NE' },
  { id: 'Nigeria', name: 'NG' },
  { id: 'Niue', name: 'NU' },
  { id: 'Norfolk Island', name: 'NF' },
  { id: 'Northern Mariana Islands', name: 'MP' },
  { id: 'Norway', name: 'NO' },
  { id: 'Oman', name: 'OM' },
  { id: 'Pakistan', name: 'PK' },
  { id: 'Palau', name: 'PW' },
  { id: 'Palestinian Territory, Occupied', name: 'PS' },
  { id: 'Panama', name: 'PA' },
  { id: 'Papua New Guinea', name: 'PG' },
  { id: 'Paraguay', name: 'PY' },
  { id: 'Peru', name: 'PE' },
  { id: 'Philippines', name: 'PH' },
  { id: 'Pitcairn', name: 'PN' },
  { id: 'Poland', name: 'PL' },
  { id: 'Portugal', name: 'PT' },
  { id: 'Puerto Rico', name: 'PR' },
  { id: 'Qatar', name: 'QA' },
  { id: 'Reunion', name: 'RE' },
  { id: 'Romania', name: 'RO' },
  { id: 'Russian Federation', name: 'RU' },
  { id: 'RWANDA', name: 'RW' },
  { id: 'Saint Helena', name: 'SH' },
  { id: 'Saint Kitts and Nevis', name: 'KN' },
  { id: 'Saint Lucia', name: 'LC' },
  { id: 'Saint Pierre and Miquelon', name: 'PM' },
  { id: 'Saint Vincent and the Grenadines', name: 'VC' },
  { id: 'Samoa', name: 'WS' },
  { id: 'San Marino', name: 'SM' },
  { id: 'Sao Tome and Principe', name: 'ST' },
  { id: 'Saudi Arabia', name: 'SA' },
  { id: 'Senegal', name: 'SN' },
  { id: 'Serbia and Montenegro', name: 'CS' },
  { id: 'Seychelles', name: 'SC' },
  { id: 'Sierra Leone', name: 'SL' },
  { id: 'Singapore', name: 'SG' },
  { id: 'Slovakia', name: 'SK' },
  { id: 'Slovenia', name: 'SI' },
  { id: 'Solomon Islands', name: 'SB' },
  { id: 'Somalia', name: 'SO' },
  { id: 'South Africa', name: 'ZA' },
  { id: 'South Georgia and the South Sandwich Islands', name: 'GS' },
  { id: 'Spain', name: 'ES' },
  { id: 'Sri Lanka', name: 'LK' },
  { id: 'Sudan', name: 'SD' },
  { id: 'Suriid', name: 'SR' },
  { id: 'Svalbard and Jan Mayen', name: 'SJ' },
  { id: 'Swaziland', name: 'SZ' },
  { id: 'Sweden', name: 'SE' },
  { id: 'Switzerland', name: 'CH' },
  { id: 'Syrian Arab Republic', name: 'SY' },
  { id: 'Taiwan, Province of China', name: 'TW' },
  { id: 'Tajikistan', name: 'TJ' },
  { id: 'Tanzania, United Republic of', name: 'TZ' },
  { id: 'Thailand', name: 'TH' },
  { id: 'Timor-Leste', name: 'TL' },
  { id: 'Togo', name: 'TG' },
  { id: 'Tokelau', name: 'TK' },
  { id: 'Tonga', name: 'TO' },
  { id: 'Trinnamead and Tobago', name: 'TT' },
  { id: 'Tunisia', name: 'TN' },
  { id: 'Turkey', name: 'TR' },
  { id: 'Turkmenistan', name: 'TM' },
  { id: 'Turks and Caicos Islands', name: 'TC' },
  { id: 'Tuvalu', name: 'TV' },
  { id: 'Uganda', name: 'UG' },
  { id: 'Ukraine', name: 'UA' },
  { id: 'United Arab Emirates', name: 'AE' },
  { id: 'United Kingdom', name: 'GB' },
  { id: 'United States', name: 'US' },
  { id: 'United States Minor Outlying Islands', name: 'UM' },
  { id: 'Uruguay', name: 'UY' },
  { id: 'Uzbekistan', name: 'UZ' },
  { id: 'Vanuatu', name: 'VU' },
  { id: 'Venezuela', name: 'VE' },
  { id: 'Viet Nam', name: 'VN' },
  { id: 'Virgin Islands, British', name: 'VG' },
  { id: 'Virgin Islands, U.S.', name: 'VI' },
  { id: 'Wallis and Futuna', name: 'WF' },
  { id: 'Western Sahara', name: 'EH' },
  { id: 'Yemen', name: 'YE' },
  { id: 'Zambia', name: 'ZM' },
  { id: 'Zimbabwe', name: 'ZW' }
];
