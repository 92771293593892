import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { useIntl } from 'react-intl';

import { Theme } from '../../assets/Theme.style';

import { Select } from './CustomerAssign.style';

function CustomerAssign({
  theme = {
    ...Theme.modal.selectbox
  },
  input = {},
  distributor = '',
  customers
}) {
  const intl = useIntl();

  const options =
    distributor && customers
      ? customers.filter(
          el => el.distributor && el.distributor.id === distributor
        )
      : [];
  return (
    <Select
      defaultValue={
        input && input.value && input.value.length > 0 ? input.value : false
      }
      theme={theme.selectrix}
      placeholder={intl.formatMessage({
        id: 'CUSTOMERS.ASSIGN.CUSTOMER'
      })}
      arrow={false}
      customScrollbar={true}
      searchable={false}
      options={
        options &&
        options.map(el => {
          return {
            ...el,
            key: el.id,
            label: el.name
          };
        })
      }
      onChange={value => {
        input.onChange(value.id);
      }}
    />
  );
}

CustomerAssign.propsTypes = {
  theme: PropTypes.object,
  input: PropTypes.object,
  distributor: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  const customers =
    state.firestore &&
    state.firestore.ordered &&
    state.firestore.ordered.customerAssignCustomers;
  return {
    customers
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props, ownProps) => {
    const listener = [];
    if (props.distributor) {
      const distributorRef =
        ownProps.firestore &&
        ownProps.firestore.collection('distributors').doc(props.distributor);
      listener.push({
        collection: 'customers',
        where: [['distributor', '==', distributorRef]],
        storeAs: 'customerAssignCustomers'
      });
    }
    return listener;
  })
)(CustomerAssign);
