import styled from 'styled-components';

import {
  Box as commonBox,
  Item as commonItem,
  Panel as commonPanel,
  Header as commonHeader,
  Name as commonName,
  Menu as commonMenu,
  Button as commonButton,
  Image as commonImage,
  Title as commonTitle,
  Content as commonContent,
  Details as commonDetails,
  Additional as commonAdditional
} from '../../assets/common/Panel.style';

export const Box = styled.div`
  ${commonBox}
`;

export const Item = styled.div`
  ${commonItem}
`;

export const Itembox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const Panel = styled.div`
  ${commonPanel}

  ${Itembox} &:first-of-type {
    margin-bottom: 15px;
  }

  ${Itembox} &:last-of-type {
    margin-top: 15px;
  }
`;

export const Header = styled.div`
  ${commonHeader}
`;

export const Name = styled.h1`
  ${commonName}
`;

export const Menu = styled.div`
  ${commonMenu}
`;

export const Button = styled.span`
  ${commonButton}
`;

export const ButtonIcon = styled.img`
  ${commonImage}
`;

export const Content = styled.div`
  ${commonContent}
`;

export const Details = styled.div`
  ${commonDetails}
`;

export const Additional = styled.div`
  ${commonAdditional}
`;

export const Title = styled.h2`
  ${commonTitle}

  ${Details} & {
    margin-bottom: 0;
  }
`;
