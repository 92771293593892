import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Icon from '../../assets/images/Modify.svg';

import { Theme } from '../../assets/Theme.style';
import {
  Box,
  Item,
  Panel,
  Header,
  Name,
  Menu,
  Number,
  Button,
  Image,
  Title,
  Content
} from './DeviceDetails.style';

// import StatusInfo from '../StatusInfo/StatusInfo';
import LocationInfo from '../LocationInfo/LocationInfo';
import SoftwareInfo from '../SoftwareInfo/SoftwareInfo';
import FillLevel from '../FillLevel/FillLevel';
import DeviceHistory from '../DeviceHistory/DeviceHistory';
import DeviceUsers from '../DeviceUsers/DeviceUsers';
import DeviceNotes from '../DeviceNotes/DeviceNotes';
import DeviceStatistics from '../DeviceStatistics/DeviceStatistics';

function DeviceDetails({
  theme = {
    ...Theme.content.details,
    modal: {
      ...Theme.modal,
      alert: Theme.alert,
      button: Theme.button
    }
  },
  data = {},
  handleClick = () => {},
  authRole = {},
  addons = {},
  reloadDeviceCommand = () => {}
}) {
  const intl = useIntl();
  const isLocation =
    data.street || data.postalCode || data.city || data.country || data.place
      ? true
      : false;

  const users = data && data.users && Object.values(data.users);

  const fillType =
    data &&
    data.cb &&
    data.cb.fill &&
    data.cb.fill.response &&
    Object.values(data.cb.fill.response).find(e => e.key === 'type');
  const fillData =
    data &&
    data.cb &&
    data.cb.fill &&
    data.cb.fill.response &&
    Object.values(data.cb.fill.response).find(e => e.key === 'data');
  const fillTimeout = data && data.cb && data.cb.fill && data.cb.fill.isTimeout;
  const fillResponseAt = data.cb && data.cb.fill && data.cb.fill.responseAt;

  const softwareType =
    data &&
    data.ir &&
    data.ir.info &&
    data.ir.info.response &&
    Object.values(data.ir.info.response).find(e => e.key === 'type');
  const softwareData =
    data &&
    data.ir &&
    data.ir.info &&
    data.ir.info.response &&
    Object.values(data.ir.info.response).find(e => e.key === 'data');
  const softwareTimeout =
    data && data.ir && data.ir.info && data.ir.info.isTimeout;

  const heartbeat =
    data &&
    data.ir &&
    data.ir.heartbeat &&
    data.ir.heartbeat[data.ir.heartbeat.length - 1] &&
    data.ir.heartbeat[data.ir.heartbeat.length - 1].value.timestamp;

  let history = [];
  if (data.history) {
    if (data.history.info) history = history.concat(data.history.info);
    if (data.history.success) history = history.concat(data.history.success);
    if (data.history.error) history = history.concat(data.history.error);
  }

  let statistics = [];
  if (data.statistics) {
    if (data.statistics.info)
      statistics = statistics.concat(data.statistics.info);
    if (data.statistics.success)
      statistics = statistics.concat(data.statistics.success);
    if (data.statistics.error)
      statistics = statistics.concat(data.statistics.error);
  }

  let notes = [];
  if (data.notes) {
    notes = notes.concat(data.notes);
  }

  return (
    <>
      {data && Object.keys(data).length > 0 && (
        <Box>
          <Item>
            <Panel theme={theme.panel}>
              <Header>
                <Name>{data.name ? data.name : data.serialNo}</Name>
                <Menu>
                  {data.name && <Number>{data.serialNo}</Number>}
                  <Button
                    onClick={handleClick}
                    title={intl.formatMessage({ id: 'DEVICES.EDIT_DEVICE' })}
                  >
                    <Image
                      src={Icon}
                      alt={intl.formatMessage({ id: 'DEVICES.EDIT_DEVICE' })}
                    />
                  </Button>
                </Menu>
              </Header>
              <Content theme={theme.content}>
                {data.distributor
                  ? data.distributorData
                    ? data.distributorData.name
                    : `${intl.formatMessage({
                        id: 'DEVICES.DISTRIBUTOR.ASSESSING_INFORMATION'
                      })}...`
                  : intl.formatMessage({
                      id: 'DEVICES.DISTRIBUTOR.NO_DISTRIBUTOR'
                    })}
                {' > '}
                {data.customer
                  ? data.customerData
                    ? data.customerData.name
                    : `${intl.formatMessage({
                        id: 'DEVICES.CUSTOMER.ASSESSING_INFORMATION'
                      })}...`
                  : intl.formatMessage({ id: 'DEVICES.CUSTOMER.NO_CUSTOMER' })}
              </Content>
            </Panel>
          </Item>
          {/* <Item data-half={isLocation ? true : undefined}>
            <Panel theme={theme.panel}>
              <Title>Status</Title>
              <Content theme={theme.content}>
                <StatusInfo
                  data={{
                    status: 0,
                    title: 'OUT-OF-ORDER',
                    text: 'Error details'
                  }}
                />
              </Content>
            </Panel>
          </Item> */}
          {isLocation && (
            <Item data-half={undefined}>
              <Panel theme={theme.panel}>
                <Title>{intl.formatMessage({ id: 'DEVICES.LOCATION' })}</Title>
                <Content theme={theme.content}>
                  <LocationInfo
                    data={{
                      street: data.street,
                      postalCode: data.postalCode,
                      city: data.city,
                      country: data.country,
                      place: data.place
                    }}
                  />
                </Content>
              </Panel>
            </Item>
          )}

          <Item>
            <Panel theme={theme.panel}>
              <Title>{intl.formatMessage({ id: 'DEVICES.FILL_LEVELS' })}</Title>
              <Content theme={theme.content}>
                <FillLevel
                  theme={theme.fillLevel}
                  status={
                    fillType
                      ? fillType.value === 'success'
                        ? ''
                        : fillType.value === 'error' &&
                          intl.formatMessage({
                            id: 'DEVICES.FILL_LEVELS.SOMETHING_WENT_WRONG'
                          })
                      : fillTimeout
                      ? intl.formatMessage({
                          id: 'DEVICES.FILL_LEVELS.TIMEOUT'
                        })
                      : `${intl.formatMessage({
                          id: 'DEVICES.FILL_LEVELS.ASSESSING_INFORMATION'
                        })}...`
                  }
                  bins={
                    softwareType &&
                    softwareType.value === 'success' &&
                    softwareData &&
                    softwareData.value &&
                    softwareData.value.general &&
                    softwareData.value.general.bins
                  }
                  data={
                    (fillData && fillData.value && fillData.value.levels) || {}
                  }
                  responseAt={fillResponseAt}
                  reloadDeviceCommand={reloadDeviceCommand}
                />
              </Content>
            </Panel>
          </Item>

          <Item>
            <Panel theme={theme.panel}>
              <Title>{intl.formatMessage({ id: 'DEVICES.USERS' })}</Title>
              <Content theme={theme.content}>
                {users ? (
                  users.length > 0 ? (
                    <DeviceUsers serial={data.serialNo} data={users} />
                  ) : (
                    intl.formatMessage({ id: 'DEVICES.USERS.NO_DATA' })
                  )
                ) : (
                  `${intl.formatMessage({
                    id: 'DEVICES.USERS.ASSESSING_INFORMATION'
                  })}...`
                )}
              </Content>
            </Panel>
          </Item>

          <Item>
            <Panel theme={theme.panel}>
              <Title>
                {intl.formatMessage({
                  id: 'DEVICES.DEVICES_DETAILS'
                })}{' '}
                ({' '}
                {intl.formatMessage({
                  id: 'DEVICES.DEVICES_DETAILS.SERVICE_PART'
                })}
                )
              </Title>
              <Content theme={theme.content}>
                <SoftwareInfo
                  data={{
                    softwareVersion: softwareType
                      ? softwareType.value === 'success'
                        ? softwareData &&
                          softwareData.value &&
                          softwareData.value.general.softwareVersion
                        : softwareType.value === 'error' &&
                          intl.formatMessage({
                            id: 'DEVICES.DEVICES_DETAILS.SOMETHING_WENT_WRONG'
                          })
                      : softwareTimeout
                      ? intl.formatMessage({
                          id: 'DEVICES.DEVICES_DETAILS.TIMEOUT'
                        })
                      : `${intl.formatMessage({
                          id: 'DEVICES.DEVICES_DETAILS.ASSESSING_INFORMATION'
                        })}...`,
                    deviceVersion: softwareType
                      ? softwareType.value === 'success'
                        ? softwareData &&
                          softwareData.value &&
                          softwareData.value.general.deviceVersion
                        : softwareType.value === 'error' &&
                          intl.formatMessage({
                            id: 'DEVICES.DEVICES_DETAILS.SOMETHING_WENT_WRONG'
                          })
                      : softwareTimeout
                      ? intl.formatMessage({
                          id: 'DEVICES.DEVICES_DETAILS.TIMEOUT'
                        })
                      : `${intl.formatMessage({
                          id: 'DEVICES.DEVICES_DETAILS.ASSESSING_INFORMATION'
                        })}...`,
                    neuralVersion: softwareType
                      ? softwareType.value === 'success'
                        ? softwareData &&
                          softwareData.value &&
                          softwareData.value.IR.neuralNetworks.classnamesFile
                        : softwareType.value === 'error' &&
                          intl.formatMessage({
                            id: 'DEVICES.DEVICES_DETAILS.SOMETHING_WENT_WRONG'
                          })
                      : softwareTimeout
                      ? intl.formatMessage({
                          id: 'DEVICES.DEVICES_DETAILS.TIMEOUT'
                        })
                      : `${intl.formatMessage({
                          id: 'DEVICES.DEVICES_DETAILS.ASSESSING_INFORMATION'
                        })}...`,
                    internetConnectType: softwareType
                      ? softwareType.value === 'success'
                        ? softwareData &&
                          softwareData.value &&
                          softwareData.value.general.internetConnectionType
                        : softwareType.value === 'error' &&
                          intl.formatMessage({
                            id: 'DEVICES.DEVICES_DETAILS.SOMETHING_WENT_WRONG'
                          })
                      : softwareTimeout
                      ? intl.formatMessage({
                          id: 'DEVICES.DEVICES_DETAILS.TIMEOUT'
                        })
                      : `${intl.formatMessage({
                          id: 'DEVICES.DEVICES_DETAILS.ASSESSING_INFORMATION'
                        })}...`,
                    configurationFile: softwareType
                      ? softwareType.value === 'success'
                        ? softwareData && softwareData.value
                        : softwareType.value === 'error' &&
                          intl.formatMessage({
                            id: 'DEVICES.DEVICES_DETAILS.SOMETHING_WENT_WRONG'
                          })
                      : softwareTimeout
                      ? intl.formatMessage({
                          id: 'DEVICES.DEVICES_DETAILS.TIMEOUT'
                        })
                      : `${intl.formatMessage({
                          id: 'DEVICES.DEVICES_DETAILS.ASSESSING_INFORMATION'
                        })}...`,
                    heartbeat: heartbeat
                  }}
                  displayNaturalNetworkVersion={
                    authRole &&
                    authRole.privileges &&
                    authRole.privileges['VIEW_DEVICE_NATURAL_NETWORK_VERSION']
                  }
                  displayHeartbeat={
                    authRole &&
                    authRole.privileges &&
                    authRole.privileges['VIEW_DEVICE_HEARTBEAT']
                  }
                  displayConfigureFile={
                    authRole &&
                    authRole.privileges &&
                    authRole.privileges['VIEW_DEVICE_CONFIGURE_FILE']
                  }
                />
              </Content>
            </Panel>
          </Item>

          {authRole &&
            authRole.privileges &&
            authRole.privileges['VIEW_DEVICE_HISTORY'] && (
              <Item>
                <Panel theme={theme.panel}>
                  <Title>
                    {intl.formatMessage({
                      id: 'DEVICES.HISTORY'
                    })}
                  </Title>
                  <Content theme={theme.content}>
                    <DeviceHistory
                      serial={data.serialNo}
                      data={history}
                      allowedDetails={
                        authRole.privileges['VIEW_DEVICE_HISTORY_DETAILS']
                      }
                    />
                  </Content>
                </Panel>
              </Item>
            )}

          {authRole &&
            authRole.privileges &&
            authRole.privileges['VIEW_DEVICE_NOTES'] && (
              <Item>
                <Panel theme={theme.panel}>
                  <Title>
                    {intl.formatMessage({
                      id: 'DEVICES.NOTES'
                    })}
                  </Title>
                  <Content theme={theme.content}>
                    <DeviceNotes serial={data.serialNo} data={notes} />
                  </Content>
                </Panel>
              </Item>
            )}

          {addons && addons.statistics && (
            <Item>
              <Panel theme={theme.panel}>
                <Title>
                  {intl.formatMessage({
                    id: 'DEVICES.STATISTICS'
                  })}
                </Title>
                <Content theme={theme.content}>
                  <DeviceStatistics serial={data.serialNo} data={statistics} />
                </Content>
              </Panel>
            </Item>
          )}
        </Box>
      )}
    </>
  );
}

DeviceDetails.propTypes = {
  theme: PropTypes.object,
  data: PropTypes.object,
  handleClick: PropTypes.func,
  authRole: PropTypes.object
};

export default memo(DeviceDetails);
