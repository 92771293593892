import styled from 'styled-components';

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 48px;
  margin-bottom: 6px;
  padding: 8px 13px;
  border-radius: 8px;
  transition: 0.2s background, 0.2s color;
  background: ${props => props.theme.background};
  color: ${props => props.theme.color};
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.backgroundOnHover};
    color: ${props => props.theme.colorOnHover};
  }

  &[data-active] {
    background: ${props => props.theme.backgroundOnActive};
    color: ${props => props.theme.colorOnActive};
  }
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
`;

export const Content = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
`;
