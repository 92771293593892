//TODO: NEED REFACTOR

import { applyMiddleware, createStore, compose } from 'redux';
import { logger } from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import { getFirebase, reactReduxFirebase } from 'react-redux-firebase';
import { getFirestore, reduxFirestore } from 'redux-firestore';
import firebase from './firebase';

export const middleware =
  process.env.NODE_ENV === 'production'
    ? compose(
        applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
        reduxFirestore(firebase),
        reactReduxFirebase(firebase, {
          useFirestoreForProfile: true,
          userProfile: 'users',
          attachAuthIsReady: true,
          enableLogging: false,
        })
      )
    : composeWithDevTools(
        compose(
          applyMiddleware(
            thunk.withExtraArgument({ getFirebase, getFirestore }),
            logger
          ),
          reduxFirestore(firebase),
          reactReduxFirebase(firebase, {
            useFirestoreForProfile: true,
            userProfile: 'users',
            attachAuthIsReady: true,
            enableLogging: true,
          })
        )
      );

export default createStore(rootReducer, middleware);
