/*
// ACTION SEND REPORT INIT
// Init send report to administration
*/
export const sendReportInit = () => {
  return dispatch => {
    dispatch({ type: 'REPORT_SEND_INT' });
  };
};

/*
// ACTION SEND REPORT
// Send report to administration
*/
export const sendReport = data => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'REPORT_SEND_PENDING' });
    const firebase = getFirebase();
    const sendReport = firebase.functions().httpsCallable('sendReport');
    return await sendReport({
      user: {
        uid: data.profile.uid,
        email: data.profile.email,
        displayName: data.profile.displayName
      },
      details: data.details,
      sendToMe: data.copy
    })
      .then(() => dispatch({ type: 'REPORT_SEND_SUCCESS' }))
      .catch(err => dispatch({ type: 'REPORT_SEND_ERROR', err }));
  };
};
