import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Theme } from '../../assets/Theme.style';

import { Select } from './CountryAssign.style';

import { countries } from '../../utils/countries';

function CountryAssign({
  theme = {
    ...Theme.modal.selectbox
  },
  input = {}
}) {
  const intl = useIntl();

  return (
    <Select
      defaultValue={(input && input.value) || false}
      theme={theme.selectrix}
      placeholder={intl.formatMessage({
        id: 'COUNTRY.ASSIGN.COUNTRY'
      })}
      arrow={false}
      customScrollbar={true}
      searchable={false}
      options={
        countries &&
        countries.map(country => {
          return {
            ...country,
            key: country.id,
            label: intl.formatMessage({
              id: `COUNTRY.COUNTRY.${country.name}`,
              defaultMessage: country.name
            })
          };
        })
      }
      onChange={value => {
        input.onChange(value.id);
        input.onBlur();
      }}
    />
  );
}

CountryAssign.propsTypes = {
  theme: PropTypes.object,
  input: PropTypes.object
};

export default CountryAssign;
