const timezones = [
  {
    id: '-12:00',
    name: '(GMT -12:00) Eniwetok, Kwajalein'
  },
  {
    id: '-11:00',
    name: '(GMT -11:00) Midway Island, Samoa'
  },
  {
    id: '-10:00',
    name: '(GMT -10:00) Hawaii'
  },
  {
    id: '-09:30',
    name: '(GMT -9:30) Taiohae'
  },
  {
    id: '-9:00',
    name: '(GMT -9:00) Alaska'
  },
  {
    id: '-08:00',
    name: '(GMT -8:00) Pacific Time (US &amp; Canada)'
  },
  {
    id: '-07:00',
    name: '(GMT -7:00) Mountain Time (US &amp; Canada)'
  },
  {
    id: '-06:00',
    name: '(GMT -6:00) Central Time (US &amp; Canada), Mexico City'
  },
  {
    id: '-05:00',
    name: '(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima'
  },
  {
    id: '-04:30',
    name: '(GMT -4:30) Caracas'
  },
  {
    id: '-04:00',
    name: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz'
  },
  {
    id: '-03:30',
    name: '(GMT -3:30) Newfoundland'
  },
  {
    id: '-03:00',
    name: '(GMT -3:00) Brazil, Buenos Aires, Georgetown'
  },
  {
    id: '-02:00',
    name: '(GMT -2:00) Mid-Atlanti'
  },
  {
    id: '-01:00',
    name: '(GMT -1:00) Azores, Cape Verde Islands'
  },
  {
    id: '+00:00',
    name: '(GMT) Western Europe Time, London, Lisbon, Casablanca'
  },
  {
    id: '+01:00',
    name: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris'
  },
  {
    id: '+02:00',
    name: '(GMT +2:00) Kaliningrad, South Africa'
  },
  {
    id: '+03:00',
    name: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg'
  },
  {
    id: '+03:30',
    name: '(GMT +3:30) Tehran'
  },
  {
    id: '+04:00',
    name: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi'
  },
  {
    id: '+04:30',
    name: '(GMT +4:30) Kabul'
  },
  {
    id: '+05:00',
    name: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent'
  },
  {
    id: '+05:30',
    name: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi'
  },
  {
    id: '+05:45',
    name: '(GMT +5:45) Kathmandu, Pokhara'
  },
  {
    id: '+06:00',
    name: '(GMT +6:00) Almaty, Dhaka, Colombo'
  },
  {
    id: '+06:30',
    name: '(GMT +6:30) Yangon, Mandalay'
  },
  {
    id: '+07:00',
    name: '(GMT +7:00) Bangkok, Hanoi, Jakarta'
  },
  {
    id: '+08:00',
    name: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong'
  },
  {
    id: '+08:45',
    name: '(GMT +8:45) Eucla'
  },
  {
    id: '+09:00',
    name: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk'
  },
  {
    id: '+09:30',
    name: '(GMT +9:30) Adelaide, Darwin'
  },
  {
    id: '+10:00',
    name: '(GMT +10:00) Eastern Australia, Guam, Vladivostok'
  },
  {
    id: '+10:30',
    name: '(GMT +10:30) Lord Howe Island'
  },
  {
    id: '+11:00',
    name: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia'
  },
  {
    id: '+11:30',
    name: '(GMT +11:30) Norfolk Island'
  },
  {
    id: '+12:00',
    name: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka'
  },
  {
    id: '+12:45',
    name: '(GMT +12:45) Chatham Islands'
  },
  {
    id: '+13:00',
    name: '(GMT +13:00) Apia, Nukualofa'
  },
  {
    id: '+14:00',
    name: '(GMT +14:00) Line Islands, Tokelau'
  }
];

export default timezones;
