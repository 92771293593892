import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';

import Image from 'react-image-autofit-frame';
import Placeholder from '../../assets/images/SignInBackground.jpg';

import { withTheme } from '../../assets/Theme.style';
import {
  Section,
  Wrapper,
  Content,
  Imagebox,
  Footer
} from './WelcomePage.style';

import {
  setUserPasswordInit,
  setUserPassword
} from '../../actions/User/UserActions';
import WelcomePanel from '../../components/WelcomePanel/WelcomePanel';

class WelcomePage extends Component {
  state = {
    code: null,
    redirectToSignIn: false
  };

  componentDidMount() {
    this.props.setUserPasswordInit();
    this.checkCode();
  }

  checkCode = () => {
    const params = queryString.parse(this.props.location.search);
    if (Object.keys(params).length > 0 && params.oobCode) {
      this.setState({ code: params.oobCode });
    } else {
      this.setState({ redirectToSignIn: true });
    }
  };

  handleSubmit = values => {
    this.props.setUserPassword(this.state.code, values.password);
  };

  render() {
    const { redirectToSignIn } = this.state;
    const { firebaseStore, userSetPasswordStore } = this.props;
    const theme = this.props.theme;

    return (
      <>
        <Helmet>
          <title>Welcome | BIN-E Smart Waste Bin</title>
        </Helmet>
        {!firebaseStore.auth.isEmpty ? (
          <Redirect to={{ pathname: '/' }} />
        ) : redirectToSignIn ? (
          <Redirect to={{ pathname: '/sign-in' }} />
        ) : (
          <Section>
            <Wrapper theme={theme.sign.wrapper.default}>
              <Content>
                <WelcomePanel
                  onSubmit={this.handleSubmit}
                  store={userSetPasswordStore}
                />
              </Content>
              <Footer theme={theme.sign.footer.default}>
                By signing in, you accept{' '}
                <a
                  href='http://www.bine.world'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Privacy policy
                </a>{' '}
                <br />
                (including Use of Cookies and Other
                <br /> Technologies) and{' '}
                <a
                  href='http://www.bine.world'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Terms of use
                </a>
              </Footer>
            </Wrapper>
            <Wrapper hideMedium theme={theme.sign.wrapper.alternative}>
              <Imagebox>
                <Image imgSrc={Placeholder} />
              </Imagebox>
            </Wrapper>
          </Section>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    firebaseStore: state.firebase,
    userSetPasswordStore: state.userSetPasswordStore
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setUserPasswordInit: () => dispatch(setUserPasswordInit()),
    setUserPassword: (code, password) =>
      dispatch(setUserPassword(code, password))
  };
};

export default compose(
  withTheme,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(WelcomePage);
