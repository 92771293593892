import styled from 'styled-components';

export const Label = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 48px;
  margin-bottom: 6px;
  padding: 8px 10px;
  border-radius: 8px;
  transition: 0.2s background, 0.2s color;
  background: ${props => props.theme.background};
  color: ${props => props.theme.color};
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.backgroundOnHover};
    color: ${props => props.theme.colorOnHover};
  }

  &[data-active] {
    background: ${props => props.theme.backgroundOnActive};
    color: ${props => props.theme.colorOnActive};
  }

  &[data-out-of-order] {
    background: ${props => props.theme.backgroundOutOfOrder};
    color: ${props => props.theme.colorOutOfOrder};

    &:hover {
      background: ${props => props.theme.backgroundOutOfOrderOnHover};
      color: ${props => props.theme.colorOutOfOrderOnHover};
    }

    &[data-active] {
      background: ${props => props.theme.backgroundOutOfOrderOnActive};
      color: ${props => props.theme.colorOutOfOrderOnActive};
    }
  }

  &[data-maintenance] {
    background: ${props => props.theme.backgroundMaintenance};
    color: ${props => props.theme.colorMaintenance};

    &:hover {
      background: ${props => props.theme.backgroundMaintenanceOnHover};
      color: ${props => props.theme.colorMaintenanceOnHover};
    }

    &[data-active] {
      background: ${props => props.theme.backgroundMaintenanceOnActive};
      color: ${props => props.theme.colorMaintenanceOnActive};
    }
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  margin: 0;

  svg {
    [data-name='background'] {
      transition: 0.2s fill;
      fill: ${props => props.theme.background};

      ${Label}:hover & {
        fill: ${props => props.theme.backgroundOnHover};
      }

      ${`${Label}[data-active]`} & {
        fill: ${props => props.theme.backgroundOnActive};
      }

      ${`${Label}[data-maintenance]`} & {
        fill: ${props => props.theme.backgroundMaintenance};
      }

      ${`${Label}[data-maintenance]`}:hover & {
        fill: ${props => props.theme.backgroundMaintenanceOnHover};
      }

      ${`${Label}[data-maintenance][data-active]`} & {
        fill: ${props => props.theme.backgroundMaintenanceOnActive};
      }

      ${`${Label}[data-out-of-order]`} & {
        fill: ${props => props.theme.backgroundOutOfOrder};
      }

      ${`${Label}[data-out-of-order]`}:hover & {
        fill: ${props => props.theme.backgroundOutOfOrderOnHover};
      }

      ${`${Label}[data-out-of-order][data-active]`} & {
        fill: ${props => props.theme.backgroundOutOfOrderOnActive};
      }
    }

    [data-name='icon'] {
      transition: 0.2s fill;
      fill: ${props => props.theme.icon};

      ${Label}:hover & {
        fill: ${props => props.theme.iconOnHover};
      }

      ${`${Label}[data-active]`} & {
        fill: ${props => props.theme.iconOnActive};
      }

      ${`${Label}[data-maintenance]`} & {
        fill: ${props => props.theme.iconMaintenance};
      }

      ${`${Label}[data-maintenance]`}:hover & {
        fill: ${props => props.theme.iconMaintenanceOnHover};
      }

      ${`${Label}[data-maintenance][data-active]`} & {
        fill: ${props => props.theme.iconMaintenanceOnActive};
      }

      ${`${Label}[data-out-of-order]`} & {
        fill: ${props => props.theme.iconOutOfOrder};
      }

      ${`${Label}[data-out-of-order]`}:hover & {
        fill: ${props => props.theme.iconOutOfOrderOnHover};
      }

      ${`${Label}[data-out-of-order][data-active]`} & {
        fill: ${props => props.theme.iconOutOfOrderOnActive};
      }
    }
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 13px;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
`;

export const Content = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
`;

export const Connection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin: 0;
  border-radius: 100%;
  background: ${props => props.theme.background};

  svg {
    [data-name='icon'] {
      transition: 0.2s fill;
      fill: ${props => props.theme.fill};

      ${Label}:hover & {
        fill: ${props => props.theme.fillOnHover};
      }

      ${`${Label}[data-active]`} & {
        fill: ${props => props.theme.fillOnActive};
      }

      ${`${Label}[data-connected]`} & {
        fill: ${props => props.theme.fillConnect};
      }

      ${`${Label}[data-connected]`}:hover & {
        fill: ${props => props.theme.fillConnectOnHover};
      }

      ${`${Label}[data-connected][data-active]`} & {
        fill: ${props => props.theme.fillConnectOnActive};
      }
    }
  }
`;
