import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Theme } from '../../assets/Theme.style';
import { Label, Title, Content } from './DistributorsItem.style';

import { countries } from '../../utils/countries';

function DistributorsItem({
  theme = Theme.content.item,
  data = {},
  isActive = false
}) {
  const intl = useIntl();
  return (
    <>
      {Object.values(data).length > 0 && (
        <Label theme={theme.label} data-active={isActive ? true : undefined}>
          <Title>{data.name}</Title>
          <Content>
            {intl.formatMessage({
              id: `COUNTRY.COUNTRY.${(() => {
                const country = countries.find(
                  country => country.id === data.country
                );
                return country && country.name;
              })()}`,
              defaultMessage: data.country
            })}
          </Content>
        </Label>
      )}
    </>
  );
}

DistributorsItem.propTypes = {
  theme: PropTypes.object,
  data: PropTypes.object,
  isActive: PropTypes.bool
};

export default memo(DistributorsItem);
