import React from 'react';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import SuccessIcon from '../../assets/images/Success.svg';
import ErrorIcon from '../../assets/images/Error.svg';
import CloseIcon from '../../assets/images/Close.svg';

import { Theme } from '../../assets/Theme.style';

import {
  Wrapper,
  Holder,
  Modal,
  Close,
  Image,
  Title,
  Content,
  Subtitle,
  Box,
  Inputbox,
  Selectbox,
  Navigation,
  Button,
  Alertbox,
  Alert,
  AlertImage,
  AlertContent,
  AlertTitle
} from './DeviceManage.style';

import CountryAssign from '../CountryAssign/CountryAssign';
import DistributorAssign from '../DistributorAssign/DistributorAssign';
import CustomerAssign from '../CustomerAssign/CustomerAssign';
import DeviceUsersAssign from '../DeviceUsersAssign/DeviceUsersAssign';
import DeviceUsersAssigned from '../DeviceUsersAssigned/DeviceUsersAssigned';

function DeviceManage({
  theme = {
    ...Theme.modal,
    alert: Theme.alert,
    button: Theme.button
  },
  store = {},
  onSubmit = () => {},
  handleClose = () => {},
  open = false,
  authRole = {}
}) {
  const intl = useIntl();
  const WhenFieldChanges = ({ field, becomes, set, to }) => (
    <Field name={set} subscription={{}}>
      {({ input: { onChange } }) => (
        <OnChange name={field}>
          {() => {
            if (becomes) {
              onChange(to);
            }
          }}
        </OnChange>
      )}
    </Field>
  );

  const validate = values => {
    const errors = {};

    if (!values.name) errors.name = 'DEVICES.MANAGE.VALIDATION.REQUIRED';
    if (!values.street) errors.street = 'DEVICES.MANAGE.VALIDATION.REQUIRED';
    if (!values.city) errors.city = 'DEVICES.MANAGE.VALIDATION.REQUIRED';
    if (!values.postalCode)
      errors.postalCode = 'DEVICES.MANAGE.VALIDATION.REQUIRED';
    if (!values.country) errors.country = 'DEVICES.MANAGE.VALIDATION.REQUIRED';

    return errors;
  };

  return (
    <Wrapper>
      <Holder
        theme={theme.holder}
        data-open={open ? true : undefined}
        data-close={!open ? true : undefined}
      >
        <Modal
          theme={theme.content.default}
          data-open={open ? true : undefined}
          data-close={!open ? true : undefined}
        >
          <Close
            onClick={handleClose}
            title={intl.formatMessage({ id: 'DEVICES.MANAGE.MENU.CLOSE' })}
          >
            <Image
              src={CloseIcon}
              alt={intl.formatMessage({ id: 'DEVICES.MANAGE.MENU.CLOSE' })}
            />
          </Close>
          <Title>
            {store.device && store.device.id
              ? intl.formatMessage({ id: 'DEVICES.MANAGE.EDIT_DEVICE' })
              : intl.formatMessage({ id: 'DEVICES.MANAGE.ADD_NEW_DEVICE' })}
          </Title>
          <Content>
            <Form
              onSubmit={onSubmit}
              validate={validate}
              initialValues={{
                name: store.device && store.device.name,
                street: store.device && store.device.street,
                city: store.device && store.device.city,
                postalCode: store.device && store.device.postalCode,
                country: store.device && store.device.country,
                place: store.device && store.device.place,
                distributor:
                  store.device &&
                  store.device.distributor &&
                  store.device.distributor.id,
                customer:
                  store.device &&
                  store.device.customer &&
                  store.device.customer.id,
                users: store.deviceUsers && store.deviceUsers.map(e => e.id)
              }}
              mutators={{}}
              keepDirtyOnReinitialize={true}
              render={({
                handleSubmit,
                submitting,
                pristine,
                values,
                invalid
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box>
                    <Field name='name'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'DEVICES.MANAGE.DEVICE_NAME'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.inputbox.default}
                        >
                          <input
                            {...input}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'DEVICES.MANAGE.DEVICE_NAME'
                            })}
                            autoComplete='off'
                            data-big={true}
                            data-invalid={
                              meta.touched && meta.error ? true : undefined
                            }
                            data-valid={
                              meta.touched && !meta.error ? true : undefined
                            }
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                  </Box>
                  <Box>
                    <Subtitle>
                      {intl.formatMessage({
                        id: 'DEVICES.MANAGE.LOCATION'
                      })}
                    </Subtitle>
                    <Field name='street'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'DEVICES.MANAGE.LOCATION.STREET'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.inputbox.default}
                        >
                          <input
                            {...input}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'DEVICES.MANAGE.LOCATION.STREET'
                            })}
                            autoComplete='off'
                            data-invalid={
                              meta.touched && meta.error ? true : undefined
                            }
                            data-valid={
                              meta.touched && !meta.error ? true : undefined
                            }
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                    <Field name='city'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'DEVICES.MANAGE.LOCATION.CITY'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.inputbox.default}
                        >
                          <input
                            {...input}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'DEVICES.MANAGE.LOCATION.CITY'
                            })}
                            autoComplete='off'
                            data-invalid={
                              meta.touched && meta.error ? true : undefined
                            }
                            data-valid={
                              meta.touched && !meta.error ? true : undefined
                            }
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                    <Field name='postalCode'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'DEVICES.MANAGE.LOCATION.POSTAL_CODE'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.inputbox.default}
                        >
                          <input
                            {...input}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'DEVICES.MANAGE.LOCATION.POSTAL_CODE'
                            })}
                            autoComplete='off'
                            data-invalid={
                              meta.touched && meta.error ? true : undefined
                            }
                            data-valid={
                              meta.touched && !meta.error ? true : undefined
                            }
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                    <Field name='country'>
                      {({ input, meta }) => (
                        <Selectbox
                          data-name={intl.formatMessage({
                            id: 'DEVICES.MANAGE.LOCATION.COUNTRY'
                          })}
                          data-filled={
                            input && input.value && input.value.length > 0
                              ? true
                              : undefined
                          }
                          theme={theme.selectbox.default}
                        >
                          <CountryAssign input={input} />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Selectbox>
                      )}
                    </Field>
                    <Field name='place'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'DEVICES.MANAGE.LOCATION.PLACE'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.inputbox.default}
                        >
                          <input
                            {...input}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'DEVICES.MANAGE.LOCATION.PLACE'
                            })}
                            autoComplete='off'
                            data-valid={
                              meta.touched && !meta.error ? true : undefined
                            }
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                  </Box>
                  {authRole &&
                    authRole.privileges &&
                    (authRole.privileges['MANAGE_DEVICE_DISTRIBUTOR'] ||
                      authRole.privileges['MANAGE_DEVICE_CUSTOMER']) && (
                      <Box>
                        <Subtitle>
                          {intl.formatMessage({
                            id: 'DEVICES.MANAGE.ALLOCATION'
                          })}
                        </Subtitle>
                        {authRole &&
                          authRole.privileges &&
                          authRole.privileges['MANAGE_DEVICE_DISTRIBUTOR'] && (
                            <>
                              <Field name='distributor'>
                                {({ input, meta }) => (
                                  <Selectbox
                                    data-name={intl.formatMessage({
                                      id:
                                        'DEVICES.MANAGE.ALLOCATION.DISTRIBUTOR'
                                    })}
                                    data-filled={
                                      input &&
                                      input.value &&
                                      input.value.length > 0
                                        ? true
                                        : undefined
                                    }
                                    theme={theme.selectbox.default}
                                  >
                                    <DistributorAssign input={input} />
                                    <span />
                                  </Selectbox>
                                )}
                              </Field>
                              <WhenFieldChanges
                                field='distributor'
                                becomes={values.currency !== null}
                                set='customer'
                                to={''}
                              />
                            </>
                          )}
                        {authRole &&
                          authRole.privileges &&
                          authRole.privileges['MANAGE_DEVICE_CUSTOMER'] && (
                            <Field name='customer'>
                              {({ input, meta }) => (
                                <Selectbox
                                  data-name={intl.formatMessage({
                                    id: 'DEVICES.MANAGE.ALLOCATION.CUSTOMER'
                                  })}
                                  data-filled={
                                    input &&
                                    input.value &&
                                    input.value.length > 0
                                      ? true
                                      : undefined
                                  }
                                  theme={theme.selectbox.default}
                                >
                                  <CustomerAssign
                                    input={input}
                                    distributor={values.distributor}
                                  />
                                  <span />
                                </Selectbox>
                              )}
                            </Field>
                          )}
                      </Box>
                    )}
                  <Box>
                    <Subtitle>
                      {intl.formatMessage({
                        id: 'DEVICES.MANAGE.USERS'
                      })}
                    </Subtitle>
                    {authRole &&
                    authRole.privileges &&
                    authRole.privileges['MANAGE_DEVICE_USERS'] ? (
                      <Field name='users'>
                        {({ input, meta }) => (
                          <DeviceUsersAssign
                            input={input}
                            distributor={values.distributor}
                            customer={values.customer}
                          />
                        )}
                      </Field>
                    ) : (
                      <DeviceUsersAssigned
                        assignedUsers={values.users}
                        distributor={values.distributor}
                        customer={values.customer}
                      />
                    )}
                  </Box>

                  {store.isError ? (
                    <Alertbox>
                      <Alert>
                        <AlertImage
                          src={ErrorIcon}
                          alt={intl.formatMessage({
                            id: 'DEVICES.MANAGE.ALERT.SOMETHING_WENT_WRONG'
                          })}
                        />
                        <AlertContent>
                          <AlertTitle theme={theme.alert.error.title}>
                            {intl.formatMessage({
                              id: 'DEVICES.MANAGE.ALERT.SOMETHING_WENT_WRONG'
                            })}
                          </AlertTitle>
                          {store.error}
                        </AlertContent>
                      </Alert>
                    </Alertbox>
                  ) : (
                    store.isSuccess && (
                      <Alertbox>
                        <Alert>
                          <AlertImage
                            src={SuccessIcon}
                            alt={intl.formatMessage({
                              id: 'DEVICES.MANAGE.ALERT.SUCCESS'
                            })}
                          />
                          <AlertContent>
                            <AlertTitle theme={theme.alert.success.title}>
                              {intl.formatMessage({
                                id: 'DEVICES.MANAGE.ALERT.SUCCESS'
                              })}
                            </AlertTitle>
                            {store.device && store.device.id
                              ? intl.formatMessage({
                                  id: 'DEVICES.MANAGE.ALERT.DEVICE_UPDATE'
                                })
                              : intl.formatMessage({
                                  id: 'DEVICES.MANAGE.ALERT.DEVICE_CRATED'
                                })}
                          </AlertContent>
                        </Alert>
                      </Alertbox>
                    )
                  )}

                  <Navigation>
                    <Button
                      theme={theme.button.default}
                      disabled={
                        submitting ||
                        pristine ||
                        invalid ||
                        (store && store.isLoading)
                      }
                    >
                      {store && store.isLoading
                        ? intl.formatMessage({
                            id: 'DEVICES.MANAGE.MENU.PLEASE_WAIT'
                          })
                        : store.device && store.device.id
                        ? intl.formatMessage({
                            id: 'DEVICES.MANAGE.MENU.EDIT_DEVICE'
                          })
                        : intl.formatMessage({
                            id: 'DEVICES.MANAGE.MENU.ADD_DEVICE'
                          })}
                    </Button>
                    <Button
                      type='button'
                      theme={theme.button.alternative}
                      onClick={handleClose}
                    >
                      {intl.formatMessage({
                        id: 'DEVICES.MANAGE.MENU.CANCEL'
                      })}
                    </Button>
                  </Navigation>
                </form>
              )}
            />
          </Content>
        </Modal>
      </Holder>
    </Wrapper>
  );
}

DeviceManage.propsTypes = {
  theme: PropTypes.object,
  store: PropTypes.object,
  onSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  authRole: PropTypes.object
};

export default DeviceManage;
