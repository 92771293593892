import styled from 'styled-components';

export const Label = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
  width: 100%;
  margin-bottom: 18px;
  padding: 8px 13px;
  border-radius: 8px;
  transition: 0.2s background, 0.2s color;
  background: ${props => props.theme.background};
  color: ${props => props.theme.color};
  cursor: pointer;
`;

export const Image = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0;
  flex-shrink: 0;
`;

export const Content = styled.div`
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
  padding-left: 20px;
`;
