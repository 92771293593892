import moment from 'moment';

/*
// ACTION MANAGE USER INIT
*/
export const manageUserInit = (data) => {
  return (dispatch) => {
    dispatch({ type: 'USER_MANAGE_INIT', data });
  };
};

/*
// ACTION ADD USER
// Create user and send profile avatar if exist photo data
*/
export const addUser = (data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'USER_MANAGE_PENDING' });
    const firebase = getFirebase();
    const firestore = getFirestore();
    const addUser = firebase.functions().httpsCallable('addUser');
    await addUser({
      ...data.user,
    })
      .then(async (res) => {
        const currentUser =
          res && res.data && res.data.object && res.data.object;

        if (currentUser && currentUser.uid) {
          if (data.photoData) {
            const photoStorage = firebase
              .storage()
              .ref('Users/' + currentUser.uid + '/photo.jpg');
            await photoStorage.put(data.photoData).then(async (snapshot) => {
              await snapshot.ref
                .getDownloadURL()
                .then(async (url) => {
                  await firestore
                    .collection('users')
                    .doc(currentUser.uid)
                    .update({
                      photoURL: url,
                      updatedAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                    })
                    .catch((err) => {
                      throw err;
                    });
                })
                .catch((err) => {
                  throw err;
                });
            });
          }
          dispatch({ type: 'USER_MANAGE_SUCCESS' });
        } else {
          throw res.data;
        }
      })
      .catch((err) => dispatch({ type: 'USER_MANAGE_ERROR', err }));
  };
};

/*
// ACTION UPDATE USER
// Update user and send profile avatar if exist photo data
*/
export const updateUser = (id, data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'USER_MANAGE_PENDING' });
    const firebase = getFirebase();
    const firestore = getFirestore();
    const updateUser = firebase.functions().httpsCallable('updateUser');

    await updateUser({
      user: id,
      ...data.user,
    })
      .then(async (res) => {
        const currentUser =
          res && res.data && res.data.object && res.data.object;

        if (currentUser && currentUser.uid) {
          if (data.photoData) {
            const photoStorage = firebase
              .storage()
              .ref('Users/' + currentUser.uid + '/photo.jpg');
            await photoStorage.put(data.photoData).then(async (snapshot) => {
              await snapshot.ref
                .getDownloadURL()
                .then(async (url) => {
                  await firestore
                    .collection('users')
                    .doc(currentUser.uid)
                    .update({
                      photoURL: url,
                      updatedAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                    })
                    .catch((err) => {
                      throw err;
                    });
                })
                .catch((err) => {
                  throw err;
                });
            });
          }
          dispatch({ type: 'USER_MANAGE_SUCCESS' });
        } else {
          throw res.data;
        }
      })
      .catch((err) => dispatch({ type: 'USER_MANAGE_ERROR', err }));
  };
};

/*
// ACTION REMOVE USER
// Remove user
*/
export const removeUser = (id) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'USER_MANAGE_PENDING' });
    const firebase = getFirebase();
    const deleteUser = firebase.functions().httpsCallable('deleteUser');

    await deleteUser({ uid: id })
      .then(() => dispatch({ type: 'USER_MANAGE_SUCCESS' }))
      .catch((err) => dispatch({ type: 'USER_MANAGE_ERROR', err }));
  };
};

/*
// ACTION MANAGE USER INIT
*/
export const setUserPasswordInit = (data) => {
  return (dispatch) => {
    dispatch({ type: 'USER_SET_PASSWORD_INIT', data });
  };
};

/*
// ACTION SET USER PASSWORD
// Set user password
*/
export const setUserPassword = (code, password) => {
  return async (dispatch, getState, { getFirebase }) => {
    dispatch({ type: 'USER_SET_PASSWORD_PENDING' });
    const firebase = getFirebase();
    const setPassword = firebase.functions().httpsCallable('setPassword');
    await setPassword({
      oobCode: code,
      password: password,
    })
      .then((res) => {
        if (res && res.data && res.data.res === 'success')
          dispatch({ type: 'USER_SET_PASSWORD_SUCCESS', res });
        else {
          throw res.data;
        }
      })
      .catch((err) => dispatch({ type: 'USER_SET_PASSWORD_ERROR', err }));
  };
};
