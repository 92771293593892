import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import moment from 'moment';

import Icon from '../../assets/images/ProfileImage.svg';

import { Theme } from '../../assets/Theme.style';
import {
  Label,
  Orbholder,
  Orb,
  Imageholder,
  Image,
  Content
} from './UsersItem.style';

import { roles } from '../../utils/roles';

function UsersItem({
  theme = Theme.content.item,
  data = {},
  isActive = false
}) {
  const intl = useIntl();
  const [, setTick] = useState(moment().format());

  useEffect(() => {
    const timerID = setInterval(() => setTick(moment().format()), 1000);
    return () => clearInterval(timerID);
  }, []);

  const currentUserRole =
    data.role && Object.entries(data.role).find(el => el[1]);
  const userRole =
    currentUserRole && roles.find(el => el.id === currentUserRole[0]);
  return (
    <>
      {Object.values(data).length > 0 && (
        <Label theme={theme.label} data-active={isActive ? true : undefined}>
          <Orbholder>
            <Imageholder theme={theme.photo}>
              <Image
                src={data.photoURL ? data.photoURL : Icon}
                alt={data.displayName ? data.displayName : data.id}
              />
            </Imageholder>
            <Orb
              theme={theme.orb}
              status={(() => {
                const lastActivity =
                  data.loggedIn && moment().diff(data.loggedIn, 'minutes');
                return lastActivity <= 15
                  ? 'online'
                  : lastActivity <= 30
                  ? 'away'
                  : 'offline';
              })()}
            />
          </Orbholder>
          <Content>
            <span>
              <strong>{data.displayName ? data.displayName : data.id}</strong>
            </span>
            <span>
              {userRole
                ? intl.formatMessage({
                    id: `USERS.ROLE.${userRole.name}`,
                    defaultMessage: userRole.name
                  })
                : intl.formatMessage({
                    id: 'USERS.ROLE.NO_ROLE'
                  })}
            </span>
            <span>
              {data.customer
                ? data.customerData
                  ? data.customerData.name
                  : `${intl.formatMessage({
                      id: 'USERS.CUSTOMER.ASSESSING_INFORMATION'
                    })}...`
                : intl.formatMessage({
                    id: 'USERS.CUSTOMER.NO_CUSTOMER'
                  })}
            </span>
            <span>
              {data.distributor
                ? data.distributorData
                  ? data.distributorData.name
                  : `${intl.formatMessage({
                      id: 'USERS.DISTRIBUTOR.ASSESSING_INFORMATION'
                    })}...`
                : intl.formatMessage({
                    id: 'USERS.DISTRIBUTOR.NO_DISTRIBUTOR'
                  })}
            </span>
          </Content>
        </Label>
      )}
    </>
  );
}

UsersItem.propTypes = {
  theme: PropTypes.object,
  data: PropTypes.object,
  isActive: PropTypes.bool
};

export default memo(UsersItem);
