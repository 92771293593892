import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';

import {
  Panel,
  Logobox,
  Textbox,
  Error,
  Formbox,
  Inputbox,
  Button,
  Successbox,
  Footerbox
} from './ResetPasswordPanel.style';

import Logo from '../../assets/images/Logo.svg';
import Check from '../../assets/images/Check.svg';
import { Theme } from '../../assets/Theme.style';

function ResetPasswordPanel({
  theme = {
    default: Theme.sign.panel.default,
    inputbox: Theme.sign.panel.inputbox,
    error: Theme.sign.panel.error,
    footerbox: Theme.sign.panel.footerbox,
    button: Theme.button
  },
  store = {},
  onSubmit = () => {}
}) {
  const validate = values => {
    const errors = {};
    if (!values.password) errors.password = 'This field is required.';
    if (!values.repeatPassword)
      errors.repeatPassword = 'This field is required.';

    if (values.password && values.password.length < 6) {
      errors.password = 'The password is too weak.';
    }

    if (
      !errors.password &&
      values.repeatPassword &&
      values.password !== values.repeatPassword
    )
      errors.repeatPassword =
        'The repeat password should be the same as the new password';

    return errors;
  };

  return (
    <Panel theme={theme.default}>
      <Logobox>
        <img src={Logo} alt='BIN-E Smart Waste Bin' />
      </Logobox>

      {store.isInvalid || store.isError ? (
        <Textbox>
          <Error theme={theme.error.default}>{store.error}</Error>
        </Textbox>
      ) : store.isValid ? (
        store.isSuccess ? (
          <Textbox>
            <strong>Password changed!</strong>
          </Textbox>
        ) : (
          <Textbox>
            <strong>Set your new password</strong>
            <br />
            <br />
            {store.email}
          </Textbox>
        )
      ) : (
        <Textbox>
          <strong>Please wait</strong>
        </Textbox>
      )}

      {store.isValid && store.isSuccess && !store.isError && (
        <Successbox>
          <img src={Check} alt='Success' />
        </Successbox>
      )}

      {store.isValid && !store.isSuccess && !store.isError && (
        <Formbox>
          <Form
            onSubmit={onSubmit}
            validate={validate}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              invalid
            }) => (
              <form onSubmit={handleSubmit}>
                <Field name='password'>
                  {({ input, meta }) => (
                    <Inputbox
                      data-name='New password'
                      data-filled={input.value ? true : undefined}
                      theme={theme.inputbox.default}
                    >
                      <input
                        {...input}
                        type='password'
                        placeholder='New password'
                        autoComplete='off'
                        data-invalid={
                          meta.touched && meta.error ? true : undefined
                        }
                        data-valid={
                          meta.touched && !meta.error ? true : undefined
                        }
                      />
                      <span>{meta.touched && meta.error}</span>
                    </Inputbox>
                  )}
                </Field>
                <Field name='repeatPassword'>
                  {({ input, meta }) => (
                    <Inputbox
                      data-name='Repeat password'
                      data-filled={input.value ? true : undefined}
                      theme={theme.inputbox.default}
                    >
                      <input
                        {...input}
                        type='password'
                        placeholder='Repeat password'
                        autoComplete='off'
                        data-invalid={
                          meta.touched && meta.error ? true : undefined
                        }
                        data-valid={
                          meta.touched && !meta.error ? true : undefined
                        }
                      />
                      <span>{meta.touched && meta.error}</span>
                    </Inputbox>
                  )}
                </Field>

                <Button
                  disabled={
                    submitting ||
                    pristine ||
                    invalid ||
                    (store && store.isLoading)
                  }
                  theme={theme.button.default}
                >
                  {store && store.isLoading ? 'Please wait' : 'Change password'}
                </Button>
              </form>
            )}
          />
        </Formbox>
      )}

      {store.isValid && store.isError ? (
        <Footerbox theme={theme.footerbox.default}>
          <Link to={`/forgot-password`}>Back to forgot password.</Link>
        </Footerbox>
      ) : (
        <Footerbox theme={theme.footerbox.default}>
          <Link to={`/sign-in`}>Back to sign in.</Link>
        </Footerbox>
      )}
    </Panel>
  );
}

ResetPasswordPanel.propTypes = {
  theme: PropTypes.object,
  store: PropTypes.object,
  onSubmit: PropTypes.func
};

export default ResetPasswordPanel;
