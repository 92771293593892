import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';

import Logo from '../../assets/images/Logo.svg';

import { Theme } from '../../assets/Theme.style';
import {
  Panel,
  Logobox,
  Textbox,
  Error,
  Formbox,
  Inputbox,
  Button,
  Groupbox,
  Rememberbox,
  Linkbox,
  Footerbox
} from './SignInPanel.style';

function SignInPanel({
  theme = {
    default: Theme.sign.panel.default,
    inputbox: Theme.sign.panel.inputbox,
    checkbox: Theme.sign.panel.checkbox,
    linkbox: Theme.sign.panel.linkbox,
    error: Theme.sign.panel.error,
    footerbox: Theme.sign.panel.footerbox,
    button: Theme.button
  },
  store = {},
  onSubmit = () => {}
}) {
  const validate = values => {
    const errors = {};
    if (!values.email) errors.email = 'This field is required.';
    if (
      values.email &&
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        values.email
      )
    )
      errors.email = 'Invalid email address';
    if (!values.password) errors.password = 'This field is required.';
    return errors;
  };

  return (
    <Panel theme={theme.default}>
      <Logobox>
        <img src={Logo} alt='BIN-E Smart Waste Bin' />
      </Logobox>

      {store.isError ? (
        <Textbox>
          <Error theme={theme.error.default}>{store.error}</Error>
        </Textbox>
      ) : (
        <Textbox>
          <strong>Welcome</strong>, sign in to continue.
        </Textbox>
      )}

      <Formbox>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            invalid
          }) => (
            <form onSubmit={handleSubmit}>
              <Field name='email'>
                {({ input, meta }) => (
                  <Inputbox
                    data-name='E-mail address'
                    data-filled={input.value ? true : undefined}
                    theme={theme.inputbox.default}
                  >
                    <input
                      {...input}
                      type='email'
                      placeholder='E-mail address'
                      autoComplete='on'
                      data-invalid={
                        meta.touched && meta.error ? true : undefined
                      }
                      data-valid={
                        meta.touched && !meta.error ? true : undefined
                      }
                    />
                    <span>{meta.touched && meta.error}</span>
                  </Inputbox>
                )}
              </Field>
              <Field name='password'>
                {({ input, meta }) => (
                  <Inputbox
                    data-name='Password'
                    data-filled={input.value ? true : undefined}
                    theme={theme.inputbox.default}
                  >
                    <input
                      {...input}
                      type='password'
                      placeholder='Password'
                      autoComplete='on'
                      data-invalid={
                        meta.touched && meta.error ? true : undefined
                      }
                      data-valid={
                        meta.touched && !meta.error ? true : undefined
                      }
                    />
                    <span>{meta.touched && meta.error}</span>
                  </Inputbox>
                )}
              </Field>

              <Groupbox>
                <Rememberbox theme={theme.checkbox.default}>
                  <Field
                    id='remember-me'
                    name='remember'
                    component='input'
                    type='checkbox'
                  />
                  <label htmlFor='remember-me'>Remember me</label>
                </Rememberbox>
                <Linkbox theme={theme.linkbox.default}>
                  <Link to={`/forgot-password`}>Forgot password?</Link>
                </Linkbox>
              </Groupbox>
              <Button
                disabled={
                  submitting ||
                  pristine ||
                  invalid ||
                  (store && store.isLoading)
                }
                theme={theme.button.default}
              >
                {store && store.isLoading ? 'Please wait' : 'Sign in'}
              </Button>
            </form>
          )}
        />
      </Formbox>
      <Footerbox theme={theme.footerbox.default}>
        <a
          href='http://www.bine.world'
          target='_blank'
          rel='noopener noreferrer'
        >
          Not a member? Contact us.
        </a>
      </Footerbox>
    </Panel>
  );
}

SignInPanel.propTypes = {
  theme: PropTypes.object,
  store: PropTypes.object,
  onSubmit: PropTypes.func
};

export default SignInPanel;
