import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Icon from '../../assets/images/Plus.svg';

import { Theme } from '../../assets/Theme.style';
import { Label, Image, Content } from './UsersAdd.style';

function UsersAdd({ theme = Theme.content.add, handleClick = () => {} }) {
  const intl = useIntl();
  return (
    <Label theme={theme.label} onClick={handleClick}>
      <Image src={Icon} alt={intl.formatMessage({ id: 'USERS.ADD' })} />
      <Content>{intl.formatMessage({ id: 'USERS.ADD_NEW_USER' })}</Content>
    </Label>
  );
}

UsersAdd.propTypes = {
  theme: PropTypes.object,
  handleClick: PropTypes.func
};

export default memo(UsersAdd);
