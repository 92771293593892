/*
//   STYLE-COMPONENT: MODAL
*/

import { css, keyframes } from 'styled-components';

import fadeIn from 'react-animations/lib/fade-in';
import fadeOut from 'react-animations/lib/fade-out';

import zoomIn from 'react-animations/lib/zoom-in';
import bounceOut from 'react-animations/lib/bounce-out';

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeOutAnimation = keyframes`${fadeOut}`;

const zoomInAnimation = keyframes`${zoomIn}`;
const bounceOutAnimation = keyframes`${bounceOut}`;

export const Wrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
`;

export const Holder = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: auto;
  background: ${props => props.theme.background};
  opacity: 0;
  transition: 0.2s opacity;

  &[data-open] {
    opacity: 1;
    animation: 0.2s ${fadeInAnimation};
  }

  &[data-close] {
    animation: 0.2s ${fadeOutAnimation};
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.thumb};
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.track};
  }
`;

export const Modal = css`
  position: relative;
  display: block;
  margin: auto;
  width: 100%;
  max-width: 630px;
  border-radius: 8px;
  padding: 20px;
  background: ${props => props.theme.background};
  opacity: 0;
  transition: 0.2s opacity;

  &[data-open] {
    opacity: 1;
    animation: 0.2s ${zoomInAnimation};
  }

  &[data-close] {
    animation: 0.2s ${bounceOutAnimation};
  }
`;

export const Close = css`
  position: absolute;
  top: 0;
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 39px;
  height: 39px;
  cursor: pointer;
`;

export const Image = css`
  display: block;
  max-width: 100%;
  height: auto;
`;

export const Title = css`
  display: block;
  width: 100%;
  margin-bottom: 15px;
  color: ${props => props.theme.color};
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  cursor: default;
`;

export const Content = css`
  display: block;
  width: 100%;
`;

export const Subtitle = css`
  display: block;
  width: 100%;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
  cursor: default;
`;

export const Navigation = css`
  display: block;
  width: 100%;
  text-align: center;
`;
