import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Theme } from '../../assets/Theme.style';
import {
  Label,
  Status,
  Details,
  Connection,
  Title,
  Content
} from './DevicesItem.style';

function DevicesItem({
  theme = Theme.content.item,
  data = {},
  isActive = false
}) {
  const [, setTick] = useState(moment().format());

  useEffect(() => {
    const timerID = setInterval(() => setTick(moment().format()), 1000);
    return () => clearInterval(timerID);
  }, []);

  const heartbeat =
    data &&
    data.heartbeat &&
    data.heartbeat[data.heartbeat.length - 1] &&
    data.heartbeat[data.heartbeat.length - 1].value.timestamp;
  const isConnected =
    heartbeat && Math.abs(moment().diff(moment(heartbeat), 'minutes')) <= 15;
  return (
    <>
      {Object.values(data).length > 0 && (
        <Label
          theme={theme.label}
          data-active={isActive ? true : undefined}
          data-connected={isConnected ? true : undefined}
          data-out-of-order={data.status === 0 ? true : undefined}
          data-maintenance={data.status === 1 ? true : undefined}
        >
          <Status theme={theme.status}>
            {data.status === 2 ? (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
              >
                <g transform='translate(236 -2079)'>
                  <rect
                    width='24'
                    height='24'
                    transform='translate(-236 2079)'
                    fill='none'
                  />
                  <circle
                    data-name='background'
                    cx='9'
                    cy='9'
                    r='9'
                    transform='translate(-233 2082)'
                  />
                  <path
                    data-name='icon'
                    d='M-1695.671,415.593a.614.614,0,0,0-.443-.187.615.615,0,0,0-.432.176l-5.153,5.029-2.912-2.966a.614.614,0,0,0-.442-.185.616.616,0,0,0-.433.177.619.619,0,0,0-.008.875l3.344,3.407a.614.614,0,0,0,.442.185.616.616,0,0,0,.432-.176l5.594-5.46A.62.62,0,0,0-1695.671,415.593Z'
                    transform='translate(1476.382 1672.745)'
                  />
                </g>
              </svg>
            ) : data.status === 1 ? (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='25'
                height='25'
                viewBox='0 0 25 25'
              >
                <g transform='translate(404 -206)'>
                  <path
                    data-name='background'
                    d='M9.375,0A9.375,9.375,0,1,1,0,9.375,9.375,9.375,0,0,1,9.375,0Z'
                    transform='translate(-401 209)'
                  />
                  <rect
                    width='25'
                    height='25'
                    transform='translate(-404 206)'
                    fill='none'
                  />
                  <g
                    id='Group_1464'
                    data-name='Group 1464'
                    transform='translate(-392 214)'
                  >
                    <path
                      data-name='icon'
                      d='M-1316.881,407a.62.62,0,0,0-.619.619v4.7a.62.62,0,0,0,.619.619.619.619,0,0,0,.619-.619v-4.7A.619.619,0,0,0-1316.881,407Z'
                      transform='translate(1317.5 -407)'
                    />
                    <path
                      data-name='icon'
                      d='M-1316.881,490a.62.62,0,0,0-.437.181.62.62,0,0,0-.182.438.62.62,0,0,0,.181.437.62.62,0,0,0,.438.182.619.619,0,0,0,.437-.181.62.62,0,0,0,.182-.438.62.62,0,0,0-.181-.437A.621.621,0,0,0-1316.881,490Z'
                      transform='translate(1317.5 -483.153)'
                    />
                  </g>
                </g>
              </svg>
            ) : (
              data.status === 0 && (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='25'
                  height='25'
                  viewBox='0 0 25 25'
                >
                  <g transform='translate(352 -206)'>
                    <circle
                      data-name='background'
                      cx='9.375'
                      cy='9.375'
                      r='9.375'
                      transform='translate(-348.875 209.125)'
                      fill='#f15a23'
                    />
                    <rect
                      width='25'
                      height='25'
                      transform='translate(-352 206)'
                      fill='none'
                    />
                    <path
                      data-name='icon'
                      d='M-285.646,72.237l-3.564-3.564,3.552-3.552a.615.615,0,0,0,.181-.437.614.614,0,0,0-.181-.437.614.614,0,0,0-.437-.181.614.614,0,0,0-.437.181l-3.552,3.552-3.552-3.552a.614.614,0,0,0-.437-.181.614.614,0,0,0-.437.181.615.615,0,0,0-.181.438.614.614,0,0,0,.181.437l3.552,3.552-3.565,3.564a.615.615,0,0,0-.181.438.615.615,0,0,0,.181.438.615.615,0,0,0,.437.181.615.615,0,0,0,.437-.181l3.565-3.565,3.564,3.564a.614.614,0,0,0,.438.181.614.614,0,0,0,.437-.181.614.614,0,0,0,.181-.437A.614.614,0,0,0-285.646,72.237Z'
                      transform='translate(-49.294 149.936)'
                      fill='#fff'
                    />
                  </g>
                </svg>
              )
            )}
          </Status>
          <Details>
            <Title>{data.name ? data.name : data.serialNo}</Title>
            {data.name && <Content>{data.serialNo}</Content>}
          </Details>
          <Connection theme={theme.connect}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='19.845'
              height='14.498'
              viewBox='0 0 19.845 14.498'
            >
              <g transform='translate(0 0)'>
                <path
                  data-name='icon'
                  d='M9.922,93.32a1.812,1.812,0,1,0,1.812,1.812A1.813,1.813,0,0,0,9.922,93.32Zm9.71-6.336a12.659,12.659,0,0,0-19.421,0A.906.906,0,0,0,1.6,88.149a10.847,10.847,0,0,1,16.645,0,.906.906,0,0,0,1.388-1.165Zm-9.71-.913a9.046,9.046,0,0,0-6.936,3.24.906.906,0,0,0,1.388,1.166,7.231,7.231,0,0,1,11.1,0,.906.906,0,0,0,1.388-1.166A9.048,9.048,0,0,0,9.922,86.071Zm0,3.625a5.426,5.426,0,0,0-4.162,1.944.906.906,0,0,0,1.388,1.166,3.614,3.614,0,0,1,5.548,0,.906.906,0,0,0,1.388-1.166A5.426,5.426,0,0,0,9.922,89.7Z'
                  transform='translate(0.001 -82.446)'
                />
              </g>
            </svg>
          </Connection>
        </Label>
      )}
    </>
  );
}

DevicesItem.propsTypes = {
  theme: PropTypes.object,
  data: PropTypes.object,
  isActive: PropTypes.bool
};

export default memo(DevicesItem);
