import styled, { keyframes } from 'styled-components';

import { Media } from '../../assets/Mixins.style';

import { Inputbox as commonInputbox } from '../../assets/common/Inputbox.style';

import fadeIn from 'react-animations/lib/fade-in';
import fadeOut from 'react-animations/lib/fade-out';
import slideInLeft from 'react-animations/lib/slide-in-left';
import slideOutLeft from 'react-animations/lib/slide-out-left';

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeOutAnimation = keyframes`${fadeOut}`;
const slideInLeftAnimation = keyframes`${slideInLeft}`;
const slideOutLeftAnimation = keyframes`${slideOutLeft}`;

export const Wrapper = styled.div`
  display: block;
  flex-shrink: 0;
  width: 300px;
  background: ${(props) => props.theme.background};
  border: 0;
  overflow: auto;
  transition: 0.2s width;

  ${Media.xlg`
    width: 200px;
  `}

  ${Media.lg`
    position: fixed;
    top: 80px;
    left: 0;
    bottom: 0;
    z-index: 2;
    transform: translate(-200px, 0);
  `}


  &[data-open] {
    ${Media.lg`
      transform: translate(0, 0);
      animation: 0.2s ${slideInLeftAnimation};
    `}
  }

  &[data-close] {
    ${Media.lg`
      animation: 0.2s ${slideOutLeftAnimation};
    `}
  }


  &::-webkit-scrollbar {
    width:  6px
    height: 6px
  }

  &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.thumb}
  }

  &::-webkit-scrollbar-track {
      background: ${(props) => props.theme.track}
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;
  padding: 55px 15px 0 84px;
  transition: 0.2s padding;

  ${Media.xlg`
    padding: 25px 15px 0;
  `}
`;

export const Item = styled.div`
  display: block;
  width: 100%;
  padding: 0 0 0 25px;
`;

export const Label = styled.div`
  display: block;
  width: 100%;
  color: ${(props) => props.theme.color};
  font-size: 16px;
  font-weight: 400;
  cursor: default;
`;

export const Inputbox = styled.div`
  ${commonInputbox}
  margin-top: 0;

  input[type='text'] {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
`;

export const Option = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 24px;
  margin-bottom: 10px;
  transition: 0.2s color;
  color: ${(props) => props.theme.color};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colorOnHover};
  }

  &[data-active] {
    color: ${(props) => props.theme.color};
    font-weight: 600;
  }

  span {
    width: 24px;
    height: 24px;
    transition: 0.2s transform;
  }

  &[data-open] {
    span {
      transform: scaleY(-1);
    }
  }
`;

export const InnerContent = styled.div`
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
  transition: 0.2s height;

  &[data-close] {
    height: 0;
  }

  &[data-open] {
    height: auto;
  }
`;

export const Overlap = styled.div`
  display: block;
  position: fixed;
  top: 80px;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${(props) => props.theme.background};
  z-index: 1;
  opacity: 1;

  &[data-open] {
    ${Media.lg`
      animation: 0.2s ${fadeInAnimation};
    `}
  }

  &[data-close] {
    ${Media.lg`
      opacity: 0;
      animation: 0.2s ${fadeOutAnimation};
    `}
  }
`;
