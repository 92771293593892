//TODO: NEED REFACTOR
import React, { memo, Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Icon from '../../assets/images/Modify.svg';

import { Theme } from '../../assets/Theme.style';
import {
  Box,
  Item,
  Panel,
  Header,
  Name,
  Menu,
  Button,
  ButtonIcon,
  Title,
  Content,
  Details,
  Columns,
  ContentColumns,
  Column,
  ContentColumn,
  Imageholder,
  Image
} from './DistributorDetails.style';

import DeviceAssigned from '../DeviceAssigned/DeviceAssigned';
import DistributorCustomers from '../DistributorCustomers/DistributorCustomers';
import DeviceUsers from '../DeviceUsers/DeviceUsers';

import { countries } from '../../utils/countries';

class DistributorDetails extends Component {
  state = {
    photoVisible: false
  };

  static propsTypes = {
    theme: PropTypes.object,
    data: PropTypes.object,
    handleClick: PropTypes.func
  };

  static defaultProps = {
    theme: Theme.content.details,
    data: {},
    handleClick: () => {}
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data.photoURL !== this.props.data.photoURL)
      this.setImageStatus(false);
  }

  setImageStatus = status => {
    this.setState({ photoVisible: status });
  };

  render() {
    const { photoVisible } = this.state;
    const { theme, data, handleClick, intl } = this.props;

    const customers = data && data.customers;
    const users = data && data.users;
    const bines = data && data.bines;

    const binesAssigned =
      bines && bines.filter(e => (e.customer ? true : false));
    const binesUnassigned =
      bines && bines.filter(e => (e.customer ? false : true));

    return (
      <>
        {data && Object.keys(data).length > 0 && (
          <Box>
            <Item>
              <Panel theme={theme.panel}>
                <Header>
                  <Name>{data.name}</Name>
                  <Menu>
                    <Button
                      onClick={() => handleClick('edit')}
                      title={intl.formatMessage({
                        id: 'DISTRIBUTORS.EDIT_DISTRIBUTOR'
                      })}
                    >
                      <ButtonIcon
                        src={Icon}
                        alt={intl.formatMessage({
                          id: 'DISTRIBUTORS.EDIT_DISTRIBUTOR'
                        })}
                      />
                    </Button>
                  </Menu>
                </Header>
                <Content theme={theme.content}>
                  <Details>
                    <Columns>
                      <Column>
                        {(data.street ||
                          data.postalCode ||
                          data.city ||
                          data.country ||
                          data.contactNo ||
                          data.contactEmail) && (
                          <Title theme={theme.title}>
                            <strong>
                              {intl.formatMessage({
                                id: 'DISTRIBUTORS.HEADQUARTERS'
                              })}
                            </strong>
                          </Title>
                        )}
                        {data.street && <span>{data.street}</span>}
                        {data.city && (
                          <span>
                            {data.postalCode
                              ? `${data.postalCode}, ${data.city}`
                              : data.city}
                          </span>
                        )}
                        {data.country && (
                          <span>
                            {intl.formatMessage({
                              id: `COUNTRY.COUNTRY.${(() => {
                                const country = countries.find(
                                  country => country.id === data.country
                                );
                                return country && country.name;
                              })()}`,
                              defaultMessage: data.country
                            })}
                          </span>
                        )}
                        {data.contactNo && <span>{data.contactNo}</span>}
                        {data.contactEmail && <span>{data.contactEmail}</span>}
                      </Column>
                      <Column data-center={true}>
                        {data.photoURL && (
                          <Imageholder>
                            <Image
                              src={data.photoURL}
                              data-visible={photoVisible ? true : undefined}
                              onLoad={() => this.setImageStatus(true)}
                              onError={() => this.setImageStatus(false)}
                              alt={data.name ? data.name : data.id}
                            />
                          </Imageholder>
                        )}
                      </Column>
                    </Columns>
                  </Details>
                </Content>
              </Panel>
            </Item>

            <Item>
              <Panel theme={theme.panel}>
                <Title>
                  {intl.formatMessage({
                    id: 'DISTRIBUTORS.DEVICES'
                  })}
                </Title>
                <Content theme={theme.content}>
                  <ContentColumns>
                    <ContentColumn>
                      <DeviceAssigned
                        theme={theme.assign}
                        title={intl.formatMessage({
                          id: 'DISTRIBUTORS.DEVICES.UNASSIGNED_DEVICES'
                        })}
                        status={2}
                        count={binesUnassigned && binesUnassigned.length}
                        handleClick={param => handleClick(param)}
                      />
                    </ContentColumn>
                    <ContentColumn>
                      <DeviceAssigned
                        theme={theme.assign}
                        title={intl.formatMessage({
                          id: 'DISTRIBUTORS.DEVICES.ASSIGNED_DEVICES'
                        })}
                        status={1}
                        count={binesAssigned && binesAssigned.length}
                        handleClick={param => handleClick(param)}
                      />
                    </ContentColumn>
                  </ContentColumns>
                </Content>
              </Panel>
            </Item>

            <Item data-list={true} data-half={true}>
              <Panel theme={theme.panel}>
                <Title>
                  {intl.formatMessage({
                    id: 'DISTRIBUTORS.CUSTOMERS'
                  })}
                </Title>
                <Content theme={theme.content}>
                  {customers !== undefined ? (
                    customers && customers.length > 0 ? (
                      <DistributorCustomers serial={data.id} data={customers} />
                    ) : (
                      intl.formatMessage({
                        id: 'DISTRIBUTORS.CUSTOMERS.NO_DATA'
                      })
                    )
                  ) : (
                    `${intl.formatMessage({
                      id: 'DISTRIBUTORS.CUSTOMERS.ASSESSING_INFORMATION'
                    })}...`
                  )}
                </Content>
              </Panel>
            </Item>

            <Item data-list={true} data-half={true}>
              <Panel theme={theme.panel}>
                <Title>
                  {intl.formatMessage({
                    id: 'DISTRIBUTORS.USERS'
                  })}
                </Title>
                <Content theme={theme.content}>
                  {users !== undefined ? (
                    users && users.length > 0 ? (
                      <DeviceUsers serial={data.id} data={users} />
                    ) : (
                      intl.formatMessage({
                        id: 'DISTRIBUTORS.USERS.NO_DATA'
                      })
                    )
                  ) : (
                    `${intl.formatMessage({
                      id: 'DISTRIBUTORS.USERS.ASSESSING_INFORMATION'
                    })}...`
                  )}
                </Content>
              </Panel>
            </Item>
          </Box>
        )}
      </>
    );
  }
}

export default memo(injectIntl(DistributorDetails));
