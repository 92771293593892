import moment from 'moment';

/*
// ACTION MANAGE FACTORY INIT
*/
export const manageFactoryInit = (data) => {
  return (dispatch) => {
    dispatch({ type: 'FACTORY_MANAGE_INIT', data });
  };
};

/*
// ACTION ADD FACTORY
// Create factory and send profile avatar if exist photo data
*/
export const addFactory = (data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'FACTORY_MANAGE_PENDING' });
    const firebase = getFirebase();
    const firestore = getFirestore();

    await firestore
      .collection('factories')
      .add({
        ...data.factory,
        createdAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
      })
      .then(async (factory) => {
        if (data.photoData) {
          const photoStorage = firebase
            .storage()
            .ref('Factories/' + factory.id + '/photo.jpg');
          await photoStorage
            .put(data.photoData)
            .then(async (snapshot) => {
              await snapshot.ref.getDownloadURL().then(async (url) => {
                await factory
                  .update({
                    contactPhotoURL: url,
                    updatedAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                  })
                  .then(() =>
                    dispatch({
                      type: 'FACTORY_MANAGE_SUCCESS',
                      data: { id: factory.id },
                    })
                  )
                  .catch((err) => {
                    throw err;
                  });
              });
            })
            .catch((err) => {
              throw err;
            });
        } else
          dispatch({
            type: 'FACTORY_MANAGE_SUCCESS',
            data: { id: factory.id },
          });
      })
      .catch((err) => dispatch({ type: 'FACTORY_MANAGE_ERROR', err }));
  };
};

/*
// ACTION UPDATE FACTORY
// Update factory and send profile avatar if exist photo data
*/
export const updateFactory = (id, data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'FACTORY_MANAGE_PENDING' });
    const firebase = getFirebase();
    const firestore = getFirestore();
    const photoStorage = firebase
      .storage()
      .ref('Factories/' + id + '/photo.jpg');

    if (data.photoData) {
      await photoStorage
        .put(data.photoData)
        .then(async (snapshot) => {
          await snapshot.ref.getDownloadURL().then(async (url) => {
            await firestore
              .collection('factories')
              .doc(id)
              .update({
                ...data.factory,
                contactPhotoURL: url,
                updatedAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
              })
              .then(() => dispatch({ type: 'FACTORY_MANAGE_SUCCESS' }))
              .catch((err) => {
                throw err;
              });
          });
        })
        .catch((err) => dispatch({ type: 'FACTORY_MANAGE_ERROR', err }));
    } else {
      await firestore
        .collection('factories')
        .doc(id)
        .update({
          ...data.factory,
          updatedAt: moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'),
        })
        .then(() => dispatch({ type: 'FACTORY_MANAGE_SUCCESS', data: { id } }))
        .catch((err) => dispatch({ type: 'FACTORY_MANAGE_ERROR', err }));
    }
  };
};
