import moment from 'moment';

export const createCommand = ({
  requestID,
  timeout = -1,
  blocking = false,
  operation,
  action,
  object,
  timestamp = moment()
    .utc()
    .format('YYYY-MM-DDTHH:mm:ssZ')
}) => {
  return {
    applicationName: 'external',
    applicationID: 'external',
    requestID,
    timeout,
    blocking,
    status: '',
    data: action && object ? { action, object } : '',
    operation,
    type: 'request',
    timestamp
  };
};
