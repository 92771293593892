import React from 'react';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import SuccessIcon from '../../assets/images/Success.svg';
import ErrorIcon from '../../assets/images/Error.svg';
import CloseIcon from '../../assets/images/Close.svg';

import { withTheme } from '../../assets/Theme.style';

import {
  Wrapper,
  Holder,
  Modal,
  Close,
  Image,
  Title,
  Content,
  Box,
  Inputbox,
  Checkbox,
  Navigation,
  Button,
  Alertbox,
  Alert,
  AlertImage,
  AlertContent,
  AlertTitle
} from './ReportProblem.style';

function ReportProblem({
  theme,
  store = {},
  onSubmit = () => {},
  handleClose = () => {},
  open = false
}) {
  const intl = useIntl();

  const validate = values => {
    const errors = {};

    if (!values.details) errors.details = 'REPORT_PROBLEM.VALIDATION.REQUIRED';

    return errors;
  };

  return (
    <Wrapper>
      <Holder
        theme={theme.modal.holder}
        data-open={open ? true : undefined}
        data-close={!open ? true : undefined}
      >
        <Modal
          theme={theme.modal.content.default}
          data-open={open ? true : undefined}
          data-close={!open ? true : undefined}
        >
          <Close
            onClick={handleClose}
            title={intl.formatMessage({
              id: 'REPORT_PROBLEM.MENU.CLOSE'
            })}
          >
            <Image
              src={CloseIcon}
              alt={intl.formatMessage({
                id: 'REPORT_PROBLEM.MENU.CLOSE'
              })}
            />
          </Close>
          <Title>
            {intl.formatMessage({
              id: 'REPORT_PROBLEM.REPORT_PROBLEM'
            })}
          </Title>
          <Content>
            <Form
              onSubmit={onSubmit}
              validate={validate}
              render={({ handleSubmit, submitting, pristine, invalid }) => (
                <form onSubmit={handleSubmit}>
                  <Box>
                    <Checkbox theme={theme.modal.checkbox.default}>
                      <Field
                        id='copy'
                        name='copy'
                        component='input'
                        type='checkbox'
                      />
                      <label htmlFor='copy'>
                        {intl.formatMessage({
                          id: 'REPORT_PROBLEM.SEND_COPY_BY_EMAIL'
                        })}
                      </label>
                    </Checkbox>
                    <Field name='details'>
                      {({ input, meta }) => (
                        <Inputbox
                          data-name={intl.formatMessage({
                            id: 'REPORT_PROBLEM.MESSAGE'
                          })}
                          data-filled={input.value ? true : undefined}
                          theme={theme.modal.inputbox.default}
                        >
                          <textarea
                            {...input}
                            type='text'
                            placeholder={intl.formatMessage({
                              id: 'REPORT_PROBLEM.MESSAGE'
                            })}
                            autoComplete='off'
                          />
                          <span>
                            {meta.touched &&
                              meta.error &&
                              intl.formatMessage({
                                id: meta.error
                              })}
                          </span>
                        </Inputbox>
                      )}
                    </Field>
                  </Box>

                  {store.isError ? (
                    <Alertbox>
                      <Alert>
                        <AlertImage
                          src={ErrorIcon}
                          alt={intl.formatMessage({
                            id: 'REPORT_PROBLEM.ALERT.SOMETHING_WENT_WRONG'
                          })}
                        />
                        <AlertContent>
                          <AlertTitle theme={theme.alert.error.title}>
                            {intl.formatMessage({
                              id: 'REPORT_PROBLEM.ALERT.SOMETHING_WENT_WRONG'
                            })}
                          </AlertTitle>
                          {store.error}
                        </AlertContent>
                      </Alert>
                    </Alertbox>
                  ) : (
                    store.isSuccess && (
                      <Alertbox>
                        <Alert>
                          <AlertImage
                            src={SuccessIcon}
                            alt={intl.formatMessage({
                              id: 'REPORT_PROBLEM.ALERT.SUCCESS'
                            })}
                          />
                          <AlertContent>
                            <AlertTitle theme={theme.alert.success.title}>
                              {intl.formatMessage({
                                id: 'REPORT_PROBLEM.ALERT.SUCCESS'
                              })}
                            </AlertTitle>
                            {intl.formatMessage({
                              id: 'REPORT_PROBLEM.ALERT.REPORT_SENT'
                            })}
                          </AlertContent>
                        </Alert>
                      </Alertbox>
                    )
                  )}

                  <Navigation>
                    <Button
                      theme={theme.button.default}
                      disabled={
                        submitting ||
                        pristine ||
                        invalid ||
                        (store && store.isLoading)
                      }
                    >
                      {store && store.isLoading
                        ? intl.formatMessage({
                            id: 'REPORT_PROBLEM.MENU.PLEASE_WAIT'
                          })
                        : intl.formatMessage({
                            id: 'REPORT_PROBLEM.MENU.SEND_REPORT'
                          })}
                    </Button>
                    <Button
                      type='button'
                      theme={theme.button.alternative}
                      onClick={handleClose}
                    >
                      {intl.formatMessage({
                        id: 'REPORT_PROBLEM.MENU.CANCEL'
                      })}
                    </Button>
                  </Navigation>
                </form>
              )}
            />
          </Content>
        </Modal>
      </Holder>
    </Wrapper>
  );
}

ReportProblem.propTypes = {
  theme: PropTypes.object,
  store: PropTypes.object,
  onSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool
};

export default withTheme(ReportProblem);
