import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { useIntl } from 'react-intl';
import moment from 'moment';

import FillGlassIcon from '../../assets/images/FillGlass.svg';
import FillPlasticIcon from '../../assets/images/FillPlastic.svg';
import FillPaperIcon from '../../assets/images/FillPaper.svg';
import FillMetalIcon from '../../assets/images/FillMetal.svg';

import { Theme } from '../../assets/Theme.style';
import {
  Box,
  Item,
  Holder,
  Image,
  Title,
  Information,
  Navigation,
  ReloadButton,
} from './FillLevel.style';

function FillLevel({
  theme = Theme.content.details.fillLevel,
  status = '',
  bins = {},
  data = {},
  reloadDeviceCommand = () => {},
  responseAt = '',
}) {
  const intl = useIntl();
  const iconSet = {
    glass: FillGlassIcon,
    plastic: FillPlasticIcon,
    plasticBottles: FillPlasticIcon,
    paper: FillPaperIcon,
    metal: FillMetalIcon,
    other: FillMetalIcon,
  };
  const bin1 =
    bins &&
    Object.entries(bins).length > 0 &&
    Object.entries(bins).find(
      (bins) => bins[1] && bins[1].find((bin) => bin === 'bin1')
    );
  const bin2 =
    bins &&
    Object.entries(bins).length > 0 &&
    Object.entries(bins).find(
      (bins) => bins[1] && bins[1].find((bin) => bin === 'bin2')
    );
  const bin3 =
    bins &&
    Object.entries(bins).length > 0 &&
    Object.entries(bins).find(
      (bins) => bins[1] && bins[1].find((bin) => bin === 'bin3')
    );
  const bin4 =
    bins &&
    Object.entries(bins).length > 0 &&
    Object.entries(bins).find(
      (bins) => bins[1] && bins[1].find((bin) => bin === 'bin4')
    );
  const bin1Name = (bin1 && bin1[0]) || 'bin1';
  const bin2Name = (bin2 && bin2[0]) || 'bin2';
  const bin3Name = (bin3 && bin3[0]) || 'bin3';
  const bin4Name = (bin4 && bin4[0]) || 'bin4';
  const bin1Icon = iconSet[bin1Name];
  const bin2Icon = iconSet[bin2Name];
  const bin3Icon = iconSet[bin3Name];
  const bin4Icon = iconSet[bin4Name];
  const bin1Data = [{ value: data.bin1 }, { value: 100 - data.bin1 }];
  const bin2Data = [{ value: data.bin2 }, { value: 100 - data.bin2 }];
  const bin3Data = [{ value: data.bin3 }, { value: 100 - data.bin3 }];
  const bin4Data = [{ value: data.bin4 }, { value: 100 - data.bin4 }];

  return (
    <>
      {status ? (
        <Information>{status}</Information>
      ) : (
        <>
          <Box>
            <Item>
              <Holder>
                {bin1Icon && (
                  <Image
                    src={bin1Icon}
                    alt={intl.formatMessage({
                      id: `FRACTION.TYPE.${bin1Name}`,
                      defaultMessage: bin1Name,
                    })}
                  />
                )}

                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={bin1Data}
                      dataKey='value'
                      cx={'50%'}
                      cy={'50%'}
                      startAngle={90}
                      endAngle={-270}
                      innerRadius={38}
                      outerRadius={48}
                    >
                      {bin1Data.map((el, index) => (
                        <Cell
                          key={index}
                          fill={
                            index === 0
                              ? el.value <= 75
                                ? theme.levels.default.fill
                                : theme.levels.full.fill
                              : theme.levels.empty.fill
                          }
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Holder>
              <Title>
                {intl.formatMessage({
                  id: `FRACTION.TYPE.${bin1Name}`,
                  defaultMessage: bin1Name,
                })}
              </Title>
            </Item>
            <Item>
              <Holder>
                {bin2Icon && (
                  <Image
                    src={bin2Icon}
                    alt={intl.formatMessage({
                      id: `FRACTION.TYPE.${bin2Name}`,
                      defaultMessage: bin2Name,
                    })}
                  />
                )}
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={bin2Data}
                      dataKey='value'
                      cx={'50%'}
                      cy={'50%'}
                      startAngle={90}
                      endAngle={-270}
                      innerRadius={38}
                      outerRadius={48}
                    >
                      {bin2Data.map((el, index) => (
                        <Cell
                          key={index}
                          fill={
                            index === 0
                              ? el.value <= 75
                                ? theme.levels.default.fill
                                : theme.levels.full.fill
                              : theme.levels.empty.fill
                          }
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Holder>
              <Title>
                {intl.formatMessage({
                  id: `FRACTION.TYPE.${bin2Name}`,
                  defaultMessage: bin2Name,
                })}
              </Title>
            </Item>
            <Item>
              <Holder>
                {bin3Icon && (
                  <Image
                    src={bin3Icon}
                    alt={intl.formatMessage({
                      id: `FRACTION.TYPE.${bin3Name}`,
                      defaultMessage: bin3Name,
                    })}
                  />
                )}
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={bin3Data}
                      dataKey='value'
                      cx={'50%'}
                      cy={'50%'}
                      startAngle={90}
                      endAngle={-270}
                      innerRadius={38}
                      outerRadius={48}
                    >
                      {bin3Data.map((el, index) => (
                        <Cell
                          key={index}
                          fill={
                            index === 0
                              ? el.value <= 75
                                ? theme.levels.default.fill
                                : theme.levels.full.fill
                              : theme.levels.empty.fill
                          }
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Holder>
              <Title>
                {intl.formatMessage({
                  id: `FRACTION.TYPE.${bin3Name}`,
                  defaultMessage: bin3Name,
                })}
              </Title>
            </Item>
            <Item>
              <Holder>
                {bin4Icon && (
                  <Image
                    src={bin4Icon}
                    alt={intl.formatMessage({
                      id: `FRACTION.TYPE.${bin4Name}`,
                      defaultMessage: bin4Name,
                    })}
                  />
                )}
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={bin4Data}
                      dataKey='value'
                      cx={'50%'}
                      cy={'50%'}
                      startAngle={90}
                      endAngle={-270}
                      innerRadius={38}
                      outerRadius={48}
                    >
                      {bin4Data.map((el, index) => (
                        <Cell
                          key={index}
                          fill={
                            index === 0
                              ? el.value <= 75
                                ? theme.levels.default.fill
                                : theme.levels.full.fill
                              : theme.levels.empty.fill
                          }
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Holder>
              <Title>
                {intl.formatMessage({
                  id: `FRACTION.TYPE.${bin4Name}`,
                  defaultMessage: bin4Name,
                })}
              </Title>
            </Item>
          </Box>
          <Navigation>
            {responseAt &&
              `${intl.formatMessage({
                id: 'DEVICES.FILL_LEVELS.LAST_CHECK',
              })}: ${moment(responseAt).format('YYYY/MM/DD HH:mm')}`}
            ,&nbsp;
            <ReloadButton onClick={() => reloadDeviceCommand('FILL')}>
              {intl.formatMessage({ id: 'DEVICES.FILL_LEVELS.RELOAD' })}
            </ReloadButton>
            .
          </Navigation>
        </>
      )}
    </>
  );
}

FillLevel.propTypes = {
  theme: PropTypes.object,
  status: PropTypes.string,
  data: PropTypes.object,
};

export default memo(FillLevel);
