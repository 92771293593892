//TODO: NEED REFACTOR
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { Theme } from '../../assets/Theme.style';
import { Breakpoints } from '../../assets/Variables.style';
import {
  Wrapper,
  Content,
  Item,
  Inputbox,
  Option,
  InnerContent,
  Overlap,
} from './Sider.style';

class Sider extends Component {
  state = {
    currentType: undefined,
    currentFilter: undefined,
    width: window.innerWidth,
    dropdown: [],
  };

  static propsTypes = {
    theme: PropTypes.object,
    options: PropTypes.array,
    location: PropTypes.object,
    history: PropTypes.object,
    visible: PropTypes.bool,
    open: PropTypes.bool,
    handleToggleFilter: PropTypes.fun,
    handleChangeFilter: PropTypes.fun,
    authRole: PropTypes.object,
  };

  static defaultProps = {
    theme: Theme.sider,
    options: [],
    location: {},
    history: {},
    visible: false,
    open: false,
    handleToggleFilter: () => {},
    handleChangeFilter: () => {},
    authRole: {},
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    const params = queryString.parse(this.props.location.search);
    if (params && params.type && params.filter) this.setFilterState(params);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  setFilter = (params) => (e) => {
    this.setFilterState(params);
    this.setHistory(params);
  };

  setInputFilter = (params) => (e) => {
    this.setFilterState({ ...params, filter: e.currentTarget.value });
    this.setHistory({ ...params, filter: e.currentTarget.value });
    this.props.handleChangeFilter({ ...params, filter: e.currentTarget.value });
  };

  setFilterState = (params) => {
    this.setState({
      currentType: params.filter ? params.type : undefined,
      currentFilter: params.filter ? params.filter : undefined,
    });
  };

  setHistory = (params) => {
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: queryString.stringify({
        type: params.filter ? params.type : undefined,
        filter: params.filter ? params.filter : undefined,
      }),
    });
  };

  toggleDropdown = (params) => (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const { dropdown } = this.state;
    const item = dropdown.find((e) => e === params.id);
    let newDropdown;
    if (item) {
      newDropdown = dropdown.filter((e) => e !== params.id);
    } else {
      newDropdown = [...dropdown, params.id];
    }
    this.setState({ dropdown: newDropdown });
  };

  renderMenu = ({ items, index, authRole }) => {
    const { currentType, currentFilter, dropdown } = this.state;
    const { theme, intl } = this.props;
    return (
      items &&
      items.map(
        (el, key) =>
          (!el.privileges ||
            (el.privileges &&
              authRole &&
              authRole.privileges &&
              authRole.privileges[el.privileges])) && (
            <Item key={`${index ? `${index}-` : ''}${key}`}>
              {el.filterInput ? (
                <Option theme={theme.option}>
                  <Inputbox
                    data-name={intl.formatMessage({
                      id: 'SIDER.SEARCH',
                    })}
                    data-filled={
                      el.type === currentType && currentFilter
                        ? true
                        : undefined
                    }
                    theme={theme.inputbox.default}
                  >
                    <input
                      type='text'
                      placeholder={`${intl.formatMessage({
                        id: 'SIDER.SEARCH',
                      })}...`}
                      defaultValue={
                        el.type === currentType && currentFilter
                          ? currentFilter
                          : el.filter
                          ? el.filter
                          : undefined
                      }
                      onChange={this.setInputFilter({
                        type: el.type,
                      })}
                    />
                  </Inputbox>
                </Option>
              ) : (
                <Option
                  theme={theme.option}
                  data-active={
                    currentType === el.type && currentFilter === el.filter
                      ? true
                      : undefined
                  }
                  data-open={
                    el.items &&
                    el.items.length > 0 &&
                    dropdown.includes(`${index ? `${index}-` : ''}${key}`)
                      ? true
                      : undefined
                  }
                  onClick={this.setFilter({
                    type: el.type,
                    filter: el.filter,
                  })}
                >
                  {el.name}
                  {el.items && el.items.length > 0 && (
                    <span
                      onClick={this.toggleDropdown({
                        id: `${index ? `${index}-` : ''}${key}`,
                      })}
                    >
                      <i className='material-icons'>expand_more</i>
                    </span>
                  )}
                </Option>
              )}
              {el.items && el.items.length > 0 && (
                <InnerContent
                  data-open={
                    el.items &&
                    el.items.length > 0 &&
                    dropdown.includes(`${index ? `${index}-` : ''}${key}`)
                      ? true
                      : undefined
                  }
                  data-close={
                    el.items &&
                    el.items.length > 0 &&
                    !dropdown.includes(`${index ? `${index}-` : ''}${key}`)
                      ? true
                      : undefined
                  }
                >
                  {this.renderMenu({
                    items: el.items,
                    index: `${index ? `${index}-` : ''}${key}`,
                    authRole,
                  })}
                </InnerContent>
              )}
            </Item>
          )
      )
    );
  };

  render() {
    const { width } = this.state;
    const {
      theme,
      options,
      visible,
      open,
      handleToggleFilter,
      authRole,
    } = this.props;
    const isMobile = width <= Breakpoints.lg;
    return (
      <>
        {!isMobile || (visible && isMobile) ? (
          <Wrapper
            theme={theme.wrapper}
            data-open={open ? true : undefined}
            data-close={!open ? true : undefined}
          >
            <Content>
              {options &&
                this.renderMenu({
                  items: options,
                  authRole,
                })}
            </Content>
          </Wrapper>
        ) : (
          ''
        )}
        {visible && isMobile && (
          <Overlap
            theme={theme.overlap}
            data-open={open ? true : undefined}
            data-close={!open ? true : undefined}
            onClick={handleToggleFilter}
          />
        )}
      </>
    );
  }
}

export default injectIntl(Sider);
