import styled from 'styled-components';

import {
  Wrapper as commonWrapper,
  Content as commonContent,
  Panel as commonPanel,
  PanelInner as commonPanelInner,
  Title as commonTitle,
  Box as commonBox,
  List as commonList,
  Details as commonDetails
} from '../../assets/common/Content.style';

import { Information as commonInformation } from '../../assets/common/Information.style';

export const Wrapper = styled.div`
  ${commonWrapper}
`;

export const Content = styled.div`
  ${commonContent}
`;

export const Panel = styled.div`
  ${commonPanel}
`;

export const PanelInner = styled.div`
  ${commonPanelInner}
`;

export const Title = styled.div`
  ${commonTitle}
`;

export const Box = styled.div`
  ${commonBox}
`;

export const List = styled.div`
  ${commonList}
`;

export const Details = styled.div`
  ${commonDetails}
`;

export const Information = styled.div`
  ${commonInformation}
`;
