//TODO: NEED REFACTOR
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withTimeout from 'react-timeout';

import ProfileImage from '../../assets/images/ProfileImage.svg';

import { Theme } from '../../assets/Theme.style';

import {
  Photobox,
  Photoholder,
  Photo,
  Text,
  Input
} from './ProfilePhoto.style';

import PhotoCrop from '../PhotoCrop/PhotoCrop';

class ProfilePhoto extends Component {
  state = {
    sourcePhoto: null,
    photo: null,
    photoOptions: { width: 120, height: 120 },
    visibleModalPhotoCrop: false,
    openModalPhotoCrop: false,
    crop: { unit: 'px', width: 120, height: 120, aspect: 1 / 1 }
  };

  static propsTypes = {
    theme: PropTypes.object,
    input: PropTypes.object,
    profile: PropTypes.object,
    label: PropTypes.string,
    onSetPhotoData: PropTypes.func
  };

  static defaultProps = {
    theme: Theme.content.settingsPhoto,
    input: {},
    profile: {},
    label: 'Add Profile',
    onSetPhotoData: () => {}
  };

  selectFile = event => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({ sourcePhoto: reader.result });
        this.openModal('photoCrop');
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  setPhoto = photo => {
    const { input, onSetPhotoData } = this.props;
    this.setState({ photo: photo.tempPhoto });
    onSetPhotoData(photo.blob);
    input.onChange(photo.blob);
    this.closeModal();
  };

  changeCrop = crop => {
    this.setState({ crop });
  };

  openModal = param => {
    this.setState({
      visibleModalPhotoCrop: param === 'photoCrop' ? true : false,
      openModalPhotoCrop: param === 'photoCrop' ? true : false
    });
  };

  closeModal = () => {
    this.setState({
      openModalPhotoCrop: false
    });
    this.props.setTimeout(() => {
      this.setState({
        visibleModalPhotoCrop: false
      });
    }, 200);
  };

  render() {
    const {
      sourcePhoto,
      photo,
      visibleModalPhotoCrop,
      openModalPhotoCrop,
      crop,
      photoOptions
    } = this.state;
    const { theme, profile, label } = this.props;
    return (
      <>
        <Photobox>
          <Photoholder theme={theme.photo}>
            <Photo
              src={
                photo
                  ? photo
                  : profile && profile.photoURL
                  ? profile.photoURL
                  : ProfileImage
              }
              alt={
                photo ? photo : profile && profile.email ? profile.email : ''
              }
            />
          </Photoholder>

          <Text theme={theme.text}>{label}</Text>

          <Input
            type='file'
            accept='image/x-png,image/jpeg'
            onClick={event => {
              event.target.value = null;
            }}
            onChange={this.selectFile}
          />
        </Photobox>
        {visibleModalPhotoCrop && (
          <PhotoCrop
            theme={theme.modal}
            handleClose={this.closeModal}
            open={openModalPhotoCrop}
            sourcePhoto={sourcePhoto}
            photoOptions={photoOptions}
            crop={crop}
            handlePhoto={this.setPhoto}
            handleCropChange={this.changeCrop}
          />
        )}
      </>
    );
  }
}

export default withTimeout(ProfilePhoto);
