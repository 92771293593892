import styled from 'styled-components';

import { Media } from '../../assets/Mixins.style';

import {
  Box as commonBox,
  Item as commonItem,
  Panel as commonPanel,
  Header as commonHeader,
  Name as commonName,
  Menu as commonMenu,
  Button as commonButton,
  Image as commonImage,
  Title as commonTitle,
  Content as commonContent,
  Details as commonDetails,
  Additional as commonAdditional
} from '../../assets/common/Panel.style';

export const Box = styled.div`
  ${commonBox}
`;

export const Item = styled.div`
  ${commonItem}
`;

export const Panel = styled.div`
  ${commonPanel}
`;

export const Header = styled.div`
  ${commonHeader}
`;

export const Name = styled.h1`
  ${commonName}
`;

export const Menu = styled.div`
  ${commonMenu}
`;

export const Button = styled.span`
  ${commonButton}
`;

export const ButtonIcon = styled.img`
  ${commonImage}
`;

export const Content = styled.div`
  ${commonContent}
`;

export const Details = styled.div`
  ${commonDetails}
`;

export const Additional = styled.div`
  ${commonAdditional}
`;

export const Title = styled.h2`
  ${commonTitle}

  ${Details} & {
    margin-bottom: 0;
  }
`;

export const Columns = styled.div`
  display: flex;

  ${Media.lg`
    flex-wrap: wrap;
  `}
`;

export const ContentColumns = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Column = styled.div`
  display: block;
  width: 50%;

  &[data-center] {
    display: flex;
    align-items: center;
  }

  ${Media.lg`
    width: 100%;
    margin-bottom: 10px;
  `}

  &:last-of-type {
    ${Media.lg`
      margin-bottom: 0;
    `}
  }
`;

export const ContentColumn = styled.div`
  display: block;
  width: 50%;

  ${Media.lg`
    width: 100%;
  `}
`;

export const Imageholder = styled.div`
  display: flex;
  max-width: 250px;
  margin: 0 auto;
  flex-shrink: 0
  align-items: center;
  justify-content: center;
  border-radius: 0;
  overflow: hidden;

  ${Media.lg`
    margin: 0 auto 15px;
  `}
`;

export const Image = styled.img`
  display: none;
  max-width: 100%;
  max-height: 100%;

  &[data-visible] {
    display: block;
  }
`;
