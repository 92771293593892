import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { useIntl } from 'react-intl';

import SuccessIcon from '../../assets/images/Success.svg';
import ErrorIcon from '../../assets/images/Error.svg';

import { Theme } from '../../assets/Theme.style';

import 'react-datepicker/dist/react-datepicker.css';
import {
  Wrapper,
  Content,
  InputPairBox,
  Label,
  Selectbox,
  Select,
  Button,
  Alertbox,
  Alert,
  AlertImage,
  AlertContent,
  AlertTitle
} from './ApplicationSettings.style';

import { lang } from './lang';

// Set client configuration
const env = process.env.REACT_APP_CLIENT_ENV || 'development';
const config = require(`../../config.json`)[env];

function ApplicationSettings({
  theme = {
    ...Theme.content.settings,
    alert: Theme.alert,
    button: Theme.button
  },
  store = {},
  settings = {},
  onSubmit = () => {}
}) {
  const intl = useIntl();

  const validate = values => {
    const errors = {};

    if (!values.lang) errors.lang = 'APPLICATION_SETTINGS.VALIDATION.REQUIRED';

    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={{
        lang: settings.lang || config.lang
      }}
      mutators={{}}
      render={({ handleSubmit, submitting, pristine, values, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Wrapper>
            <Content>
              <InputPairBox>
                <Label>
                  {intl.formatMessage({
                    id: 'APPLICATION_SETTINGS.LANGUAGE'
                  })}
                </Label>
                <Field name='lang'>
                  {({ input, meta }) => (
                    <Selectbox theme={theme.selectbox.default}>
                      <Select
                        defaultValue={(input && input.value) || false}
                        theme={theme.selectbox.selectrix}
                        placeholder={intl.formatMessage({
                          id: 'APPLICATION_SETTINGS.LANGUAGE'
                        })}
                        arrow={false}
                        customScrollbar={true}
                        searchable={false}
                        options={
                          lang &&
                          lang.map(el => {
                            return {
                              ...el,
                              key: el.id,
                              label: el.name
                            };
                          })
                        }
                        onChange={value => {
                          input.onChange(value.id);
                          input.onBlur();
                        }}
                      />
                      <span>
                        {meta.touched &&
                          meta.error &&
                          intl.formatMessage({
                            id: meta.error
                          })}
                      </span>
                    </Selectbox>
                  )}
                </Field>
              </InputPairBox>

              {store.isError ? (
                <Alertbox>
                  {store.isError && (
                    <Alert>
                      <AlertImage
                        src={ErrorIcon}
                        alt={intl.formatMessage({
                          id: 'APPLICATION_SETTINGS.ALERT.SOMETHING_WENT_WRONG'
                        })}
                      />
                      <AlertContent>
                        <AlertTitle theme={theme.alert.error.title}>
                          {intl.formatMessage({
                            id:
                              'APPLICATION_SETTINGS.ALERT.SOMETHING_WENT_WRONG'
                          })}
                        </AlertTitle>
                        {store.error}
                      </AlertContent>
                    </Alert>
                  )}
                </Alertbox>
              ) : (
                store.isSuccess && (
                  <Alertbox>
                    <Alert>
                      <AlertImage
                        src={SuccessIcon}
                        alt={intl.formatMessage({
                          id: 'APPLICATION_SETTINGS.ALERT.SUCCESS'
                        })}
                      />
                      <AlertContent>
                        <AlertTitle theme={theme.alert.success.title}>
                          {intl.formatMessage({
                            id: 'APPLICATION_SETTINGS.ALERT.SUCCESS'
                          })}
                        </AlertTitle>
                        {intl.formatMessage({
                          id:
                            'APPLICATION_SETTINGS.ALERT.APPLICATION_SETTINGS_UPDATED'
                        })}
                      </AlertContent>
                    </Alert>
                  </Alertbox>
                )
              )}

              <Button
                disabled={
                  submitting ||
                  pristine ||
                  invalid ||
                  (store && store.isLoading)
                }
                theme={theme.button.default}
              >
                {store && store.isLoading
                  ? intl.formatMessage({
                      id: 'APPLICATION_SETTINGS.MENU.PLEASE_WAIT'
                    })
                  : intl.formatMessage({
                      id: 'APPLICATION_SETTINGS.MENU.SAVE_CHANGES'
                    })}
              </Button>
            </Content>
          </Wrapper>
        </form>
      )}
    />
  );
}

ApplicationSettings.propsTypes = {
  theme: PropTypes.object,
  store: PropTypes.object,
  settings: PropTypes.object,
  onSubmit: PropTypes.func
};

export default ApplicationSettings;
